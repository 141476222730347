import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconTasks = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.75 6.75v1.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2v-1.5a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2Zm0 9v1.5a2 2 0 0 0 2 2h1.5a2 2 0 0 0 2-2v-1.5a2 2 0 0 0-2-2h-1.5a2 2 0 0 0-2 2Zm9 0v1.5a2 2 0 0 0 2 2h1.5a2 2 0 0 0 2-2v-1.5a2 2 0 0 0-2-2h-1.5a2 2 0 0 0-2 2Z"
      />
    </SVGIcon>
  );
};

export { IconTasks };
