import React from 'react';
import { Plural, Trans } from '@lingui/macro';

import { Tag } from '@float/ui/components/Tag';
import { Tooltip } from '@float/ui/components/Tooltip';
import { IconDottedCircleLeft } from '@float/ui/icons/essentials/IconDottedCircleLeft';

import { useTeamMemberPhases } from './ProjectTeamSection.hooks';

import * as styles from './styles.css';

export const LimitedAccessTag: React.FC<{
  personId: number;
  index: number;
  isMemberOrPeopleManager?: boolean;
}> = ({ personId, index, isMemberOrPeopleManager }) => {
  return (
    <Tooltip
      content={
        <LimitedAccessTooltipMessage
          personId={personId}
          isMemberOrPeopleManager={isMemberOrPeopleManager}
        />
      }
    >
      <div className={styles.limitedAccessTagWrapper}>
        <Tag
          className={styles.limitedAccessTag}
          size="icon"
          data-testid={`person.${index}.limitedAccessTag`}
        >
          <IconDottedCircleLeft size={16} />
        </Tag>
      </div>
    </Tooltip>
  );
};

const LimitedAccessTooltipMessage: React.FC<{
  personId: number;
  isMemberOrPeopleManager?: boolean;
}> = ({ isMemberOrPeopleManager, personId }) => {
  const phases = useTeamMemberPhases(personId);
  return (
    <div className={styles.limitedAccessTooltipMessage}>
      <p>
        <Trans>
          {isMemberOrPeopleManager ? 'Can assign time' : 'Assigned'} on the
          following <Plural value={phases.length} one="phase" other="phases" />{' '}
          only:
        </Trans>
      </p>
      <ul className={styles.limitedAccessPhasesList}>
        {phases.map((p) => (
          <li key={p.phase_id} className={styles.limitedAccessPhasesListItem}>
            {p.phase_name}
          </li>
        ))}
      </ul>
    </div>
  );
};
