import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconFolderTag = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.25 19.25h-5.5a2 2 0 0 1-2-2v-9.5h12.5a2 2 0 0 1 2 2v2.5"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m13.5 7.5-.932-1.708a2 2 0 0 0-1.755-1.042H6.75a2 2 0 0 0-2 2V11m12.65 3.4h3v3l-2.77 2.759a.828.828 0 0 1-1.188-.02l-1.818-1.937a.827.827 0 0 1 .03-1.163L17.4 14.4Z"
      />
    </SVGIcon>
  );
};

export { IconFolderTag };
