import { useState } from 'react';
import { useUpdateEffect } from 'react-use';

export const LOADING_STATE_DELAY = 500;

/**
 * Delays the activation of the loading state
 * to give the feel of "istant loading" when data fetching lasts less than 500ms
 */
export function useDeferredLoadingState(isLoading: boolean) {
  const [loading, setLoading] = useState(isLoading);

  useUpdateEffect(() => {
    if (!isLoading) {
      setLoading(false);
      return;
    }

    const timeout = setTimeout(
      () => setLoading(isLoading),
      LOADING_STATE_DELAY,
    );

    return () => clearTimeout(timeout);
  }, [isLoading]);

  return loading;
}
