import React, { ChangeEvent, forwardRef } from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';

import { IconSearch } from '@float/ui/icons/essentials/IconSearch';

import * as styles from './styles.css';

type ListSearchFieldProps = {
  onValueChange: (value: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const ListSearchField = forwardRef<HTMLInputElement, ListSearchFieldProps>(
  (props, ref) => {
    const { onValueChange, className, ...rest } = props;

    function handleChange(evt: ChangeEvent<HTMLInputElement>) {
      onValueChange(evt.currentTarget.value);
    }

    return (
      <div className={cn(styles.listSearchField, className)}>
        <IconSearch className={styles.icon} size={20} />
        <input
          placeholder={t`Search`}
          onChange={handleChange}
          {...rest}
          ref={ref}
          className={styles.input}
        />
      </div>
    );
  },
);

export { ListSearchField };
