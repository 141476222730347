import { Transforms } from 'slate';

import { toNodesFrom } from './utils/nodes';

function LinkifyPlugin(editor) {
  const { isInline, insertText, insertNode } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    const children = toNodesFrom(text);

    if (Array.isArray(children)) {
      children.forEach((child) => {
        insertNode(child);

        if (child.type === 'link') {
          Transforms.move(editor, { unit: 'offset' });
        }
      });
    } else {
      insertText(children);
    }
  };

  return editor;
}

export default LinkifyPlugin;
