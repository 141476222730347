import { useMemo } from 'react';
import moment, { Moment } from 'moment';

import {
  TimeoffBalanceType,
  TimeoffPreviewMode,
} from '@float/types/timeoffType';

interface UseTimeoffPreviewDatesProps {
  timeoffBalanceType: TimeoffBalanceType;
  previewMode: TimeoffPreviewMode;
  previewAccruedEnd: string;
  endDate: string;
  personStartDate?: string;
}

/**
 * This hook determines the appropriate preview date and repeat end date for timeoff
 * calculations, taking into account the timeoff balance type, preview mode, and
 * other relevant dates.
 *
 * @param {Object} props - The input parameters for the hook.
 * @param {TimeoffBalanceType} props.timeoffBalanceType - The type of timeoff balance calculation.
 * @param {TimeoffPreviewMode} props.previewMode - The mode of preview (Today or Future).
 * @param {string} props.previewAccruedEnd - The end date for accrued preview calculations.
 * @param {string} props.endDate - The end date of the timeoff period.
 * @param {string} [props.personStartDate] - The start date of the person's employment (optional).
 *
 * @returns {Object} An object containing the calculated preview dates.
 * @returns {Moment | null} return.previewDate - The calculated preview date or null if it's unlimited type.
 * @returns {Moment | null} return.previewRepeatEndDate - The calculated repeat end date for preview or null if it's unlimited type.
 */

export const useTimeoffPreviewDates = ({
  timeoffBalanceType,
  previewMode,
  previewAccruedEnd,
  endDate,
  personStartDate,
}: UseTimeoffPreviewDatesProps) => {
  const [previewDate, previewRepeatEndDate] = useMemo<
    Array<Moment | null>
  >(() => {
    // skip calculations if timeoff balance type is unlimited
    if (timeoffBalanceType === TimeoffBalanceType.Unlimited)
      return [null, null];

    if (
      timeoffBalanceType === TimeoffBalanceType.AccruedPerYear ||
      timeoffBalanceType === TimeoffBalanceType.AccruedPerAnniversary
    ) {
      return previewMode === TimeoffPreviewMode.Today
        ? [moment().startOf('day'), moment().startOf('day')]
        : [moment(previewAccruedEnd), moment(previewAccruedEnd)];
    }

    const peopleStart = personStartDate
      ? moment(personStartDate)
      : moment().startOf('year');

    const peopleNextEndDate = moment(peopleStart)
      .set('year', moment(endDate).year() + 1)
      .subtract(1, 'day');

    return [moment(endDate), peopleNextEndDate];
  }, [
    timeoffBalanceType,
    previewMode,
    endDate,
    previewAccruedEnd,
    personStartDate,
  ]);

  return { previewDate, previewRepeatEndDate };
};
