export const FIN = {
  Dr: {
    Emphasis: {
      High: '#DBD3C6',
      Medium: '#BAA586',
      Low: '#b3aea6',
      Disabled: '#594C38',
      White: '#FFFFFF',
      Primary: '#D1A017',
    },
    Surface: {
      Surf2: '#333333',
      Surf9: '#171A1D',
    },
    Type: {
      OnSurface: {
        HighEmphasis: '#FFFFFF',
      },
    },
  },
  Lt: {
    Emphasis: {
      High: '#242C39',
      Medium: '#344765',
      Low: '#8290AA',
      Disabled: '#A6B3C7',
      White: '#FFFFFF',
      Primary: '#2E5FE8',
    },
    Surface: {
      Surf1: '#FFFFFF',
      Surf2: '#F8F7F9',
      Surf3: '#F3F2F5',
      Surf4: '#EDEBF0',
      Surf5: '#DCE2EB',
      Surf6: '#D6DEEB',
    },
    Stroke: {
      Stroke1: '#EDEBF0',
      Stroke2: '#DCE2EB',
      Stroke3: '#A6B3C7',
      Stroke4: '#637A9A',
    },
    Buttons: {
      Primary: {
        Default: {
          Bg: '#2E5FE8',
        },
        Hover: {
          Bg: '#254CBA',
        },
        Pressed: {
          Bg: '#173074',
        },
        Disabled: {
          Bg: '#F3F9FF',
          Label: '#A6B3C7',
        },
      },
      Secondary: {
        Default: {
          Bg: '#F3F2F5',
          Label: '#242C39',
        },
        Hover: {
          Bg: '#DCE2EB',
          Label: '#242C39',
        },
        Pressed: {
          Bg: '#D6DEEB',
          Label: '#242C39',
        },
        Disabled: {
          Bg: '#F8F7F9',
          Label: '#A6B3C7',
        },
      },
      SecondaryDark: {
        Default: {
          Bg: '#344765',
          Label: '#FFFFFF',
        },
        Hover: {
          Bg: '#242C39',
          Label: '#242C39',
        },
        Pressed: {
          Bg: '#13181F',
          Label: '#242C39',
        },
        Disabled: {
          Bg: '#A6B3C7',
          Label: '#FFFFFF',
        },
      },
      Tertiary: {
        Default: {
          Bg: '#EAF4FD',
          Label: '#2E5FE8',
        },
        Hover: {
          Bg: '#CEE7FE',
          Label: '#2E5FE8',
        },
        Pressed: {
          Bg: '#B7D9F8',
          Label: '#173074',
        },
        Disabled: {
          Bg: '#F3F9FF',
          Label: '#A6B3C7',
        },
      },
      Ghost: {
        Default: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#2E5FE8',
          Stroke: '#2E5FE8',
        },
        Hover: {
          Bg: '#CEE7FE',
          Label: '#2E5FE8',
          Stroke: '#2E5FE8',
        },
        Pressed: {
          Bg: '#B7D9F8',
          Label: '#173074',
          Stroke: '#173074',
        },
        Disabled: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#A6B3C7',
          Stroke: '#DCE2EB',
        },
      },
      Clear: {
        Default: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#2E5FE8',
        },
        Hover: {
          Bg: '#CEE7FE',
          Label: '#2E5FE8',
        },
        Pressed: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#173074',
        },
        Disabled: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#A6B3C7',
        },
      },
      ClearDanger: {
        Default: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#E5484D',
        },
        Hover: {
          Bg: '#FFEFEF',
          Label: '#DC3D43',
        },
        Pressed: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#CD2B31',
        },
        Disabled: {
          Bg: 'rgba(0,0,0,0)',
          Label: '#EB9091',
        },
      },
      Danger: {
        Default: {
          Bg: '#E5484D',
          Label: '#ffffff',
        },
        Hover: {
          Bg: '#DC3D43',
          Label: '#ffffff',
        },
        Pressed: {
          Bg: '#CD2B31',
          Label: '#ffffff',
        },
        Disabled: {
          Bg: '#FFEFEF',
          Label: '#EB9091',
        },
      },
    },
    Danger: {
      Default: '#DC3D43',
      Active: '#CD2B31',
    },
    Icon: {
      Brand: '#2E5FE8',
      Default: '#242C39',
      Subdued: '#617798',
    },
    Type: {
      OnSurface: {
        HighEmphasis: '#171A1D',
      },
    },
    Text: {
      Default: '#242C39',
      Subdued: '#617798',
    },
  },
} as const;

export const TCore = {
  Emp: {
    Medium11: '#344765',
    MainBlue9: '#2E5FE8',
    Low9: '#8290AA',
    High12: '#242C39',
  },
  Surface: {
    Surf1: '#FFFFFF',
    Surf2: '#F8F7F9',
  },
  Stroke: {
    Stroke2: '#D6DEEB',
    Stroke4: '#8290AA',
  },
} as const;

export const Core = {
  Lt: {
    Blue: {
      Primary: '#2E5FE8',
    },
    Emp: {
      Medium: '#344765',
      High: '#242C39',
    },
    Danger: {
      Default: '#DC3D43',
    },
  },
  ActionFlay: {
    Default: '#F3F2F5',
    Hovered: '#CFD8E5',
    Pressed: '#C0CAD7',
  },
  ActionFlue: {
    Pressed: '#B7D9F8',
  },
  Icon: {
    Default: '#242C39',
    Secondary: '#344765',
    Subdued: '#8290AA',
    Critical: '#9D1010',
  },
  Text: {
    Default: '#242C39',
    Secondary: '#344765',
    Subdued: '#617798',
    Flue: '#2E5FE8',
    Disabled: '#A6B3C7',
    Critical: '#CC1010',
    Learn: '#173074',
    Success: '#0B752E',
    Warning: '#BD4B00',
  },
  SurfaceBackground: {
    Default: '#F3F2F5',
  },
  Surface: {
    Primary: '#FFFFFF',
    Secondary: '#F8F7F9',
    Icon: '#DCE2EB',
    IconWhite: '#ECEBF0',
    Critical: '#FFEFEF',
    Learn: '#E1F0FF',
    Success: '#DCFAEC',
    Warning: '#FFF1E7',
  },
  Stroke: {
    ActionFlay: '#A6B3C7',
    Tertiary: '#BFCBDC',
    Critical: '#D82C0D',
    Learn: '#173074',
    Success: '#0B752E',
    Warning: '#BD4B00',
    Secondary: '#D6DEEB',
  },
} as const;

export const Alias = {
  Primary: {
    Flay: {
      5: '#DCE2EB',
      6: '#CFD8E5',
      7: '#BFCBDC',
    },
  },
} as const;

export const Primary = {
  Flay: {
    Light: {
      1: '#FFFFFF',
      2: '#F8F7F9',
      3: '#F3F2F5',
      4: '#EDEBF0',
      5: '#DCE2EB',
      6: '#D6DEEB',
      7: '#BFCBDC',
      8: '#A6B3C7',
      9: '#8290AA',
      10: '#637A9A',
      11: '#344765',
      12: '#242C39',
      13: '#13181F',
    },
  },
  Flue: {
    Light: {
      1: '#FDFDFE',
      2: '#F3F9FF',
      3: '#EAF4FD',
      4: '#E1F0FF',
      5: '#CEE7FE',
      6: '#B7D9F8',
      7: '#5EB1E7',
      8: '#1A90DC',
      9: '#2E5FE8',
      10: '#254CBA',
      11: '#173074',
      12: '#0E1C46',
    },
  },
} as const;

export const Radix = {
  Primary: {
    Flue: {
      3: '#EAF4FD',
      5: '#CEE7FE',
      7: '#99CEF0',
      11: '#173074',
    },
    Flay: {
      4: '#EDEBF0',
      5: '#DCE2EB',
      6: '#CFD8E5',
      7: '#BFCBDC',
    },
  },
  Secondary: {
    Orange: {
      4: '#FFE0BB',
      9: '#F76808',
    },
    Emerald: {
      4: '#C7F7E0',
      9: '#11AF45',
      10: '#0E9B24',
    },
  },
};

export const Grays = {
  Gray: {
    Light: {
      2: '#F8F8F8',
    },
  },
} as const;

export const Secondary = {
  Red: {
    Light: {
      1: '#FFFCFC',
      2: '#FFF8F8',
      3: '#FFEFEF',
      4: '#FFE5E5',
      5: '#FDD8D8',
      6: '#F9C6C6',
      7: '#F3AEAF',
      8: '#EB9091',
      9: '#E5484D',
      10: '#DC3D43',
      11: '#CD2B31',
      12: '#381316',
    },
  },
  Green: {
    Light: {
      1: '#FBFEFC',
      2: '#F2FCF5',
      3: '#E9F9EE',
      4: '#DDF3E4',
      5: '#CCEBD7',
      6: '#B4DFC4',
      7: '#92CEAC',
      8: '#5BB98C',
      9: '#30A46C',
      10: '#299764',
      11: '#18794E',
      12: '#153226',
    },
  },
  Yellow: {
    // TODO: earhart - These yellows are actually 2, 3, 4. Come back to
    // writing a cleaner way to name them accurately and use in Filters--
    Light: {
      4: '#FFFCE8',
      5: '#FFFBD1',
      6: '#FFF8BB',
      11: '#946800',
    },
  },
  Blue: {
    Light: {
      4: '#E1F0FF',
      5: '#CEE7FE',
      6: '#B7D9F8',
      11: '#006ADC',
    },
  },
  Orange: {
    Light: {
      2: '#FEF8F4',
      4: '#FFE8D7',
      5: '#FFDCC3',
      6: '#FFCCA7',
      11: '#BD4B00',
    },
  },
  Teal: {
    Light: {
      2: '#F1FCFA',
      4: '#D9F3EE',
      5: '#C7EBE5',
      6: '#AFDFD7',
      11: '#067A6F',
    },
  },
  Pink: {
    Light: {
      4: '#FCE5F3',
      5: '#F9D8EC',
      6: '#F3C6E2',
      11: '#CD1D8D',
    },
  },
  Tomato: {
    Light: {
      4: '#FFE6E2',
      5: '#FDD8D3',
      6: '#FAC7BE',
      11: '#CA3213',
    },
  },
  Gray: {
    Light: {
      4: '#F5F6F7',
      5: '#E8EAED',
      6: '#CDD1D5',
      11: '#6F6F6F',
    },
  },
  Violet: {
    Light: {
      4: '#EDE9FE',
      5: '#E4DEFC',
      6: '#D7CFF9',
      11: '#5746AF',
    },
  },
} as const;

export const Text = {
  TextRole: {
    Critical: '#9D1010',
  },
};

// Importing colors from the old theme file doesn't work for VE because it imports
// api's that require the navigator. Moving the ones that are needed to over here for now.
export const Legacy = {
  grey: '#E2E2E2',
  blueGrey: '',
};
