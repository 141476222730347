import React, { useRef } from 'react';

import { useRect } from '@float/common/lib/hooks/useRect';
import {
  PERSON_RELATED_KEYS,
  TYPE_TO_CATEGORY,
} from '@float/common/search/helpers';
import { ScrollArea } from '@float/ui/primitives/ScrollArea';
import { useScrollAreaState } from 'components/SearchFilterDropdown/hooks/useScrollAreaState';

import { SearchFilterDropdownData as data } from '../../data';
import { filterSearchCategories } from '../../helpers/filterSearchCategories';
import {
  SearchFilterDropdownCategoryItem,
  SearchFilterListProps,
} from '../../types';
import { CategoryItem } from './components/CategoryItem';
import { SeparatorItem } from './components/SeparatorItem';

import * as styles from './styles.css';

const Category = (
  props: SearchFilterListProps & { category: SearchFilterDropdownCategoryItem },
) => {
  const { currentCategoryType, category, onClick } = props;

  const isSelected = Boolean(
    currentCategoryType &&
      TYPE_TO_CATEGORY[currentCategoryType] === category.value,
  );

  return (
    <>
      <CategoryItem
        className={styles.item}
        category={category}
        onClick={onClick}
        selected={isSelected}
      />

      {category.showSeparatorAfter && (
        <SeparatorItem className={styles.separatorItem} />
      )}
    </>
  );
};

const SearchFilterList = (props: SearchFilterListProps) => {
  const { hasSavedFilters, hideMeFilter, hidePeopleFilter } = props;

  const categories = filterSearchCategories(data, PERSON_RELATED_KEYS, {
    hideMeFilter,
    hidePeopleFilter,
    hasSavedFilters,
  });

  const listRef = useRef<HTMLDivElement>(null);

  const rect = useRect(listRef);
  const scrollAreaState = useScrollAreaState(rect);

  return (
    <ScrollArea.Root
      className={styles.scrollAreaRoot}
      data-scrollbar-state={scrollAreaState.scrollbarState}
      style={{ height: scrollAreaState.height }}
      type="auto"
    >
      <ScrollArea.Content>
        <div ref={listRef} className={styles.list} role="list">
          {categories.map((value, index) => (
            <Category key={index} category={value} {...props} />
          ))}
        </div>
      </ScrollArea.Content>
    </ScrollArea.Root>
  );
};

export { SearchFilterList };
