import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { Resources } from './constants';
import { makeRequest, MakeRequestPagination } from './makeRequest';

export type RemoteEntityType =
  | 'client'
  | 'department'
  | 'manager'
  | 'people'
  | 'peopleTag'
  | 'peopleType'
  | 'role'
  | 'timeOffStatus'
  | 'project'
  | 'phase'
  | 'projectOwner'
  | 'projectTag'
  | 'projectStatus'
  | 'task'
  | 'timeOffType'
  | 'taskStatus';

// Not implemented yet
type EntityMeta = {
  parent_department_id?: null | number;
  isActive?: 0 | 1;
};

export type QueryApiResult = {
  id: number;
  name: string;
  type: RemoteEntityType;
  score: number;
  meta?: EntityMeta;
};

export type QueryApiParams = {
  query: string;
  filter?: {
    key: 'category';
    value: string;
  };
  pagination?: {
    page: number;
    pageSize: number;
  };
};

interface SearchResolveContainsFilter {
  operator: 'contains';
  field: null;
  value: string;
}

interface SearchResolveIsFilter {
  operator: 'is' | 'isNot';
  field: RemoteEntityType;
  value: number | number[];
}

export type SearchResolveFilter =
  | SearchResolveContainsFilter
  | SearchResolveIsFilter;

type SearchResolveLogicalOperator = 'and' | 'or';

export interface SearchResolveQuery {
  filters: (SearchResolveFilter | SearchResolveQuery)[];
  logicalOperator: SearchResolveLogicalOperator;
}

export type SearchResolveContext = 'schedule' | 'logged_time';

interface SearchResolveApiParams {
  query: SearchResolveQuery;
  context: SearchResolveContext;
}

export type SearchResolveEntityKey = keyof SearchResolveResponse['result'];

export interface SearchResolveResponse {
  contextId: string; // UUID
  result: {
    loggedTime: string[];
    people: number[];
    project: number[];
    task: number[];
    timeOff: number[];
  };
}

enum MatchAlgorithm {
  CONTAINS = 'contains',
  EXACT = 'exact',
  FULL_TEXT_EXACT = 'full_text_exact',
  FULL_TEXT_WILDCARD = 'full_text_wildcard',
}

const API_VERSION = '1.0.0';

function getQueryAlg() {
  return featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimitsNewAlg)
    ? MatchAlgorithm.CONTAINS
    : MatchAlgorithm.FULL_TEXT_WILDCARD;
}

function getLookupAlg() {
  return featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimitsNewAlg)
    ? MatchAlgorithm.EXACT
    : MatchAlgorithm.FULL_TEXT_EXACT;
}

export const search = {
  query: (data: QueryApiParams) => {
    return makeRequest<
      [QueryApiResult[], number, number],
      QueryApiParams & { alg: MatchAlgorithm }
    >({
      version: API_VERSION,
      resource: Resources.SearchAutocompleteQuery,
      method: 'POST',
      data: {
        ...data,
        alg: getQueryAlg(),
      },
      pagination: MakeRequestPagination.On,
    });
  },
  lookup: (value: string, category: RemoteEntityType) => {
    return makeRequest<
      QueryApiResult[],
      QueryApiParams & { alg: MatchAlgorithm }
    >({
      version: API_VERSION,
      resource: Resources.SearchAutocompleteQuery,
      method: 'POST',
      data: {
        query: value,
        filter: {
          key: 'category',
          value: category,
        },
        alg: getLookupAlg(),
      },
      pagination: MakeRequestPagination.Off,
    });
  },
  resolve: (data: SearchResolveApiParams) => {
    return makeRequest<SearchResolveResponse, typeof data>({
      version: API_VERSION,
      resource: Resources.SearchResolve,
      method: 'POST',
      data,
      pagination: MakeRequestPagination.Off,
    });
  },
};
