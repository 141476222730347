import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import { getClientsMap, getProjectsMap } from 'selectors';
import styled from 'styled-components';

import { FloatLogo } from '@float/ui/components/FloatLogo';

import ScheduleViewType from './ScheduleViewType';

function SharedLinkNav(props) {
  const { company, user, projects, departments, clients } = props;

  const suffix = useMemo(() => {
    const { shared_link_filters: filters } = user;
    if (isEmpty(filters)) {
      return '';
    }
    const suff = [];
    if (filters.client_id && clients[filters.client_id]) {
      suff.push(clients[filters.client_id].client_name);
    }
    if (filters.project_id && projects[filters.project_id]) {
      suff.push(projects[filters.project_id].project_name);
    }
    if (filters.department_id && departments[filters.department_id]) {
      suff.push(departments[filters.department_id].name);
    }
    return suff.join(' / ');
  }, [user, projects, departments, clients]);

  const StyledNav = styled.nav`
    background-color: #1a1d22;
    color: #fff;
    min-height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 9999;
    width: 100%;

    &,
    & * {
      box-sizing: border-box;
    }

    &.nav-settings {
      justify-content: flex-end;
      position: fixed;
      height: 60px !important;
    }

    @media screen and (max-width: 1010px) {
      padding: 0 10px;
    }
  `;

  const StyledTitle = styled.span`
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    margin-right: 50px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  `;

  const StyledNavRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  const StyledPlannedWith = styled.span`
    font-size: 16px;
    color: #a3a3a3;
  `;

  return (
    <StyledNav id="main-nav">
      <ScheduleViewType isSharedLink />
      <StyledNavRight>
        <StyledTitle>
          {company.company_name}
          {suffix ? ` / ${suffix}` : ''}
        </StyledTitle>
        <StyledPlannedWith>
          <Trans>Planned with</Trans>
        </StyledPlannedWith>
        <a
          href="https://www.float.com?utm_source=share_feature"
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: 10 }}
        >
          <FloatLogo color="primaryDark" height={20} />
        </a>
      </StyledNavRight>
    </StyledNav>
  );
}

const mapStateToProps = (state) => ({
  user: state.currentUser,
  company: state.companyPrefs,
  projects: getProjectsMap(state),
  clients: getClientsMap(state),
  departments: state.departments.departments,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SharedLinkNav);
