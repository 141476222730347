import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconChevronRight = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="m10.75 8.75 3.5 3.25-3.5 3.25"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);
