import { isArray } from 'lodash';

import { isNot } from '@float/common/search/helpers';
import { moment } from '@float/libs/moment';
import { FilterToken, ProjectStatus, TaskStatusEnum } from '@float/types';

import {
  ReportGlobalQueryDict,
  ReportGlobalQueryDictKey,
  ReportQueryField,
  ReportQueryOperator,
  ReportQuerySettings,
} from './types';

function buildTaskStatusQueryStr(
  filter: Partial<FilterToken>,
  settingsTaskStatus: string[],
) {
  const { operator = '', val = [] } = filter;
  const values = isArray(val) ? val : [val];

  const uniqueValues = values.length ? values : settingsTaskStatus;
  const numericValues = uniqueValues
    .map((ts) => {
      switch (ts) {
        case 'Tentative':
          return 1;
        case 'Confirmed':
        case 'Scheduled':
          return 2;
        case 'Completed':
          return 3;
        case 'Billable':
          return 4;
        case 'Non-Billable':
          return 5;
        default:
          return -1;
      }
    })
    .filter(Boolean);

  return {
    values: numericValues,
    operator: isNot(operator) ? 'nin' : 'in',
  } as const;
}

function buildTimeOffStatusQueryStr(
  filter: Partial<FilterToken>,
  settingsTaskStatus: string[],
) {
  const { operator = '', val = [] } = filter;
  const values = isArray(val) ? val : [val];

  const uniqueValues = values.length ? values : settingsTaskStatus;
  const numericValues = uniqueValues
    .map((ts) => {
      switch (ts) {
        case 'Declined':
          return -1;
        case 'Tentative':
          return 1;
        case 'Approved':
          return 2;
        case 'Confirmed':
        default:
          return 2;
      }
    })
    .filter(Boolean);

  return {
    values: numericValues,
    operator: isNot(operator) ? 'nin' : 'in',
  } as const;
}

export function parseTimeOffStatus(
  filter: Partial<FilterToken>,
  settingValues: string[] = [],
  timeoffApprovalsEnabled = false,
) {
  const result: ReportGlobalQueryDict = {};
  const { values, operator } = buildTimeOffStatusQueryStr(
    filter,
    settingValues,
  );

  if (timeoffApprovalsEnabled) {
    const timeOffStatusIds = values.filter(
      (id) => id === 1 || id === 2 || id === -1,
    );

    if (timeOffStatusIds.length && timeOffStatusIds.length < 3) {
      result.timeoff_status_id = {
        field: 'timeoff_status_id',
        operator,
        value: timeOffStatusIds,
      };
    }
  } else {
    const timeOffStatusIds = values.filter((id) => id === 1 || id === 2);

    if (timeOffStatusIds.length === 1) {
      result.timeoff_status_id = {
        field: 'timeoff_status_id',
        operator,
        value: timeOffStatusIds,
      };
    }
  }
  return result;
}

export function parseTaskStatus(
  filter: Partial<FilterToken>,
  settingValues: string[] = [],
  isDraftProject = false,
) {
  const result: ReportGlobalQueryDict = {};
  const { values, operator } = buildTaskStatusQueryStr(filter, settingValues);
  const taskStatusIds = values.filter((id) => id >= 0 && id <= 3);
  if (isDraftProject) {
    result.task_status_id = {
      field: 'task_status_id',
      operator,
      value: [TaskStatusEnum.Draft],
    };
  } else if (taskStatusIds.length && taskStatusIds.length < 3) {
    result.task_status_id = {
      field: 'task_status_id',
      operator,
      value: taskStatusIds,
    };
  } else {
    // If no task status is selected, and not viewing a singe (draft) project
    // report, we default to excluding draft tasks.
    result.task_status_id = {
      field: 'task_status_id',
      operator,
      value: [
        TaskStatusEnum.Tentative,
        TaskStatusEnum.Scheduled,
        TaskStatusEnum.Complete,
      ],
    };
  }
  const billableIds = values.filter((id) => id === 4 || id === 5);
  if (billableIds.length === 1) {
    result.is_task_billable = {
      field: 'is_task_billable',
      operator,
      value: billableIds.includes(4),
    };
  }
  return result;
}

export function toArray(val: string | string[]) {
  return Array.isArray(val) ? val : [val];
}

const PERSON_TYPE_TO_ID = {
  Employee: 1,
  Contractor: 2,
  Placeholder: 3,
};

const EMPLOYEE_TYPE_TO_ID = {
  'Full-time': 1,
  'Part-time': 0,
};

// Takes a filter value and translates it to ids
// using one of the above id maps
function mapToIds(
  val: FilterToken['val'] | undefined,
  map: Record<string, number>,
) {
  if (val === undefined) return [];

  const values = new Set<number>();

  for (const value of toArray(val)) {
    const id = map[value];

    if (id) {
      values.add(id);
    }
  }

  return Array.from(values);
}

function parsePersonTypeValues(
  filter: Partial<FilterToken>,
  activeTeamModes: number[],
) {
  const values = mapToIds(filter.val, PERSON_TYPE_TO_ID);

  return values.length
    ? values
    : activeTeamModes.map((teamMode) => teamMode + 1);
}

function parseEmployeeTypeValues(filter: Partial<FilterToken>) {
  return mapToIds(filter.val, EMPLOYEE_TYPE_TO_ID);
}

export function parsePersonType(
  filter: Partial<FilterToken>,
  settingValues: number[] = [],
) {
  const result: ReportGlobalQueryDict = {};

  // convert operator to expected value for API call
  const operator = filter?.operator?.includes('-') ? 'nin' : 'in';

  const peopleTypeIds = parsePersonTypeValues(filter, settingValues);
  if (peopleTypeIds.length && peopleTypeIds.length < 3) {
    result.people_type_id = {
      field: 'people_type_id',
      operator,
      value: peopleTypeIds,
    };
  }
  const peopleEmployeeTypeIds = parseEmployeeTypeValues(filter);
  if (peopleEmployeeTypeIds.length === 1) {
    result.people_employee_type = {
      field: 'people_employee_type',
      operator,
      value: peopleEmployeeTypeIds[0],
    };
  }
  return result;
}

export function parseProjectStatus(
  operator: ReportQueryOperator,
  val: string[],
): ReportQueryField {
  // val is always an array on one element
  const [rawValue] = val;
  if (rawValue === 'Mine') {
    return { field: 'project_creator_id', operator, value: 'me' };
  }
  if (rawValue === 'Non-billable') {
    return { field: 'is_project_non_billable', operator, value: true };
  }
  if (rawValue === 'Billable') {
    return { field: 'is_project_non_billable', operator, value: false };
  }
  if (rawValue === 'Tentative') {
    return { field: 'is_project_tentative', operator, value: true };
  }
  const field = 'is_project_active';
  const value = rawValue === 'Active';
  return { field, operator, value };
}

function parseGlobalQueries(settings: ReportQuerySettings, meFilter: boolean) {
  const globalQueries: ReportGlobalQueryDict = {};
  // compute task status and billable filter
  if (settings.taskStatus && settings.taskStatus.length > 0) {
    const isDraftProject = settings.project?.status === ProjectStatus.Draft;
    Object.assign(
      globalQueries,
      parseTaskStatus({}, settings.taskStatus, isDraftProject),
    );
  }

  if (settings.timeOffStatus && settings.timeOffStatus.length > 0) {
    Object.assign(
      globalQueries,
      parseTimeOffStatus(
        {},
        settings.timeOffStatus,
        settings.timeoffApprovalsEnabled,
      ),
    );
  }

  // compute people and employee types
  if (settings.activeTeamModes && settings.activeTeamModes.length) {
    Object.assign(globalQueries, parsePersonType({}, settings.activeTeamModes));
  }
  // compute person active
  if (settings.activeMode! <= 1) {
    globalQueries.is_people_active = {
      field: 'is_people_active',
      operator: 'in',
      value: Boolean(+settings.activeMode!),
    };
  }
  // compute me filter - filter people ids
  if (meFilter) {
    globalQueries.people_ids = {
      field: 'people_id',
      operator: 'in',
      value: ['me'],
    };
  }
  return globalQueries;
}

export function addGlobalFilters(
  queries: ReportQueryField[][],
  settings: ReportQuerySettings,
  meFilter: boolean,
) {
  const globalQueries = parseGlobalQueries(settings, meFilter);

  // if no filter applied, use global filters directly
  if (!queries.length) {
    queries.push(Object.values(globalQueries));
  } else {
    const globalQueriesKeys = Object.keys(globalQueries);

    // apply global filters to each "or" block
    queries.forEach((andConditions) => {
      const nonConflictingKeys = new Set(globalQueriesKeys);

      andConditions.forEach(({ field }) => {
        // filters on andConditions overwrites global filters
        // so we remove all the possible conflicts
        if (nonConflictingKeys.has(field)) {
          nonConflictingKeys.delete(field);
        }
      });

      for (const key of nonConflictingKeys) {
        const filter = globalQueries[key as ReportGlobalQueryDictKey];

        if (filter !== undefined) {
          andConditions.push(filter);
        }
      }
    });
  }
}

function dateToMDY(date: string | Date) {
  return moment(date).format('MM-DD-YYYY');
}

export function getAsQuerystringTuple(
  queries: ReportQueryField[][],
  settings: ReportQuerySettings,
) {
  return [
    ['end_date', dateToMDY(settings.endDate)],
    ['start_date', dateToMDY(settings.startDate)],
    ['query', JSON.stringify(queries)],
  ];
}
