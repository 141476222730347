import React from 'react';
import TaskModalActivity from 'activity/components/TaskModalActivity';
import { getClientsMap, getPhasesMapRaw, getProjectsMap } from 'selectors';

import { TaskNameLabel } from '@float/common/components/TaskNameSelect';
import { displayHoursFormatIsTime } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { Modal, ReadOnlyField, RichText } from '@float/ui/deprecated';
import { NoTaskAllocation } from 'components/elements/NoTaskAllocation';

const readOnlyProps = { wrapperStyle: { marginBottom: 21 } };

export const ReadOnlyLogTimeProjectPhase = ({ entity }) => {
  const projects = useAppSelector(getProjectsMap);
  const clients = useAppSelector(getClientsMap);
  const phases = useAppSelector(getPhasesMapRaw);

  const project = projects[entity.project_id];

  let projectName = 'No project';

  if (project) {
    projectName = project.project_name;

    if (!project.active) {
      projectName += ' (Archived)';
    }
    if (project.client_id) {
      projectName = `${
        clients[project.client_id].client_name
      } / ${projectName}`;
    }
  }

  const phase = phases[entity.phase_id];
  let phaseName = null;

  if (phase) {
    phaseName = phase.phase_name || 'No phase';

    if (phaseName && !phase.active) {
      phaseName += ' (Archived)';
      phaseName;
    }
  }

  if (phaseName) {
    return (
      <>
        <ReadOnlyField label="Client / Project" value={projectName} />
        <ReadOnlyField
          type="phase"
          value={phaseName}
          style={{ marginTop: 5, marginBottom: 21 }}
        />
      </>
    );
  }

  return (
    <ReadOnlyField
      label="Client / Project"
      value={projectName}
      {...readOnlyProps}
    />
  );
};

export const ReadOnlyLogTimeTaskField = ({ taskName, projectId, style }) => {
  const projects = useAppSelector(getProjectsMap);

  const project = projects[projectId];
  const isLocked = project?.locked_task_list === 1;

  if (!taskName) {
    return null;
  }

  return (
    <ReadOnlyField
      label={<TaskNameLabel locked={isLocked} />}
      value={taskName || <NoTaskAllocation />}
      {...readOnlyProps}
      wrapperStyle={style}
    />
  );
};

function LogTimeModalReadOnly(props) {
  const { manageModal, modalSettings, user } = props;
  const { entity } = modalSettings;

  const displayAsClockTime = displayHoursFormatIsTime(user.prefs);
  const hours = displayAsClockTime
    ? formatDecimalHoursAsClockTime(entity.hours || 0)
    : entity.hours;

  const onClose = () => {
    manageModal({
      visible: false,
      modalType: 'logTimeModal',
    });
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Log time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 140, marginRight: 20 }}>
            <ReadOnlyField label="Total Hrs" value={hours} {...readOnlyProps} />
          </div>
          <div style={{ width: 140 }}>
            <ReadOnlyField
              label="Date"
              value={moment(entity.date).format('DD MMM YYYY')}
              {...readOnlyProps}
            />
          </div>
        </div>
        <ReadOnlyLogTimeProjectPhase entity={entity} />
        <ReadOnlyLogTimeTaskField
          taskName={entity.task_name}
          projectId={entity.project_id}
          style={{ marginBottom: 21 }}
        />
        <RichText label="Notes" value={entity.notes} readOnly />
        <TaskModalActivity
          task={entity}
          composed={false}
          style={{ margin: '30px 0' }}
        />
      </Modal.Body>
    </Modal>
  );
}

export { LogTimeModalReadOnly };
