import { useEffect, useRef, useState } from 'react';

import { fetchTaskMetas as fetchTaskMetasAction } from '@float/common/actions/taskMetas';
import { ReduxState } from '@float/common/reducers/lib/types';
import { getTaskMetasOptions } from '@float/common/selectors/taskMetas';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { useControllableState } from '@float/libs/hooks/useControllableState';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { TaskMeta } from '@float/types';

export type UseTaskMetasState = {
  taskMetaId: number | null;
  taskName: string;
};

export type UseTaskMetasParams = {
  controlledState?: UseTaskMetasState;
  entity: { task_name: string | null; task_meta_id: number | null } | null;
  onChange?: (state: UseTaskMetasState) => void;
  phaseId: number;
  projectId?: number;
};

export function useTaskMetas(params: UseTaskMetasParams) {
  const { entity, phaseId, projectId, controlledState, onChange } = params;
  const storeDispatch = useAppDispatch();

  const taskMetasLoadState = useAppSelector(
    (state: ReduxState) => state.taskMetas.loadState,
  );

  async function fetchTaskMetas(newProjectId: number | undefined) {
    if (newProjectId === undefined) return setLoading(null);

    const notCached = taskMetasLoadState[newProjectId] !== 'LOADED';

    setLoading(
      notCached ? { prevProjectId: projectId, prevPhaseId: phaseId } : null,
    );
    await storeDispatch(fetchTaskMetasAction(newProjectId));
    setLoading(null);
  }

  useOnMount(() => {
    fetchTaskMetas(projectId);
  });

  const originalTaskName = useRef(entity?.task_name || null).current;

  const [state, setState] = useControllableState<UseTaskMetasState>({
    prop: controlledState,
    defaultProp: {
      taskMetaId: entity?.task_meta_id || null,
      taskName: entity?.task_name || '',
    },
    onChange,
  });

  const [forceShowTaskField, setForceShowTaskField] = useState(false);

  function resetState() {
    setState({
      taskMetaId: null,
      taskName: '',
    });
    setForceShowTaskField(false);
  }

  function resetTask() {
    setState({
      taskMetaId: null,
      taskName: '',
    });
  }

  function updateTask(taskMetaId: number | null, taskName: string) {
    setState({
      taskMetaId,
      taskName,
    });
  }

  function updateTaskName(taskName: string) {
    setState({
      taskMetaId: null,
      taskName,
    });
  }

  const [loading, setLoading] = useState<{
    prevProjectId?: number;
    prevPhaseId: number;
  } | null>(() => {
    if (taskMetasLoadState[projectId!] === 'LOADED') {
      return null;
    }

    return {
      prevPhaseId: phaseId,
      prevProjectId: projectId,
    };
  });

  const taskMetas = useAppSelector((state: ReduxState) =>
    getTaskMetasOptions(
      state.taskMetas.taskMetas,
      // While the taskMetas for the current project are loading,
      // we continue to use the taskMetas from the previous project
      // in order to skip the default empty state we would have during the load
      // Related issue: https://linear.app/float-com/issue/FT-1454/loading-state-on-project-selection
      loading ? loading.prevProjectId : projectId,
      loading ? loading.prevPhaseId : phaseId,
      originalTaskName,
    ),
  );

  const lastDeletedTaskMetaIds = useAppSelector(
    (state: ReduxState) => state.taskMetas.lastDeletedIds,
  );

  // Handle the effects of the live updates on the current selection
  useEffect(() => {
    if (!lastDeletedTaskMetaIds) return;
    if (!state.taskMetaId) return;
    if (!lastDeletedTaskMetaIds.has(state.taskMetaId)) return;

    resetTask();

    // This effect should be triggered only on lastDeletedTaskMetaIds change
    // to catch changes coming from the live updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDeletedTaskMetaIds]);

  const hasTaskOptions = Boolean(
    taskMetas && taskMetas.filter((meta) => meta.task_name).length > 0,
  );

  const showEditTaskField = hasTaskOptions || forceShowTaskField;

  function handleTaskInputChange(value: string) {
    updateTaskName(value);
  }

  function handleTaskChange(value: {
    taskMetaId: number | null;
    taskName: string;
  }) {
    updateTask(value.taskMetaId, value.taskName);
  }

  async function handleProjectChange(newProjectId: number) {
    if (projectId !== newProjectId) {
      resetState();
      await fetchTaskMetas(newProjectId);
    }
  }

  function handlePhaseChange(newPhaseId: number) {
    if (phaseId !== newPhaseId) {
      resetState();
    }
  }

  function handleForceShowTaskField(enabled: boolean) {
    setForceShowTaskField(enabled);
  }

  return {
    taskName: state.taskName,
    taskMetaId: state.taskMetaId,
    data: taskMetas as TaskMeta[],
    showEditTaskField,
    showNoTaskLine: !showEditTaskField && !loading,
    loading: Boolean(loading),
    forceShowTaskField,
    onTaskInputChange: handleTaskInputChange,
    onTaskChange: handleTaskChange,
    onProjectChange: handleProjectChange,
    onPhaseChange: handlePhaseChange,
    onForceShowTaskField: handleForceShowTaskField,
  };
}
