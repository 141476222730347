import { search, SearchResolveFilter } from '@float/common/api3/search';
import { CurrentUser } from '@float/types/account';
import { BaseFilterToken } from '@float/types/view';

import { isNot } from '../../helpers';
import { FILTER_TYPE_TO_REMOTE_TYPE } from '../constants';

function toArray(val: string | string[]) {
  return Array.isArray(val) ? val : [val];
}

export async function convertFilterTokenToSearchResolveQuery(
  filter: BaseFilterToken,
  user: Pick<CurrentUser, 'people_id' | 'account_id'>,
): Promise<SearchResolveFilter[] | null> {
  const values = toArray(filter.val);

  if (filter.type === 'me') {
    if (user.people_id === null) return null;

    return [
      {
        field: 'people',
        operator: 'is',
        value: user.people_id,
      },
    ];
  }

  if (filter.type === 'contains') {
    return values.map((value) => ({
      field: null,
      operator: 'contains',
      value,
    }));
  }

  const field = FILTER_TYPE_TO_REMOTE_TYPE[filter.type];
  const operator: SearchResolveFilter['operator'] = isNot(filter.operator)
    ? 'isNot'
    : 'is';

  const result = values.map(async (value) => {
    if (filter.type === 'projectOwner' && value === 'Mine') {
      return {
        field: 'projectOwner' as const,
        operator,
        value: user.account_id,
      };
    }

    // TODO: Implement SWR caching and use the exact-match algorithm when available
    // https://linear.app/float-com/issue/PS-1448/discovery-handle-the-label-ids-lookup-before-calling-resolve
    const items = await search.lookup(value, field);

    return {
      field,
      operator,
      value: items.map((item) => item.id),
    };
  });

  return Promise.all(result);
}
