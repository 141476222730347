/* eslint-disable no-use-before-define, check-file/filename-naming-convention */
import { cloneDeep, filter, forEach, isUndefined, union } from 'lodash';

import { isFullDayTimeoff as _isFullDayTimeoff } from '@float/common/lib/timeoffs';

import { fastObjectSpread } from '../../../lib/fast-object-spread';
import { entityEditable, entityLoggable } from '../../../lib/rights';
import { getDownstreamLinkedTasks } from '../../util/linkedTasks';
import { isDateInLockPeriod } from '../../util/lockPeriod';
import { now } from '../../util/timer';
import { buildLogTimeCell } from './buildLogTimeCell';
import { buildPersonCell } from './buildPersonCell';
import { buildProjectCell } from './buildProjectCell';
import { buildTopCell } from './buildTopCell';
import { createBuildCells } from './helpers/createBuildCells';
import { createGetApplicableCellsKeys } from './helpers/createGetApplicableCellsKeys';
import { getCellKeyRowId } from './helpers/getCellKeyRowId';
import { shouldIgnoreMissing } from './helpers/shouldIgnoreMissing';

// Note: JavaScript Symbols are not serializable across web workers, so we
// use strings instead.
export const WORK_DAY = 'float-symbol/WORK_DAY_WORK_DAY';
export const NON_WORK_DAY = 'float-symbol/WORK_DAY_NON_WORK_DAY';

const ID_PROPS = {
  task: 'task_id',
  timeoff: 'timeoff_id',
  oneOff: 'oneoff_id',
  holiday: 'holiday_id',
  milestone: 'milestone_id',
  phase: 'phase_id',
  status: 'status_id',
  loggedTime: 'logged_time_id',
  timeRange: 'time_range_id',
};

function isPeopleSuperset(superEntity, subEntity) {
  const subPeopleIds = subEntity.people_ids ?? [subEntity.people_id];

  if (!superEntity.people_ids) throw Error('Missing people_ids');

  for (let i = 0; i < subPeopleIds.length; i++) {
    const id = subPeopleIds[i];
    if (!superEntity.people_ids.includes(id)) return false;
  }

  return true;
}

function overlapsDays(a, b) {
  return !(a.end_date < b.start_date || b.end_date < a.start_date);
}

export default function createCellHelpers(props) {
  const {
    dates,
    maps,
    bimaps,
    rowMetas,
    leftHiddenDays,
    rightHiddenDays,
    numVisibleWeeks,
    logTimeView,
    timeIncrementUnit,
    hasTimeTracking,
  } = props;

  const {
    task: tasksMap,
    timeoff: timeoffsMap,
    oneOff: oneOffsMap,
    holiday: holidaysMap,
    loggedTime: loggedTimeMap,
  } = maps;

  const {
    task: tasksBimap,
    timeoff: timeoffsBimap,
    oneOff: oneOffsBimap,
    holiday: holidaysBimap,
    loggedTime: loggedTimeBimap,
  } = bimaps;

  const buildCells = createBuildCells(
    {
      bimaps,
      maps,
      rowMetas,
      numVisibleWeeks,
      timeIncrementUnit,
    },
    {
      buildCell,
      calcHeight,
      calcWorkDays,
    },
  );

  const getApplicableCellKeys = createGetApplicableCellsKeys({
    hasTimeTracking,
    dates,
    rowMetas,
  });

  function loadData(cells, action) {
    const { dataType, data, ...rest } = action;
    const convertedAction = { ...rest, dataMap: { [dataType]: data } };
    return loadBulkData(cells, convertedAction);
  }

  function loadBulkData(cells, action) {
    const { dataMap } = action;
    // loadData is called anytime the relevant array from Redux is
    // changed, which happens on entity updates as well as loading new
    // chunks of data while scrolling. We want to handle this in a way that
    // minimizes re-renders, so we:
    //  1) Find which entities we have that are no longer valid, delete them
    //  2) Find new/updated entities, add/replace them
    //  3) Rebuild cells that were affected

    // Note that loadData is called during the LOAD_DATA reducer event and also
    // when using the ETM to create a new task/timeoff via the loadChanges
    // method called from the persistence pipeline. In this scenario, a
    // re-render will not be triggered.

    const affectedCellKeys = [];

    function addAffectedCellKeys(keys) {
      // Using for..of to avoid to incur with "RangeError: Maximum call stack size exceeded"
      for (const key of keys) {
        affectedCellKeys.push(key);
      }
    }

    if (action.additionalKeysToRebuild) {
      addAffectedCellKeys(action.additionalKeysToRebuild);
    }

    const dataTypesAffected = {};
    forEach(dataMap, (data, dataType) => {
      if (!data) {
        return;
      }

      dataTypesAffected[dataType] = true;
      const map = maps[dataType];
      const bimap = bimaps[dataType];
      const idProp = ID_PROPS[dataType];

      if (!shouldIgnoreMissing(action, dataType)) {
        Object.values(map).forEach((entity) => {
          // TODO the loggedTime map contains also the task references entites and entity[idProp] in that case returns undefined
          if (!entity.temporaryId && !data[entity[idProp]]) {
            delete map[entity[idProp]];
            addAffectedCellKeys(bimap.delete(entity[idProp]));
          }
        });
      }

      if (action.isRemove && action.id) {
        delete map[action.id];
        addAffectedCellKeys(bimap.delete(action.id));
      }

      Object.values(data).forEach((entity) => {
        if (!entity) return;

        if (entity.replacesTempId) {
          delete map[entity.replacesTempId];
          addAffectedCellKeys(bimap.delete(entity.replacesTempId));
        }

        const isRenderable =
          entity &&
          ((entity.task_id && entity.start_date) ||
            (entity.timeoff_id && entity.start_date) ||
            (entity.oneoff_id && entity.date) ||
            (entity.holiday_id && entity.date) ||
            (entity.milestone_id && entity.date) ||
            (dataType === 'phase' &&
              entity.phase_id &&
              entity.start_date &&
              entity.end_date) ||
            (dataType === 'project' && entity.active) ||
            (entity.status_id && entity.start_date) ||
            (entity.logged_time_id && entity.date) ||
            entity.time_range_id);

        if (!isRenderable) return;
        if (entity._dragInProgress) return;

        if (map[entity[idProp]] !== entity || action.forceLoad) {
          // Add the current entity's cell keys to the affected array in case
          // a network update shortens a task and causes it to no longer need
          // a cell - we still have to re-render that one to clear out
          // anything that previously existed.
          const prevKeys = bimap.getFwd(entity[idProp]);
          addAffectedCellKeys(prevKeys);

          // If a Redux reducer merges new state on top of new state, we might
          // end up with incorrect values. Delete them here, and they'll get
          // recomputed later on.
          if (!entity.isSidebarItem) {
            // @entity.length
            delete entity.length;
          }

          delete entity.repeatInstances;
          delete entity.allInstances;
          delete entity._allInstancesMemoKey;
          delete entity._linkedTasks;
          delete entity._downstreamLinkedTasks;

          if (entity.repeat_state) {
            createRepeatInstances(entity);
          }

          const cellKeys = getApplicableCellKeys(entity);

          // Eagerly build the allInstances array. It's necessary to do this
          // here because we call loadData within the persistence validation
          // pipeline and it uses data from allInstances.
          getAllInstances(entity);

          addAffectedCellKeys(cellKeys);
          map[entity[idProp]] = entity;
          bimap.replace(entity[idProp], cellKeys);
        }
      });
    });

    buildCells(cells, affectedCellKeys, {
      dataTypesAffected,
      lazyProcessing: action.lazyProcessing,
    });
  }

  function isOneOffDay(oneOffs, loggedTimes, day) {
    for (let i = 0; i < oneOffs.length; i++) {
      const oo = oneOffsMap[oneOffs[i]];
      if (oo.start_date <= day && oo.end_date >= day) {
        return true;
      }
    }
    for (let i = 0; i < loggedTimes.length; i++) {
      const lt = loggedTimeMap[loggedTimes[i]];
      if (!lt.isTaskReference && lt.date == day && lt.hours > 0) {
        return true;
      }
    }
    return false;
  }

  function isHoliday(holidays, timeoffs, day) {
    for (let i = 0; i < holidays.length; i++) {
      const h = holidaysMap[holidays[i]];
      if (h.start_date <= day && h.end_date >= day) {
        return true;
      }
    }

    for (let i = 0; i < timeoffs.length; i++) {
      const t = timeoffsMap[timeoffs[i]];
      if (t.region_holiday_id && t.start_date <= day && t.end_date >= day) {
        return true;
      }
    }

    return false;
  }

  function getFullDayTimeoff(cellKey, day) {
    const timeoffs = timeoffsBimap.getRev(cellKey);
    for (let i = 0; i < timeoffs.length; i++) {
      const instances = getAllInstances(timeoffsMap[timeoffs[i]]);
      for (let j = 0; j < instances.length; j++) {
        const to = instances[j];
        if (
          _isFullDayTimeoff(to) &&
          to.start_date <= day &&
          to.end_date >= day
        ) {
          return to;
        }
      }
    }
    return null;
  }

  function isEntityFullDayTimeoff(entity) {
    return (
      entity.full_day ||
      (entity.hours === getMinWorkHoursInRange(entity) && entity.status !== 1)
    );
  }

  function isFullDayTimeoff(cells, rowId, day) {
    const [colIdx] = dates.toDescriptor(day);
    const cellKey = `${rowId}:${colIdx}`;
    return !!getFullDayTimeoff(cellKey, day);
  }

  function isLoggedTimeWorkDay(cells, rowId, day) {
    // Log Time view doesn't use oneoff days that are persisted to the
    // database. Instead, we calculate it dynamically to support the
    // combinations of LoggedTimes/TaskReferences in conjuncton with non work
    // days and holidays.
    //
    // This method is like isWorkDay below, but it doesn't leverage the
    // workDays cache array on the cell as that array factors in oneoffs, which
    // need to to be ignored. This is less performant, but since it's only used
    // in Log Time view, the impact is minimal.
    //
    // Note that we still do want the version that considers oneoffs to be
    // built into rowMetas - we have to generate task references in accordance
    // to the oneoffs that exist on schedule.
    const [colIdx, subCol] = dates.toDescriptor(day);
    const cellKey = `${rowId}:${colIdx}`;
    const cell = cells[cellKey];

    const meta = rowMetas.get(rowId);
    if (!meta) return true;
    if (meta.start && meta.start > day) return false;
    if (meta.end && meta.end < day) return false;

    if (!cell) {
      return meta.getDailyWorkHours(day)[subCol] > 0;
    }

    const holidays = holidaysBimap.getRev(cellKey);
    const timeoffs = timeoffsBimap.getRev(cellKey);

    return (
      !isHoliday(holidays, timeoffs, day) &&
      meta.getDailyWorkHours(day)[subCol] > 0
    );
  }

  function isWorkDay(cells, rowId, day, ignoreTimeoff) {
    if (rowId === '_company') return isCompanyWorkDay(day);

    const [colIdx, subCol] = dates.toDescriptor(day);

    const meta = rowMetas.get(rowId);
    if (!meta) return true;
    if (meta.start_date && meta.start_date > day) return false;
    if (meta.end_date && meta.end_date < day) return false;

    const cellKey = `${rowId}:${colIdx}`;
    const cell = cells[cellKey];

    if (!cell) {
      return meta.getDailyWorkHours(day)[subCol] > 0;
    }

    if (cell.workDays[subCol] === WORK_DAY) return true;
    if (cell.workDays[subCol] === NON_WORK_DAY) return false;
    if (!ignoreTimeoff) return false;

    if (ignoreTimeoff.full_day) return true;

    return cell.workDays[subCol] === ignoreTimeoff.timeoff_id;
  }

  function isCompanyWorkDay(day) {
    const [colIdx, subCol] = dates.toDescriptor(day);
    const cellKey = `top:${colIdx}`;

    const holidays = holidaysBimap.getRev(cellKey);
    if (isHoliday(holidays, [], day)) return false;

    return rowMetas.get('_company').getDailyWorkHours(day)[subCol] > 0;
  }

  function countWorkDays(cells, rowId, start, end, ignoreTimeoff) {
    let days = 0;

    const startNum = dates.toNum(start);
    const endNum = dates.toNum(end);

    for (let i = startNum; i < endNum; i++) {
      if (isWorkDay(cells, rowId, dates.fromNum(i), ignoreTimeoff)) {
        days++;
      }
    }

    return days;
  }

  function countNonWorks(cells, rowId, start, end, ignoreTimeoff) {
    let nonWorks = 0;

    const startNum = dates.toNum(start);
    const endNum = dates.toNum(end);

    for (let i = startNum; i <= endNum; i++) {
      if (!isWorkDay(cells, rowId, dates.fromNum(i), ignoreTimeoff)) {
        nonWorks++;
      }
    }

    return nonWorks;
  }

  function findNthWorkDay(cells, rowId, start, delta, ignoreTimeoff) {
    const meta = rowMetas.get(rowId);
    const direction = delta >= 0 ? 'R' : 'L';

    while (delta !== 0) {
      start = dates.addDays(start, direction === 'R' ? 1 : -1);
      if (
        (meta.start_date && meta.start_date > start) ||
        (meta.end_date && meta.end_date < start)
      ) {
        throw Error('PERSON_DATE_OUT_OF_RANGE');
      }

      if (isWorkDay(cells, rowId, start, ignoreTimeoff)) {
        delta += direction === 'R' ? -1 : 1;
      }
    }

    return start;
  }

  function calcWorkDays(rowId, cell) {
    const { colIdx } = cell;
    const cellKey = `${rowId}:${colIdx}`;

    const oneOffs = oneOffsBimap.getRev(cellKey);
    const loggedTimes = logTimeView ? loggedTimeBimap.getRev(cellKey) : [];
    const holidays = holidaysBimap.getRev(cellKey);
    const timeoffs = timeoffsBimap.getRev(cellKey);

    for (let d = 0; d < 7 - leftHiddenDays - rightHiddenDays; d++) {
      const day = dates.fromNum(colIdx * 7 + d + leftHiddenDays);
      let workDay = WORK_DAY;

      const fullDayTimeoff = getFullDayTimeoff(cellKey, day);
      if (!isOneOffDay(oneOffs, loggedTimes, day) && fullDayTimeoff) {
        workDay = fullDayTimeoff.timeoff_id;
      }

      if (
        !isOneOffDay(oneOffs, loggedTimes, day) &&
        (isHoliday(holidays, timeoffs, day) ||
          rowMetas.get(rowId)?.getDailyWorkHours(day)[d] === 0)
      ) {
        workDay = NON_WORK_DAY;
      }

      cell.workDays[d] = workDay;
    }
  }

  function firstWorkDay(cells, rowId, colIdx, ignoreTimeoff) {
    return findWorkDay(
      cells,
      rowId,
      dates.fromNum(colIdx * 7),
      'R',
      ignoreTimeoff,
    );
  }

  function findWorkDay(
    cells,
    rowId,
    start,
    direction,
    ignoreTimeoff,
    logTimeView,
    entity,
    lockPeriodDates,
  ) {
    const meta = rowMetas.get(rowId);

    while (!isWorkDay(cells, rowId, start, ignoreTimeoff)) {
      start = dates.addDays(start, direction === 'R' ? 1 : -1);
      if (
        (meta.start_date && meta.start_date > start) ||
        (meta.end_date && meta.end_date < start)
      ) {
        throw Error('PERSON_DATE_OUT_OF_RANGE');
      }
    }

    if (logTimeView) {
      let logTimeCellAccess = hasLogTimeCellAccess(
        start,
        entity,
        cells,
        lockPeriodDates,
      );

      while (!logTimeCellAccess) {
        start = dates.addDays(start, 1);

        logTimeCellAccess = hasLogTimeCellAccess(
          start,
          entity,
          cells,
          lockPeriodDates,
        );
      }
    }

    return start;
  }

  function hasLogTimeCellAccess(date, entity, cells, lockPeriodDates) {
    const isInLockPeriod = isDateInLockPeriod(
      new Date(date),
      new Date(lockPeriodDates?.latest),
    );

    return (
      !isInLockPeriod ||
      entityLoggable(entity, cells._helperData, isInLockPeriod)
    );
  }

  function getWorkHours(rowId, dayNum) {
    const meta = rowMetas.get(rowId);
    const day = dates.fromNum(dayNum);
    const [, subCol] = dates.toDescriptor(day);
    return meta?.getDailyWorkHours(day)[subCol];
  }

  function hasVaryingWorkHoursInRange({
    start_date,
    end_date,
    people_ids,
    people_id,
  }) {
    let seenWorkHours = null;

    for (let i = dates.toNum(start_date); i <= dates.toNum(end_date); i++) {
      if (people_ids) {
        for (let j = 0; j < people_ids.length; j++) {
          const rowId = `person-${people_ids[j]}`;
          const workHours = getWorkHours(rowId, i);
          if (workHours && seenWorkHours && workHours != seenWorkHours) {
            return true;
          } else if (workHours) {
            seenWorkHours = workHours;
          }
        }
      } else if (people_id) {
        const rowId = `person-${people_id}`;
        const workHours = getWorkHours(rowId, i);
        if (workHours && seenWorkHours && workHours != seenWorkHours) {
          return true;
        } else if (workHours) {
          seenWorkHours = workHours;
        }
      }
    }

    return false;
  }

  function getMinWorkHoursInRange({
    start_date,
    end_date,
    people_ids,
    people_id,
  }) {
    let minWorkHours = Number.POSITIVE_INFINITY;

    for (let i = dates.toNum(start_date); i <= dates.toNum(end_date); i++) {
      if (people_ids) {
        for (let j = 0; j < people_ids.length; j++) {
          if (people_ids[j] == null) {
            continue;
          }
          const rowId = `person-${people_ids[j]}`;
          const workHours = getWorkHours(rowId, i);
          if (workHours && workHours < minWorkHours) {
            minWorkHours = workHours;
          }
        }
      } else if (people_id) {
        const rowId = `person-${people_id}`;
        const workHours = getWorkHours(rowId, i);
        if (workHours && workHours < minWorkHours) {
          minWorkHours = workHours;
        }
      }
    }

    return minWorkHours;
  }

  function getTotalDaysInRange({
    start_date,
    end_date,
    people_ids,
    people_id,
    hours_pd,
    ignoreTimeoff,
    cells,
  }) {
    let totalHours = 0;

    for (let i = dates.toNum(start_date); i <= dates.toNum(end_date); i++) {
      if (people_ids) {
        for (let j = 0; j < people_ids.length; j++) {
          const rowId = `person-${people_ids[j]}`;
          const workHours = getWorkHours(rowId, i);
          let workDay = true;

          if (!!ignoreTimeoff && cells) {
            workDay = isWorkDay(cells, rowId, dates.fromNum(i), ignoreTimeoff);
          }

          if (workHours && workDay) {
            totalHours += hours_pd / workHours;
          }
        }
      } else if (people_id) {
        const rowId = `person-${people_id}`;
        const workHours = getWorkHours(rowId, i);
        let workDay = true;

        if (!!ignoreTimeoff && cells) {
          workDay = isWorkDay(cells, rowId, dates.fromNum(i), ignoreTimeoff);
        }

        if (workHours && workDay) {
          totalHours += hours_pd / workHours;
        }
      }
    }

    return totalHours;
  }

  function overlaps(a, b) {
    return !(a.x + a.w - 1 < b.x || b.x + b.w - 1 < a.x);
  }

  function overlapsFullDayTimeoff(cells, entity) {
    const type = entity.task_id ? 'task' : 'timeoff';
    const id = type === 'task' ? entity.task_id : entity.timeoff_id;
    const cellKeys = getApplicableCellKeys(entity);

    for (const ck of cellKeys) {
      const cell = cells[ck];
      const fullDayTimeoffs = cell
        ? cell.items.filter(
            (i) =>
              i.type === 'timeoff' &&
              i.entityId !== id &&
              i.entity.full_day &&
              !i.entity.region_holiday_id,
          )
        : [];
      if (fullDayTimeoffs.length) {
        for (let i = 0; i < fullDayTimeoffs.length; i++) {
          if (overlapsDays(fullDayTimeoffs[i].entity, entity)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  function overlapsEntity(cells, entity) {
    const type = entity.task_id ? 'task' : 'timeoff';
    const id = type === 'task' ? entity.task_id : entity.timeoff_id;
    const cellKeys = getApplicableCellKeys(entity);

    for (const ck of cellKeys) {
      const cell = cells[ck];
      const entities = cell
        ? cell.items.filter(
            (i) => i.entityId !== id && !i.entity.region_holiday_id,
          )
        : [];
      if (entities.length) {
        for (let i = 0; i < entities.length; i++) {
          if (overlapsDays(entities[i].entity, entity)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  function getAssociatedChangesForEntity(cells, entity, opts) {
    const cellKeys = getApplicableCellKeys(entity);
    return getAssociatedChanges(cells, cellKeys, entity, opts);
  }

  function getOverlappingItems(cell, item) {
    if (!cell) return [];
    const overlappingItems = cell.items
      .filter((i) => {
        if (i.isPlaceholder) return false;
        return overlaps(item, i);
      })
      .sort((a, b) => a.y - b.y);
    return overlappingItems;
  }

  function intersectsRepeatingEntity(cells, entity) {
    const [colStart] = dates.toDescriptor(entity.start_date);
    const [colStop] = dates.toDescriptor(entity.end_date);
    const personIds = entity.people_ids || [entity.people_id];

    for (let col = colStart; col <= colStop; col++) {
      for (const id of personIds) {
        const cell = cells[`person-${id}:${col}`];
        if (cell && cell.items && cell.items.length) {
          for (const item of cell.items) {
            if (
              item.entity &&
              item.entity.repeat_state &&
              overlapsDays(item.entity, entity)
            ) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  function intersectsMultiAssignEntity(cells, entity) {
    const [colStart] = dates.toDescriptor(entity.start_date);
    const [colStop] = dates.toDescriptor(entity.end_date);
    const personIds = entity.people_ids || [entity.people_id];

    for (let col = colStart; col <= colStop; col++) {
      for (const id of personIds) {
        const cell = cells[`person-${id}:${col}`];
        if (cell && cell.items && cell.items.length) {
          for (const item of cell.items) {
            if (
              item.entity &&
              item.entity.people_ids &&
              item.entity.people_ids.length > 1 &&
              overlapsDays(item.entity, entity)
            ) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  function calcEntityStartDate(cells, rowId, entity) {
    const { end_date, length } = entity;
    const ignoreTimeoff = entity.timeoff_id ? entity : null;
    const meta = rowMetas.get(rowId);

    let start_date = end_date;
    let curLength = 1;

    while (
      curLength < length &&
      (!meta.start_date || meta.start_date < start_date)
    ) {
      start_date = dates.addDays(start_date, -1);

      if (isWorkDay(cells, rowId, start_date, ignoreTimeoff)) {
        curLength++;
      }
    }

    return start_date;
  }

  function calcEntityEndDate(cells, rowId, entity) {
    const { start_date, length } = entity;
    const ignoreTimeoff = entity.timeoff_id ? entity : null;
    const meta = rowMetas.get(rowId);

    let end_date = start_date;
    let curLength = 1;

    while (curLength < length && (!meta.end_date || meta.end_date > end_date)) {
      end_date = dates.addDays(end_date, 1);

      if (isWorkDay(cells, rowId, end_date, ignoreTimeoff)) {
        curLength++;
      }
    }

    return end_date;
  }

  function calcEntityLength(cells, rowId, entity) {
    const ignoreTimeoff = entity.timeoff_id ? entity : null;
    const start = dates.toNum(entity.start_date);
    const end = dates.toNum(entity.end_date);
    return (
      end -
      start +
      1 -
      countNonWorks(
        cells,
        rowId,
        entity.start_date,
        entity.end_date,
        ignoreTimeoff,
      )
    );
  }

  function calcEntityHoursForRepeatInstance(cells, entityInstance) {
    return entityInstance.people_ids.reduce((acc, id) => {
      acc[id] =
        calcEntityLength(cells, `person-${id}`, entityInstance) *
        entityInstance.hours;
      return acc;
    }, {});
  }

  function calcEntityTotalHours(cells, entity) {
    let totalHours = 0;
    const hoursByPerson = entity.people_ids.reduce((acc, id) => {
      acc[id] = 0;
      return acc;
    }, {});

    entity.allInstances.forEach((instance) => {
      const result = calcEntityHoursForRepeatInstance(cells, instance);
      forEach(result, (hours, id) => {
        hoursByPerson[id] += hours;
        totalHours += hours;
      });
    });

    return { totalHours, hoursByPerson };
  }

  function calcHeight(cells, rowId, boundaryCol) {
    let maxHeight = 0;

    for (let i = 0; i < numVisibleWeeks; i++) {
      const cell = cells._getCell(`${rowId}:${boundaryCol + i}`, false);

      if (cell && cell.height > maxHeight) {
        maxHeight = cell.height;
      }
    }

    if (!cells._heights[rowId]) {
      cells._heights[rowId] = {};
    }

    if (cells._heights[rowId][boundaryCol]?.maxHeight !== maxHeight) {
      cells._heightsUpdatedAt = now();
      cells._heights[rowId][boundaryCol] = {
        maxHeight,
      };
      cells._emitHeightsUpdate(cells._heightsUpdatedAt);
    }
  }

  function getHorizontalDimensions(item, firstCellDay, lastCellDay) {
    const start = dates.toNum(item.start_date);
    const end = dates.toNum(item.end_date);

    const x = start > firstCellDay ? start - firstCellDay : 0;
    const w =
      end > lastCellDay
        ? 7 - leftHiddenDays - rightHiddenDays - x
        : end - firstCellDay - x + 1;

    return { x, w };
  }

  // Gets all the intances of the entity considering the repeat rules
  function getAllInstances(entity) {
    if (
      !entity.allInstances ||
      entity._allInstancesMemoKey !== entity.repeatInstances
    ) {
      // Cache the denormalized version of repeatInstances so that we don't
      // have to recreate it frequently.
      const allInstances = [entity];
      entity.instanceCount = 0;

      if (entity.repeatInstances) {
        entity.repeatInstances.forEach((ri, i) => {
          const instance = fastObjectSpread(entity, ri, {
            instanceCount: i + 1,
            allInstances,
          });
          allInstances.push(instance);
        });
      }

      entity.allInstances = allInstances;
      entity._allInstancesMemoKey = entity.repeatInstances;
    }

    return entity.allInstances;
  }

  function getRepeatInstances(entity) {
    const length =
      dates.toNum(entity.end_date) - dates.toNum(entity.start_date);
    return dates
      .getRepeatStarts(
        entity.repeat_state,
        entity.start_date,
        entity.repeat_end_date,
      )
      .map((s) => ({
        start_date: s,
        end_date: dates.addDays(s, length),
      }));
  }

  function createRepeatInstances(entity) {
    entity.repeatInstances = getRepeatInstances(entity);
  }

  function buildCell(cells, cellKey, opts) {
    if (cellKey.startsWith('top:')) {
      return buildTopCell(props, cells, cellKey, { getHorizontalDimensions });
    }

    if (cellKey.startsWith('person')) {
      return buildPersonCell(props, cells, cellKey, opts, {
        getHorizontalDimensions,
        calcEntityLength,
        getAllInstances,
        getPriority,
        overlaps,
        isWorkDay,
        shouldComputeTaskLength,
      });
    }

    if (cellKey.startsWith('logged_time')) {
      return buildLogTimeCell(props, cells, cellKey, opts, {
        getHorizontalDimensions,
        calcEntityLength,
        getAllInstances,
        getPriority,
        overlaps,
        isWorkDay,
        getApplicableCellKeys,
        isOneOffDay,
        isLoggedTimeWorkDay,
        shouldComputeTaskLength,
      });
    }

    if (cellKey.startsWith('project')) {
      return buildProjectCell(props, cells, cellKey, {
        getHorizontalDimensions,
        overlaps,
      });
    }

    throw Error(`Unknown row type for cellKey [${cellKey}]`);
  }

  function getPriority(rowId, entity) {
    if (entity.priority_info) {
      const personId = rowMetas.get(rowId)?.personId;
      if (!isUndefined(entity.priority_info[personId])) {
        return entity.priority_info[personId];
      }
    }
    if (!isUndefined(entity.priority)) return entity.priority;
    return 0;
  }

  function setPriority(rowId, entity, priority) {
    if (entity.priority_info) {
      const { personId } = rowMetas.get(rowId);
      entity._old_priority_info = cloneDeep(entity.priority_info);
      entity.priority_info[personId] = priority;
      return;
    }

    entity._old_priority = entity.priority;
    entity.priority = priority;
  }

  function getLinkedTaskAssociatedChanges(cells, entity, dayDelta) {
    const linkedTasks = filter(
      tasksMap,
      (task) => task.root_task_id == entity.root_task_id,
    );

    const downstreamLinkedTasks = getDownstreamLinkedTasks(
      linkedTasks,
      entity.task_id,
    );

    const changes = [];

    if (entity.taskIdsToUnlink) {
      entity.taskIdsToUnlink.forEach((id) => {
        const e = tasksMap[id];
        const originalEntity = cloneDeep(e);

        if (entity.task_id == id) {
          // The entity we're touching doesn't need an additional change
          // object - we can modify it directly.
          entity.parent_task_id = null;
        } else {
          e.parent_task_id = null;
          changes.push({
            type: 'task',
            id,
            entity: e,
            originalEntity,
          });
        }
      });
    }

    downstreamLinkedTasks.forEach((lt) => {
      const linkedTask = tasksMap[lt.task_id];
      const parentTask = tasksMap[lt.parent_task_id];

      if (!parentTask) return;

      const originalEntity = cloneDeep(linkedTask);
      const rowId = `person-${linkedTask.people_ids[0]}`;
      const length = calcEntityLength(cells, rowId, linkedTask);
      let forceMaxStartDate = false;

      try {
        linkedTask.start_date = findNthWorkDay(
          cells,
          rowId,
          originalEntity.start_date,
          dayDelta,
        );
      } catch (e) {
        if (e.message === 'PERSON_DATE_OUT_OF_RANGE') {
          forceMaxStartDate = true;
        } else {
          throw e;
        }
      }

      // Linked tasks can never start before their parents
      if (parentTask.start_date > linkedTask.start_date) {
        try {
          linkedTask.start_date = findWorkDay(
            cells,
            rowId,
            parentTask.start_date,
            'R',
          );
        } catch (e) {
          if (e.message !== 'PERSON_DATE_OUT_OF_RANGE') {
            throw e;
          }
        }
      }

      // If the target person has an end date set, we want to clamp the task
      // such that its length is unchanged. This is the same block as a little
      // further up, but works on each multi-selected task independently.
      if (rowMetas.get(rowId).end_date) {
        try {
          const maxStartDate = findNthWorkDay(
            cells,
            rowId,
            rowMetas.get(rowId).end_date,
            -length,
          );

          if (forceMaxStartDate || linkedTask.start_date > maxStartDate) {
            linkedTask.start_date = maxStartDate;
          }
        } catch (e) {
          if (e.message !== 'PERSON_DATE_OUT_OF_RANGE') {
            throw e;
          }
        }
      }

      linkedTask.end_date = calcEntityEndDate(cells, rowId, linkedTask);
      linkedTask._dragInProgress = true;

      changes.push({
        type: 'task',
        id: linkedTask.task_id,
        entity: linkedTask,
        originalEntity,
        ignore:
          linkedTask.start_date === originalEntity.start_date &&
          linkedTask.end_date === originalEntity.end_date,
      });
    });

    return changes;
  }

  function getFullDayTimeoffAssociatedChanges(cells, cellKeys, entity, opts) {
    const changes = [];
    const processed = new Set();

    function processCandidate(cellKey, c) {
      if (c.repeat_state) return;
      if (c.timeoff_id && c.timeoff_id === entity.timeoff_id) return;
      if (c.timeoff_id && c.region_holiday_id) return;
      if (c.task_id && c.task_id === entity.task_id) return;
      if (!isPeopleSuperset(entity, c)) return;
      if (processed.has(c)) return;
      processed.add(c);

      const rowId = getCellKeyRowId(cellKey);

      const shrinkRight =
        entity.start_date <= c.end_date && entity.end_date >= c.end_date;
      const shrinkLeft =
        entity.end_date >= c.start_date && entity.start_date <= c.start_date;
      const remove =
        entity.start_date <= c.start_date && entity.end_date >= c.end_date;

      // If we drag a full-day timeoff into a range that's completely covered
      // by a longer-spanning full-day timeoff, we want to keep that longer
      // timeoff and remove the one we're dragging.
      const removeEntityBeingTouched =
        c.timeoff_id &&
        c.full_day &&
        c.start_date <= entity.start_date &&
        c.end_date >= entity.end_date;

      if (!removeEntityBeingTouched && (shrinkRight || shrinkLeft || remove)) {
        changes.push({
          type: c.logged_time_id
            ? 'loggedTime'
            : c.task_id
              ? 'task'
              : 'timeoff',
          id: c.logged_time_id || c.task_id || c.timeoff_id,
          entity: cloneDeep(c),
          hasRights: entityEditable(
            cloneDeep(c),
            cells._helperData.user,
            cells._helperData,
          ),
          originalEntity: cloneDeep(c),
          parentAssociation: entity,
        });
      }

      // Note that we have to do the deep clone prior to change the dates here
      // as we're directly modifying the candidate in non-repeat instances.
      if (opts.noMutations) {
        return;
      } else if (removeEntityBeingTouched) {
        changes.push({
          type: 'error',
          error: new Error('REMOVE_ENTITY_BEING_TOUCHED'),
        });
      } else if (remove) {
        changes[changes.length - 1].isRemove = true;
      } else if (shrinkLeft) {
        c.start_date = findWorkDay(
          cells,
          rowId,
          dates.addDays(entity.end_date, 1),
          'R',
          entity,
        );
        // @entity.length
        c.length = undefined;
        changes[changes.length - 1].start_date = c.start_date;
      } else if (shrinkRight) {
        c.end_date = findWorkDay(
          cells,
          rowId,
          dates.addDays(entity.start_date, -1),
          'L',
          entity,
        );
        // @entity.length
        c.length = undefined;
        changes[changes.length - 1].end_date = c.end_date;
      }
    }

    cellKeys.forEach((cellKey) => {
      tasksBimap
        .getRev(cellKey)
        .map((id) => tasksMap[id])
        .forEach((t) => {
          processCandidate(cellKey, t);
        });

      timeoffsBimap
        .getRev(cellKey)
        .map((id) => timeoffsMap[id])
        .forEach((t) => {
          processCandidate(cellKey, t);
        });

      loggedTimeBimap
        .getRev(cellKey)
        .map((id) => loggedTimeMap[id])
        .forEach((t) => {
          processCandidate(cellKey, t);
        });
    });

    return changes;
  }

  function getAssociatedChanges(cells, cellKeys, entity, opts) {
    if (entity.range_mode === 'custom') {
      return [];
    }
    if (entity.root_task_id || entity.taskIdsToUnlink?.length) {
      // Linked tasks require us to move all other linked tasks at the same time
      return getLinkedTaskAssociatedChanges(cells, entity, opts.dayDelta);
    }

    if (
      (entity.timeoff_id || entity.isTimeoff) &&
      isEntityFullDayTimeoff(entity) &&
      entity.status != -1
    ) {
      // When we move full day timeoffs around, we may need to adjust the start
      // and end dates (or fully delete) other entities that intersect the
      // new position.
      return getFullDayTimeoffAssociatedChanges(cells, cellKeys, entity, opts);
    }

    return [];
  }

  function unapplyChanges(pendingChanges) {
    let affectedCellKeys = [];

    pendingChanges.forEach((c) => {
      if (c.type === 'error') return;
      if (c.ignoreUnapply) return;
      const oldKeys = getApplicableCellKeys(c.entity);
      const keys = getApplicableCellKeys(c.originalEntity);
      bimaps[c.type].replace(c.id, keys);
      maps[c.type][c.id] = c.originalEntity;
      Object.assign(c.entity, c.originalEntity);
      affectedCellKeys = union(affectedCellKeys, oldKeys, keys);
    });

    return affectedCellKeys;
  }

  function applyChanges(cells, newCellKeys, entity, pendingChanges, opts) {
    let affectedCellKeys = [];

    getAssociatedChanges(cells, newCellKeys, entity, opts).forEach((c) => {
      if (c.type !== 'error') {
        const oldKeys = getApplicableCellKeys(c.originalEntity);
        const keys = getApplicableCellKeys(c.entity);
        bimaps[c.type].replace(c.id, keys);
        affectedCellKeys = union(affectedCellKeys, oldKeys, keys);
      }

      if (!c.ignore) {
        // Linked tasks may require children cellKeys to be regenerated to
        // render the link arrows in the correct place (would happen in the
        // above block) but we don't actually need to change anything.
        pendingChanges.push(c);
      }
    });

    return affectedCellKeys;
  }

  function shouldComputeTaskLength(cellKey, task, opts) {
    // Task lengths are an approximation. Since full-day time offs need
    // to be deducted to get task length, we wait for all involved cells
    // to be rendered before calculating task length. That might still be
    // incorrect if all time-offs between a task's start and end dates
    // (for long running tasks) have not been fetched yet.
    const lastAffectedCellKey = opts.lastAffectedCellKeyByTaskId[task.task_id];
    return cellKey === lastAffectedCellKey;
  }

  return {
    loadData,
    loadBulkData,
    isWorkDay,
    isOneOffDay,
    isEntityFullDayTimeoff,
    isFullDayTimeoff,
    calcEntityStartDate,
    calcEntityEndDate,
    calcEntityLength,
    calcEntityTotalHours,
    firstWorkDay,
    findWorkDay,
    getApplicableCellKeys,
    calcHeight,
    buildCells,
    getOverlappingItems,
    getPriority,
    setPriority,
    unapplyChanges,
    applyChanges,
    getMinWorkHoursInRange,
    getTotalDaysInRange,
    hasVaryingWorkHoursInRange,
    countNonWorks,
    overlapsFullDayTimeoff,
    overlapsEntity,
    intersectsRepeatingEntity,
    intersectsMultiAssignEntity,
    countWorkDays,
    findNthWorkDay,
    getAllInstances,
    createRepeatInstances,
    getRepeatInstances,
    getAssociatedChangesForEntity,
    shouldComputeTaskLength,
  };
}
