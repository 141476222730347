import Rollbar from 'rollbar';

import { config } from '@float/libs/config';
import { logger } from '@float/libs/logger';
import { initializeRollbarWebLoggerClient } from '@float/libs/logger/rollbarWebLoggerAdapter';
import { CurrentUser } from '@float/types/account';

import supportedBrowsers from '../../constants/supportedBrowsers';

const BUILD_VERSION = process.env.BUILD_VERSION || 'unknown';
const BUILD_TIME = process.env.BUILD_TIME || 'unknown';

const isDevMode = process.env.NODE_ENV === 'development';

initializeRollbarWebLoggerClient(new Rollbar(), {
  environment: config.environment,
  // TODO: Rename this parameter to `version`
  gitSha: BUILD_VERSION,
  supportedBrowsers,
  isDevMode,
  onReady: () => {
    logger.log(`g20230726:mono:${BUILD_TIME}:${BUILD_VERSION}`);
  },
});

export const identifyUserToLogger = (user: CurrentUser) => {
  logger.identify({
    id: user.account_id,
    companyId: user.cid,
    companyName: user.company_name,
  });
};
