import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import { bindActionCreators, compose } from 'redux';
import {
  getCurrencyProps,
  getDepartmentOptions,
  getDepartments,
  getJobTitleOptions,
  getManagerAndAdminAccounts,
  getMemberViewRightsOptions,
  getParentDepartments,
  getPeopleMap,
  getPeopleTagByLabel,
  getPeopleTagOptions,
  getPersonTypeOptions,
  getProjectManagerViewRightsOptions,
  getProjectsByClient,
  getProjectsMap,
  getProjectsOptions,
  getUser,
} from 'selectors';

import * as peopleActions from '@float/common/actions/people';
import API3 from '@float/common/api3';
import { Access, Rights } from '@float/common/lib/acl';
import { formatAmount } from '@float/common/lib/budget';
import { isExcerpt } from '@float/common/lib/notes';
import { searchService } from '@float/common/search/service';
import { getRoles, getRolesOptions } from '@float/common/selectors/roles';
import { provideStoreHoc } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import {
  bindVal,
  Col,
  Modal,
  withConfirm,
  withSnackbar,
} from '@float/ui/deprecated';
import withConfirmDelete from 'components/decorators/withConfirmDelete';
import { hasSeatsAvailable } from 'components/modals/helpers/hasSeatsAvailable';
import { showTimeToUpgradeConfirmModal } from 'components/modals/TimeToUpgrade';

import renderPersonAccess from './access/PersonAccess';
import renderPersonAccessReadOnly from './access/PersonAccessReadOnly';
import renderPersonAvailability from './PersonAvailability';
import renderPersonAvailabilityReadOnly from './PersonAvailabilityReadOnly';
import renderPersonInfo from './PersonInfo';
import renderPersonInfoReadOnly from './PersonInfoReadOnly';
import renderActions, { savePerson } from './PersonModalActions';
import PersonModalHeader from './PersonModalHeader';
import { PersonModalTabs } from './PersonModalTabs';
import PersonPeople from './PersonPeople';
import renderPersonProjects from './PersonProjects';
import renderPersonProjectsReadOnly from './PersonProjectsReadOnly';

async function fetchiCalLink(personId) {
  const json = await fetch(`/ical/${personId}`, {
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .catch(() => null);
  return json && json.ical;
}

export class PersonModal extends React.Component {
  constructor(props) {
    super(props);

    this.abortController;
    this.initialPerson = this.getInitialPerson();

    const form = cloneDeep(this.initialPerson);

    this.state = {
      activeTab: props.activeTab || 'info',
      notesFetched: !isExcerpt(form.notes),
      form,
      formErrors: {
        name: [],
        email: [],
      },
      timeToUpgrade: this.isAdding() && !hasSeatsAvailable(props.companyPrefs),
      workDaysHours: {
        allDays: this.initialPerson.work_days_hours,
        history: this.initialPerson.work_days_hours_history,
      },
    };
    this.readOnlyProps = { wrapperStyle: { marginBottom: 21 } };
  }

  componentDidMount() {
    this.getPublicHolidays();
    this.getIcalLink();
    this.getAccount();
    this.getFullPersonNotesIfNeeded();
  }

  componentWillUnmount() {
    if (this.abortController) this.abortController.abort();
  }

  async getIcalLink() {
    const personId = this.state.form.people_id;
    if (personId) {
      const ical = await fetchiCalLink(personId);
      this.setState({
        ical,
      });
    }
  }

  getFullPersonNotesIfNeeded() {
    const { notesFetched } = this.state;
    const { people_id: personId } = this.state.form;

    if (personId && !notesFetched) {
      const req = this.props.actions.fetchPersonFullNotes(personId);

      this.abortController = req.abortController;

      req.promise
        .then((response) => {
          this.setState({
            notesFetched: true,
            form: { ...this.state.form, notes: response.notes },
          });
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            throw error;
          }
        });
    }
  }

  isUserLimitedByDepartment() {
    const { currentUser } = this.props;
    if (currentUser.department_filter?.length) {
      return true;
    }

    return false;
  }

  getInitialPerson = () => {
    const { currentUser } = this.props;
    if (this.isAdding()) {
      const result = {
        name: '',
        employee_type: 1,
        people_type_id: 1,
        email: '',
        tags: [],
        project_ids: [],
        notes: '',
        work_days_hours: this.props.companyPrefs.work_days_hours,
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
      };

      if (currentUser.department_filter?.length) {
        result.department_id = currentUser.department_filter[0];
      }

      return result;
    }

    const { person: personFromProps } = this.props.modalSettings;
    const person = cloneDeep(personFromProps);
    person.tags = (person.tags || []).map((t) => ({
      label: t.name,
      value: t.name,
      type: t.type,
    }));

    person.account = person.account_id
      ? { ...this.props.accounts[person.account_id], ...person.account }
      : null;

    if (person.account) {
      person.account.department_filter_set =
        person.account.department_filter?.length > 0;
    }

    if (!person.work_days_hours && !person.work_days_hours_history) {
      person.work_days_hours = this.props.companyPrefs.work_days_hours;
    }

    if (person.work_days_hours_history) {
      delete person.work_days_hours;
    }

    this.initialAccount = person.account;
    person.notes = person.notes || '';
    person.email = person.email || '';
    person.default_hourly_rate = formatAmount(null, person.default_hourly_rate);
    person.default_hourly_rate_role = formatAmount(
      null,
      person?.role?.default_hourly_rate,
    );

    return person;
  };

  getPublicHolidays = async () => {
    const regions = await API3.getPublicHolidays();
    if (regions && regions.length) {
      const newState = {
        allRegions: regions.map((r) => ({ value: r.region_id, label: r.name })),
      };
      if (this.isAdding()) {
        const defaultRegion = regions.find((r) => r.is_default) || regions[0];
        newState.form = {
          ...this.state.form,
          regionHolidayIds: defaultRegion ? defaultRegion.holiday_ids : null,
          region_id: defaultRegion ? defaultRegion.region_id : null,
        };
        this.initialPerson.regionHolidayIds = newState.form.regionHolidayIds;
        this.initialPerson.region_id = newState.form.region_id;
      }
      this.setState(newState);
    }
  };

  getAccount = async () => {
    const { account } = this.state.form;
    if (!account?.account_id) {
      return;
    }

    const response = await API3.getAccountV3({
      id: account.account_id,
      query: {
        expand: 'management_group',
      },
    });

    const nextFormState = this.state.form;

    nextFormState.account = {
      ...nextFormState.account,
      management_group: response.management_group,
      access: response.access || 0,
    };

    this.setFormState(nextFormState);
  };

  close = (e) => {
    prevent(e);
    this.props.manageModal({ visible: false, modalType: 'personModal' });
  };

  closeUpgradeModal = () => {
    this.setState({ timeToUpgrade: false });
    this.close();
  };

  noChangesMade = () => {
    if (this.isReadOnly()) return true;
    if (
      !isEqual(
        this.initialPerson.work_days_hours,
        this.state.workDaysHours.allDays,
      )
    )
      return false;
    if (
      !isEqual(
        this.initialPerson.work_days_hours_history,
        this.state.workDaysHours.history,
      )
    )
      return false;
    return isEqual(this.initialPerson, this.state.form);
  };

  hideAfterCheckingForChanges = () => {
    if (this.noChangesMade()) {
      this.close();
      return;
    }
    this.props.confirm({
      title: 'You have unsaved changes.',
      message: 'Are you sure you want to leave?',
      confirmLabel: 'Leave',
      cancelLabel: 'Cancel',
      onConfirm: this.close,
    });
  };

  isAdding = () => this.props.modalSettings.isAdding;

  isExistingAccount = () => {
    const { account } = this.state.form;
    return !!(account && account.account_id);
  };

  isPlaceholder = () => this.state.form.people_type_id == 3;

  isAccountOwner = () => (this.state.form.account || {}).account_type == 1;

  isPartTime = () => this.state.form.employee_type == 0;

  isReadOnly = () => {
    const { currentUser } = this.props;
    const { form } = this.state;
    if (this.isAdding()) {
      return false;
    }

    return !Rights.canUpdatePeople(currentUser, {
      account: form.account,
      person: form,
    });
  };

  getPeopleId = () => {
    return this.props.modalSettings.person?.people_id;
  };

  hasTasks = async () => {
    const peopleId = this.getPeopleId();

    const peopleWithTasks = await searchService.getSelectorValue(
      this.props.store.getState(),
      'getPeopleWithTasks',
      [],
    );

    return Boolean(peopleId && peopleWithTasks.has(peopleId));
  };

  hasErrors = (fields) => {
    const { formErrors } = this.state;
    return fields.some((field) => (formErrors[field] || {}).length);
  };

  setFormState = (formChanges, cb) => {
    this.setState({ form: { ...this.state.form, ...formChanges } }, cb);
  };

  setFormErrors = (formErrors, cb) => {
    this.setState(
      { formErrors: { ...this.state.formErrors, ...formErrors } },
      cb,
    );
  };

  showMessage = (message) => {
    this.props.showSnackbar(message);
  };

  setActiveTab = (activeTab) => {
    const cb =
      activeTab === 'info' && !this.isReadOnly()
        ? () => {
            this.personName?.focusInput();
          }
        : undefined;
    this.setState({ activeTab }, cb);
  };

  setPersonNameRef = (el) => {
    this.personName = el;
  };

  renderActiveTabReadOnly = () => {
    switch (this.state.activeTab) {
      case 'info':
        return renderPersonInfoReadOnly(this);
      case 'access':
        return renderPersonAccessReadOnly(this);
      case 'availability':
        return renderPersonAvailabilityReadOnly(this);
      case 'projects':
        return renderPersonProjectsReadOnly(this);
      case 'manages':
        return (
          <PersonPeople
            account={this.state.form.account}
            peopleId={this.getPeopleId()}
          />
        );
      default:
        return null;
    }
  };

  renderActiveTab = () => {
    if (this.isReadOnly()) {
      return this.renderActiveTabReadOnly();
    }

    const { currentUser } = this.props;
    const { form } = this.state;

    switch (this.state.activeTab) {
      case 'info':
        return renderPersonInfo(this);
      case 'access':
        return Access.canUpdateAccountAccess(currentUser, form)
          ? renderPersonAccess(this)
          : renderPersonAccessReadOnly(this);
      case 'availability':
        return renderPersonAvailability(this);
      case 'projects':
        return Rights.canUpdateProject(currentUser)
          ? renderPersonProjects(this)
          : renderPersonProjectsReadOnly(this);
      case 'manages':
        return (
          <PersonPeople
            account={this.state.form.account}
            peopleId={this.getPeopleId()}
            onChange={
              Access.canUpdateAccountManagementGroup(currentUser, form) &&
              ((nextAccount) => {
                this.setFormState({
                  account: {
                    ...this.state.form.account,
                    ...nextAccount,
                  },
                });
              })
            }
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { formErrors, timeToUpgrade, form, activeTab } = this.state;
    if (timeToUpgrade) {
      if (this.showingUpgradeModal) return null;
      showTimeToUpgradeConfirmModal({
        ...this.props,
        onCancel: this.closeUpgradeModal,
      });
      this.showingUpgradeModal = true;
      return null;
    }

    const readOnly = this.isReadOnly();
    return (
      <Modal
        isOpen
        onClose={this.close}
        onBgClick={this.hideAfterCheckingForChanges}
      >
        <form noValidate onSubmit={savePerson.bind(this)}>
          <Modal.Header
            style={{
              paddingBottom: 0,
              paddingRight: 32,
            }}
          >
            <Col>
              <PersonModalHeader
                ref={(ref) => (this.header = ref)}
                readOnly={readOnly}
                form={form}
                initialPerson={this.initialPerson}
                formErrors={formErrors}
                onFieldChange={(fieldName, value) => {
                  const { onChange } = bindVal(this, fieldName);
                  onChange(value);
                }}
              />
              <PersonModalTabs
                form={form}
                initialPerson={this.initialPerson}
                activeTab={activeTab}
                formErrors={formErrors}
                onTabChange={(tanKey) => {
                  this.setActiveTab(tanKey);
                }}
              />
            </Col>
          </Modal.Header>
          <Modal.Body style={readOnly ? { paddingBottom: 30 } : undefined}>
            {this.renderActiveTab()}
          </Modal.Body>
          {!readOnly && (
            <Modal.Actions style={{ paddingTop: 30 }}>
              {renderActions(this)}
            </Modal.Actions>
          )}
        </form>
      </Modal>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    jobTitleOptions: getJobTitleOptions(state),
    departmentOptions: getDepartmentOptions(state),
    tagOptions: getPeopleTagOptions(state),
    personTypeOptions: getPersonTypeOptions(state),
    memberViewRightsOptions: getMemberViewRightsOptions(state),
    projectManagerViewRightsOptions: getProjectManagerViewRightsOptions(state),
    managerAndAdminOptions: getManagerAndAdminAccounts(state),
    projectOptions: getProjectsOptions(state),
    rolesOptions: getRolesOptions(state),
    currencyConfig: getCurrencyProps(state),

    currentUser: getUser(state),
    departments: getDepartments(state),
    parentDepartments: getParentDepartments(state),
    companyPrefs: state.companyPrefs,
    peopleTagByLabel: getPeopleTagByLabel(state),

    projects: getProjectsMap(state),
    accounts: state.accounts.accounts,
    roles: getRoles(state),
    people: getPeopleMap(state),
    search: state.search,
    projectsByClient: getProjectsByClient(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(peopleActions, dispatch),
  },
  dispatch,
});

const Component = compose(
  withConfirm,
  withConfirmDelete,
  withSnackbar,
)(provideStoreHoc(PersonModal));

export default modalManagerHoc({
  Comp: connect(mapStateToProps, mapDispatchToProps)(Component),
  modalType: 'personModal',
});
