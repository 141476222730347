import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import { createSelectorWithShallowEqualSetResultCheck } from '@float/common/selectors/lib/createSelectorWithShallowEqualSetResultCheck';
import { getFullLoggedTimesList } from '@float/common/selectors/loggedTimes';
import { getMeFilter } from '@float/common/selectors/search';
import { getFullTasksList } from '@float/common/selectors/tasks';
import { getFullTimeoffsList } from '@float/common/selectors/timeoffs';
import { getActiveFilters } from '@float/common/selectors/views';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { forLoggedTimes, forTasks, forTimeoffs } from '../core';
import { FilteredEntities } from '../types';
import { getSearchDerivedContext } from './derivedContext';
import { getSearchResults } from './searchResults';

const selectTaskIdsFromSearchResolve =
  createSelectorWithShallowEqualSetResultCheck(
    [getActiveFilters, getSearchResults, getFullTasksList],
    (filters, searchResults, tasks) => {
      // If there are no filters we do not want to filter the tasks
      if (searchResults && filters.length) {
        return searchResults.tasks;
      }

      // Generate a set with all the tasks ids
      const result = new Set<string>();

      for (const { task_id } of tasks) {
        result.add(task_id);
      }

      return result;
    },
  );

const selectTimeoffIdsFromSearchResolve =
  createSelectorWithShallowEqualSetResultCheck(
    [getActiveFilters, getSearchResults, getFullTimeoffsList],
    (filters, searchResults, timeoffs) => {
      // If there are no filters we do not want to filter the timeoffs
      if (searchResults && filters.length) {
        return searchResults.timeoffs;
      }

      // Generate a set with all the timeoffs ids
      const result = new Set<string>();

      for (const { timeoff_id } of timeoffs) {
        result.add(String(timeoff_id));
      }

      return result;
    },
  );

const selectLoggedTimesIdsFromSearchResolve =
  createSelectorWithShallowEqualSetResultCheck(
    [getActiveFilters, getSearchResults, getFullLoggedTimesList],
    (filters, searchResults, loggedTimes) => {
      // If there are no filters we do not want to filter the logged times
      if (searchResults && filters.length) {
        return searchResults.loggedTimes;
      }

      // Generate a set with all the timeoffs ids
      const result = new Set<string>();

      for (const { logged_time_id } of loggedTimes) {
        result.add(logged_time_id);
      }

      return result;
    },
  );

const selectFilteredEntitiesFromSearchResolve = createSelector(
  [
    selectTaskIdsFromSearchResolve,
    selectTimeoffIdsFromSearchResolve,
    selectLoggedTimesIdsFromSearchResolve,
  ],
  (resolvedTasksIds, resolvedTimeoffsIds, resolvedLoggedTimes) => {
    return {
      task: resolvedTasksIds,
      timeoff: resolvedTimeoffsIds,
      loggedTime: resolvedLoggedTimes,
    };
  },
);

const selectFilteredEntitiesFromSearchWorkerResults = createSelector(
  [getSearchResults],
  (searchResults) => {
    // Check on the searchResults state
    // It is undefined when the search is processed with selectors (Search worker disabled)
    // or when this selector is executed inside the Search worker
    if (searchResults) {
      return {
        task: searchResults.tasks,
        timeoff: searchResults.timeoffs,
        loggedTime: searchResults.loggedTimes,
      };
    }

    return null;
  },
);

const selectFilteredEntitiesFromSearchCore = createSelector(
  [
    getFullTasksList,
    getFullTimeoffsList,
    getFullLoggedTimesList,
    getSearchDerivedContext,
    getActiveFilters,
    getMeFilter,
    (state: ReduxStateStrict) => state.clients.clients,
    (state: ReduxStateStrict) => state.projects.projects,
    (state: ReduxStateStrict) => state.phases.phases,
    (state: ReduxStateStrict) => state.people.people,
    (state: ReduxStateStrict) => state.departments.departments,
    (state: ReduxStateStrict) => state.timeoffTypes.timeoffTypes,
    getUser,
    (state: ReduxStateStrict) => state.accounts.accounts,
    (state: ReduxStateStrict) => state.tasks.tasks,
  ],
  (
    taskList,
    timeoffList,
    loggedTimeList,
    search,
    filters,
    me,
    clients,
    projects,
    phases,
    people,
    departments,
    timeoffTypes,
    user,
    accounts,
    tasks,
  ) => {
    const filterContext = {
      user,
      clients,
      projects,
      search,
      phases,
      people,
      departments,
      timeoffTypes,
      accounts,
      me,
    };

    const filteredTasks = forTasks(filterContext, taskList, filters);
    const filteredTimeoffs = forTimeoffs(filterContext, timeoffList, filters);
    const filteredLoggedTimes = forLoggedTimes(
      {
        clients,
        projects,
        phases,
        people,
        departments,
        timeoffTypes,
        accounts,
        me,
        tasks,
        user,
        search,
      },
      loggedTimeList,
      filters,
    );

    return {
      task: filteredTasks,
      timeoff: filteredTimeoffs,
      loggedTime: filteredLoggedTimes,
    } satisfies FilteredEntities;
  },
);

// OPTIMIZATION: We are deliberately not using `createSelector` here in order to run only the selector that we need
export const getFilteredEntities = (state: ReduxStateStrict) => {
  if (
    // On the WebWorker the featureFlags are not available
    // and we don't need to check the FF there because with SBL
    // the search won't run there
    featureFlags.isReady &&
    featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)
  ) {
    return selectFilteredEntitiesFromSearchResolve(state);
  }

  const searchWorkerResults =
    selectFilteredEntitiesFromSearchWorkerResults(state);

  if (searchWorkerResults) {
    return searchWorkerResults;
  }

  return selectFilteredEntitiesFromSearchCore(state);
};
