import { CSSProperties } from 'react';

import { SerenaState } from '@float/common/selectors/serena';
import { CellItem, CompanyPreferences } from '@float/types';

import { getBoxInnerNarrow } from './getBoxInnerNarrow';
import { getBoxInnerRegular } from './getBoxInnerRegular';
import { BoxGetterProps } from './types';

export const getBoxInner = (
  dayWidth: number,
  itemWidth: number,
  item: CellItem<'task'> | CellItem<'timeoff'>,
  reduxData: SerenaState,
  companyPrefs: CompanyPreferences,
  styles: CSSProperties,
  props: BoxGetterProps,
) => {
  return dayWidth === 27 && itemWidth === 1
    ? getBoxInnerNarrow(item, reduxData, styles, props)
    : getBoxInnerRegular(item, reduxData, companyPrefs, styles, props);
};
