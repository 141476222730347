import { useRef } from 'react';

export function useHasChangedOnce(value: unknown) {
  const initialValue = useRef(value).current;
  const hasChangedOnceSinceMount = useRef(false);

  if (initialValue !== value) {
    hasChangedOnceSinceMount.current = true;
  }

  return hasChangedOnceSinceMount.current;
}
