import { createSelector } from 'reselect';

import { ReduxState, ReduxStateStrict } from '@float/common/reducers/lib/types';

import { fastDictionary } from '../lib/fast-object-spread';
import { userCanViewOthers } from '../lib/rights';
import { getUser } from './currentUser';
import { getLoggedTimesListRaw } from './loggedTimes';
import { getTasksList } from './tasks/getTasksList';

export const getMeFilter = createSelector(
  [(state: ReduxState) => state.currentUser.prefs?.me_filter, getUser],
  (isMeFilterActive, user) =>
    Boolean((isMeFilterActive || !userCanViewOthers(user)) && !!user.people_id),
);

/***
 * Attention: all these selectors require the search data to work properly
 *
 * They must be used through the SearchService
 */

// @test-export
export const _TEST_EXPORT_getProjectTaskedPeople = createSelector(
  [getTasksList],
  (tasks) => {
    const projectTaskedPeople = fastDictionary() as Record<string, Set<string>>;

    for (const task of tasks) {
      let peopleIds = projectTaskedPeople[task.project_id];

      if (!peopleIds) {
        peopleIds = new Set();
        projectTaskedPeople[task.project_id] = peopleIds;
      }

      for (const id of task.people_ids) {
        peopleIds.add(String(id));
      }
    }

    return projectTaskedPeople;
  },
);

// @test-export
export const _TEST_EXPORT_getPhaseTaskedPeople = createSelector(
  [getTasksList],
  (tasks) => {
    const phaseTaskedPeople = fastDictionary() as Record<string, Set<string>>;

    for (const task of tasks) {
      if (!task.phase_id) continue;

      let peopleIds = phaseTaskedPeople[task.phase_id];

      if (!peopleIds) {
        peopleIds = new Set();
        phaseTaskedPeople[task.phase_id] = peopleIds;
      }

      for (const id of task.people_ids) {
        peopleIds.add(String(id));
      }
    }

    return phaseTaskedPeople;
  },
);

export const getProjectsTasksIds = createSelector([getTasksList], (tasks) => {
  const projectTaskIds: Record<number, Set<string>> = fastDictionary();

  for (const task of tasks) {
    const taskIds = projectTaskIds[task.project_id];

    if (!taskIds) {
      projectTaskIds[task.project_id] = new Set([task.task_id]);
    } else {
      taskIds.add(task.task_id);
    }
  }

  return projectTaskIds;
});

export const getProjectsWithTasks = createSelector(
  [getTasksList, getLoggedTimesListRaw],
  (tasks, loggedTimes) => {
    const projectsWithTasks = new Set<number>();

    for (const task of tasks) {
      projectsWithTasks.add(task.project_id);
    }

    for (const loggedTime of loggedTimes) {
      if ('project_id' in loggedTime) {
        projectsWithTasks.add(loggedTime.project_id);
      }
    }

    return projectsWithTasks;
  },
);

export const getPeopleTasks = createSelector(
  [getTasksList, getLoggedTimesListRaw],
  (tasks, loggedTimes) => {
    const peopleTasks: Record<number, Set<string>> = fastDictionary();

    for (const task of tasks) {
      for (const id of task.people_ids) {
        const taskNames = peopleTasks[id];
        const taskName = task.name || 'Unnamed';

        if (!taskNames) {
          peopleTasks[id] = new Set([taskName]);
        } else {
          taskNames.add(taskName);
        }
      }
    }

    for (const loggedTime of loggedTimes) {
      if ('people_id' in loggedTime && loggedTime.task_name) {
        const id = loggedTime.people_id;
        const taskNames = peopleTasks[id];

        if (!taskNames) {
          peopleTasks[id] = new Set([loggedTime.task_name]);
        } else {
          taskNames.add(loggedTime.task_name);
        }
      }
    }

    return peopleTasks;
  },
);

export const getPeopleWithTasks = createSelector(
  [getTasksList, getLoggedTimesListRaw],
  (tasks, loggedTimes) => {
    const peopleWithTasks = new Set<number>();

    for (const task of tasks) {
      for (const id of task.people_ids) {
        peopleWithTasks.add(id);
      }
    }

    for (const loggedTime of loggedTimes) {
      if ('people_id' in loggedTime) {
        peopleWithTasks.add(loggedTime.people_id);
      }
    }

    return peopleWithTasks;
  },
);

export const isProjectWithTasks = createSelector(
  [getProjectsWithTasks, (_: ReduxStateStrict, projectId: number) => projectId],
  (projectsWithTasks, projectId) => {
    return projectsWithTasks.has(projectId);
  },
);

export const getTaskedPeopleByProjectId = createSelector(
  [
    _TEST_EXPORT_getProjectTaskedPeople,
    (_: ReduxStateStrict, projectId: number | undefined) => projectId,
  ],
  (projectTaskedPeople, projectId) => {
    if (!projectId) return new Set<string>();

    return projectTaskedPeople[projectId] || new Set<string>();
  },
);

export const isPersonTaskedInTheProject = createSelector(
  [
    _TEST_EXPORT_getProjectTaskedPeople,
    (_: ReduxStateStrict, projectId: number) => projectId,
    (_: ReduxStateStrict, __: number, peopleId: number) => peopleId,
  ],
  (projectTaskedPeople, projectId, peopleId) => {
    const taskedPeople = projectTaskedPeople[projectId];

    if (!taskedPeople) return false;

    return taskedPeople.has(String(peopleId));
  },
);

export const getTaskedPeopleByPhaseId = createSelector(
  [
    _TEST_EXPORT_getPhaseTaskedPeople,
    (_: ReduxStateStrict, phaseId: number | undefined) => phaseId,
  ],
  (phaseTaskedPeople, phaseId) => {
    if (!phaseId) return new Set<string>();

    return phaseTaskedPeople[phaseId] || new Set<string>();
  },
);

export const isPersonTaskedInThePhase = createSelector(
  [
    getTaskedPeopleByPhaseId,
    (
      _: ReduxStateStrict,
      __: number | undefined,
      peopleId: number | undefined,
    ) => peopleId,
  ],
  (taskedPeople, peopleId) => {
    return taskedPeople.has(String(peopleId));
  },
);

export const selectIsSearchContextLoaded = (state: ReduxStateStrict) =>
  state.search.contextState === 'LOADED';
