import { memoizeWithArgs } from 'proxy-memoize';
import { createSelector } from 'reselect';

import { stringCompare } from '@float/common/lib/sort';
import { ReduxState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import { getMeFilter } from '@float/common/selectors/search';
import { FilterToken } from '@float/types';
import { UserPrefs } from '@float/types/account';

import { SearchQueryItem } from '../../api/queryApi';
import {
  CATEGORY_TO_TYPE,
  FILTERABLE_KEYS,
  getValueAndOpFromStr,
  normalize,
  PERSON_RELATED_KEYS,
  SearchAutocompleteCategory,
} from '../../helpers';
import { getSearchDerivedContext } from '../derivedContext';
import { getCategoryTotalSize } from './getCategoryTotalSize';
import {
  addParentDepartmentToCandidates,
  getOrderedDepartments,
} from './getOrderedDepartments';
import { isCandidateVisibleForUser } from './isCandidateVisible';
import { isRemoteResultVisibleForUser } from './isRemoteResultVisibleForUser';
import { truncateCandidates } from './truncateCandidates';
import { Candidate } from './types';

const getFilterableKeys = createSelector(
  [getMeFilter, (_: ReduxState, isLogTimeView?: boolean) => isLogTimeView],
  (meFilter, isLogTimeView) => {
    // We hide the person related categories when the user is on a
    // single person view (me filter or log my time view)
    if (!meFilter && !isLogTimeView) {
      return FILTERABLE_KEYS;
    }

    const result: SearchAutocompleteCategory[] = [];

    for (const key of FILTERABLE_KEYS) {
      if (PERSON_RELATED_KEYS.includes(key)) {
        continue;
      }

      result.push(key);
    }

    return result;
  },
);

function sortFilteredCandidates(
  key: string,
  candidates: SearchAutocompleteQueryItem[],
  subDepartments?: boolean,
) {
  if (key === 'people' || key === 'projects' || key === 'timeoffs') {
    const values = candidates as Extract<
      SearchAutocompleteQueryItem,
      { type: 'person' | 'project' | 'timeoff' }
    >[];

    values.sort((a, b) => {
      if (a.isActive !== b.isActive) {
        if (a.isActive) return -1;
        if (b.isActive) return 1;
      }

      return stringCompare(a.normalizedVal, b.normalizedVal);
    });

    return values;
  }

  // sub-departments are placed right below the parent departments
  if (key === 'departments' && subDepartments) {
    return getOrderedDepartments(
      candidates as Extract<
        SearchAutocompleteQueryItem,
        { type: 'department' }
      >[],
    );
  }

  candidates.sort((a, b) =>
    stringCompare(a.val.toUpperCase(), b.val.toUpperCase()),
  );

  return candidates;
}

const getFilterableKeyCandidates = (
  state: ReduxStateStrict,
  params: {
    key: SearchAutocompleteCategory;
    input: string;
    subDepartments?: boolean;
    remoteQueryItems?: SearchQueryItem[];
  },
) => {
  const { key, input } = params;

  let filtered: SearchAutocompleteQueryItem[];

  if (params.remoteQueryItems) {
    const type = CATEGORY_TO_TYPE[key];
    filtered = params.remoteQueryItems.filter(
      (item) => item.type === type && isRemoteResultVisibleForUser(item, state),
    );
  } else {
    const user = getUser(state);

    const context = getSearchDerivedContext(state);
    const candidates = context[key] as Candidate[];

    filtered = candidates.filter((c) => {
      // We never want to show the empty string in the dropdown
      if (c.normalizedVal === '') return false;

      // Suppress filters that the user shouldn't see
      if (
        !isCandidateVisibleForUser(c, user, {
          people: state.people,
          projects: state.projects,
          peopleTasks: context.peopleTasks,
          userPrefs: user.prefs as UserPrefs,
        })
      ) {
        return false;
      }

      if (!input) return true;

      return c.normalizedVal.includes(input);
    });
  }

  const size = getCategoryTotalSize(filtered);

  // Root departments are visible when one of their sub deparmtnets
  // matchs the input filter
  if (key === 'departments' && params.subDepartments) {
    addParentDepartmentToCandidates(state.departments.departments, filtered);
  }

  return {
    candidates: sortFilteredCandidates(key, filtered, params.subDepartments),
    size,
  };
};

const MAX_PER_CATEGORY = 4;

export type SearchAutocompleteParams = {
  rawInput: string;
  expandedCategory?: SearchAutocompleteCategory;
  isLogTimeView: boolean; // On Mobile we can't derive the log time view from the state
  myProjectsItem: boolean; // An extra item added by Mobile
  subDepartments: boolean; // On mobile sub departments aren't supported yet
  containsItem: boolean;
  truncateResults: boolean;
  remoteQueryResult?: {
    items: SearchQueryItem[];
    count: number;
  };
};

export type SearchAutocompleteQueryItem =
  | {
      type:
        | 'client'
        | 'contains'
        | 'jobTitle'
        | 'manager'
        | 'personTag'
        | 'personType'
        | 'phase'
        | 'projectOwner'
        | 'projectTag'
        | 'task'
        | 'taskStatus'
        | 'timeoffStatus';
      normalizedVal: string; // Required for the internal sorting, not used externally to getSearchAutocompleteResults
      val: string;
    }
  | {
      type: 'person' | 'project' | 'timeoff';
      isActive: boolean;
      normalizedVal: string;
      val: string;
    }
  | {
      type: 'department';
      parent_id: number | null;
      id: number;
      normalizedVal: string;
      val: string;
    }
  | {
      type: 'projectStatus';
      normalizedVal: string;
      val: string;
      hideCategoryName?: boolean; // Required by the Mobile
    }
  | {
      type: 'savedSearch';
      filters: FilterToken[];
      val: string;
      normalizedVal: string;
    };

export type SearchAutocompleteResultItem =
  | {
      type:
        | 'client'
        | 'contains'
        | 'jobTitle'
        | 'manager'
        | 'personTag'
        | 'personType'
        | 'phase'
        | 'projectOwner'
        | 'projectTag'
        | 'task'
        | 'taskStatus'
        | 'timeoffStatus'
        | 'person'
        | 'project'
        | 'timeoff'
        | 'department';
      val: string;
    }
  | {
      type: 'projectStatus';
      val: string;
      hideCategoryName?: boolean; // Required by the Mobile
    }
  | {
      type: 'savedSearch';
      filters: FilterToken[];
      val: string;
    };

export const getSearchAutocompleteResults = memoizeWithArgs(
  (state: ReduxStateStrict, params: SearchAutocompleteParams) => {
    const { rawInput, expandedCategory } = params;
    const { operator, val } = getValueAndOpFromStr(rawInput);
    const input = normalize(val);

    let myProjectsAdded = false;

    const result: SearchAutocompleteResultItem[] = [];
    const categoryIndices: Record<string, number> = {};
    const categorySizes: Record<string, { shown: number; total: number }> = {};

    function addToResults(values: SearchAutocompleteQueryItem[]) {
      // Using for..of to avoid to incur with "RangeError: Maximum call stack size exceeded"
      for (const value of values) {
        if (value.type !== 'savedSearch') {
          result.push({
            type: value.type,
            val: value.val,
          });
        } else {
          result.push({
            type: value.type,
            val: value.val,
            filters: value.filters,
          });
        }
      }
    }

    const keys = expandedCategory
      ? [expandedCategory]
      : getFilterableKeys(state, params.isLogTimeView);

    for (const key of keys) {
      if (operator && key === 'savedSearches') {
        // We don't support adding modifiers directly to a saved search
        continue;
      }

      if (params.myProjectsItem) {
        // On mobile a virtual "My projects" filter is added
        // right below the saved searches
        if (key !== 'savedSearches' && !myProjectsAdded) {
          myProjectsAdded = true;
          result.push({
            type: 'projectStatus',
            val: 'My projects',
            hideCategoryName: true,
          });
          categoryIndices.projectStatuses = result.length - 1;
          categorySizes.projectStatuses = { shown: 1, total: 1 };
        }
      }

      const { candidates, size } = getFilterableKeyCandidates(state, {
        key,
        input,
        subDepartments: params.subDepartments,
        remoteQueryItems: params.remoteQueryResult?.items,
      });

      if (expandedCategory === 'timeoffs') {
        // When the timoffs category is expanded
        // we show the "Any" filter
        candidates.unshift({
          type: 'timeoff',
          val: '*',
          normalizedVal: '*',
          isActive: true,
        });
      }

      if (candidates.length) {
        categoryIndices[key] = result.length;

        // On the web app, when a category is not expanded
        // we limit the results per category to MAX_PER_CATEGORY

        if (params.remoteQueryResult && expandedCategory) {
          categorySizes[key] = {
            shown: candidates.length,
            total: params.remoteQueryResult.count,
          };
          addToResults(candidates);
        } else if (params.truncateResults && !expandedCategory) {
          const truncated = truncateCandidates({
            key,
            candidates,
            expandedCategory,
            maxPerCategory: MAX_PER_CATEGORY,
          });
          categorySizes[key] = { shown: truncated.length, total: size };
          addToResults(truncated);
        } else {
          categorySizes[key] = { shown: candidates.length, total: size };
          addToResults(candidates);
        }
      }
    }

    // When the category is not expanded we show a "contains" item
    // to run a contains search on all the possible categories
    if (!operator && !expandedCategory && input && params.containsItem) {
      categoryIndices.contains = result.length;
      categorySizes.contains = { shown: 1, total: 1 };
      result.push({
        type: 'contains',
        val: input,
      });
    }

    return { result, categoryIndices, categorySizes, input: rawInput };
  },
);

export type SearchAutocompleteResults = ReturnType<
  typeof getSearchAutocompleteResults
>;
