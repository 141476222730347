import React from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import JoinComponents from '@float/common/components/elements/JoinComponents';
import { toFriendlyTime } from '@float/common/lib/time';
import { SerenaState } from '@float/common/selectors/serena';
import { getIsDebugTaskNamesEnabled } from '@float/libs/web/devtools/debugTaskNames';
import { CompanyPreferences, Phase } from '@float/types';
import { Icons } from '@float/ui/deprecated';

import { isArchived } from '../utils/isArchived';
import { addStartTimeElement } from './addStartTimeElement';
import { MainCellItem } from './types';

import {
  firstTextElement,
  iconGroupWidthVar,
  otherTextElement,
} from '../../EntityItemStyles.css';

export function getTextElements(
  entity: MainCellItem['entity'],
  numRows: number,
  iconGroupWidth: number,
  reduxData: SerenaState,
  companyPrefs: CompanyPreferences,
  suvSingleDay: string | null | undefined,
  logTimeView: boolean | undefined,
  color?: string,
  isTimer?: boolean,
) {
  const integrationStatus =
    'integration_status' in entity ? entity.integration_status : 0;

  const syncElement =
    integrationStatus === 1 ? (
      <Icons.SyncWhite
        color={color}
        style={{
          verticalAlign: -2,
        }}
      />
    ) : null;

  if ('timeoff_id' in entity && entity.timeoff_id) {
    const timeOffType = reduxData.timeoffTypes[entity.timeoff_type_id];
    return [
      <div
        key="0"
        className={firstTextElement}
        style={assignInlineVars({ [iconGroupWidthVar]: `${iconGroupWidth}px` })}
      >
        {entity.start_time &&
          !isTimer &&
          `${toFriendlyTime(entity.start_time, companyPrefs)} - `}
        {timeOffType && timeOffType.timeoff_type_name}
        {timeOffType && !timeOffType.active ? ' (Archived)' : ''}
      </div>,
    ];
  }

  const projectId = 'project_id' in entity ? entity.project_id : undefined;
  const phaseId = 'phase_id' in entity ? entity.phase_id : undefined;
  const project = projectId ? reduxData.projects[projectId] : undefined;
  const phase: Phase | undefined = phaseId
    ? reduxData.phases[phaseId]
    : undefined;

  let clientElement =
    project && project.client_name !== 'No Client' ? project.client_name : null;

  if (isArchived(project, phase)) {
    if (clientElement) {
      clientElement += ' (Archived)';
    } else {
      clientElement = '(Archived)';
    }
  }

  const elements = [];

  let entityName = 'name' in entity ? entity.name : undefined;

  if ('logged_time_id' in entity && entity.task_name) {
    entityName = entity.task_name;
  }

  if (getIsDebugTaskNamesEnabled()) {
    // @ts-expect-error it's ok to access and undefined property here
    // it's just for debug
    const id = entity.task_id || entity.timeoff_id;

    entityName = `[${id}] ${entityName}`;
  }

  const entityCalendarName =
    'ext_calendar_event_name' in entity
      ? entity.ext_calendar_event_name
      : undefined;

  const hasName = entityName || entityCalendarName;
  if (hasName) {
    if (syncElement) {
      elements.push(
        <>
          {entityCalendarName} {syncElement} {entityName}
        </>,
      );
    } else {
      elements.push(entityName);
    }
  } else {
    if (phase && phase.phase_name) {
      elements.push(phase.phase_name);
    }
  }

  if (project && project.project_name) {
    if (!hasName && syncElement) {
      elements.push(
        <>
          {syncElement} {project.project_name}
        </>,
      );
    } else {
      elements.push(project.project_name);
    }
  }

  if (clientElement) elements.push(clientElement);
  if (!logTimeView && !isTimer)
    addStartTimeElement(entity, elements, companyPrefs);

  if (numRows <= 1) {
    return [
      <div
        className={firstTextElement}
        style={assignInlineVars({ [iconGroupWidthVar]: `${iconGroupWidth}px` })}
        key="0"
      >
        <JoinComponents components={elements} />
      </div>,
    ];
  }

  if (numRows === 2) {
    const res = [];
    if (elements.length)
      res.push(
        <div
          key="0"
          className={firstTextElement}
          style={assignInlineVars({
            [iconGroupWidthVar]: `${iconGroupWidth}px`,
          })}
        >
          {elements[0]}
        </div>,
      );
    if (elements.length > 1) {
      res.push(
        <div className={otherTextElement} key="1">
          {elements.slice(1).join(' / ')}
        </div>,
      );
    }
    return res;
  }

  return elements.map((e, idx) => {
    if (idx === 0)
      return (
        <div
          key="0"
          className={firstTextElement}
          style={assignInlineVars({
            [iconGroupWidthVar]: `${iconGroupWidth}px`,
          })}
        >
          {e}
        </div>
      );
    return (
      <div className={otherTextElement} key={idx}>
        {' '}
        {e}{' '}
      </div>
    );
  });
}
