import { ComponentPropsWithRef } from 'react';

import { ModalProps } from '@float/ui/primitives/Modal';

export enum ModalConfirmAppearance {
  Info = 'info',
  Danger = 'danger',
  SoftDanger = 'soft-danger',
}

export type ModalConfirmProps = {
  appearance?: ModalConfirmAppearance;
  description: string;
  modalProps: ModalProps;
  onClickConfirm?: () => Promise<void> | void;
  onOpenChange?: (state: boolean) => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  title: string;
  twoStepConfirmation?: boolean;
} & ComponentPropsWithRef<'div'>;
