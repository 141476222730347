import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconPin = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m15.75 5.75 2.5 2.5m-2.5-2.5L8 10m7.75-4.25-1-1m3.5 3.5L14 16m4.25-7.75 1 1m-12.5-.5 8.5 8.5M11 13l-6.25 6.25"
      />
    </SVGIcon>
  );
};

export { IconPin };
