import React, {
  ComponentProps,
  CSSProperties,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { useAnchor } from '@float/libs/web/hooks/useAnchor';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import { IconClose } from '../Earhart/Icons';
import {
  StyledTooltipClose,
  StyledTooltipContent,
  StyledTooltipNotification,
  StyledTooltipPararaph,
  StyledTooltipTitle,
} from './styles';

import * as styles from './styles.css';

export type AnchorItem = {
  id: string;
  config?: {
    arrowPosition: string;
    position: 'top' | 'bottom' | 'left' | 'right';
    alignment: 'start' | 'center' | 'end';
    offset?: {
      y: number;
      x: number;
    };
    padding?: [number, number];
  };
};

export type AnchorData = {
  anchors: AnchorItem[];
};

const Content = (props: ComponentProps<typeof StyledTooltipContent>) => (
  <StyledTooltipContent {...props} />
);
const Title = (props: ComponentProps<typeof StyledTooltipTitle>) => (
  <StyledTooltipTitle {...props} />
);
const Paragraph = (props: ComponentProps<typeof StyledTooltipPararaph>) => (
  <StyledTooltipPararaph {...props} />
);
const ActionBar = (
  props: PropsWithChildren<{
    className?: string;
    justify?: 'end' | 'spaceBetween';
  }>,
) => (
  <div className={cn(props.className, styles.actionBar)}>{props.children}</div>
);
const Close = (props: ComponentProps<typeof StyledTooltipClose>) => (
  <StyledTooltipClose {...props}>
    <IconClose />
  </StyledTooltipClose>
);

export type CalloutProps = {
  visible: boolean;
  onClose: () => void;
  anchorData: AnchorData;
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
  width?: number;
};

interface BaseCalloutComponent extends React.FC<CalloutProps> {
  Content: typeof Content;
  Title: typeof Title;
  Paragraph: typeof Paragraph;
  ActionBar: typeof ActionBar;
  Close: typeof Close;
}

export const Callout: BaseCalloutComponent = (props) => {
  const anchor = useAnchor(props.anchorData, 'callout');

  if (!anchor.rect) return null;

  return createPortal(
    <CSSTransition
      appear
      unmountOnExit
      in={props.visible}
      timeout={1000}
      onExited={props.onClose}
    >
      <StyledTooltipNotification
        anchor={anchor}
        position={anchor.config?.position}
        alignment={anchor.config?.alignment}
        arrowPosition={anchor.config?.arrowPosition}
        bgColor={Colors.Radix.Primary.Flue[3]}
        borderColor={Colors.Primary.Flue.Light[8]}
        borderWidth={1.5}
        showClose={false}
        closeOnBgClick={true}
        onClose={props.onClose}
        className={props.className}
        style={props.style}
        width={props.width}
      >
        {props.children}
      </StyledTooltipNotification>
    </CSSTransition>,
    document.body,
  );
};

Callout.Content = Content;
Callout.Title = Title;
Callout.Paragraph = Paragraph;
Callout.ActionBar = ActionBar;
Callout.Close = Close;
