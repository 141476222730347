import React, { StrictMode, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';

import { useOnMount } from '@float/libs/hooks/useOnMount';
import { logger } from '@float/libs/logger';
import { noop } from '@float/libs/utils/noop';
import { FloatLogo } from '@float/ui/components/FloatLogo';

import PageNotFoundIllustration from './images/404-illustration.svg';

import * as styles from './PageNotFound.css';

export const logPageNotFound = () => {
  logger.warn(
    'An unsupported path was encountered, resulting in the Page Not Found component being displayed',
    {
      context: {
        pathname: location.pathname,
      },
    },
  );
};

export const PageNotFound = ({ onRender = noop }) => {
  useEffect(onRender, [onRender]);

  useOnMount(logPageNotFound);

  return (
    <StrictMode>
      <main className={styles.page}>
        <header className={styles.headerSection}>
          <div className={styles.headerInnerSection}>
            <FloatLogo width={132} />
          </div>
        </header>
        <section className={styles.content}>
          <img
            className={styles.image}
            src={PageNotFoundIllustration}
            width={529}
            alt={t`404 error illustration`}
          />
          <h2 className={styles.headerText}>
            <Trans>Page not found.</Trans>
          </h2>
          <p className={styles.paragraph}>
            <Trans>
              The page you're looking for can't be found. Check the URL and try
              again.
              <br />
              Return to{' '}
              <a className={styles.link} href={'/'} title={t`Go to home`}>
                {window.location.hostname}
              </a>{' '}
              or visit our{' '}
              <a
                className={styles.link}
                href="https://support.float.com/"
                title={t`Go to help center`}
              >
                Help Center
              </a>
              .
            </Trans>
          </p>
        </section>
      </main>
    </StrictMode>
  );
};
