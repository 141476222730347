import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { getUse24HourFormat } from '@float/common/lib/time';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { getUser } from '@float/common/selectors/currentUser';
import { useWebAppSelectorStrict } from '@float/web/lib/store';

import * as activityActions from '../actions';
import Activity from './Activity';

const ActivityWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  transform: translateX(${({ visible }) => (visible ? '0%' : '100%')});
  transition: transform 0.2s ease-in;
  width: 100%;
  max-width: 400px;
  z-index: 998;
  background: #fff;
  box-shadow: ${({ visible }) =>
    visible ? '-2px 2px 15px rgba(204, 204, 204, 0.5)' : 'none'};

  @media (max-width: 1010px) {
    top: 96px;
  }
`;

const ActivityWrapperContainer = ({
  visible,
  actions,
  disablePointerEvents,
}) => {
  const companyPrefs = useWebAppSelectorStrict(getCompanyPrefs);

  return createPortal(
    <ActivityWrapper
      visible={visible}
      disablePointerEvents={disablePointerEvents}
      key={String(getUse24HourFormat(companyPrefs))}
    >
      {visible && (
        <Activity
          visible={visible}
          onHide={actions.toggleActivityFeed}
          wasLoaded={true}
          activityIsOpen={visible}
        />
      )}
    </ActivityWrapper>,
    document.body,
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(activityActions, dispatch),
  },
});

// Note that while we don't use the "user" property directly in this component,
// we do want it to fully re-render when user changes as the time format
// flag is within.
const mapStateToProps = (state) => {
  return {
    visible: !!state.activityData.visible,
    user: getUser(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityWrapperContainer);
