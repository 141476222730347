import React from 'react';
import { t } from '@lingui/macro';

import { ComboboxField, ComboboxFieldProps } from '../components/ComboboxField';
import { useStatusFieldOptions } from './StatusField.hooks';

import * as styles from './StatusField.styles.css';

type StatusFieldName = 'project.status' | 'phase.status';

type StatusFieldProps<N extends StatusFieldName> = {
  name: N;
  onChange?: ComboboxFieldProps<N>['onChange'];
};

export function StatusField<N extends StatusFieldName>({
  name,
  onChange,
}: StatusFieldProps<N>) {
  const { options, readOnly } = useStatusFieldOptions();
  return (
    <ComboboxField
      readOnly={readOnly}
      name={name}
      label={t`Status`}
      options={options}
      className={styles.statusField}
      searchable={false}
      clearInputOnDropdownOpen={false}
      data-testid="projectStatusFieldDropdown"
      nonNullable
      onChange={onChange}
    />
  );
}
