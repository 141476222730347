import { userCanViewOthers } from '@float/common/lib/rights';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { selectPeopleIdsByClientMap } from '@float/common/selectors/clients/selectPeopleIdsByClientMap';
import { getUser } from '@float/common/selectors/currentUser';
import { selectPersonById } from '@float/common/selectors/people/people';
import { getPhaseById } from '@float/common/selectors/phases';
import { getProjectsMapRaw } from '@float/common/selectors/projects';
import { selectEnhancedProjectById } from '@float/common/selectors/projects/selectEnhancedProjectById';
import { selectProjectsTagsSearchTokensMap } from '@float/common/selectors/tags';
import { AccountType } from '@float/constants/accounts';
import { FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';
import { CurrentUser } from '@float/types/account';

import { SearchQueryItem } from '../../api/queryApi';
import { PROJECT_RELATED_TYPES } from '../../helpers';
import { getPersonStatuses } from './isCandidateVisible';

export function isRemoteResultVisibleForUser(
  candidate: SearchQueryItem,
  state: ReduxStateStrict,
) {
  const user = getUser(state) as CurrentUser;

  const userPeopleId = user.people_id;
  const userPrefs = user.prefs;

  if (config.platform === FloatAppPlatform.Mobile && userPrefs.mobile_suv) {
    if (candidate.type === 'projectOwner') {
      return candidate.id === user.account_id;
    }
    if (candidate.type === 'person') {
      return userPeopleId ? user.account_id === userPeopleId : false;
    }

    const person = userPeopleId ? selectPersonById(state, userPeopleId) : null;

    if (candidate.type === 'department') {
      return candidate.id === person?.department_id;
    }

    if (candidate.type === 'personTag') {
      const tags = person?.tags ?? [];
      return tags.some((tag) => tag.name === candidate.val);
    }

    if (candidate.type === 'jobTitle') {
      return person?.job_title === candidate.val;
    }

    if (candidate.type === 'personType') {
      const statuses = person ? getPersonStatuses(person) : [];
      return statuses.some((status) => status === candidate.val);
    }
  }

  // Account owners, admins, billing view everything
  if (
    [AccountType.AccountOwner, AccountType.Admin, AccountType.Billing].includes(
      user.account_type_id,
    )
  ) {
    return true;
  }

  // If you have view rights and aren't restricted to a dept, view everything
  const canViewOthers = userCanViewOthers(user);

  if (canViewOthers && !user.department_filter?.length) return true;

  // If you don't have view rights, don't show non-project related things
  if (!canViewOthers && !PROJECT_RELATED_TYPES.includes(candidate.type)) {
    return candidate.type === 'savedSearch';
  }

  // If you're a member, project manager or department manager with a department filter,
  // you see most things except for people in other departments and the other depts.
  if (user.department_filter_all?.length) {
    if (candidate.type === 'department') {
      return user.department_filter_all.includes(candidate.id);
    }

    if (candidate.type === 'person') {
      if (!candidate.id) return false;
      if (!candidate.id) return false;

      const person = selectPersonById(state, candidate.id);
      const departmentId = person?.department_id;

      if (!departmentId) return false;

      return user.department_filter_all.includes(departmentId);
    }

    return true;
  }

  // Everything that follows is a member with no view rights and no dept filter,
  // which means that they only see things that are explicitly relevant to them.
  if (candidate.type === 'project') {
    if (!userPeopleId) return false;

    const ehnancedProject = selectEnhancedProjectById(state, candidate.id);

    if (!ehnancedProject) return false;

    return ehnancedProject.all_people_ids.includes(userPeopleId);
  }

  if (candidate.type === 'projectTag') {
    if (!userPeopleId) return false;

    const projectsTagsMap = selectProjectsTagsSearchTokensMap(state);
    const projectTag = projectsTagsMap.get(candidate.val);

    if (!projectTag) return false;

    const projects = getProjectsMapRaw(state);

    return projectTag.projectIds.some(
      (projectId) => projects[projectId]?.people_ids.includes(userPeopleId),
    );
  }

  if (candidate.type === 'phase') {
    if (!userPeopleId) return false;

    const phase = getPhaseById(state, candidate.id);

    if (!phase) return false;

    return phase.people_ids.includes(userPeopleId);
  }

  if (candidate.type === 'client') {
    if (!candidate.id || !userPeopleId) return false;

    const peopleIds = selectPeopleIdsByClientMap(state).get(candidate.id);

    if (!peopleIds) return false;

    return peopleIds.has(candidate.id);
  }

  // Task ACL checks are done on the server side
  return true;
}
