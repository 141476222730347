import React, { useCallback, useState } from 'react';

import { Button } from '@float/ui/deprecated';
import { Card } from '@float/ui/deprecated/Earhart/Cards';
import { TagStatic } from '@float/ui/deprecated/Earhart/Tags';

import { StyledRow } from '../styles';
import { ConfigureDomainRestiction } from './ConfigureDomainRestiction';

type DomainRestrictionSectionProps = {
  domains: string[];
  defaultDomain: string;
  onSubmit: (arg: string[]) => Promise<void>;
  hasPlusPack: boolean;
  hasTimeTracking: boolean;
  showSnackbar: (text: string) => void;
};

const DomainRestrictionSection = ({
  domains,
  defaultDomain,
  onSubmit,
  hasPlusPack,
  hasTimeTracking,
  showSnackbar,
}: DomainRestrictionSectionProps) => {
  const [isConfiguring, setIsConfiguring] = useState(false);
  const isEnabled = hasTimeTracking || hasPlusPack;
  const toggleConfiguring = useCallback(
    (val = !isConfiguring) => {
      setIsConfiguring(val);
    },
    [isConfiguring],
  );

  const handleSave = useCallback(
    async (domains: string[]) => {
      await onSubmit(domains);

      showSnackbar('Domain restrictions updated.');

      toggleConfiguring(false);
    },
    [toggleConfiguring, onSubmit, showSnackbar],
  );

  const label = domains.length ? (
    <span>
      Your team needs to sign in using their{' '}
      <b style={{ color: '#262626' }}>{domains.join(', ')}</b> account
      {domains.length > 1 ? 's' : ''}.
    </span>
  ) : (
    <span>
      Restrict team invites and account access to specific domains, e.g.
      @yourcompany.com.
    </span>
  );

  return (
    <Card>
      <StyledRow>
        <h4>
          Domain sign in restriction{' '}
          {(!isEnabled || !domains.length) && (
            <TagStatic>Recommended</TagStatic>
          )}
        </h4>
      </StyledRow>

      <StyledRow>
        <p>{label}</p>
      </StyledRow>

      <StyledRow direction="row">
        {isConfiguring ? (
          <ConfigureDomainRestiction
            domains={domains}
            defaultDomain={defaultDomain}
            onSubmit={handleSave}
            onCancel={() => {
              toggleConfiguring(false);
            }}
          />
        ) : (
          <Button
            appearance="secondary"
            size="large"
            alignment="center"
            disabled={!isEnabled}
            onClick={() => toggleConfiguring(true)}
          >
            {domains?.length ? 'Edit' : 'Configure'}
          </Button>
        )}
      </StyledRow>
    </Card>
  );
};

export default DomainRestrictionSection;
