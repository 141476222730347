import { useCallback } from 'react';

import { PROMPT_ID } from '@float/types/onboarding';
import {
  useWebAppDispatchStrict,
  useWebAppSelector,
} from '@float/web/lib/store';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';
import { isPromptDone } from '@float/web/store/onboardingManager/selectors/common';

export function usePromptTracker(id: PROMPT_ID) {
  const done = useWebAppSelector((state) => isPromptDone(state, id));

  const dispatch = useWebAppDispatchStrict();

  const setDone = useCallback(() => {
    if (!done) {
      dispatch(updatePrompts([id], true));
    }
  }, [dispatch, id, done]);

  return [done, setDone] as const;
}
