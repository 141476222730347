import styled from 'styled-components';

import EH from '@float/ui/deprecated/Earhart';

export const StyledRow = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};

  color: ${EH.Colors.FIN.Lt.Emphasis.High};

  & + & {
    margin-top: 20px;
  }

  h4 {
    ${EH.Typography.TextXXL.M500};
    font-family: Lexend;

    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
    }
  }

  p {
    ${EH.Typography.TextM.R400};

    color: ${EH.Colors.FIN.Lt.Emphasis.High};
  }

  a {
    ${EH.Typography.TextM.SB600};

    color: ${EH.Colors.FIN.Lt.Buttons.Primary.Default.Bg};

    &:hover {
      color: ${EH.Colors.FIN.Lt.Buttons.Primary.Hover.Bg};
    }
  }
`;
