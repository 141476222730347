import React from 'react';
import { Link } from 'react-router-dom';
import { Plural, Trans } from '@lingui/macro';

import {
  AlertBar,
  AlertBarVariantType,
} from '@float/ui/components/AlertBar/AlertBar';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';
import { Placeholders } from '@float/web/settingsV2/components/constants';

export type DowngradeAlertProps = {
  exceedingPlaceholders: number;
  downgradingToPlan: keyof typeof Placeholders;
};

export const DowngradeAlert = (props: DowngradeAlertProps) => {
  const { exceedingPlaceholders, downgradingToPlan } = props;

  return (
    <AlertBar
      icon={IconWarningTriangle}
      message={
        <span>
          <Trans>
            You’ll need to archive{' '}
            <Plural
              value={exceedingPlaceholders}
              one="# Placeholder"
              other="# Placeholders"
            />{' '}
            to be within the {downgradingToPlan} plan’s{' '}
            <Plural
              value={Placeholders[downgradingToPlan]}
              one="# Placeholder"
              other="# Placeholders"
            />{' '}
            limit before downgrading. These can be re-activated as standard
            seats once you’re on your new plan.
            <br /> <br />
            <Link to="/people">Go to the People page</Link> to archive
            Placeholders.
          </Trans>
        </span>
      }
      type={AlertBarVariantType.Warning}
      role="alert"
    />
  );
};
