import React from 'react';
import cn from 'classnames';

import { userAgent } from '@float/libs/web/detect';
import { FloatLogo } from '@float/ui/components/FloatLogo';

import * as styles from './FullPageLoader.css';

export const FullPageLoader = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children?: React.ReactNode;
}) => {
  if (isLoading) {
    return (
      <div className={`app-wrapper ua-${userAgent}`}>
        <div className="app-inner">
          <div className={cn(styles.loader, 'schedule-loader')}>
            <FloatLogo width={100} wordmark={false} />
          </div>
        </div>
      </div>
    );
  }

  return children ?? null;
};
