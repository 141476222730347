/**
 * @todo:
 * 1. toUiFormat and toNodesFrom do similar things, with the latter also creating the link nodes. We could probably unify both
 *    and avoid having to parse the links on mount.
 */

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';
import { Editor, Path, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import styled from 'styled-components';

import { getPeopleWithAccount } from '@float/common/selectors/people';
import { useAppSelector } from '@float/common/store';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { filter } from '@float/ui/deprecated/Earhart/RichText/utils/filter';
import { FieldWrapper } from '@float/ui/deprecated/Input/styles';
import { FieldLabel } from '@float/ui/deprecated/Label';

import Element from './components/element';
import { SlateEditor } from './components/SlateEditor';
import { useMentions } from './hooks/useMentions';
import { hasLink } from './plugins/utils/has-link';
import { linkifyOnKeyDown } from './plugins/utils/linkify-on-key-down';
import { toNodesFrom } from './plugins/utils/nodes';
import {
  StyledInput,
  StyledInputContainer,
  StyledTextAreaWrapper,
  StyledVirtualList,
} from './styles';
import { createEditor } from './utils/createEditor';
import { getInitialValue } from './utils/getInitialValue';

export const ReziseHandleWrapper = styled.div`
  width: calc(100% + 6px);
  margin-bottom: -4px;
  padding-bottom: 4px;

  min-height: 64px;

  [data-slate-placeholder] {
    color: ${Colors.Core.Text.Subdued};
  }

  .slate-editor {
    outline: none;
  }

  overflow: auto;
  resize: vertical;
`;

const RichText = forwardRef((props, ref) => {
  const {
    className,
    inputClassName,
    style,
    label = '',
    placeholder = '',
    value = '',
    valueMeta = null,
    readOnly = false,
    maxLength = 1500,
    onChange,
    // onEscape,
    onBlur,
    onKeyDown,
    onTabKey,
    mentionsEnabled = false,
    mentionsData = null,
    mentionsTrigger = '@',
    mentionsValueField = 'name',
    mentionsWithAvatar = true,
    autoFocus = false,
    onFocus,
    textColor,
    background,
  } = props;

  const virtualListRef = useRef();
  const styledTextWrapperRef = useRef();

  const focused = useRef(false);
  const people = useAppSelector(getPeopleWithAccount);

  const [editor] = useState(() => createEditor({ maxLength }));

  const mentions = useMentions(
    filter,
    mentionsEnabled,
    mentionsData || people || [],
    mentionsTrigger,
    mentionsValueField,
    virtualListRef,
    editor,
  );

  const [initialValue, setInitialValue] = useState(
    () => getInitialValue(value, valueMeta, maxLength),
    [],
  );

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const setFocused = useCallback((state) => {
    focused.current = state;

    if (focused.current) {
      styledTextWrapperRef.current.classList.add('focused');
    } else {
      styledTextWrapperRef.current.classList.remove('focused');
    }
  }, []);

  const handleMouseDown = useCallback(
    (e) => {
      if (readOnly) return;

      // because the input doesn't fill the whole
      // StyledTextAreaWrapper area, we are making sure
      // the the input get the focus when this get clicked
      if (e.currentTarget == e.target) {
        setTimeout(() => {
          ReactEditor.focus(editor);
          Transforms.select(editor, Editor.end(editor, []));
        }, 0);
      }
    },
    [editor, readOnly],
  );

  const handleFocus = useCallback(() => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [setFocused, onFocus]);

  const handleTabFocus = useCallback(() => {
    ReactEditor.focus(editor);
    Transforms.select(editor, Editor.end(editor, []));
  }, [editor]);

  const handleTabKey = useCallback(
    (e) => {
      if (onTabKey) onTabKey(e);
    },
    [onTabKey],
  );

  const handleBlur = useCallback(() => {
    setFocused(false);

    // linkify on blur in case there are links that weren't picked up while typing
    const textNodes = Editor.nodes(editor, {
      at: [],
      match: (node, path) => {
        const parentNode =
          path.length > 0 ? Editor.node(editor, Path.parent(path)) : null;

        return (
          Text.isText(node) &&
          hasLink(node.text) &&
          parentNode &&
          parentNode[0].type !== 'link'
        );
      },
    });

    for (const node of textNodes) {
      const nodes = toNodesFrom(node[0].text);

      Transforms.select(editor, node[1]);
      Editor.insertFragment(editor, nodes);
    }

    editor.selection = null;

    if (onBlur) {
      onBlur();
    }
  }, [editor, onBlur, setFocused]);

  const handleChange = useCallback(
    (data) => {
      if (onChange) onChange(data);
      if (mentions && mentions.enabled) mentions.handleChange();
    },
    [onChange, mentions],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (onKeyDown) onKeyDown(e);

      if (mentions && mentions.enabled) mentions.handleKeyDown(e);

      linkifyOnKeyDown(e, editor);
    },
    [editor, mentions, onKeyDown],
  );

  const handleSelection = useCallback(
    (item, index, e) => {
      // prevent editor from losing focus when
      // clicking on one option from the list
      if (e) e.preventDefault();

      if (mentions && mentions.enabled) mentions.handleSelection(item);
    },
    [mentions],
  );

  useImperativeHandle(ref, () => ({
    editor,
    focus: () => {
      setTimeout(() => {
        ReactEditor.focus(editor);
        Transforms.select(editor, Editor.end(editor, []));
      }, 0);
    },
  }));

  // update editor when value changes without user interaction
  useUpdateEffect(() => {
    if (value !== undefined && value !== null && !focused.current) {
      setInitialValue(getInitialValue(value, valueMeta, maxLength));
    }
  }, [value, valueMeta]);

  useUpdateEffect(() => {
    if (!focused.current) {
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      });

      Transforms.insertFragment(editor, initialValue, {
        at: Editor.start(editor, []),
      });
    }
  }, [initialValue]);
  // --

  // make sure we linkify when initialValue is set or changes
  useEffect(() => {
    const textNodes = Editor.nodes(editor, {
      at: [],
      match: (node, path) => {
        const parentNode =
          path.length > 0 ? Editor.node(editor, Path.parent(path)) : null;

        return (
          Text.isText(node) &&
          hasLink(node.text) &&
          parentNode &&
          parentNode[0].type !== 'link'
        );
      },
    });

    for (const node of textNodes) {
      const nodes = toNodesFrom(node[0].text);

      Transforms.select(editor, node[1]);
      Editor.insertFragment(editor, nodes);
    }
  }, [editor, initialValue]);

  return (
    <StyledInputContainer ref={ref} className={className} style={style}>
      <FieldWrapper>
        <FieldLabel>{label}</FieldLabel>
        <StyledTextAreaWrapper
          ref={styledTextWrapperRef}
          onMouseDown={handleMouseDown}
          readOnly={readOnly}
          background={background}
          textColor={textColor}
          withValue={!!value}
        >
          <ReziseHandleWrapper onMouseDown={handleMouseDown}>
            <StyledInput>
              <SlateEditor
                autoFocus={autoFocus}
                className={inputClassName}
                editor={editor}
                value={initialValue}
                placeholder={placeholder}
                readOnly={readOnly}
                renderElement={renderElement}
                focused={focused}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onTabFocus={handleTabFocus}
                onTabKey={handleTabKey}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </StyledInput>
          </ReziseHandleWrapper>
        </StyledTextAreaWrapper>
      </FieldWrapper>

      {createPortal(
        <CSSTransition
          in={
            mentions &&
            mentions.enabled &&
            mentions.target &&
            mentions.results.length > 0
          }
          classNames="virtual-list"
          timeout={300}
          unmountOnExit
        >
          <StyledVirtualList
            ref={virtualListRef}
            items={mentions.results}
            defaultHighlightedItem={0}
            onSelection={handleSelection}
            withAvatar={mentionsWithAvatar}
            field={mentionsValueField}
          />
        </CSSTransition>,
        document.body,
      )}
    </StyledInputContainer>
  );
});

export default RichText;
