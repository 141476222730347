import React from 'react';
import { t } from '@lingui/macro';

import { TIME_FORMAT } from '@float/constants/time';
import { CellItem } from '@float/types';
import { IconActiveTimer } from '@float/ui/deprecated/Earhart/Icons/Icon/IconActiveTimer';
import IconLogged from '@float/ui/deprecated/Earhart/Icons/Icon/IconLogged';

import { formatHours } from '../utils/formatHours';

import { hourElement } from '../../EntityItemStyles.css';

export function getHourElements(
  item: CellItem<'task'> | CellItem<'timeoff'> | CellItem<'loggedTime'>,
  color: string | undefined,
  isFullDay: boolean,
  displayFormat: keyof typeof TIME_FORMAT,
) {
  const { hasActiveTimer } = item;

  if (item.type === 'loggedTime') {
    if (hasActiveTimer) {
      return (
        <div className={hourElement}>
          <IconActiveTimer color={color} size={15} data-testid="icon-clock" />
          {t`Timer running...`}
        </div>
      );
    }

    return (
      <div className={hourElement}>
        {!item.isTaskReference && (
          <IconLogged color={color} size={15} data-testid="icon-clock" />
        )}
        {formatHours(item.entity, false, displayFormat)}
      </div>
    );
  } else if (!isFullDay) {
    return <div className={hourElement}>{formatHours(item.entity)}</div>;
  }

  return null;
}
