import useSWR, { BareFetcher, Fetcher, SWRConfiguration } from 'swr';

/**
 * A version of the useSWR hook that takes an `isDataFetchingEnabled` attribute
 * which will stop the hook from making requests when set to false
 */
export const useStoppableSWR = <Data, Args extends readonly [...unknown[]]>(
  key: string,
  fetcher: Fetcher<Data, Args>,
  options: SWRConfiguration<Data, Error, BareFetcher<Data>> & {
    isDataFetchingEnabled: boolean;
  },
) => {
  const { isDataFetchingEnabled, ...swrOptions } = options;

  const maybeKey = isDataFetchingEnabled ? key : null;

  const results = useSWR(maybeKey, fetcher, swrOptions);

  return results;
};
