import React from 'react';
import modalManagerHoc from 'modalManager/modalManagerHoc';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button, Input, Modal } from '@float/ui/deprecated';

class AddBookmarkModal extends React.Component {
  state = { value: '' };

  hide = (evt) => {
    prevent(evt);
    this.props.manageModal({
      visible: false,
      modalType: 'addBookmarkModal',
    });
  };

  save = (evt) => {
    prevent(evt);
    this.props.onSave({ name: this.state.value });
    this.hide();
  };

  setValue = (evt) => {
    this.setState({ value: evt.currentTarget.value });
  };

  render() {
    const { value } = this.state;

    return (
      <Modal isOpen onClose={this.hide}>
        <Modal.Header>
          <Modal.Title>Save filter</Modal.Title>
        </Modal.Header>
        <form onSubmit={this.save}>
          <Modal.Body>
            <Input
              name="search_name"
              label="Filter name"
              value={value}
              autoFocus
              required
              onChange={this.setValue}
            />
          </Modal.Body>

          <Modal.Actions>
            <Button type="submit">Save</Button>
            <Button appearance="secondary" onClick={this.hide}>
              Cancel
            </Button>
          </Modal.Actions>
        </form>
      </Modal>
    );
  }
}

export default modalManagerHoc({
  Comp: AddBookmarkModal,
  modalType: 'addBookmarkModal',
});
