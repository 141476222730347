import * as Comlink from 'comlink';

import CellColorsWorker from './cellColors.worker?worker';
import { CellWorkerApi } from './cellColors.worker';

export function spawnCellStylesWorker() {
  const worker = new CellColorsWorker();

  return Comlink.wrap<CellWorkerApi>(worker as Worker);
}
