import { useEffect, useState } from 'react';

export const useIsTotalHoursInputDisabled = (
  numberOfAllocationDays: number,
) => {
  // We want to keep the enabled state, otherwise total input will become disabled when
  // user will type into total hours and the number of allocation days temporarily will become 1
  const [isTotalHoursInputEnabled, setIsTotalHoursInputEnabled] = useState(
    () => numberOfAllocationDays > 1,
  );

  // When number of allocation days becomes more than one day
  // We enable the input
  useEffect(() => {
    if (numberOfAllocationDays > 1 && !isTotalHoursInputEnabled) {
      setIsTotalHoursInputEnabled(true);
    }
  }, [
    numberOfAllocationDays,
    isTotalHoursInputEnabled,
    setIsTotalHoursInputEnabled,
  ]);

  const isTotalHoursInputDisabled = !isTotalHoursInputEnabled;

  return isTotalHoursInputDisabled;
};
