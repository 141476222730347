import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useUpdateEffect } from 'react-use';
import gsap from 'gsap';
import Flip from 'gsap/Flip';
import { trackOnboardingPrompt } from 'OnboardingManager/helpers/analytics';

import { positioner } from '@float/common/components/Schedule/Cell/MainCell/styles.css';
import { useAppSelector } from '@float/common/store';
import { PROMPTS } from '@float/constants/onboarding';
import useWindowSize from '@float/libs/web/hooks/useWindowSize';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import {
  StyledAddTaskContainer,
  StyledClickDrag,
  StyledContainer,
  StyledTooltipContent,
  StyledTooltipNotification,
} from './styles';

export const AddTask = memo((props: { next: (prompt: number) => void }) => {
  const { next } = props;

  const [height, setHeight] = useState(0);
  const [left, setLeft] = useState(0);
  const [visible, setVisible] = useState(false);

  const elRef = useRef<HTMLElement>();
  const clickDragRef = useRef<HTMLDivElement>(null);
  const timeline = useRef(
    null,
  ) as React.MutableRefObject<gsap.core.Timeline | null>;

  const density = useAppSelector((state) => state.currentUser.prefs.sked_zoom);

  const screen = useWindowSize(true, 600);

  const onClose = useCallback(() => {
    if (timeline.current) {
      timeline.current.clear();
      timeline.current.kill();
      timeline.current = null;
    }

    if (clickDragRef.current) {
      gsap.to(clickDragRef.current, { opacity: 0, ease: 'power3.out' });
    }

    setVisible(false);

    trackOnboardingPrompt(PROMPTS.addFirstTask, undefined, 1);
  }, [timeline]);

  const onExited = useCallback(() => {
    next(PROMPTS.addFirstTask);
  }, [next]);

  useEffect(() => {
    window.requestIdleCallback(() => {
      const el = document.querySelector<HTMLElement>(`.${positioner}`);

      if (el) {
        const rect = el.getBoundingClientRect();

        setLeft(rect.right);
        setHeight(el.offsetHeight);

        setVisible(true);

        elRef.current = el;
      }
    });
  }, [density]);

  useUpdateEffect(() => {
    window.requestIdleCallback(() => {
      if (elRef.current) {
        const rect = elRef.current.getBoundingClientRect();

        setLeft(rect.right);
      }
    });
  }, [screen.width, screen.height]);

  useEffect(() => {
    if (visible && !timeline.current && clickDragRef.current) {
      const clickDragEl = clickDragRef.current;
      const circles = clickDragEl.querySelectorAll('div');

      gsap.set(circles, { opacity: 0, scale: 0.95 });

      const tml = gsap.timeline({ delay: 0.5, repeat: -1, repeatDelay: 0.5 });

      tml.set(clickDragRef.current, { width: undefined });

      tml.to(circles, { duration: 0.75, opacity: 1, scale: 1, stagger: 0.05 });

      tml.to(circles, {
        duration: 0.25,
        scale: 0.9,
        stagger: -0.05,
        yoyo: true,
        yoyoEase: 'power1.out',
        repeat: 1,
      });

      tml.to(circles, {
        duration: 0.25,
        opacity: 0,
        scale: 0.95,
        stagger: -0.05,
        delay: 0.25,
      });

      tml.to(circles, {
        duration: 0.5,
        opacity: 1,
        scale: 1,
        stagger: 0.05,
        delay: 0.25,
        ease: 'power3.inOut',
      });

      // @ts-expect-error Typescript is not aligned with the supported
      // return types
      tml.addPause('>', () => {
        // FLIP transition from current to next step
        const state = Flip.getState(clickDragEl);

        clickDragEl.style.width = '235px';

        return Flip.from(state, {
          duration: 0.65,
          ease: 'power3.inOut',
          onComplete: () => {
            tml.resume();
          },
        });
      });

      tml.to(circles, {
        duration: 0.5,
        opacity: 0,
        stagger: 0.05,
        delay: 0.25,
        ease: 'power3.inOut',
      });

      timeline.current = tml;

      trackOnboardingPrompt(PROMPTS.addFirstTask, undefined, 0);
    }
  }, [visible]);

  const portalTarget = document.querySelector('#serena-portal');

  if (!height || !portalTarget) {
    return null;
  }

  return createPortal(
    <CSSTransition in={visible} timeout={500} onExited={onExited} appear>
      <StyledContainer $height={height} $left={left}>
        <StyledAddTaskContainer>
          <StyledClickDrag ref={clickDragRef}>
            <div />
            <div />
          </StyledClickDrag>

          <StyledTooltipNotification
            position="bottom"
            borderColor={Colors.Radix.Primary.Flue[7]}
            width={240}
            showClose={false}
            closeOnBgClick={true}
            onClose={onClose}
          >
            <StyledTooltipContent>
              Select cells on the schedule to allocate time to someone
            </StyledTooltipContent>
          </StyledTooltipNotification>
        </StyledAddTaskContainer>
      </StyledContainer>
    </CSSTransition>,
    portalTarget,
  );
});
