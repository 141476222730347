import React, { Component } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';

import PersonAvatar from '@float/common/components/elements/PersonAvatar';
import { hexToRgb } from '@float/common/lib/colors/utils/oldColorUtils';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { NewIndicator } from '@float/web/components/Notifications/NewIndicator';

import ActivityItemMention from './ActivityItemMention';
import ActivityItemTitle from './ActivityItemTitle';
import TimeAgo from './TimeAgo';

const ExtendLink = styled.div`
  visibility: hidden;
  display: flex;
  padding: 6px 0;
`;

const Wrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border-bottom: 1px solid #e3e3e4;
  transition: background 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  padding: 15px 20px 17px 56px;
  position: relative;

  &.clickable {
    cursor: pointer;

    &:hover {
      ${ExtendLink} {
        visibility: visible;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;

  &:first-child {
    margin-top: 0;
  }
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${(p) => p.theme.medGrey};
  display: inline-block;
  margin: 0 1px;
`;

const PersonAvatarContainer = styled.div`
  position: absolute;
  left: 21px;
  width: 24px;
  height: 24px;
`;

const TimeAndMore = styled.div`
  text-align: right;
  margin-left: auto;
`;

const ExpandedContent = styled.div`
  flex-basis: 100%;
`;

const StyledBorderColor = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  background-color: ${(p) => p.color || 'transparent'};
`;

const StyledBorderGradient = styled(StyledBorderColor)`
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 1px,
    ${({ color }) => `rgba(${hexToRgb(color, '.2')})`} 2px,
    ${({ color }) => `rgba(${hexToRgb(color, '.1')})`} 4px
  );
`;

const newIndicatorStyle = {
  position: 'absolute',
  top: 5,
  right: 6,
};

const BorderColor = ({ item }) => {
  const { color } = item;
  if (!color) {
    return null;
  }

  if (item.item_type === 'timeoff') {
    return <StyledBorderGradient color={color} />;
  }

  return <StyledBorderColor color={color} />;
};

class ActivityItem extends Component {
  toggleExtended = (e) => {
    prevent(e);
    if (typeof this.props.onDetailsToggle === 'function') {
      this.props.onDetailsToggle();
    }
  };

  onActionClick = () => {
    return this.props.onActionClick(this.props.clickedData);
  };

  // Determine whether to show the activity item with green "completed" text
  isActionComplete() {
    // If they deleted the task, no complete
    if (get(this.props.baseTitleArgs, 'actionLabel') === 'deleted') {
      return false;
    }
    // If they just created the task, check the data
    if (!this.props.displayHistory.length) {
      return get(this.props, ['clickedData', 'after_data', 'status']) === 3;
    }
    // Otherwise, check the most recent update
    return (
      get(this.props.displayHistory, [0, 0, 'nextPrevData', 'next']) ===
      'Completed'
    );
  }
  render() {
    const {
      hideableContent,
      baseTitleArgs,
      timestamp,
      clickedData,
      style,
      expanded,
      bgColor,
    } = this.props;
    const { actioned_by: { account_id } = {} } = clickedData || {};
    if (this.isActionComplete()) {
      baseTitleArgs.actionComplete = true;
      baseTitleArgs.actionLabel = 'completed';
    }

    return (
      <Wrapper
        bgColor={clickedData.unseen ? '#eff5f8' : bgColor}
        style={style}
        className={baseTitleArgs.disabledLink ? '' : 'clickable'}
        onClick={baseTitleArgs.disabledLink ? undefined : this.onActionClick}
        data-testid={clickedData.activity_id}
      >
        <BorderColor item={clickedData} />
        {clickedData.unseen && (
          <NewIndicator style={newIndicatorStyle} fadeOut />
        )}
        <PersonAvatarContainer>
          <PersonAvatar accountId={account_id} size="xs" readOnly />
        </PersonAvatarContainer>
        <ActivityItemTitle {...baseTitleArgs} />
        <ActivityItemMention item={clickedData} />
        <TimeAndMore>
          <Row>
            <TimeAgo timestamp={timestamp} />
          </Row>
          <Row>
            {!!hideableContent && (
              <ExtendLink onClick={this.toggleExtended}>
                <Dot />
                <Dot />
                <Dot />
              </ExtendLink>
            )}
          </Row>
        </TimeAndMore>
        {expanded && hideableContent ? (
          <ExpandedContent>{hideableContent}</ExpandedContent>
        ) : null}
      </Wrapper>
    );
  }
}

export default ActivityItem;
