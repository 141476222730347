import React, { ChangeEvent, useState } from 'react';
import useSWRMutation from 'swr/mutation';

import API3 from '@float/common/api3';
import { trackEvent } from '@float/common/lib/analytics';
import { trackCompanyData } from '@float/common/lib/intercom';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalTitle,
} from '@float/ui/deprecated';

import {
  ErrorMessage,
  ModalFooter,
  PauseSubscriptionLink,
  StyledModalBody,
  StyledRow,
} from './styles';
import { getRequestError } from './utils';

export type ConfirmDeleteTeamProps = {
  onClose: () => void;
  onPauseClick?: () => void;
  onBeforeConfirm?: () => Promise<unknown>;
  companyName: string;
};

const getDeleteCompany = (cid: number) => async () => {
  const json = await API3.deleteCompany();

  trackEvent('company-deleted');
  trackCompanyData('company_deleted', true, cid);

  if (json.reload_url) {
    location.assign(json.reload_url);
  }
};

export function ConfirmDeleteTeam(props: ConfirmDeleteTeamProps) {
  const user = useAppSelectorStrict(getUser);
  const deleteCompanyMutation = useSWRMutation(
    'deleteCompany',
    getDeleteCompany(user.cid),
  );
  const [inputValue, setInputValue] = useState('');

  const submitDisabled = props.companyName !== inputValue;

  const handleDelete = async () => {
    if (props.onBeforeConfirm) {
      await props.onBeforeConfirm();
    }

    deleteCompanyMutation
      .trigger()
      // Avoid to trigger unhandledRejection
      .catch(() => {});
  };

  return (
    <Modal
      isOpen
      shouldCloseOnBgClick={false}
      shouldCloseOnEsc
      onClose={props.onClose}
    >
      <ModalHeader>
        <ModalTitle>Delete your team</ModalTitle>
      </ModalHeader>
      <StyledModalBody>
        <StyledRow $margin={20}>
          This will delete all of your project, people and data permanently.
          <div>This can't be undone.</div>
        </StyledRow>
        <StyledRow $margin={16}>
          Enter your team name (<b>{props.companyName}</b>) to proceed:
        </StyledRow>
        <StyledRow $margin={32}>
          <Input
            size="large"
            placeholder="Type team name"
            value={inputValue}
            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
              setInputValue(evt.target.value)
            }
          />
        </StyledRow>
        {deleteCompanyMutation.error && (
          <StyledRow $margin={16}>
            <ErrorMessage>
              {getRequestError(deleteCompanyMutation.error)}
            </ErrorMessage>
          </StyledRow>
        )}
        <StyledRow $margin={props.onPauseClick ? 16 : 32}>
          <Button
            appearance="danger"
            loader={deleteCompanyMutation.isMutating}
            onClick={handleDelete}
            disabled={submitDisabled}
          >
            Delete team
          </Button>{' '}
          <Button appearance="secondary" onClick={props.onClose}>
            Cancel
          </Button>
        </StyledRow>
        {props.onPauseClick && (
          <ModalFooter style={{ marginBottom: 32 }}>
            If you want to keep your data,{' '}
            <PauseSubscriptionLink onClick={props.onPauseClick}>
              pause your subscription instead
            </PauseSubscriptionLink>
            .
          </ModalFooter>
        )}
      </StyledModalBody>
    </Modal>
  );
}
