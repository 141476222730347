import { get } from 'lodash';

import { validateTaskName } from '@float/common/components/TaskNameSelect/validateTaskName';
import { TaskMetaOption } from '@float/common/selectors/taskMetas';
import { Project } from '@float/types';

export type ValidateTaskParams = {
  peopleIds: number[];
  project?: Project;
  hoursPd: number | null;
  taskName: string;
};

export type ValidateTaskError = Partial<
  Record<'assignedElem' | 'project' | 'hoursPd' | 'task', string[]>
>;
export function validateTask(
  taskMetas: TaskMetaOption[],
  { peopleIds, project, hoursPd, taskName }: ValidateTaskParams,
) {
  const errors: ValidateTaskError = {};

  if (!peopleIds || !peopleIds.length) {
    errors.assignedElem = ['Please select at least one person'];
  }
  if (!get(project, 'project_name')) {
    errors.project = ['Please select a project'];
  }
  if (!hoursPd || hoursPd === 0) {
    errors.hoursPd = ['Invalid'];
  }

  if (project?.locked_task_list == 1) {
    const taskNameError = validateTaskName({
      taskMetas,
      project,
      taskName,
    });

    if (taskNameError) {
      errors.task = taskNameError;
    }
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }
}
