import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconNonBillable = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 17.546H6.318c-.728 0-1.318-.59-1.318-1.319V8.318C5 7.59 5.59 7 6.318 7h11.864c.728 0 1.318.59 1.318 1.318V12M5 10.955h14.5M18.59 14.59l-3.181 3.183M18.591 17.773 15.41 14.59"
      />
    </SVGIcon>
  );
};

export { IconNonBillable };
