import isUndefined from 'lodash/isUndefined';
import useSWR from 'swr';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { LinearCyclesIntegrationConfig } from '@float/types/linearCyclesIntegration';

import {
  LINEAR_CYCLES_INTEGRATION_CONFIG_RESYNC_PATH,
  Resources,
} from './constants';
import { makeRequest, MakeRequestPagination } from './makeRequest';

export const getLinearCyclesIntegrationConfig =
  async (): Promise<LinearCyclesIntegrationConfig | null> =>
    makeRequest({
      resource: Resources.LinearCyclesIntegrationConfig,
      method: 'GET',
      pagination: MakeRequestPagination.Off,
    });

export type LinearCyclesIntegrationUpdatePayload = {
  feedUrl?: string | undefined;
  active?: boolean;
  version?: number;
};

export const upsertLinearCyclesIntegrationConfig = async (
  data: LinearCyclesIntegrationUpdatePayload,
): Promise<LinearCyclesIntegrationConfig> => {
  const method = isUndefined(data.version) ? 'POST' : 'PUT';

  return makeRequest({
    data,
    method,
    resource: Resources.LinearCyclesIntegrationConfig,
  });
};

export type LinearCyclesIntegrationDeletePayload = {
  version?: number;
};

export const deleteLinearCyclesIntegrationConfig = async (
  data: LinearCyclesIntegrationDeletePayload,
): Promise<null> => {
  return makeRequest({
    data,
    method: 'DELETE',
    resource: Resources.LinearCyclesIntegrationConfig,
  });
};

export type LinearCyclesIntegrationResyncPayload = {
  version?: number;
};

export const resyncLinearCyclesIntegrationConfig = async (
  data: LinearCyclesIntegrationResyncPayload,
): Promise<LinearCyclesIntegrationConfig> =>
  makeRequest({
    data,
    method: 'POST',
    resource: LINEAR_CYCLES_INTEGRATION_CONFIG_RESYNC_PATH,
  });

export function useLinearCyclesIntegrationConfig() {
  const { data: linearCyclesIntegrationConfig, isLoading } = useSWR(
    Resources.LinearCyclesIntegrationConfig,
    getLinearCyclesIntegrationConfig,
    {
      fallbackData: null,
    },
  );

  const isFeatureEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.LinearIntegration,
  );

  if (!isFeatureEnabled) {
    return {
      linearCyclesIntegrationConfig: null,
      isLoading: false,
    };
  }

  return {
    linearCyclesIntegrationConfig,
    isLoading,
  };
}
