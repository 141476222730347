import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconPlaceholder = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m12 5 6 7-6 7-6-7 6-7Z"
    />
  </SVGIcon>
);
