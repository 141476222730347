import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';

import {
  AccessPermissionAction,
  AccessPermissionItem,
} from '@float/common/api3/accessPermissions';
import CompanySelect from '@float/common/components/CompanySelect/CompanySelect';
import { SignupExist } from '@float/web/components/auth/SignupExist';
import withTitle from '@float/web/components/decorators/withTitle';
import { useBootstrapGlobalServicesForAuth } from '@float/web/lib/bootstrap/bootstrap.hooks';

import { FullPageLoader } from '../FullPageLoader';
import { PermissionGuard } from '../PermissionGuard';
import ReactivateSubscription from './ReactivateSubscription/ReactivateSubscription';

import './Auth.css';

export const Auth = () => {
  const { isReady } = useBootstrapGlobalServicesForAuth();

  return (
    <FullPageLoader isLoading={!isReady}>
      <AuthWithInitializedServices />
    </FullPageLoader>
  );
};

class AuthWithInitializedServices extends React.Component {
  getRootElement() {
    return document.querySelector('.app');
  }

  componentDidMount() {
    const el = this.getRootElement();

    el?.classList.add('scrollable-wrap');
  }

  componentWillUnmount() {
    const el = this.getRootElement();

    el?.classList.add('scrollable-wrap');
  }

  render() {
    return (
      <Switch>
        <Route
          path="/signup/exist"
          component={withTitle(t`Account exists | Float`, SignupExist)}
        />
        <Route
          path="/me/reactivate-subscription"
          component={withTitle(t`Reactivate subscription | Float`, () => (
            <PermissionGuard
              action={AccessPermissionAction.View}
              item={AccessPermissionItem.UIAdminBillingResumeSubscription}
              shouldRedirectIfNotPermitted
            >
              <ReactivateSubscription />
            </PermissionGuard>
          ))}
        />
        <Route
          path="/select-company"
          component={withTitle(t`Select company | Float`, CompanySelect)}
        />
      </Switch>
    );
  }
}

export default Auth;
