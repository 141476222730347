import {
  createListenerMiddleware,
  Dispatch,
  ListenerEffectAPI,
} from '@reduxjs/toolkit';
import client from 'socketcluster-client';
import { createActor, Subscription } from 'xstate';

import {
  FETCH_USER_SUCCESS,
  GET_JWT_SUCCESS,
  SEARCH_ADD_FILTERS,
  SEARCH_CLEAR_REMOVED_FILTERS,
  SEARCH_REMOVE_ALL_FILTERS,
  SEARCH_REMOVE_FILTER,
  SEARCH_SET_FILTERS,
} from '@float/common/actions';
import { VIEW_APPLIED, VIEW_UNAPPLY } from '@float/common/actions/views';
import { AllActionsStrict } from '@float/common/reducers';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { searchServiceReady } from '@float/common/reducers/search';
import { searchResultsUpdateAction } from '@float/common/reducers/searchResults';
import { getIsLogTimeView } from '@float/common/selectors/appInfo/scheduleView';
import { getUser } from '@float/common/selectors/currentUser';
import { selectIsSearchContextLoaded } from '@float/common/selectors/search';
import { getActiveFilters } from '@float/common/selectors/views';

import { SearchRemoteResolveMachine } from './SearchRemoteResolveMachine';

export const searchRemoteResolveListener = createListenerMiddleware<
  ReduxStateStrict,
  Dispatch<AllActionsStrict>
>();

const listenedActions = new Set<string>([
  SEARCH_ADD_FILTERS,
  SEARCH_CLEAR_REMOVED_FILTERS,
  SEARCH_REMOVE_ALL_FILTERS,
  SEARCH_REMOVE_FILTER,
  SEARCH_SET_FILTERS,
  VIEW_APPLIED,
  VIEW_UNAPPLY,
  FETCH_USER_SUCCESS, // To run initial resolve after the user is fetched
]);

function createSocket() {
  return client.create({
    secure: true,
    path: '/svc/search-context-ws/socketcluster/',
    autoReconnect: true,
  });
}

export function startListeningSearchRemoteResolve() {
  const searchRemoteResolveActor = createActor(SearchRemoteResolveMachine, {
    input: {
      socket: createSocket(),
    },
  });
  searchRemoteResolveActor.start();

  const unsubscribeAuthUpdates = searchRemoteResolveListener.startListening({
    predicate: (action) => action.type === GET_JWT_SUCCESS,
    effect: (_, listenerApi) => {
      const state = listenerApi.getState();
      const accessToken = state.jwt.accessToken;

      if (!accessToken) return;

      searchRemoteResolveActor.send({
        type: 'authTokenReceived',
        payload: accessToken,
      });
    },
  });

  let subscription: Subscription | null = null;

  // Listen to all the search filters related actions to trigger
  // a search resolution whenever a the filters changes and subscribe
  // to the search context WebSocket
  const unsubscribeSearchUpdates = searchRemoteResolveListener.startListening({
    // TODO: Trigger the effect when navigating in/out the log time view
    // https://linear.app/float-com/issue/PS-1459/frontend-trigger-a-new-resolution-when-navigating-inout-the-log-time

    // Check if an action should trigger the effect
    predicate: (action, currentState, originalState) => {
      if (listenedActions.has(action.type)) return true;

      if (getIsLogTimeView(currentState) !== getIsLogTimeView(originalState)) {
        return true;
      }

      return false;
    },

    // The effect stops the previous runs and requests a new search resolution
    effect: async (_, listenerApi) => {
      const state = listenerApi.getState();
      const filters = getActiveFilters(state);
      const isLogTimeView = getIsLogTimeView(state);

      if (subscription) {
        // We unsubscribe to avoid causing memory leaks
        subscription.unsubscribe();
      }

      // We subscribe here to get access to the Redux dispatch
      subscription = searchRemoteResolveActor.on(
        'searchResultsUpdated',
        ({ payload }) => {
          listenerApi.dispatch(searchResultsUpdateAction(payload));
          markSearchServiceAsReady(listenerApi);
        },
      );

      if (filters.length === 0) {
        searchRemoteResolveActor.send({
          type: 'searchParamsUpdatedWithNoFilters',
        });
        markSearchServiceAsReady(listenerApi);
      } else {
        searchRemoteResolveActor.send({
          type: 'searchParamsUpdated',
          payload: {
            filters,
            context: isLogTimeView ? 'logged_time' : 'schedule',
            user: getUser(state),
          },
        });
      }
    },
  });

  function markSearchServiceAsReady(
    listenerApi: ListenerEffectAPI<
      ReduxStateStrict,
      Dispatch<AllActionsStrict>,
      unknown
    >,
  ) {
    if (!selectIsSearchContextLoaded(listenerApi.getState())) {
      listenerApi.dispatch(searchServiceReady());
    }
  }

  // Unsubscribe function
  return () => {
    subscription?.unsubscribe();
    searchRemoteResolveActor.stop();
    unsubscribeSearchUpdates();
    unsubscribeAuthUpdates();
  };
}
