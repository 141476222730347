import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconBuilding = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86612 5.86612C7.10054 5.6317 7.41848 5.5 7.75 5.5H16.25C16.5815 5.5 16.8995 5.6317 17.1339 5.86612C17.3683 6.10054 17.5 6.41848 17.5 6.75V18.5H6.5V6.75C6.5 6.41848 6.6317 6.10054 6.86612 5.86612ZM5 18.5V6.75C5 6.02065 5.28973 5.32118 5.80546 4.80546C6.32118 4.28973 7.02065 4 7.75 4H16.25C16.9793 4 17.6788 4.28973 18.1945 4.80546C18.7103 5.32118 19 6.02065 19 6.75V18.5H19.25C19.6642 18.5 20 18.8358 20 19.25C20 19.6642 19.6642 20 19.25 20H18.25H5.75H4.75C4.33579 20 4 19.6642 4 19.25C4 18.8358 4.33579 18.5 4.75 18.5H5ZM10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11ZM11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14ZM14 11C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9C13.4477 9 13 9.44772 13 10C13 10.5523 13.4477 11 14 11ZM15 14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14Z"
        fill="#000"
      />
    </SVGIcon>
  );
};

export { IconBuilding };
