import React, { useCallback, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { flatMap } from 'lodash';
import modalManagerHoc from 'modalManager/modalManagerHoc';

import { trackEvent } from '@float/common/lib/gtm';
import { SerenaState } from '@float/common/selectors/serena';
import { ScheduleDataFetcher } from '@float/common/serena/Data/ScheduleDataFetcher';
import { RowMetas } from '@float/common/serena/Data/useRowMetas.helpers';
import { useAppStoreStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { CellsMap } from '@float/types/cells';
import { ScheduleRowList } from '@float/types/rows';
import {
  Button,
  DateRangePicker,
  ErrorText,
  ToggleGroup,
} from '@float/ui/deprecated';
import { useLoader } from '@float/ui/deprecated/helpers/formHooks';
import { Modal } from '@float/ui/deprecated/Modal';

import { ModalConfig } from '../../../../../modalManager/useManageModal';
import { convertToCsv } from './ExportCSV.converter';
import {
  ExportCSVActionType,
  ExportCSVStep,
  useExportCSVStateMachine,
} from './hooks/useExportCSVStateMachine';
import { parseData } from './parseData';
import rangeOpts, { endOfXUnits, startOf } from './rangeOpts';

const DATE_FORMAT = 'YYYY-MM-DD';

const viewByOptions = [
  { value: 'week', label: 'Week' },
  { value: 'day', label: 'Day' },
];

type ScheduleCSVExportModalProps = {
  cells: CellsMap;
  dates: DatesManager;
  fetcher: ScheduleDataFetcher;
  manageModal: (config: ModalConfig) => unknown;
  mondayStart: boolean;
  reduxData: SerenaState;
  rowMetas: RowMetas;
  rows: ScheduleRowList;
  viewType: 'people' | 'projects';
};

function ScheduleCSVExportModal(props: ScheduleCSVExportModalProps) {
  const { rows, reduxData, cells, mondayStart, dates, fetcher, viewType } =
    props;

  const [viewBy, setViewBy] = useState<string | undefined>('week');
  const [rangeMode, setRangeMode] = useState('0');
  const [state, dispatch] = useExportCSVStateMachine();
  const { loader, setLoading } = useLoader();
  const analyticsDataRef = useRef<{
    conversionTimeMs?: number;
    exportTriggeredAt?: number;
    fetchTimeMs?: number;
    parseTimeMs?: number;
  }>({});

  const [startDate, setStartDate] = useState(startOf('week')().toDate());
  const [endDate, setEndDate] = useState(endOfXUnits(12, 'weeks')().toDate());

  const momentRange = moment.range(moment(startDate), moment(endDate));

  function closeModal(e?: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) {
    prevent(e);

    props.manageModal({
      visible: false,
      modalType: 'scheduleCSVExportModal',
    });
  }

  async function triggerExport(e?: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) {
    prevent(e);
    setLoading();

    const start = moment(startDate).format(DATE_FORMAT);
    const end = moment(endDate).format(DATE_FORMAT);

    const [colStart] = dates.toDescriptor(start);
    const [colStop] = dates.toDescriptor(end);

    analyticsDataRef.current.exportTriggeredAt = Date.now();
    const dateRanges = await fetcher.ensureRangeFetched({ colStart, colStop });
    const rangesToFetch = flatMap(dateRanges, (r) => r.ranges);
    dispatch({ type: ExportCSVActionType.ExportTriggered, rangesToFetch });
  }

  const store = useAppStoreStrict();
  const triggerParse = useCallback(
    async function () {
      const payload = {
        rows,
        state: store.getState(),
        filters: reduxData.filters,
        filteredEntities: reduxData.filteredEntities,
        projects: reduxData.projects,
        phases: reduxData.phases,
        cells,
        startDate: moment(startDate).format(DATE_FORMAT),
        endDate: moment(endDate).format(DATE_FORMAT),
        viewBy,
        viewType,
        serializedDates: dates.serialize(),
        rowMetas: props.rowMetas,
        areNewParseModulesEnabled: featureFlags.isFeatureEnabled(
          FeatureFlag.ScheduleDataWindowing,
        ),
      };

      const start = Date.now();
      const result = parseData(payload);

      analyticsDataRef.current.parseTimeMs = Date.now() - start;

      dispatch({
        type: ExportCSVActionType.ParseComplete,
        result: result,
      });
    },
    [
      store,
      viewType,
      rows,
      reduxData.filters,
      reduxData.filteredEntities,
      reduxData.projects,
      reduxData.phases,
      cells,
      startDate,
      endDate,
      viewBy,
      props.rowMetas,
      dates,
      dispatch,
    ],
  );

  async function triggerCsvConversionAndDownload() {
    if (!state.result) return;

    const start = Date.now();

    const extraData = {
      companyName: reduxData.user.company_name,
      timeoffTypes: reduxData.timeoffTypes,
      projects: reduxData.projects,
      filters: reduxData.filters,
      viewBy,
      viewType: props.viewType,
    };

    convertToCsv(state.result, extraData).then((csv) => {
      analyticsDataRef.current.conversionTimeMs = Date.now() - start;
      // @ts-expect-error – convertToCsv is missing types
      dispatch({ type: ExportCSVActionType.CsvConversionComplete, csv });
    });
  }

  function onDateRangeChange(start: Moment, end: Moment, range: string) {
    setRangeMode(range);
    setStartDate(start.toDate());
    setEndDate(end.toDate());
  }

  useEffect(() => {
    if (state.step === ExportCSVStep.AwaitingFetch) {
      if (
        state.rangesToFetch.every((r) => cells?._fetchedRanges?.includes(r))
      ) {
        dispatch({ type: ExportCSVActionType.FetchComplete });
      }
    }

    if (state.step === ExportCSVStep.AwaitingParse) {
      analyticsDataRef.current.fetchTimeMs =
        Date.now() - (analyticsDataRef.current.exportTriggeredAt ?? 0);
      delete analyticsDataRef.current.exportTriggeredAt;
      triggerParse();
    }

    if (state.step === ExportCSVStep.AwaitingCsvConversion) {
      triggerCsvConversionAndDownload();
    }

    if (state.step === ExportCSVStep.Finished) {
      trackEvent('scheduleCsvExport', {
        ...analyticsDataRef.current,
        viewBy,
        viewType: props.viewType,
        lengthDays: moment(endDate).diff(moment(startDate), 'days'),
      });
      dispatch({ type: ExportCSVActionType.Reset });
      setLoading(false);
      closeModal();
    }
    // only update when state and _fetchedRanges changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, cells._fetchedRanges]);

  return (
    <Modal isOpen={true} onEnter={triggerExport} onClose={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <Trans>Export .CSV</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DateRangePicker
          label={t`Date range`}
          hidePrevNext
          maximumDays={365}
          style={{ display: 'inline-block', marginBottom: 13 }}
          firstOfWeek={mondayStart ? 1 : 0}
          rangeOpts={rangeOpts}
          rangeMode={rangeMode}
          value={momentRange}
          arrowOffset={36}
          onChange={onDateRangeChange}
        />
        <ToggleGroup
          label={t`View by`}
          value={viewBy}
          appearance="stitched"
          disableUnselect
          options={viewByOptions}
          onChange={setViewBy}
          optionStyle={{ padding: '0 18px' }}
        />
        {state.error && (
          <ErrorText style={{ paddingTop: 20 }}>{state.error}</ErrorText>
        )}
      </Modal.Body>
      <Modal.Actions>
        <form onSubmit={triggerExport}>
          <Button type="submit" disabled={loader.active} loader={loader}>
            <Trans>Export</Trans>
          </Button>
          <Button appearance="secondary" onClick={closeModal}>
            <Trans>Cancel</Trans>
          </Button>
        </form>
      </Modal.Actions>
    </Modal>
  );
}

export default modalManagerHoc({
  Comp: ScheduleCSVExportModal,
  modalType: 'scheduleCSVExportModal',
});
