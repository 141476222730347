import React, { forwardRef, useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import cs from 'classnames';
import { LayoutProps, motion } from 'framer-motion';

import { useOnMount } from '@float/libs/hooks/useOnMount';
import { TimeString } from '@float/types/datesManager';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';

import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import {
  InputAllocationHours,
  InputAllocationHoursProps,
} from '../../components/InputAllocationHours/InputAllocationHours';
import {
  InputAllocationTimeRange,
  InputAllocationTimeRangeProps,
} from '../../components/InputAllocationTimeRange/InputAllocationTimeRange';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import {
  getAllocationCorrectedHoursUsingHoursPerDay,
  getAllocationCorrectedHoursUsingHoursTotal,
} from '../../helpers/getAllocationCorrectedHours';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';
import { useAllocationSettings } from '../../hooks/useAllocationSettings';
import { useAllocationTimeToggle } from '../../hooks/useAllocationTimeToggle';
import { useIsTotalHoursInputDisabled } from '../../hooks/useIsTotalHoursInputDisabled';

import * as styles from '../../EditTaskModal.css';

export type AllocationTimeSectionProps = {
  layout: LayoutProps['layout'];
  startDate: Date;
  startTime: TimeString | null;
  endDate: Date;
  hoursPerDay: number;
  hoursTotal: number;
  peopleIds: number[];
  isReadOnly: boolean;
  is24HoursTimeFormat: boolean;
  onChange: (data: AllocationTimeSectionPayload) => void;
};

export const AllocationTimeSection = forwardRef<
  HTMLDivElement,
  AllocationTimeSectionProps
>((props, ref) => {
  const {
    layout,
    startDate,
    startTime,
    endDate,
    hoursPerDay,
    hoursTotal,
    peopleIds,
    isReadOnly,
    is24HoursTimeFormat,
    onChange,
  } = props;

  const { getIsWorkDay, getNumberOfAllocationDays, getEndDateFromTotalHours } =
    useAllocationDaysHelpers({
      peopleIds,
    });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate);
  }, [startDate, endDate, getNumberOfAllocationDays]);

  const minimumHoursPerDayValue = 0.1;
  const maximumHoursPerDayValue = 24;

  // The total hours might need a re-computation on mount
  useOnMount(() => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  });

  const isTotalHoursInputDisabled = useIsTotalHoursInputDisabled(
    numberOfAllocationDays,
  );

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const { allocationSettings, setAllocationSettings } = useAllocationSettings();

  const isFixedEndDate = allocationSettings.isFixedAllocationEndDate;

  const {
    isAllocationByTime,
    isAllocationByHours,
    handleClickSetSpecificTimeButton,
    handleClickSetTotalHoursButton,
  } = useAllocationTimeToggle({
    startTime,
    hoursPerDay,
    numberOfAllocationDays,
    onChange,
  });

  const handleHoursPerDayChange: InputAllocationHoursProps['onChangeHoursPerDay'] =
    (hoursPerDay) => {
      onChange({
        hoursPerDay,
      });
    };

  const handleHoursTotalChange: InputAllocationHoursProps['onChangeHoursTotal'] =
    (hoursTotal) => {
      // Update the total hours value
      if (isFixedEndDate) {
        onChange({
          hoursTotal,
        });
      }
      // adjust the date range and update the total hours value
      else {
        const endDateRecomputed = getEndDateFromTotalHours({
          startDate,
          hoursPerDay,
          hoursTotal: hoursTotal || hoursPerDay,
        });

        onChange({
          hoursTotal,
          endDate: endDateRecomputed,
        });
      }
    };

  const handleHoursPerDayBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  };

  const handleHoursTotalBlur = () => {
    if (isFixedEndDate) {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursTotal({
          hoursPerDay: Math.min(hoursPerDay, hoursTotal),
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    } else {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursPerDay({
          hoursPerDay: Math.min(hoursPerDay, hoursTotal),
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
  };

  const handleTimeRangeChange: InputAllocationTimeRangeProps['onChange'] = (
    data,
  ) => {
    const { startTime, hoursPerDay } = data;

    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      startTime,
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  };

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    const numberOfAllocationDays = getNumberOfAllocationDays(
      startDate,
      endDate,
    );

    // When the end date is fixed we want to correct the hours per day value from total value
    if (isFixedEndDate) {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursTotal({
          hoursPerDay,
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        startDate,
        endDate,
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
    // When the end date is not fixed we want to correct the hours total value from hours per day value
    else {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursPerDay({
          hoursPerDay,
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        startDate,
        endDate,
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
  };

  const handleIsFixedEndDateChange: InputAllocationDateRangeProps['onChangeIsFixedEndDate'] =
    (isFixedEndDate) => {
      setAllocationSettings('isFixedAllocationEndDate', isFixedEndDate);
    };

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      {isAllocationByHours && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationHours
            hoursPerDay={hoursPerDay}
            hoursTotal={hoursTotal}
            isReadOnly={isReadOnly}
            isTotalHoursInputDisabled={isTotalHoursInputDisabled}
            onChangeHoursPerDay={handleHoursPerDayChange}
            onChangeHoursTotal={handleHoursTotalChange}
            onBlurHoursPerDay={handleHoursPerDayBlur}
            onBlurHoursTotal={handleHoursTotalBlur}
          />

          {!isReadOnly && (
            <div className={styles.timeSectionSpecificTimeToggleWrapper}>
              <TextButton
                appearance="flue-fill"
                onClick={handleClickSetSpecificTimeButton}
              >
                <Trans>Specific time</Trans>
              </TextButton>
            </div>
          )}
        </div>
      )}

      {isAllocationByTime && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationTimeRange
            hoursPerDay={hoursPerDay}
            startTime={startTime}
            isReadOnly={isReadOnly}
            is24HoursTimeFormat={is24HoursTimeFormat}
            onChange={handleTimeRangeChange}
          />

          {!isReadOnly && (
            <div className={styles.timeSectionTotalHoursToggleWrapper}>
              <TextButton
                appearance="flue-fill"
                onClick={handleClickSetTotalHoursButton}
              >
                <Trans>Total hours</Trans>
              </TextButton>
            </div>
          )}
        </div>
      )}

      <div className={cs(styles.timeSectionColumn)}>
        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          numberOfAllocationDaysInDateRange={numberOfAllocationDays}
          isReadOnly={isReadOnly}
          isFixedEndDate={isFixedEndDate}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
          onChangeIsFixedEndDate={handleIsFixedEndDateChange}
        />
      </div>
    </motion.div>
  );
});
