import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  addMinutes,
  getUse24HourFormat,
  toDbTime,
  toLongFriendlyTime,
} from '@float/common/lib/time';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { useAppSelectorStrict } from '@float/common/store';
import { FieldLabel } from '@float/ui/deprecated/Label';

import { inputStyles } from './styles';

const TimePickerContainer = styled.div`
  width: ${(p) => (p.is24Hour ? 85 : 91)}px;
  ${inputStyles}

  input {
    width: ${(p) => (p.is24Hour ? 60 : 130)}px;
    height: 40px;
  }
`;

function isValidInputValue(value, companyPrefs) {
  if (getUse24HourFormat()) {
    return /^\d{0,2}:?\d{0,2}$/gi.test(value);
  }

  return /^\d{0,2}:?\d{0,2}(a|p)?m?$/gi.test(value);
}

const TimePicker = React.forwardRef((props, ref) => {
  const { label, style = {}, value = '', onChange } = props;

  const companyPrefs = useAppSelectorStrict(getCompanyPrefs);

  const originalValue = useRef(value);
  const inputRef = useRef();
  const isAm = useRef(null);
  const [inputValue, setInputValue] = useState(
    toLongFriendlyTime(value, companyPrefs),
  );

  let inputValueWithHeuristics = inputValue;
  if (!/[ap]/.test(inputValue) && !getUse24HourFormat(companyPrefs)) {
    inputValueWithHeuristics += isAm.current ? 'am' : 'pm';
  }
  const dbFormatValue = toDbTime(inputValueWithHeuristics, 1);

  useEffect(() => {
    setInputValue(toLongFriendlyTime(value, companyPrefs));
    isAm.current = value < '12:00';
  }, [value, companyPrefs]);

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      inputRef.current.focus();
      inputRef.current.select();
    },
  }));

  function handleChange(evt) {
    if (!isValidInputValue(evt.target.value)) return;

    let v = evt.target.value;

    if (v.length > inputValue.length && /^\d{2}$/.test(v)) {
      v += ':';
    }

    setInputValue(v);
  }

  function handleBlur() {
    onChange(dbFormatValue || originalValue.current);
    setInputValue(
      toLongFriendlyTime(dbFormatValue || originalValue.current, companyPrefs),
    );
  }

  function handleKeyDown(evt) {
    if (evt.key === 'ArrowUp') {
      if (dbFormatValue !== null) {
        onChange(toDbTime(addMinutes(dbFormatValue, 1), 1), true);
      }
    }

    if (evt.key === 'ArrowDown') {
      if (dbFormatValue !== null) {
        onChange(toDbTime(addMinutes(dbFormatValue, -1), 1), true);
      }
    }

    if (evt.key === 'Enter') {
      handleBlur();
    }
  }

  return (
    <TimePickerContainer
      style={{ ...style }}
      is24Hour={getUse24HourFormat(companyPrefs)}
    >
      {label && <FieldLabel>{label}</FieldLabel>}
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </TimePickerContainer>
  );
});

export default TimePicker;
