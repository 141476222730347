import { Middleware } from '@reduxjs/toolkit';

import { PHASES_UPDATED } from '@float/common/actions/phases';
import { PROMPTS } from '@float/constants/onboarding';
import { WebAppActions, WebAppDispatch } from '@float/web/lib/store';
import { WebAppState } from '@float/web/reducers/types';

import { updatePrompts } from './actions';

export const listenForOnboardingTriggers: Middleware<
  NonNullable<unknown>,
  WebAppState,
  WebAppDispatch
> = (storeApi) => (next) => (action: WebAppActions) => {
  const { onboardingManager } = storeApi.getState();

  if (action.type === PHASES_UPDATED) {
    if (
      !onboardingManager?.promptsUserHasDone?.includes(PROMPTS.welcomeAddPhase)
    ) {
      storeApi.dispatch(updatePrompts([PROMPTS.welcomeAddPhase]));
    }
  }

  return next(action);
};
