import { useMemo } from 'react';

import { selectDatesManager } from '@float/common/selectors/currentUser';
import { useCellsHelpers } from '@float/common/serena/hooks/useCellsHelpers';
import { useAppSelectorStrict } from '@float/common/store';
import { getRepeatedDates } from '@float/libs/datesRepeated/getRepeatedDates';
import { Person } from '@float/types/person';
import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';

import { calculateRepeatDays } from '../helpers/calculateRepeatDays';
import { formatDate } from '../helpers/formatDate';

export type UseRequestedDaysBalanceProps = {
  endDate: string;
  hoursPd: number;
  ignoreTimeoffId: Timeoff['timeoff_id'] | null | undefined;
  personId: Person['people_id'];
  repeatEndDate: string | null;
  repeatState: RepeatState;
  startDate: string;
};

export const useRequestedDaysBalance = (
  props: UseRequestedDaysBalanceProps,
): number => {
  const {
    endDate,
    hoursPd,
    ignoreTimeoffId,
    personId,
    repeatEndDate,
    repeatState,
    startDate,
  } = props;

  const { cells, helpers } = useCellsHelpers();

  const dates = useAppSelectorStrict(selectDatesManager);

  const totalDays = useMemo<number>(() => {
    let days = helpers.getTotalDaysInRange({
      cells,
      hours_pd: hoursPd,
      people_id: personId,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      ignoreTimeoff: { timeoff_id: ignoreTimeoffId },
    });

    if (repeatState && repeatEndDate) {
      const repeats = getRepeatedDates(dates, {
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        repeat_state: repeatState,
        repeat_end_date: formatDate(repeatEndDate),
      });

      days += calculateRepeatDays(
        helpers,
        cells,
        hoursPd,
        personId,
        repeats,
        dates,
        ignoreTimeoffId,
      );
    }

    return days;
    // we want to run this hook specifically only when these props change
    // https://linear.app/float-com/issue/EXP-540/bug-time-off-balance-changes-when-adding-a-new-time-off
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    helpers.getTotalDaysInRange,
    endDate,
    hoursPd,
    ignoreTimeoffId,
    personId,
    repeatEndDate,
    repeatState,
    startDate,
  ]);

  return totalDays;
};
