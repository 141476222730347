import {
  LINEAR_CYCLES_INTEGRATION_CONFIG_RESYNC_PATH,
  Resources,
} from '@float/common/api3/constants';

export const SETTINGS_BASE_URI = 'admin-api/';
export const API3_BASE_URI = 'svc/api3/v3/';

export const SETTINGS_RESOURCES = [
  'timezones',
  'get-company-sso',
  'pause-subscription',
  'delete-account',
  'api-token',
  'list-invoices',
];

export const TOP_LEVEL_RESOURCES = [
  'auto-log-time',
  'example-data',
  LINEAR_CYCLES_INTEGRATION_CONFIG_RESYNC_PATH,
  Resources.LinearCyclesIntegrationConfig,
  Resources.MePageData,
  Resources.MyCompanies,
  Resources.PageData,
  Resources.SearchAutocompleteQuery,
  Resources.SearchResolve,
  Resources.SharePageData,
];

export const RESOURCES_WITH_TOKEN_AUTH: Array<string | Resources> = [
  LINEAR_CYCLES_INTEGRATION_CONFIG_RESYNC_PATH,
  Resources.IntercomHash,
  Resources.LinearCyclesIntegrationConfig,
  Resources.SearchAutocompleteQuery,
  Resources.SearchResolve,
];
