import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconInstituition = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.25 19.25V11.5m4 0v7.75-7.75Zm-12.5 7.75V11.5v7.75Zm4 0V11.5v7.75ZM12 4.75l7.25 6.5H4.75L12 4.75Zm-7.25 14.5h14.5"
      />
    </SVGIcon>
  );
};

export { IconInstituition };
