import { Phase, Project, TaskStatus, TaskStatusEnum } from '@float/types';

export function getTaskStatusOnSave(
  newStatus: TaskStatus,
  project: Project,
  phase?: Phase,
): TaskStatus {
  const parentToInheritFrom = phase || project;
  const isParentTentative = parentToInheritFrom?.tentative;
  return isParentTentative ? TaskStatusEnum.Tentative : newStatus;
}
