import { SavedView } from '@float/types';

export function getViewPathname(view: SavedView) {
  const page = view.settings.page;

  switch (page) {
    case 'log-time':
    case 'schedule-people':
    case 'project-plan': {
      return '/';
    }
    case 'log-my-time': {
      return '/log-time';
    }
    case 'manage-people': {
      return '/people';
    }
    case 'manage-projects': {
      return '/projects';
    }
    case 'people-report':
    case 'projects-report': {
      return '/report';
    }
  }

  return '/';
}
