import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';

import {
  Candidate,
  CategoryIndices,
  FilteredContext,
} from '@float/common/search/selectors/getSearchAutocompleteResults/types';
import { FilterCategory } from '@float/common/search/types';
import { SearchFilterItem } from 'components/SearchFilterDropdown/components/SearchFilterItem';
import { AddFilter } from 'components/SearchFilterDropdown/types';

import * as styles from './styles.css';

export type ResultsListProps = {
  addFilter: AddFilter;
  addValue: (item: Candidate) => void;
  categoryIndices: CategoryIndices;
  data: FilteredContext['result'];
  deleteSavedSearch: (item: Candidate) => void;
  disableMultiSelect: boolean;
  expandCategory?: (value: FilterCategory) => void;
  expandedCategory: FilterCategory;
  highlightedIndex: number;
  measureElement: ReturnType<typeof useVirtualizer>['measureElement'];
  removeValue: (item: Candidate) => void;
  replaceValue: (args: {
    type: Candidate['type'];
    add: string[];
    remove: string[];
  }) => void;
  selectedValues: string[];
  setHighlightedIndex: (index: number) => void;
  virtualItems: VirtualItem[];
} & Pick<ComponentPropsWithRef<'div'>, 'className'>;

export const ResultsList = forwardRef<HTMLUListElement, ResultsListProps>(
  (props, forwardedRef) => {
    const { virtualItems } = props;

    const scrollY = virtualItems[0]?.start ?? 0;

    return (
      <ul
        ref={forwardedRef}
        className={styles.scroller}
        style={{ transform: `translateY(${scrollY}px)` }}
      >
        {virtualItems.map((virtualItem) => (
          <SearchFilterItem
            key={virtualItem.key}
            {...props}
            virtualItem={virtualItem}
          />
        ))}
      </ul>
    );
  },
);
