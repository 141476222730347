import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconOrganization = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.422 7.266a2.266 2.266 0 1 1-4.531 0 2.266 2.266 0 0 1 4.53 0Zm-2.266 2.492v5.437m6.316.227s.45-3.172-2.256-3.172h-8.12c-2.706 0-2.255 3.172-2.255 3.172m1.784 2.266a1.813 1.813 0 1 1-3.625 0 1.813 1.813 0 0 1 3.625 0Zm6.344 0a1.812 1.812 0 1 1-3.625 0 1.812 1.812 0 0 1 3.625 0Zm6.344 0a1.813 1.813 0 1 1-3.626 0 1.813 1.813 0 0 1 3.625 0Z"
      />
    </SVGIcon>
  );
};

export { IconOrganization };
