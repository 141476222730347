/* eslint-disable no-use-before-define */
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import {
  AnyEntityType,
  CellChange,
  CellItem,
  CellKey,
  CustomHoliday,
  LoadDataMap,
  LoadDataType,
  LoggedTime,
  Milestone,
  OneOffDay,
  Phase,
  RowId,
  Selection,
  Status,
  Task,
  TaskReference,
  Timeoff,
  TimeoffType,
  Timer,
  TimeRange,
} from '@float/types';

import BiMap from '../util/bimap';
import {
  getLinkParent as _getLinkParent,
  getLinkSourceItem as _getLinkSourceItem,
  getLinkTargetItem as _getLinkTargetItem,
} from '../util/linkedTasks';
import createCellHelpers from './useCells/_helpers';
import {
  dragEntity,
  dragEntityStop,
  dragMilestone,
  dragSort,
} from './useCells/drag';
import { getCellsInitialState } from './useCells/getCellsInitialState';
import { getCellKeyColIdx } from './useCells/helpers/getCellKeyColIdx';
import { getCellKeyRowId } from './useCells/helpers/getCellKeyRowId';
import { linkConfirm, linkDelete } from './useCells/link';
import {
  resizeEntityHorizontal,
  resizeEntityStop,
  resizeEntityVertical,
} from './useCells/resize';
import {
  removeSelection,
  SELECTION_ID,
  setSelection,
} from './useCells/selection';
import { split, splitConfirm } from './useCells/split';
import {
  DateUtils,
  EntityId,
  LockPeriodDates,
  UseCellsAction,
  UseCellsReducerProps,
  UseCellsState,
} from './useCells/types';
import type { RowMetas } from './useRowMetas.helpers';

function setAllLoaded(
  state: UseCellsState,
  action: {
    dataMap: LoadDataMap;
  },
) {
  if (!state.cells._allLoaded) {
    state.cells._loadedTypes = {
      ...state.cells._loadedTypes,
      ...Object.keys(action.dataMap).reduce(
        (acc, key) => {
          acc[key] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      ),
    };

    state.cells._allLoaded =
      state.cells._loadedTypes.task &&
      state.cells._loadedTypes.timeoff &&
      state.cells._loadedTypes.oneOff &&
      state.cells._loadedTypes.holiday &&
      state.cells._loadedTypes.milestone &&
      state.cells._loadedTypes.phase &&
      state.cells._loadedTypes.status;
  }
}

function toBoundaryCol(col: number, numVisibleWeeks: number) {
  return Math.floor(col / numVisibleWeeks) * numVisibleWeeks;
}

function createReducer(props: UseCellsReducerProps) {
  const { numVisibleWeeks, cellHelpers } = props;

  const { loadData, loadBulkData, calcHeight, buildCells } = cellHelpers;

  const reducer = (
    state: UseCellsState,
    action: UseCellsAction,
  ): UseCellsState => {
    const { cells } = state;

    switch (action.type) {
      case 'PROCESS_CHUNK': {
        buildCells(cells, action.cellKeys, {});

        return { ...state, cells };
      }

      case 'RESET': {
        // Since the cells object is mutated directly in the other actions,
        // we need to actually get a new object to perform a reset.
        return getCellsInitialState();
      }

      case 'FORCE_ALL_LOADED': {
        // This should only be used for unit tests to force the _allLoaded
        // boolean to true as other things (like sorting) need it as a pre-req.
        cells._allLoaded = true;
        return state;
      }

      case 'SET_FETCHED_RANGES': {
        cells._fetchedRanges = action.fetchedRanges;
        return state;
      }

      case 'REBUILD_HEIGHTS': {
        cells._heights = {};
        cells._heightsResetCount++;
        Object.keys(cells).forEach((cellKey) => {
          const rowId = getCellKeyRowId(cellKey as CellKey);
          const boundaryCol = toBoundaryCol(
            getCellKeyColIdx(cellKey as CellKey),
            numVisibleWeeks,
          );
          calcHeight(cells, rowId, boundaryCol);
        });

        return state;
      }

      case 'REBUILD_ROWS': {
        const { rowIds } = action;

        const cellKeys = Object.keys(cells).filter((cellKey) =>
          rowIds.includes(getCellKeyRowId(cellKey as CellKey)),
        );

        if (!cellKeys.length) return state;

        buildCells(cells, cellKeys, {
          lazyProcessing: true,
        });

        return { ...state, cells };
      }

      case 'REBUILD_ALL_CELLS': {
        const cellKeys = Object.keys(cells).filter((k) => !k.startsWith('_'));
        if (!cellKeys.length) return state;

        buildCells(cells, cellKeys, {
          lazyProcessing: true,
        });

        return { ...state, cells };
      }

      case 'LOAD_DATA': {
        loadData(cells, action);
        const { dataType, data, ...rest } = action;
        const convertedAction = {
          ...rest,
          dataMap: { [dataType]: data } as unknown as LoadDataMap,
        };
        setAllLoaded(state, convertedAction);
        return { ...state, cells };
      }

      case 'LOAD_BULK_DATA': {
        loadBulkData(cells, action);
        setAllLoaded(state, action);
        return { ...state, cells };
      }

      case 'LOAD_HELPER_DATA': {
        state.cells._helperData = action.data;

        return { ...state, cells };
      }

      case 'SET_SELECTION': {
        return setSelection(props, state, action);
      }

      case 'REMOVE_SELECTION': {
        return removeSelection(props, state);
      }

      case 'DRAG_ENTITY': {
        if (
          action.items.length === 1 &&
          /(milestone|phase|timeRange)/.test(action.items[0].type)
        ) {
          return dragMilestone(props, state, action);
        }

        return dragEntity(props, state, action);
      }

      case 'DRAG_ENTITY_STOP': {
        return dragEntityStop(props, state, action);
      }

      case 'DRAG_SORT': {
        return dragSort(props, state, action);
      }

      case 'RESIZE_ENTITY_VERTICAL': {
        return resizeEntityVertical(props, state, action);
      }

      case 'RESIZE_ENTITY_HORIZONTAL': {
        return resizeEntityHorizontal(props, state, action);
      }

      case 'RESIZE_ENTITY_STOP': {
        return resizeEntityStop(props, state, action);
      }

      case 'SPLIT': {
        return split(props, state, action);
      }

      case 'SPLIT_CONFIRM': {
        return splitConfirm(props, state, action);
      }

      case 'LINK_CONFIRM': {
        return linkConfirm(props, state, action);
      }

      case 'LINK_DELETE': {
        return linkDelete(props, state, action);
      }

      default: {
        // @ts-expect-error action.type is never because all the actions are handled
        throw Error(`Unhandled action type [${action.type}]`);
      }
    }
  };

  return (state: UseCellsState, action: UseCellsAction) => {
    try {
      return reducer(state, action);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
}

const createMapObject = <V>(): Record<string, V> => Object.create(null);

function createMaps() {
  return {
    task: createMapObject<Task>(),
    timeoff: createMapObject<Timeoff>(),
    timeoffTypes: createMapObject<TimeoffType>(),
    oneOff: createMapObject<OneOffDay>(),
    holiday: createMapObject<CustomHoliday>(),
    milestone: createMapObject<Milestone>(),
    phase: createMapObject<Phase>(),
    selection: createMapObject<Selection>(),
    status: createMapObject<Status>(),
    loggedTime: createMapObject<LoggedTime>(),
    taskReference: new WeakMap<Task, Record<string, TaskReference>>(), // See buildLogTimeCell:132 for an explanation
    timeRange: createMapObject<TimeRange>(),
    timer: createMapObject<Timer>(),
  };
}

function createBimaps() {
  return {
    task: BiMap<EntityId, string>(),
    timeoff: BiMap<EntityId, string>(),
    oneOff: BiMap<EntityId, string>(),
    holiday: BiMap<EntityId, string>(),
    milestone: BiMap<EntityId, string>(),
    phase: BiMap<EntityId, string>(),
    selection: BiMap<EntityId, string>(),
    status: BiMap<EntityId, string>(),
    loggedTime: BiMap<EntityId, string>(),
    timeRange: BiMap<EntityId, string>(),
    timer: BiMap<EntityId, string>(),
  };
}

type UseCellsCtx = {
  hasTimeTracking: boolean;
  singleUserView: boolean;
  logTimeView: boolean;
  logMyTimeView: boolean;
  numVisibleWeeks: number;
  timeIncrementUnit: unknown;
  dates: DateUtils;
  leftHiddenDays: number;
  rightHiddenDays: number;
  numDays: number;
  dayWidth: number;
  hourHeight: number;
  lockPeriodDates?: LockPeriodDates;
  suvSingleDay?: string | null;
};

export default function useCells(
  ctx: UseCellsCtx,
  rowMetas: RowMetas,
  containerWidth: number,
) {
  const {
    dates,
    leftHiddenDays,
    rightHiddenDays,
    numDays,
    dayWidth,
    hourHeight,
    suvSingleDay,
    logTimeView,
    logMyTimeView,
    singleUserView,
    timeIncrementUnit,
    lockPeriodDates,
    hasTimeTracking,
  } = ctx;
  let { numVisibleWeeks } = ctx;
  const [maps, setMaps] = useState(createMaps);
  const [bimaps, setBimaps] = useState(createBimaps);
  const rowMetasVersionRef = useRef<Record<string, any>>({});

  const processChunk = (cellKeys: string[]) => {
    dispatch({
      type: 'PROCESS_CHUNK',
      cellKeys,
    });
  };

  const colWidth = numDays * dayWidth;
  if (!numVisibleWeeks) {
    numVisibleWeeks = singleUserView
      ? 1
      : Math.max(2, Math.ceil(containerWidth / colWidth));
  }

  // We're using a ref here to ensure the latest version is always available in
  // the exposed _helpers object.
  const cellHelpers: any = useMemo(
    () =>
      createCellHelpers({
        dates,
        maps,
        bimaps,
        rowMetas,
        leftHiddenDays,
        rightHiddenDays,
        numVisibleWeeks,
        hourHeight,
        singleUserView,
        suvSingleDay,
        logTimeView,
        timeIncrementUnit,
        lockPeriodDates,
        hasTimeTracking,
      }),
    [
      dates,
      maps,
      bimaps,
      rowMetas,
      leftHiddenDays,
      rightHiddenDays,
      numVisibleWeeks,
      hourHeight,
      singleUserView,
      suvSingleDay,
      logTimeView,
      timeIncrementUnit,
      lockPeriodDates,
      hasTimeTracking,
    ],
  );

  // We want to memoize our reducer function since it depends on some extra
  // props. If we didn't do this, useReducer would get a new reducer function
  // each render, and we double-dispatch actions. This is typically OK, but
  // buildCells is expensive, and we want to avoid the double dispatch. See
  // https://stackoverflow.com/questions/55055793#55056623 for more info.
  // eslint-disable-next-line
  const memoizedReducer = useCallback(
    createReducer({
      dates,
      maps,
      bimaps,
      rowMetas,
      leftHiddenDays,
      rightHiddenDays,
      numVisibleWeeks,
      hourHeight,
      cellHelpers,
      logTimeView,
      logMyTimeView,
      timeIncrementUnit,
      lockPeriodDates,
    }),
    [
      dates,
      maps,
      bimaps,
      rowMetas,
      leftHiddenDays,
      rightHiddenDays,
      numVisibleWeeks,
      hourHeight,
      cellHelpers,
      logTimeView,
      timeIncrementUnit,
      lockPeriodDates,
    ],
  );
  const [state, dispatch] = useReducer(
    memoizedReducer,
    {},
    getCellsInitialState,
  );

  const reset = useCallback(() => {
    setMaps(createMaps());
    setBimaps(createBimaps());
    dispatch({ type: 'RESET' });
  }, []);

  useEffect(() => {
    dispatch({ type: 'REBUILD_HEIGHTS' });
  }, [numVisibleWeeks]);

  useEffect(() => {
    const invalidRowIds: RowId[] = [];

    rowMetas.forEach((meta, rowId) => {
      if (rowMetasVersionRef.current[rowId] !== meta.version) {
        rowMetasVersionRef.current[rowId] = meta.version;
        invalidRowIds.push(rowId as RowId);
      }
    });

    dispatch({ type: 'REBUILD_ROWS', rowIds: invalidRowIds });
  }, [rowMetas]);

  const tentativeChangesRef = useRef<CellChange<LoadDataType>[]>([]);

  // Expose some helper functions on the cells object for use in validation,
  // saving, etc.
  state.cells._helpers = {
    loadChanges: (changes: CellChange<LoadDataType>[]) => {
      changes.forEach((change) => {
        cellHelpers.loadData(state.cells, {
          type: 'LOAD_DATA',
          dataType: change.type,
          data: {
            [change.id]: change.entity,
          },
          ignoreMissing: true,
          forceLoad: true,
          additionalKeysToRebuild: cellHelpers.getApplicableCellKeys(
            change.originalEntity,
          ),
        });
      });
    },

    loadTentativeChanges(changes: CellChange<LoadDataType>[]) {
      if (tentativeChangesRef.current.length) {
        throw Error('Duplicate attempt to load tentativeChanges');
      }

      if (changes.some((c) => c.type !== 'oneOff')) {
        throw Error('Only oneOff tentative changes are implemented');
      }

      tentativeChangesRef.current = changes;
      this.loadChanges(changes);
    },

    undoTentativeChanges: () => {
      tentativeChangesRef.current.forEach((change) => {
        cellHelpers.loadData(state.cells, {
          type: 'LOAD_DATA',
          dataType: 'oneOff',
          isRemove: true,
          ignoreMissing: true,
          id: change.id,
          data: {},
        });
      });
      tentativeChangesRef.current = [];
    },

    getTentativeChanges: () => {
      const tentativeChanges = tentativeChangesRef.current;
      tentativeChangesRef.current = [];
      return tentativeChanges;
    },

    intersectsRepeatingEntity: (change: CellChange<AnyEntityType>) => {
      return cellHelpers.intersectsRepeatingEntity(state.cells, change.entity);
    },

    intersectsMultiAssignEntity: (change: CellChange<AnyEntityType>) => {
      return cellHelpers.intersectsMultiAssignEntity(
        state.cells,
        change.entity,
      );
    },
    addAssociatedChanges: (
      changes: CellChange<AnyEntityType>[],
      opts: { dayDelta: number },
    ) => {
      if (changes.length !== 1) throw Error('Should only be called from ETM');
      const [change] = changes;

      const keys = cellHelpers.getApplicableCellKeys(change.entity);
      cellHelpers.applyChanges(state.cells, keys, change.entity, changes, opts);
    },

    isOneOffDay: (cellKey: string, day: string) => {
      const { oneOff: oneOffsBimap, loggedTime: loggedTimeBimap } = bimaps;
      const oneOffs = oneOffsBimap.getRev(cellKey);
      const loggedTimes = logTimeView ? loggedTimeBimap.getRev(cellKey) : [];
      return cellHelpers.isOneOffDay(oneOffs, loggedTimes, day);
    },

    getCurrentSelection: () => {
      return maps.selection[SELECTION_ID];
    },

    getLinkParent: (item: CellItem) => {
      return _getLinkParent(state.cells, maps, bimaps, item);
    },

    getLinkSourceItem: (item: CellItem) => {
      return _getLinkSourceItem(state.cells, bimaps, item);
    },

    getLinkTargetItem: (item: CellItem) => {
      return _getLinkTargetItem(state.cells, bimaps, item);
    },

    isWorkDay: cellHelpers.isWorkDay,
    isEntityFullDayTimeoff: cellHelpers.isEntityFullDayTimeoff,
    isFullDayTimeoff: cellHelpers.isFullDayTimeoff,
    calcEntityLength: cellHelpers.calcEntityLength,
    calcEntityStartDate: cellHelpers.calcEntityStartDate,
    calcEntityEndDate: cellHelpers.calcEntityEndDate,
    calcEntityTotalHours: cellHelpers.calcEntityTotalHours,
    getMinWorkHoursInRange: cellHelpers.getMinWorkHoursInRange,
    getTotalDaysInRange: cellHelpers.getTotalDaysInRange,
    hasVaryingWorkHoursInRange: cellHelpers.hasVaryingWorkHoursInRange,
    overlapsFullDayTimeoff: cellHelpers.overlapsFullDayTimeoff,
    overlapsEntity: cellHelpers.overlapsEntity,
    getAssociatedChangesForEntity: cellHelpers.getAssociatedChangesForEntity,
    createRepeatInstances: cellHelpers.createRepeatInstances,
    getRepeatInstances: cellHelpers.getRepeatInstances,
    getAllInstances: cellHelpers.getAllInstances,
    countWorkDays: cellHelpers.countWorkDays,
    processChunk,
  };

  const res = useMemo(
    () => ({
      cells: state.cells,
      changes: state.changes,
      dispatch,
      reset,
    }),
    [reset, state.cells, state.changes],
  );

  // (window.floatDebug || (window.floatDebug = {})).cells = res;

  return res;
}

export type UseCellsReturnType = ReturnType<typeof useCells>;
