import { useEffect, useState } from 'react';

import { useEventListenerOnElement } from '@float/libs/web/hooks/useEventListenerOnElement';
import { TooltipVariants } from '@float/ui/components/Tooltip/Tooltip.css';

export const useValidationTooltip = ({
  hasErrors,
  inputElementRef,
}: {
  hasErrors: boolean;
  inputElementRef: { current: HTMLInputElement | null };
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipVariant, setTooltipVariant] = useState<TooltipVariants>(
    TooltipVariants.default,
  );

  useEventListenerOnElement(inputElementRef, 'focusin', () => {
    if (hasErrors) {
      setIsTooltipOpen(true);
      setTooltipVariant(TooltipVariants.error);
    }
  });

  useEventListenerOnElement(inputElementRef, 'focusout', () => {
    if (!hasErrors) {
      setIsTooltipOpen(false);
    }
  });

  useEffect(() => {
    if (hasErrors) {
      setIsTooltipOpen(true);
      setTooltipVariant(TooltipVariants.error);
    } else {
      setIsTooltipOpen(false);
      setTooltipVariant(TooltipVariants.default);
    }
  }, [hasErrors]);

  return { open: isTooltipOpen, variant: tooltipVariant };
};
