import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconCloseSmall = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M15.5 9L9 15.5"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9L15.5 15.5"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
};

export { IconCloseSmall };
