import React from 'react';
import classNames from 'classnames';

import { SerenaState } from '@float/common/selectors/serena';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { CellsMap } from '@float/types';

import { ScheduleActions } from '../types';
import { MainCellItem } from './box/BoxElements/types';
import { getDimensions } from './helpers';
import LinkArrow from './LinkedLayer/components/LinkArrow';
import { useLinkArrowCurve } from './LinkedLayer/hooks/useLinkArrowCurve';
import { getLinkArrowColor } from './LinkedLayer/utils/getLinkArrowColor';

import { placeHolderItemBox } from './PlaceholderItem.css';

type PlaceholderItemProps = {
  actions: ScheduleActions;
  cells: CellsMap;
  dayWidth?: number;
  fullDay?: boolean;
  hourHeight?: number;
  item: MainCellItem;
  linkedArrowTargetRef?: React.MutableRefObject<SVGSVGElement | null>;
  reduxData?: SerenaState;
  row?: PersonRow | PersonProjectRow | ProjectRow;
  suvSingleDay?: string | null;
  wrapperRef?: React.RefObject<HTMLDivElement>;
};

function PlaceholderItem({
  actions,
  cells,
  dayWidth,
  fullDay,
  hourHeight,
  item,
  linkedArrowTargetRef,
  reduxData,
  row,
  suvSingleDay,
  wrapperRef,
}: PlaceholderItemProps) {
  const dimensions = getDimensions(item, dayWidth, hourHeight, suvSingleDay);

  const linkArrowCurve = useLinkArrowCurve(
    cells,
    item,
    linkedArrowTargetRef,
    wrapperRef,
    row,
  );

  // @ts-expect-error Selection shouldn't have parent_task_id
  const parentTaskId = item.entity?.parent_task_id;

  return (
    <div
      className={classNames('ItemWrapper', placeHolderItemBox)}
      ref={(node) => {
        item._boxRef = node;
      }}
      style={{
        ...dimensions,
        borderRadius: fullDay ? 0 : undefined,
      }}
      data-is-start={item.isStart}
      data-is-end={item.isEnd}
    >
      {parentTaskId && linkArrowCurve && (
        <LinkArrow
          actions={actions}
          item={item}
          color={getLinkArrowColor(reduxData, item)}
          opacity={1}
          linkedArrowTargetRef={linkedArrowTargetRef}
          allowRemove={false}
          {...linkArrowCurve}
        />
      )}
    </div>
  );
}

export default PlaceholderItem;
