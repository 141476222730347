import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cls from 'classnames';
import { kebabCase } from 'lodash';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import * as styles from './styles.css';

export type TextToggleOption<V extends unknown> = {
  label: string;
  labelElement?: React.ReactNode;
  hasTooltip?: boolean; // if the option has a tooltip, we're omitting setting data-disabled on selected option
  subLabel?: string;
  value: V;
};
export type TextToggleVariant = keyof typeof styles.container;

export type TextToggleProps<V extends unknown> = {
  variant?: TextToggleVariant;
  className?: string;
  name?: string;
  options: TextToggleOption<V>[];
  value: V;
  disabled?: boolean;
  onChange: (option: TextToggleOption<V>, name: string) => void;
  toggle?: boolean;
};

export const TextToggle = <V extends unknown>(props: TextToggleProps<V>) => {
  const {
    className,
    name = 'defaultToggleName',
    options = [],
    value,
    disabled = false,
    onChange,
    toggle,
    variant = 'default',
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const refStates = useRef<HTMLDivElement | null>(null);
  const refStatesGroup = useRef<HTMLDivElement | null>(null);

  const ids = useMemo(
    () => options.map((option) => `${name}-${kebabCase(option.label)}`),
    [name, options],
  );

  const getIndex = useCallback(
    (value: unknown) => {
      const index = options.findIndex((option) => value == option.value);

      if (index > -1) return index;

      return 0;
    },
    [options],
  );

  const selectedIndex = getIndex(value);
  const [hoveringIndex, setHoveringIndex] = useState<null | number>(null);

  // Events

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const nextIndex = ids.indexOf(e.target.id);

    if (!disabled && onChange) onChange(options[nextIndex], name);
  };

  const onMouseOver = () => {
    const nextIndex = (selectedIndex + 1) % ids.length;

    setHoveringIndex(nextIndex);
  };

  const onMouseOut = () => {
    setHoveringIndex(null);
  };

  const onClick = () => {
    setHoveringIndex(null);

    const nextIndex = (selectedIndex + 1) % ids.length;

    if (!disabled && onChange) onChange(options[nextIndex], name);
  };

  // Selected state style

  const updateStylesFor = (index: number, animated = true) => {
    const id = ids[index];

    const container = ref.current;

    if (!container) return;

    const currentLabel = container.querySelector(
      `label[for=${id}]`,
    ) as HTMLLabelElement;

    const refStatesEl = refStates.current;
    const refStatesGroupEl = refStatesGroup.current;

    if (!currentLabel || !refStatesEl || !refStatesGroupEl) {
      return;
    }

    // parseInt automatically strips the px suffix
    const padding = parseInt(getComputedStyle(container).padding, 10);

    const x = currentLabel.offsetLeft - padding;
    const width = currentLabel.offsetWidth;

    refStatesEl.style.transitionDuration = animated ? '' : '0s';
    refStatesGroupEl.style.transitionDuration = animated ? '' : '0s';

    refStatesEl.style.width = `${width}px`;
    refStatesEl.style.transform = `translateX(${x}px)`;
    refStatesGroupEl.style.transform = `translateX(${x * -1}px)`;

    if (!animated) {
      // Resets the transition duration to make the hover transition work
      requestAnimationFrame(() => {
        refStatesEl.style.transitionDuration = '';
        refStatesGroupEl.style.transitionDuration = '';
      });
    }
  };

  // Hooks
  useUpdateEffect(() => {
    // Here we animate the selection change
    updateStylesFor(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    window.requestIdleCallback(() => {
      updateStylesFor(selectedIndex, false);
    });
    // we want to recalculate the styles when the component is mounted, the options or the variant changes
    // to keep them aligned with the label size
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, variant]);

  return (
    <div
      className={cls(styles.container[variant], className)}
      ref={ref}
      data-disabled={disabled}
    >
      {/* Overlay for when the whole Toggle is clickable */}
      {toggle && !disabled && (
        <div
          data-testid="TextToggleOverlay"
          className={styles.overlay}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
        />
      )}

      {/* Each clickable option */}
      {options.map((option, index) => {
        const id = ids[index];
        const hasCustomContent = !!option.labelElement;

        return (
          <Fragment key={id}>
            <input
              className={styles.input}
              type="radio"
              name={name}
              id={id}
              value={String(option.value)}
              data-value={option.value}
              onChange={onInputChange}
              checked={selectedIndex === index}
              disabled={disabled}
            />
            <label
              className={cls(
                styles.label[variant],
                hasCustomContent && styles.labelWithCustomContent,
              )}
              htmlFor={id}
              data-disabled={
                disabled || (selectedIndex === index && !option.hasTooltip)
              }
              data-hover={hoveringIndex === index}
              data-testid={id}
            >
              {option.labelElement ? option.labelElement : option.label}
              {option.subLabel && (
                <span className={styles.subLabel}> {option.subLabel}</span>
              )}
            </label>
          </Fragment>
        );
      })}

      {/* Selected option */}
      <div className={styles.states[variant]} ref={refStates}>
        <div className={styles.statesGroup} ref={refStatesGroup}>
          {options.map((option) => {
            const hasCustomContent = !!option.labelElement;

            return (
              <label
                className={cls(
                  styles.statesGroupLabel[variant],
                  hasCustomContent && styles.labelWithCustomContent,
                )}
                key={option.label}
                data-disabled={disabled}
              >
                {option.labelElement ? option.labelElement : option.label}
                {option.subLabel && (
                  <span className={styles.subLabel}> {option.subLabel}</span>
                )}
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const TextToggleStyles = {
  label: `.${styles.labelBase}`,
};
