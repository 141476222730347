import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { t, Trans } from '@lingui/macro';

import { createProject } from '@float/common/actions/projects';
import { randomColor } from '@float/common/lib/utils';
import { KEYS } from '@float/common/search/helpers';
import { getPeople } from '@float/common/selectors/people';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { PROMPTS } from '@float/constants/onboarding';
import { Button } from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { useLoader } from '@float/ui/deprecated/helpers/formHooks';
import { setPromptData } from '@float/web/store/onboardingManager/actions';
import { Transition } from '@float/web/TransitionManager/Transition';

import { StyledInput, StyledInputContainer, StyledLabel } from './styles';

import * as commonStyles from '../../styles.css';

export const AddProject = forwardRef((props, ref) => {
  const { onClickNext, previousState, transitionId } = props;

  const transitionRef = useRef();
  const contentRef = useRef();

  const inputRef = useRef();

  const [state, setState] = useState(previousState || { data: {} });

  const { loader, setLoading } = useLoader();

  const dispatch = useAppDispatchStrict();

  const projectManager = useAppSelectorStrict((state) => {
    const projectManager = getPeople(state).find((person) => person.account_id);

    return projectManager?.account_id;
  });

  // Submit

  const addProject = useCallback(
    (state) => {
      const { data } = state;
      return new Promise((success, reject) => {
        // skip if entry was submitted already
        if (state.success) {
          success(state);
          return;
        }

        dispatch(
          createProject({
            project_name: data.projectName,
            tentative: 0,
            non_billable: 0,
            active: 1,
            canEdit: true,
            project_manager: projectManager,
            locked_task_list: true,
            color: randomColor(),
            tags: [],
          }),
        )
          .then((response) => {
            success({ data, success: true, response });
          })
          .catch((error) => {
            reject({
              data,
              error: true,
              message: [(error && error.message) || t`An error occurred.`],
            });
          });
      });
    },
    [dispatch, projectManager],
  );

  // Interaction handlers

  const onClickNextHandler = useCallback(async () => {
    if (state.data.projectName) {
      setLoading(true);

      addProject(state)
        .then((res) => {
          setLoading(false);

          onClickNext(res);
        })
        .catch((res) => {
          setLoading(false);

          setState(res);
        });
    }
  }, [state, setLoading, addProject, onClickNext]);

  // Input handlers

  const onInputChange = useCallback(() => {
    setState({ data: { projectName: inputRef.current.input.value.trim() } });

    // keep track of the project name in store
    dispatch(
      setPromptData(PROMPTS.welcomeAddProject, {
        data: { projectName: inputRef.current.input.value.trim() },
      }),
    );
  }, [dispatch]);

  const onInputKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KEYS.enter) {
        onClickNextHandler();
      }
    },
    [onClickNextHandler],
  );

  // Expose

  useImperativeHandle(ref, () => ({ ...transitionRef.current }));

  return (
    <Transition ref={transitionRef} id={transitionId}>
      <div className={commonStyles.content} ref={contentRef}>
        <h1 className={commonStyles.h1}>
          <Trans>Add your first project</Trans>
        </h1>

        <div className={commonStyles.question}>
          <h2 className={commonStyles.h2}>
            <Trans>
              Use this project to learn how Float works. You can add more
              details later.
            </Trans>
          </h2>

          <StyledInputContainer>
            <StyledLabel>
              <Trans>Project</Trans>
            </StyledLabel>

            <StyledInput
              ref={inputRef}
              defaultValue={state.data.projectName}
              placeholder={t`Enter project name`}
              autoFocus
              readOnly={loader.loading}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              errors={state.error && state.message}
            />
          </StyledInputContainer>
        </div>

        <Button
          iconRight={Icons.IconArrowRight}
          size="large"
          loader={loader.active ? loader : null}
          disabled={!state.data.projectName || state.data.projectName === ''}
          onClick={onClickNextHandler}
        >
          {state.error ? t`Try again` : t`Next`}
        </Button>
      </div>
    </Transition>
  );
});
