import useSWR from 'swr';

import { Company } from '@float/types/company';

import { Resources } from './constants';
import { makeRequest, MakeRequestPagination } from './makeRequest';

export type MyCompanies = {
  companies: Company[];
  email: string;
  name: string;
};

export const requestMyCompanies = (): Promise<MyCompanies> =>
  makeRequest<MyCompanies>({
    resource: Resources.MyCompanies,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
  });

export function useMyCompanies() {
  return useSWR(Resources.MyCompanies, requestMyCompanies, {
    revalidateOnFocus: false,
    fallbackData: {
      companies: [],
      email: '',
      name: '',
    },
  });
}
