import { createSelector } from 'reselect';

import { getLocationPathname } from '@float/common/selectors/location';
import { getUserAccessRights } from '@float/common/selectors/userAccessRights';
import { PROMPTS } from '@float/constants/onboarding';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { OnboardingCalloutStatus } from '@float/web/store/onboardingManager/types';
import { getFriendlyLocation } from 'components/Nav/helpers';

import { getModalManagerState, getPromptsUserHasDone } from './common';

export const getOnboardingCalloutsStatus = createSelector(
  [
    getPromptsUserHasDone,
    getUserAccessRights,
    getLocationPathname,
    getModalManagerState,
  ],
  (promptsUserHasDone, userAccessRights, pathname, modalManager) => {
    // default state
    const status: OnboardingCalloutStatus = {
      showAddATask: false,
      showHelpGuides: false,
      completed: false,
    };

    // do not show any callouts when example data project is available
    if (featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData)) {
      return { ...status, completed: true };
    }

    const anyModalShowing = Object.values(modalManager).some(
      (modal) => modal.showing,
    );
    const pageName = getFriendlyLocation(pathname);
    const { accessRights } = userAccessRights;
    const { isAccountOwner } = accessRights;

    const userHasSeenAddATask =
      promptsUserHasDone && promptsUserHasDone.includes(PROMPTS.addFirstTask);
    const userHasSeenHelpGuides =
      promptsUserHasDone && promptsUserHasDone.includes(PROMPTS.helpGuide);

    status.showAddATask =
      isAccountOwner() && !userHasSeenAddATask && pageName === 'timeline';

    status.showHelpGuides =
      !status.showAddATask &&
      !userHasSeenHelpGuides &&
      pageName === 'timeline' &&
      !anyModalShowing;

    status.completed = Object.values(status).every((willShow) => !willShow);

    return status;
  },
);
