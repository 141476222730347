import { omit } from 'lodash';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ReduxState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { FilterToken } from '@float/types';

import request from '../lib/request';
import { SearchAutocompleteResultItem } from '../search/selectors/getSearchAutocompleteResults';
import { AppDispatchStrict } from '../store';
import { ensureAccountsLoaded } from './accounts';
import { ensureClientsLoaded } from './clients';
import { ensurePeopleLoaded } from './people';
import { ensurePhasesLoaded } from './phases';
import { ensureProjectsLoaded } from './projects';
import { ensureRolesLoaded } from './roles';

export const SEARCH_CONTEXT_LOAD_START = 'search/CONTEXT_LOAD_START' as const;
export const SEARCH_CONTEXT_LOAD_FAILED = 'search/CONTEXT_LOAD_FAILED' as const;
export const SEARCH_CONTEXT_LOAD_FINISH = 'search/CONTEXT_LOAD_FINISH' as const;
export const SEARCH_DERIVE_CONTEXT = 'search/DERIVE_CONTEXT' as const;

export const SEARCH_SET_FILTERS = 'search/SET_FILTERS' as const;
export const SEARCH_ADD_FILTERS = 'search/ADD_FILTERS' as const;
export const SEARCH_REMOVE_FILTER = 'search/REMOVE_FILTER' as const;
export const SEARCH_REMOVE_ALL_FILTERS = 'search/REMOVE_ALL_FILTERS' as const;
export const SEARCH_CLEAR_REMOVED_FILTERS =
  'search/CLEAR_REMOVED_FILTERS' as const;

export const SEARCH_SET_PLACEHOLDER = 'search/SET_PLACEHOLDER' as const;
export const SEARCH_SET_NAV_PROJECT = 'search/SET_NAV_PROJECT' as const;

export const SEARCH_UPDATE_SAVED_SEARCHES = 'search/UPDATE_SAVED_SEARCHES';

type Dispatch<A extends Action<any>> = ThunkDispatch<ReduxState, unknown, A>;

export type AddFiltersAction = {
  type: typeof SEARCH_ADD_FILTERS;
  filters: FilterToken[];
  usePushState?: boolean;
  editingFilterIndex?: number;
};

export const addFilters = (
  filters: FilterToken[],
  {
    usePushState,
    editingFilterIndex,
  }: { usePushState?: boolean; editingFilterIndex?: number } = {},
) => {
  return {
    type: SEARCH_ADD_FILTERS,
    filters,
    editingFilterIndex,
    usePushState,
  };
};

export type SetFiltersAction = {
  type: typeof SEARCH_SET_FILTERS;
  filters: FilterToken[];
  usePushState?: boolean;
};

// This actions is used for mobile app, since only single filter token is allowed.
export const setFilters = (
  filters: (SearchAutocompleteResultItem | FilterToken)[],
  { usePushState }: { usePushState?: boolean } = {},
) => {
  return {
    type: SEARCH_SET_FILTERS,
    filters,
    usePushState,
  };
};

export type RemoveFilterAction = {
  type: typeof SEARCH_REMOVE_FILTER;
};

export const removeFilter = (filter: FilterToken) => {
  return {
    type: SEARCH_REMOVE_FILTER,
    filter,
  };
};

export type RemoveAllFiltersAction = {
  type: typeof SEARCH_REMOVE_ALL_FILTERS;
};

export const removeAllFilters = () => {
  return {
    type: SEARCH_REMOVE_ALL_FILTERS,
  };
};

export type ClearRemovedFiltersAction = {
  type: typeof SEARCH_CLEAR_REMOVED_FILTERS;
};

export const clearRemovedFilters = () => {
  return {
    type: SEARCH_CLEAR_REMOVED_FILTERS,
  };
};

export type SetPlaceholderAction = {
  type: typeof SEARCH_SET_PLACEHOLDER;
  placeholder: string;
};

export const setPlaceholder =
  (placeholder: string) =>
  (dispatch: Dispatch<SetPlaceholderAction>, getState: () => ReduxState) => {
    if (getState().search.placeholder !== placeholder) {
      dispatch({
        type: SEARCH_SET_PLACEHOLDER,
        placeholder,
      });
    }
  };

async function fetchContext(data: { companyId: number; accountId: number }) {
  const res = await request.get('svc/search/search?noProjects=true', data, {
    hostname: '',
    version: '',
    jwt: true,
    timeout: 60000,
  });
  return res;
}

export const ensureContextLoaded = ({
  forceLoad = false,
  rebuild = false,
  skipRoles = false,
} = {}) => {
  return async (
    dispatch: AppDispatchStrict,
    getState: () => ReduxStateStrict,
  ) => {
    // This method only wants to load the search context if it hasn't been
    // previously loaded. If we want to refresh the search context, a new
    // method should be created.
    const { contextState: currentContextState } = getState().search;
    const { currentUser } = getState();

    // These actions have their own loaded checks
    const dependencies = [
      ensureProjectsLoaded({ includeArchived: true, forceLoad })(
        dispatch,
        getState,
      ),
      ensurePeopleLoaded(true)(dispatch, getState),
      ensureAccountsLoaded(true)(dispatch, getState),
      ensurePhasesLoaded({ includeArchived: true, forceLoad })(
        dispatch,
        getState,
      ),
      ensureClientsLoaded(true)(dispatch, getState),
      ...(!skipRoles ? [ensureRolesLoaded()(dispatch, getState)] : []),
    ];

    if (!forceLoad && currentContextState === 'LOADED') {
      await Promise.all(dependencies);
      return;
    }

    if (currentContextState === 'LOADING') {
      await Promise.all(dependencies);
      return;
    }

    if (featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)) {
      await Promise.all(dependencies);
      return;
    }

    try {
      dispatch({ type: SEARCH_CONTEXT_LOAD_START });

      const promises = [
        fetchContext({
          companyId: currentUser.cid,
          accountId: currentUser.admin_id,
        }),
        ...dependencies,
      ];

      const [context] = await Promise.all(promises);

      dispatch({ type: SEARCH_CONTEXT_LOAD_FINISH, context, rebuild });

      // Although we re-derive the context asynchronously when actions change
      // the state, this specific action should block until at least the initial
      // derive is finished.
      dispatch({ type: SEARCH_DERIVE_CONTEXT, fullState: getState() });
    } catch (e) {
      console.error(e);
      dispatch({ type: SEARCH_CONTEXT_LOAD_FAILED });
    }
  };
};

export type UpdateSavedSearchesAction = {
  type: typeof SEARCH_UPDATE_SAVED_SEARCHES;
  savedSearches: unknown;
};

export const saveSearch = (name: string) => {
  return async (
    dispatch: Dispatch<UpdateSavedSearchesAction>,
    getState: () => ReduxState,
  ) => {
    const {
      search: { filters },
      currentUser: { savedSearches },
    } = getState();

    const filtersToSave = (filters as FilterToken[]).map(
      ({ type, val, operator }) => ({
        type,
        val,
        operator,
      }),
    );

    const newSavedSearches = {
      ...savedSearches,
      [name]: filtersToSave,
    };

    dispatch({
      type: SEARCH_UPDATE_SAVED_SEARCHES,
      savedSearches: newSavedSearches,
    });

    return request.post(
      'searches',
      {
        search_text: JSON.stringify(newSavedSearches),
      },
      { version: 'f3' },
    );
  };
};

export const deleteSearch = (name: string) => {
  return async (
    dispatch: Dispatch<UpdateSavedSearchesAction>,
    getState: () => ReduxState,
  ) => {
    const {
      currentUser: { savedSearches },
    } = getState();

    const newSavedSearches = omit(savedSearches, name);

    dispatch({
      type: SEARCH_UPDATE_SAVED_SEARCHES,
      savedSearches: newSavedSearches,
    });

    return request.post(
      'searches',
      {
        search_text: JSON.stringify(newSavedSearches),
      },
      { version: 'f3' },
    );
  };
};

export type SetNavProjectAction = {
  type: typeof SEARCH_SET_NAV_PROJECT;
  navProject: number;
};

export const setNavProject = (projectId: number) => ({
  type: SEARCH_SET_NAV_PROJECT,
  navProject: projectId,
});
