import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconCloseCircle = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.75 12a7.25 7.25 0 1 1 14.5 0 7.25 7.25 0 0 1-14.5 0ZM9.75 9.75l4.5 4.5M14.25 9.75l-4.5 4.5"
      />
    </SVGIcon>
  );
};

export { IconCloseCircle };
