import React, { MouseEvent, useRef } from 'react';

import {
  CellStylesConfig,
  useCellStyles,
} from '@float/common/lib/cellStylesManager/hooks/useCellStyles';
import { getCellStylesConfig } from '@float/common/lib/cellStylesManager/utils/getCellStylesConfig';
import { useTaskMetasPrefetch } from '@float/common/lib/hooks/useTaskMetasPrefetch';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { SerenaState } from '@float/common/selectors/serena';
import { useAppSelectorStrict } from '@float/common/store';
import { CellItem } from '@float/types';

import { Dimensions } from '../../../types';
import { BaseEntityLayer } from '../../BaseEntityLayer';
import { getBoxInner } from '../BoxElements/getBoxInner';

import { entityItemBox } from '../../EntityItemStyles.css';

export type BoxEntityItemProps = {
  actions: {
    actionMode: string;
    trackHoveredItem: (item: CellItem | null) => void;
    setDragItem: (params: unknown) => void;
    logTime: (item: CellItem, hours: number) => void;
    onItemClick: (item: CellItem, event?: MouseEvent) => void;
  };
  dimensions: Dimensions;
  dayWidth: number;
  item: CellItem<'timeoff'> | CellItem<'task'>;
  printMode?: boolean;
  reduxData: SerenaState;
  rounded: boolean;
  hourHeight: number;
};

export const BoxEntityItem = (props: BoxEntityItemProps) => {
  const { actions, dayWidth, dimensions, item, printMode, reduxData, rounded } =
    props;

  const { entity, isEnd, isStart } = item;

  const companyPrefs = useAppSelectorStrict(getCompanyPrefs);

  const { color, config } = getCellStylesConfig(
    item,
    reduxData,
    actions,
    printMode,
  );

  const styles = useCellStyles(item, color, config as CellStylesConfig);

  const { prefetchTaskMetas } = useTaskMetasPrefetch();

  const onMouseEnter = () => {
    actions.trackHoveredItem(item);

    if (item.type === 'task') {
      prefetchTaskMetas(item.entity.project_id);
    }
  };

  const onMouseLeave = () => {
    actions.trackHoveredItem(null);
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const setBoxRef = (node: HTMLDivElement | null) => {
    ref.current = node;
    item._boxRef = node;
  };

  const onMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (event.button !== 0) return;

    const element = ref.current;

    if (!element) return;
    if (!element.contains(event.target as HTMLElement)) {
      // The user clicked on a tooltip
      return;
    }

    const rect = element.getBoundingClientRect();
    const { height } = rect;

    // Edge uses left/top for unknown reasons
    const offsetX = rect.x || rect.left;
    const offsetY = rect.y || rect.top;

    // @entity.length
    const length = 'length' in entity ? entity.length ?? 1 : 1;
    const dragVersionWidth = dayWidth * Math.min(5, length) - 7;
    const baseLayerStyle = {
      width: dragVersionWidth,
      height: dimensions.height,
      boxShadow: window.TASK_DRAG_SHADOW || '',
    };
    const dragVersion = (
      <BaseEntityLayer rounded={rounded} isStart isEnd style={baseLayerStyle}>
        <div
          className={entityItemBox}
          data-rounded={rounded}
          data-is-start
          data-is-end
          style={styles}
        >
          {getBoxInner(
            dayWidth,
            dragVersionWidth,
            item,
            reduxData,
            companyPrefs,
            styles,
            props,
          )}
        </div>
      </BaseEntityLayer>
    );

    actions.setDragItem({
      clientX: event.clientX,
      clientY: event.clientY,
      element: dragVersion,
      item,
      offsetX,
      offsetY,
      shiftKey: event.shiftKey,
      width: dragVersionWidth,
      height,
    });
  };

  return (
    <div
      className={entityItemBox}
      data-testid={`box-entity-${item.type}`}
      data-is-end={isEnd}
      data-is-start={isStart}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={setBoxRef}
      data-rounded={rounded}
      style={styles}
    >
      {getBoxInner(
        dayWidth,
        item.w,
        item,
        reduxData,
        companyPrefs,
        styles,
        props,
      )}
    </div>
  );
};
