import React, { MutableRefObject, ReactNode, useRef } from 'react';
// @ts-expect-error @floatschedule/react-virtualized doesn't have the ts typings
import type { WindowScroller } from '@floatschedule/react-virtualized';

import { useResizeObserver } from '@float/libs/web/hooks/useResizeObserver';

export type AccordionTableListProps = {
  top: () => ReactNode;
  bottom: (ref: MutableRefObject<WindowScroller | null>) => ReactNode;
};

/**
 * Handles the rendering of a vertical aligned couple of AccordionTable
 */
export function AccordionTableList(props: AccordionTableListProps) {
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const bottomTableRef = useRef<WindowScroller>(null);

  const top = props.top();
  const hasTop = Boolean(top);

  // Recalculate the scroll positions of the bottom table when the top table gets resized
  // Required because WindowScroller doesn't manage position changes external to it's content
  // fixes https://linear.app/float-com/issue/CS-1108#comment-15096ffe
  useResizeObserver(
    tableWrapperRef,
    () => {
      const scroller = bottomTableRef.current;

      scroller?.updatePosition?.();
    },
    { enabled: hasTop },
  );

  return (
    <>
      {hasTop && <div ref={tableWrapperRef}>{top}</div>}

      {props.bottom(bottomTableRef)}
    </>
  );
}
