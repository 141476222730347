import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { trackEvent } from '@float/common/lib/analytics';
import ActivityBody from '@float/web/activity/components/ActivityBody';
import {
  getNotificationFeedActivities,
  getNotifications,
} from '@float/web/selectors';
import {
  markNotificationsAsSeen,
  requestNotifications,
} from '@float/web/store/notifications/notifications.actions';

const NotificationsComponent = ({
  activitiesByNotifications,
  notifications,
  hasMore,
  notificationsLoaded,
  fetchNotifications,
  markAsSeen,
  onClick,
  maxHeight = undefined,
}) => {
  const loadMore = () => {
    if (!hasMore) {
      return Promise.resolve();
    }

    return fetchNotifications();
  };

  useEffect(() => {
    if (!notificationsLoaded) {
      fetchNotifications();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const ids = notifications.filter((n) => !n.seen).map((x) => x.nf_id);
    if (ids && ids.length) {
      markAsSeen(ids);
    }

    trackEvent('Notification feed opened', {
      unseen: ids ? ids.length : 0,
    });
  }, []); // eslint-disable-line

  return (
    <ActivityBody
      isNotificationFeed
      items={activitiesByNotifications}
      maxHeight={maxHeight}
      loadMore={loadMore}
      loadingMore={!notificationsLoaded}
      emptyActivity={!activitiesByNotifications?.length}
      activityItemClick={onClick}
    />
  );
};

const mapStateToProps = (state) => {
  const { loadState, hasMore } = state.notifications;
  const notificationsLoaded = loadState === 'LOADED';
  const activitiesByNotifications = getNotificationFeedActivities(state);
  const notifications = getNotifications(state);

  return {
    activitiesByNotifications: activitiesByNotifications,
    notifications: notifications,
    notificationsLoaded: notificationsLoaded,
    hasMore,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: () => dispatch(requestNotifications()),
  markAsSeen: (ids) => dispatch(markNotificationsAsSeen(ids)),
});

export const Notifications = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsComponent);
