import React, { forwardRef, memo, useCallback, useState } from 'react';

import { isEligibleLinkTarget } from '@float/common/components/Schedule/actions/_handleLinkInteraction';
import { useRegisterVisibleTask } from '@float/common/components/Schedule/util/ViewportContext';
import { isFullDayTimeoff } from '@float/common/lib/timeoffs';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { SerenaState } from '@float/common/selectors/serena';
import { RowMetaItem } from '@float/common/serena/Data/useRowMetas.helpers';
import {
  PersonProjectRow,
  PersonRow,
  ProjectRow,
} from '@float/common/serena/Data/useScheduleRows';
import { useAppSelectorStrict } from '@float/common/store';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { CellItem, CellsMap } from '@float/types';

import { Dimensions, ScheduleActions } from '../types';
import { BaseEntityLayer, BaseEntityLayerProps } from './BaseEntityLayer';
import { BoxEntityItem } from './box/BoxEntityItem';
import getTooltipContent, {
  getLinkIneligibleTooltip,
} from './EntityItemTooltip';
import GhostLayer from './GhostLayer';
import { isGhostTask } from './GhostLayer/helpers/isGhostTask';
import { getDimensions, TOOLTIP_DELAY } from './helpers';
import { isInaccessibleDraftTask } from './helpers/isInaccessibleDraftTask';
import LinkedLayer from './LinkedLayer';

import { bottomResize, leftResize, rightResize } from './styles.css';

type EntityItemProps = {
  actions: ScheduleActions;
  cells: CellsMap;
  className?: string;
  dayWidth: number;
  hourHeight: number;
  isProjectPlanView?: boolean;
  item: CellItem<'timeoff'> | CellItem<'task'>;
  linkedArrowTargetRef?: React.MutableRefObject<SVGSVGElement | null>;
  linkOpacity?: string | number;
  printMode?: boolean;
  reduxData: SerenaState;
  row: PersonRow | PersonProjectRow | ProjectRow;
  suvSingleDay?: string | null;
  tooltipProps?: BaseEntityLayerProps['tooltipProps'];
  wrapperRef?: React.RefObject<HTMLDivElement>;
};

const EntityItem = forwardRef<HTMLDivElement, EntityItemProps>((props, ref) => {
  const {
    actions,
    item,
    dayWidth,
    hourHeight,
    reduxData,
    row,
    printMode,
    suvSingleDay,
    isProjectPlanView,
  } = props;

  const { isStart, isEnd, entity, type } = item;

  const noAccess = isInaccessibleDraftTask(
    item,
    reduxData.projects,
    isProjectPlanView,
  );

  useRegisterVisibleTask(item, noAccess);

  if (noAccess) {
    return null;
  }

  const dimensions = getDimensions(
    item,
    dayWidth,
    hourHeight,
    suvSingleDay,
  ) as Dimensions;
  const isEditable = actions.isItemEditable(item);

  const isLinkedLayer = Boolean(
    // @ts-expect-error Entity may not be a Task, but making TS happy would make this check way more verbose
    entity?.parent_task_id || entity?.has_child,
  );

  const isResizable = isEditable && !actions.hideResize();
  const isTask = type === 'task';
  const rounded = isTask;
  const Layer = isLinkedLayer ? LinkedLayer : BaseEntityLayer;

  // The BaseLayer is used by many cell entities (Task, Ghost, LoggedTime, LinkedTasks)
  // and since we introduced Compliant Task Colors (PSQ1-287) the default white
  // background-color is not needed / suits all instances. Until we refactor/rewrite
  // all the task entities, I am adding this prop to optinionally set the base layer
  // transparent as needed — in the future I belive we will be able to drop this
  // and the above 'background-color: white' default style.
  const transparent = isTask;

  if (isGhostTask(row, item, reduxData)) {
    return (
      <GhostLayer
        ref={ref}
        actions={actions}
        dimensions={dimensions}
        isEnd={isEnd}
        isStart={isStart}
        item={item}
        tooltipProps={props.tooltipProps}
      />
    );
  }

  return (
    <Layer
      ref={ref}
      className="ItemWrapper"
      {...props}
      rounded={rounded}
      isStart={isStart}
      isEnd={isEnd}
      style={dimensions}
      data-floatid={item.key}
      transparent={transparent}
    >
      {isResizable && isStart && (
        <div
          className={leftResize}
          onMouseDown={(e) => {
            if (e.button !== 0) return prevent(e);
            e.stopPropagation();
            actions.onItemResizeStart(item, 'L');
          }}
        />
      )}

      <BoxEntityItem
        actions={actions}
        dayWidth={dayWidth}
        dimensions={dimensions}
        hourHeight={hourHeight}
        item={item}
        printMode={printMode}
        reduxData={reduxData}
        rounded={rounded}
      />

      {isResizable && isEnd && (
        <div
          className={rightResize}
          onMouseDown={(e) => {
            if (e.button !== 0) return prevent(e);
            e.stopPropagation();
            actions.onItemResizeStart(item, 'R');
          }}
        />
      )}

      {isResizable && !isFullDayTimeoff(entity) && (
        <div
          className={bottomResize}
          onMouseDown={(e) => {
            if (e.button !== 0) return prevent(e);
            e.stopPropagation();
            actions.onItemResizeStart(item, 'B');
          }}
        />
      )}
    </Layer>
  );
});

type EntityItemWithTooltipProps = EntityItemProps & {
  rowMeta?: RowMetaItem;
  boundaryRef: React.MutableRefObject<Element | Element[] | undefined>;
};

const EntityItemWithTooltip = memo((props: EntityItemWithTooltipProps) => {
  const { actions, item, reduxData, rowMeta, boundaryRef } = props;

  const companyPrefs = useAppSelectorStrict(getCompanyPrefs);

  const [open, setOpen] = useState(false);

  let delay = TOOLTIP_DELAY;
  let tooltipContent = getTooltipContent(
    item,
    rowMeta,
    reduxData,
    companyPrefs,
  );
  let forceEnableTooltip = false;

  if (actions.linkInfo?.base) {
    const [eligible, error] = isEligibleLinkTarget(actions.linkInfo, item);
    if (!eligible) {
      // @ts-expect-error isEligibleLinkTarget is in JS
      const newTooltipContent = getLinkIneligibleTooltip(error);
      if (newTooltipContent) {
        tooltipContent = newTooltipContent;
        delay = 500;
        forceEnableTooltip = true;
      }
    }
  }

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        if (forceEnableTooltip || actions.isItemTooltipEnabled(item)) {
          setOpen(true);
        }
      } else {
        setOpen(false);
      }
    },
    [actions, forceEnableTooltip, item, setOpen],
  );

  const tooltipProps = {
    collisionBoundary: boundaryRef?.current,
    content: tooltipContent,
    delay: delay,
    distance: 1,
    onOpenChange: onOpenChange,
    open: open,
    placement: 'bottom',
  } as const;

  return <EntityItem {...props} tooltipProps={tooltipProps} />;
});

export default EntityItemWithTooltip;
