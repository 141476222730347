import { useEffect } from 'react';

type EventListenerParams<E extends HTMLElement> = Parameters<
  E['addEventListener']
>;

/**
 * Utility hook for adding event listeners to elements. Takes care
 * of cleanup and reduces need for additional unit testing.
 *
 * Is mockable, see './__mocks__/useEventListenerOnElement`.
 *
 * For global event subscriptions use `useEvent` from `react-use`.
 */

export function useEventListenerOnElement<E extends HTMLElement>(
  target: { current?: E | null },
  event: EventListenerParams<E>[0],
  listener: EventListenerParams<E>[1],
  eventListenerOptions?: boolean | AddEventListenerOptions,
  options?: { disabled: boolean },
): void {
  const isDisabled = options?.disabled;

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const { current } = target;

    current?.addEventListener(event, listener, eventListenerOptions);

    return () => {
      current?.removeEventListener(event, listener);
    };
  }, [target, event, listener, eventListenerOptions, isDisabled]);
}
