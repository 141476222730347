import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getShowUpgradeNotice } from 'selectors';

import { isTimeTrackingEnabled } from '@float/common/selectors/companyPrefs';
import { useSnackbar } from '@float/ui/deprecated';
import { fetchBillingInfo } from '@float/web/settingsV2/actions/account/billingInfo';
import withUserCanSee from 'components/decorators/withUserCanSee';

const TrialEndingSoon = ({
  showUpgradeNotice,
  trialDaysLeft,
  userCanSee,
  fetchBilling,
  hasTimeTracking,
}) => {
  const { showSnackbar, closeSnackbar } = useSnackbar();
  const upgradePath = `/thankyou?plan=${hasTimeTracking ? 'pro' : 'starter'}`;

  useEffect(() => {
    if (userCanSee.billing) {
      fetchBilling();
    }

    // close trial snackbar if component get dismounted (e.g. navigate to Team settings)
    // https://app.asana.com/0/1201258914201276/1201372035173705/f
    return () => {
      closeSnackbar('trial');
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!showUpgradeNotice) {
      closeSnackbar('trial');
      return;
    }
    const message =
      trialDaysLeft > 0
        ? `${trialDaysLeft} day${
            trialDaysLeft == 1 ? '' : 's'
          } left on your trial.`
        : 'Your free trial has ended.';

    showSnackbar(message, {
      id: 'trial',
      className: 'info',
      persist: true,
      loader: false,
      showClose: false,
      linkText: <a href={upgradePath}>Upgrade</a>,
      closeOnLinkClick: false,
    });
  }, [
    showUpgradeNotice,
    trialDaysLeft,
    showSnackbar,
    closeSnackbar,
    upgradePath,
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  showUpgradeNotice: getShowUpgradeNotice(state).expiringSoon,
  trialDaysLeft: state.settingsBillingInfo.trial_days_left,
  hasTimeTracking: isTimeTrackingEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBilling: () => dispatch(fetchBillingInfo()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUserCanSee(TrialEndingSoon));
