import * as Animation from './Animation';
import * as Buttons from './Buttons';
import * as Cards from './Cards';
import * as Colors from './Colors';
import * as Dropdowns from './Dropdowns';
import * as Elevations from './Elevations';
import * as Icons from './Icons';
import * as Inputs from './Inputs';
import List from './List';
import * as Scrollbar from './Scrollbar';
import { Separator } from './Separator';
import * as Tags from './Tags';
import * as Toggles from './Toggles';
import * as Typography from './Typography';

export default {
  Animation,
  Typography,
  Colors,
  Icons,
  Tags,
  Buttons,
  Cards,
  Elevations,
  Toggles,
  Inputs,
  List,
  Separator,
  Dropdowns,
  Scrollbar,
};

export * from './Text';
