import clamp from 'lodash/clamp';

import {
  cutDecimalValues,
  divisionOperation,
  multiplyOperation,
} from '@float/libs/utils/floats';

export type GetAllocationCorrectedHoursParams = {
  hoursPerDay: number;
  hoursTotal: number;
  numberOfAllocationDays: number;
  minimumHoursPerDayValue: number;
  maximumHoursPerDayValue: number;
};

/**
 * Clamps the hours per day value and computes the corrected total hours
 */
export const getAllocationCorrectedHoursUsingHoursPerDay = ({
  hoursPerDay,
  hoursTotal,
  numberOfAllocationDays,
  minimumHoursPerDayValue,
  maximumHoursPerDayValue,
}: GetAllocationCorrectedHoursParams) => {
  let hoursPerDayCorrected = hoursPerDay;

  hoursPerDayCorrected = cutDecimalValues(hoursPerDayCorrected);

  hoursPerDayCorrected = clamp(
    hoursPerDayCorrected,
    minimumHoursPerDayValue,
    maximumHoursPerDayValue,
  );

  const hoursTotalCorrected = multiplyOperation(
    hoursPerDayCorrected,
    numberOfAllocationDays,
  );

  return {
    hoursPerDayCorrected,
    hoursTotalCorrected,
  };
};

/**
 * Clamps the total hours and computes hours per day corrected value
 */
export const getAllocationCorrectedHoursUsingHoursTotal = ({
  hoursPerDay,
  hoursTotal,
  numberOfAllocationDays,
  minimumHoursPerDayValue,
  maximumHoursPerDayValue,
}: GetAllocationCorrectedHoursParams) => {
  // This is the allowed maximum hours in the time range
  const hoursTotalMaximumAllowedValue = cutDecimalValues(
    numberOfAllocationDays * maximumHoursPerDayValue,
  );

  const hoursTotalMinimumAllowedValue = cutDecimalValues(
    numberOfAllocationDays * minimumHoursPerDayValue,
  );

  const hoursTotalCorrected = clamp(
    hoursTotal,
    hoursTotalMinimumAllowedValue,
    hoursTotalMaximumAllowedValue,
  );

  const hoursPerDayCorrected = divisionOperation(
    hoursTotalCorrected,
    numberOfAllocationDays,
  );

  return {
    hoursPerDayCorrected,
    hoursTotalCorrected,
  };
};
