import React, { useEffect, useRef } from 'react';

import { RichText } from '@float/ui/deprecated';

const PersonNotes = (props) => {
  const { autoFocus, onChange, value, readOnly } = props;

  const richTextRef = useRef();

  const onRichTextChange = (value) => {
    if (onChange) {
      onChange(value.text);
    }
  };

  const focus = () => {
    richTextRef.current.focus();
  };

  useEffect(() => {
    if (autoFocus) focus();
  }, [autoFocus]);

  return (
    <RichText
      ref={richTextRef}
      label="Notes"
      onChange={onRichTextChange}
      value={value}
      maxLength={1500}
      mentionsEnabled={false}
      readOnly={readOnly}
    />
  );
};

export default PersonNotes;
