import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { BaseFilterToken } from '@float/types';

import { getCurrentViewFilters } from './getCurrentViewId';

export const getActiveFilters = createSelector(
  [(state: ReduxStateStrict) => state.search.filters, getCurrentViewFilters],
  (filters, currentViewFilters) => {
    const result: BaseFilterToken[] = currentViewFilters
      .concat(filters)
      .map((f) => ({
        type: f.type,
        val: f.val,
        operator: f.operator,
      }));

    return result;
  },
);
