import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconKebabDots = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M12.5 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM12.5 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM12.5 16a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        stroke="#000"
      />
    </SVGIcon>
  );
};
