import { FETCH_USER_SUCCESS } from '@float/common/actions/currentUser';
import {
  LOGGED_TIME_CREATED,
  LOGGED_TIME_UPDATED,
} from '@float/common/actions/loggedTimes';
import { PROJECTS_UPDATED } from '@float/common/actions/projects';
import { CREATE_TASK_SUCCESS, UPDATE_TASK } from '@float/common/actions/tasks';
import { formatTask, formatTimeoff } from '@float/common/lib/formatters';

const getLastProjectFromLocalStorage = (userId) => {
  let data = localStorage.getItem('lastProject');
  data = data ? JSON.parse(data) : {};
  return data[userId] || {};
};

const setLastProjectInLocalStorage = (args, userId) => {
  if (typeof args !== 'object') return;
  let data;
  if (localStorage.getItem('lastProject')) {
    data = JSON.parse(localStorage.getItem('lastProject'));
  }
  if (data == null) {
    data = {};
  }
  data[userId] = args;
  localStorage.setItem('lastProject', JSON.stringify(data));
};

function incrementCounterInLocalStorage(key, userId) {
  let data = localStorage.getItem(key);
  data = data ? JSON.parse(data) : {};
  const count = +(data[userId] || 0) + 1;
  data[userId] = count;
  localStorage.setItem(key, JSON.stringify(data));
  return count;
}

const incrementTasksCreatedCounterInLocalStorage = () => {
  const count = incrementCounterInLocalStorage('tasksCreatedCount');
  if (count === 1) {
    window.dispatchEvent(new CustomEvent('firstTaskCreated'));
  } else if (count > 2) {
    window.dispatchEvent(new CustomEvent('moreThanTwoTasksCreated'));
  }
};

const incrementLoggedTimeCounterInLocalStorage = () => {
  const count = incrementCounterInLocalStorage('loggedTimeCount');
  if (count) {
    window.dispatchEvent(new CustomEvent('loggedTime'));
  }
};

const formatTaskOrTimeoffFields = (taskOrTimeoff) => {
  if (taskOrTimeoff.logged_time_id) return null;
  if (taskOrTimeoff.timeoff_id) {
    return formatTimeoff(taskOrTimeoff);
  }
  return formatTask(taskOrTimeoff);
};

const DEFAULT_STATE = {
  project: undefined,
  taskOrTimeoff: {},
  DO_NOT_USE_userCID: undefined,
};

const lastUpdated = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    // TODO: Deprecate this case and the `userCID` state property
    // https://linear.app/float-com/issue/PI-242/remove-user-data-from-lastupdated-and-pmsidebar-reducers
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        DO_NOT_USE_userCID: action.user.cid,
        project:
          state.project || getLastProjectFromLocalStorage(action.user.cid),
      };
    case PROJECTS_UPDATED: {
      if (action.isLiveUpdate) {
        return state;
      }
      const { project_id, active } = action.project;
      if (!active) {
        return state;
      }
      const project = { project_id };
      setLastProjectInLocalStorage({ project_id }, state.DO_NOT_USE_userCID);
      return { ...state, project };
    }

    case LOGGED_TIME_UPDATED:
    case LOGGED_TIME_CREATED:
    case CREATE_TASK_SUCCESS:
    case UPDATE_TASK: {
      if (action.isLiveUpdate) {
        return state;
      }

      const item = action.item || action.loggedTime || {};
      const { project_id } = item;
      const updatedState = {};

      const taskOrTimeoff = formatTaskOrTimeoffFields(item);
      if (taskOrTimeoff) {
        updatedState.taskOrTimeoff = taskOrTimeoff;
      }

      if (project_id) {
        updatedState.project = { project_id };
        setLastProjectInLocalStorage(
          updatedState.project,
          state.DO_NOT_USE_userCID,
        );
      }

      if (action.type === CREATE_TASK_SUCCESS) {
        incrementTasksCreatedCounterInLocalStorage();
      }

      if (action.type === LOGGED_TIME_CREATED) {
        incrementLoggedTimeCounterInLocalStorage();
      }

      return { ...state, ...updatedState };
    }

    default:
      return state;
  }
};

export default lastUpdated;
