import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconReport = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.208 7.918v7.919M4.794 10.418v5.419M10 4.375v11.456"
      />
    </SVGIcon>
  );
};

export { IconReport };
