import { moment } from '@float/libs/moment';

export type EditTaskModalProps = {
  calcEntityLength: (params: {
    start_date: string;
    end_date: string;
    people_ids: number[];
    timeoff_id?: number;
  }) => number | undefined;
};

export type Payload = {
  start_date: string;
  end_date: string;
  people_ids: number[];
  timeoff_id?: number;
};

export const getWorkDays = (props: EditTaskModalProps, params: Payload) => {
  return (
    props.calcEntityLength({
      start_date: moment(params.start_date).format('YYYY-MM-DD'),
      end_date: moment(params.end_date).format('YYYY-MM-DD'),
      people_ids: params.people_ids,
      timeoff_id: params.timeoff_id,
    }) ?? 1
  );
};
