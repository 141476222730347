import React, {
  Children,
  cloneElement,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  ReactElement,
  SVGProps,
  useId,
} from 'react';

export type SVGIconProps = {
  children?: ReactElement | ReactElement[];
  className?: string;
  color?: string;
  noScalingStroke?: boolean;
  ref?: ForwardedRef<SVGSVGElement | null>;
  size?: number | string;
  title?: string;
} & Pick<SVGProps<SVGSVGElement>, 'viewBox' | 'className'>;

export type SVGIconComponent = FunctionComponent<SVGIconProps>;

export const SVGIcon = forwardRef(
  (props: SVGIconProps, ref: ForwardedRef<SVGSVGElement>) => {
    const {
      children,
      className,
      color,
      noScalingStroke,
      size = '24',
      title,
      viewBox = '0 0 24 24',
      ...svgAttributes
    } = props;

    const titleId = `svg-title-${useId()}`;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby={title ? titleId : undefined}
        className={className}
        fill="none"
        height={size}
        ref={ref}
        viewBox={viewBox}
        width={size}
        {...svgAttributes}
      >
        {title && <title id={titleId}>{title}</title>}

        {children &&
          Children.map(children, (child: ReactElement, index) => {
            if (!child) return null;

            const childProps = child.props as SVGProps<SVGSVGElement>;
            const style: CSSProperties = {};

            let vectorEffect = '';

            if (childProps.stroke) {
              style.stroke = `var(--svg-icon-color, ${
                color || childProps.stroke
              })`;

              if (noScalingStroke) vectorEffect = 'non-scaling-stroke';
            }

            if (childProps.fill) {
              style.fill = `var(--svg-icon-color, ${color || childProps.fill})`;
            }

            return cloneElement(child, {
              ...childProps,
              fillprop: null,
              key: index,
              style,
              vectorEffect,
            });
          })}
      </svg>
    );
  },
);
