import React, { useMemo } from 'react';
import { max } from 'lodash';
import styled, { css } from 'styled-components';

import { MARGIN } from '@float/common/components/Schedule/Cell/MainCell/Item/PhaseItem';
import {
  buildProjectRowSortData,
  getSortKey,
} from '@float/common/serena/Data/useCells/helpers/buildProjectRowSortData';
import { moment } from '@float/libs/moment';
import { formatTickDate } from '@float/ui/deprecated/Chart/BarChart/BarChart';
import { CHART_TYPES, UNITS } from '@float/ui/deprecated/Chart/constants';

const MARGINS = {
  [CHART_TYPES.BAR]: 75,
  [CHART_TYPES.LINE]: 109,
};

const PHASE_BAR_HEIGHT = 28;

const Box = styled.div`
  position: absolute;

  display: flex;
  align-items: center;

  background-color: #${(p) => p.color};
  border-radius: 3px 3px 0 0;

  padding: 0 5px;

  outline: none;

  cursor: pointer;

  transition: top 0.4s ease;

  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    border-top: 0;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #${(p) => p.color};
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -4px;
    border-top: 0;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #${(p) => p.color};
  }
`;

const FadeableBox = styled(Box)`
  box-sizing: border-box;
  cursor: default;

  ${(p) =>
    p.fadeStart &&
    p.fadeEnd &&
    css`
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-left: 15px;
      background: linear-gradient(
        90deg,
        transparent 0,
        #${p.color} 15px,
        #${p.color} ${p.w - 15}px,
        transparent
      );

      &::before,
      &::after {
        display: none;
      }
    `}

  ${(p) =>
    p.fadeStart &&
    !p.fadeEnd &&
    css`
      border-top-left-radius: 0px;
      padding-left: 15px;
      background: linear-gradient(
        90deg,
        transparent 0,
        #${p.color} 15px,
        #${p.color}
      );

      &::before {
        display: none;
      }
    `}

  ${(p) =>
    p.fadeEnd &&
    !p.fadeStart &&
    css`
      border-top-right-radius: 0px;
      background: linear-gradient(
        90deg,
        #${p.color} 0,
        #${p.color} ${p.w - 15}px,
        transparent
      );

      &::after {
        display: none;
      }
    `}
`;

const Container = styled.div`
  height: ${(p) => (p.maxY + 1) * (PHASE_BAR_HEIGHT + MARGIN)}px;
  width: ${(p) => p.width}px;
  margin-left: ${(p) => (p.type === CHART_TYPES.BAR ? 50 : 80)}px;
  position: relative;
  z-index: 0;

  ${FadeableBox} {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
`;

export default function PhaseBars(props) {
  const { type, unit, dates, phases, project, barChartScale: xScale } = props;

  const width = props.width - MARGINS[type];

  // ensure dates are in the correct format
  const startDate = moment(props.startDate).format('YYYY-MM-DD');
  const endDate = moment(props.endDate).format('YYYY-MM-DD');
  const numDays = dates.toNum(endDate) - dates.toNum(startDate);
  const dayWidthPx = width / numDays;

  function calculateLineChartDimensions(phase) {
    let fadeStart = false;
    let fadeEnd = false;

    let x =
      (dates.toNum(phase.start_date) - dates.toNum(startDate)) * dayWidthPx;
    let w =
      (dates.toNum(phase.end_date) - dates.toNum(phase.start_date)) *
      dayWidthPx;

    if (x < 0) {
      w += x;
      x = 0;
      fadeStart = true;
    }

    if (x + w > width) {
      w = width - x;
      fadeEnd = true;
    }

    return { x, w, fadeStart, fadeEnd };
  }

  function calculateBarChartDimensions(phase) {
    if (!xScale?.domain()?.length) return null;

    let fadeStart = false;
    let fadeEnd = false;

    const isMonthMode = unit === UNITS.MONTH;
    const startTickDate = formatTickDate(phase.start_date, isMonthMode);
    const endTickDate = formatTickDate(phase.end_date, isMonthMode);

    // In day mode, we want to line up the phase bars with the center of each
    // bar chart bar.
    let startAdjustment = 0.5;
    let endAdjustment = 0.5;

    if (isMonthMode) {
      // In month mode, adjust the start to represent how far into the month
      // the start/end dates were
      const startMoment = moment(phase.start_date);
      const endMoment = moment(phase.end_date);
      startAdjustment = startMoment.date() / startMoment.daysInMonth();
      endAdjustment = endMoment.date() / endMoment.daysInMonth();
    }

    let x = xScale(startTickDate) + xScale.bandwidth() * startAdjustment;
    if (isNaN(x)) {
      x = 0;
      fadeStart = true;
    }

    let w = xScale(endTickDate) + xScale.bandwidth() * endAdjustment - x;
    if (isNaN(w)) {
      w = width - x;
      fadeEnd = true;
    }

    return { x, w, fadeStart, fadeEnd };
  }

  const { phaseItems, sortData, maxY } = useMemo(() => {
    const items = phases.filter((i) => {
      return i.end_date > startDate && i.start_date < endDate;
    });

    const sortData = buildProjectRowSortData(items);

    return {
      phaseItems: items,
      sortData,
      maxY: max(Object.values(sortData)) || 0,
    };
  }, [phases, startDate, endDate]);

  return (
    <Container type={type} width={width} maxY={maxY} dayWidthPx={dayWidthPx}>
      {phaseItems.map((phase) => {
        const dimensions =
          type === CHART_TYPES.BAR
            ? calculateBarChartDimensions(phase)
            : calculateLineChartDimensions(phase);

        if (!dimensions) return null;

        const { x, w, fadeStart, fadeEnd } = dimensions;
        const y = sortData[getSortKey(phase)];

        return (
          <FadeableBox
            key={phase.phase_id}
            color={phase.color || project.color}
            style={{
              left: x,
              width: w,
              top: y * PHASE_BAR_HEIGHT + MARGIN,
            }}
            x={x}
            w={w}
            y={y}
            fadeStart={fadeStart}
            fadeEnd={fadeEnd}
          >
            {phase.phase_name}
          </FadeableBox>
        );
      })}
    </Container>
  );
}
