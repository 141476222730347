import React, { JSXElementConstructor, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmConfig } from 'manage/people-v2/People';
import { compose } from 'redux';

import { featureFlags } from '@float/libs/featureFlags';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { withSnackbar } from '@float/ui/deprecated/Snackbar/withSnackbar';
import { getFeatureFlagChangeHandler } from 'components/app.helpers';
import Refresh from 'components/elements/Refresh';

type AppUpdatesNotificationProps = {
  confirm: (config: ConfirmConfig) => void;
  showSnackbar: (message: string, options?: unknown) => void;
  closeSnackbar: (id: string) => void;
};

const REFRESH_STICKY_FOOTER = 'refresh-sticky-footer';

const AppUpdatesNotificationComponent: React.FC<
  AppUpdatesNotificationProps
> = ({ confirm, showSnackbar, closeSnackbar }) => {
  const notificationRef = useRef(false);

  const showRefreshModal = () => {
    notificationRef.current = true;
    closeSnackbar(REFRESH_STICKY_FOOTER);
    confirm({
      title: t`New version available`,
      message: (
        <>
          <p>
            <Trans>
              Float has been updated. Please hit reload to view the latest
              version.
            </Trans>
          </p>
          <p className="mb-0">
            <Trans>
              If you were in the middle of something, please close this message,
              save your work and then reload this page.
            </Trans>
          </p>
        </>
      ),
      confirmLabel: t`Reload`,
      cancelLabel: t`Close`,
      onConfirm: () => window.location.reload(),
      onCancel: () => {
        notificationRef.current = false;
      },
    });
  };

  const showRefreshStickyFooter = () => {
    if (notificationRef.current) return;
    notificationRef.current = true;
    showSnackbar(
      t`Float has been updated. Please reload to view latest version.`,
      {
        id: REFRESH_STICKY_FOOTER,
        persist: true,
        showClose: true,
        linkText: (
          <Refresh>
            <Trans>Refresh</Trans>
          </Refresh>
        ),
        closeOnLinkClick: false,
        onClose: (_id: string) => {
          notificationRef.current = false;
        },
      },
    );
  };

  const featureFlagChangeHandler = getFeatureFlagChangeHandler({
    onGentleRefresh: showRefreshStickyFooter,
    onStrongRefresh: showRefreshModal,
  });

  const listenForFeatureFlagChanges = () => {
    featureFlags.addChangeHandler(featureFlagChangeHandler);
  };

  const removeFeatureFlagChangeEventListener = () => {
    featureFlags.removeChangeHandler(featureFlagChangeHandler);
  };

  useOnMount(() => {
    listenForFeatureFlagChanges();

    return removeFeatureFlagChangeEventListener;
  });

  return null;
};

const AppUpdatesNotification = compose(
  withConfirm,
  withSnackbar,
)(AppUpdatesNotificationComponent) as JSXElementConstructor<any>;

export { AppUpdatesNotification };
