import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconTrash = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m6.75 7.75.841 9.673a2 2 0 0 0 1.993 1.827h4.832a2 2 0 0 0 1.993-1.827l.841-9.673m-7.5-.25v-.75a2 2 0 0 1 2-2h.5a2 2 0 0 1 2 2v.75M5 7.75h14"
      />
    </SVGIcon>
  );
};

export { IconTrash };
