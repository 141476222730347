import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { updateUserPref } from '@float/common/actions/currentUser';
import { userCanViewOthers } from '@float/common/lib/rights';
import { getUser } from '@float/common/selectors/currentUser';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { DropdownSelect } from '@float/ui/deprecated';
import { IconChevronDown } from '@float/ui/icons/essentials/IconChevronDown';
import { measureInteractivity } from '@float/web/perfMonitoring/interactivity';

const StyledViewType = styled.div`
  margin-right: -6px;
  ${DropdownSelect._styles.StyledDropdownLabel} {
    padding: 4px 2px 4px 10px;

    ${(p) => (p.isSharedLink ? 'background-color: transparent;' : null)}
  }

  ${DropdownSelect._styles.StyledDropdownLabelText} {
    &:hover {
      background-color: transparent;
    }

    color: ${(p) => (p.isSharedLink ? 'white' : p.theme.emphasisHigh)};
  }

  ${DropdownSelect._styles.StyledIcon} {
    width: 24px;
    height: 24px;
    margin-left: 0px;

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      stroke: ${(p) =>
        p.isSharedLink ? 'white' : p.theme.emphasisHigh} !important;
    }
  }
`;

export default function ScheduleViewType({ isSharedLink }) {
  const { viewType, logTimeViewType, setViewType, setLogTimeViewType } =
    useScheduleContext();
  const user = useAppSelector(getUser);
  const canViewOthers = userCanViewOthers(user);
  const hasTimeTracking = user.time_tracking > 0;

  const options = useMemo(() => {
    const result = [
      { value: 'people', label: t`Schedule` },
      { value: 'projects', label: t`Project plan` },
    ];

    if (hasTimeTracking && canViewOthers && !isSharedLink) {
      result.push({ value: 'logTime', label: t`Log team` });
    }
    return result;
  }, [canViewOthers, hasTimeTracking, isSharedLink]);

  const [value, setValue] = useState(() =>
    logTimeViewType ? 'logTime' : viewType,
  );

  useEffect(
    () => {
      setValue(logTimeViewType ? 'logTime' : viewType);
    },
    // eslint-disable-next-line
    [logTimeViewType, viewType],
  );

  const reduxDispatch = useAppDispatch();

  const onChange = useCallback(
    ({ value }) => {
      let nextViewType;
      if (value === 'logTime') {
        setLogTimeViewType(true);
        nextViewType = 'people';
      } else {
        setLogTimeViewType(false);
        nextViewType = value;
      }

      const currentValue = logTimeViewType ? 'logTime' : viewType;

      if (currentValue !== value) {
        measureInteractivity.trackSingleInteraction(
          `${currentValue}-to-${value}`.toUpperCase(),
        );
      }

      setViewType(nextViewType);
      if (!isSharedLink)
        reduxDispatch(updateUserPref('sked_view_type', nextViewType));
    },
    [
      logTimeViewType,
      viewType,
      setViewType,
      isSharedLink,
      reduxDispatch,
      setLogTimeViewType,
    ],
  );

  return (
    <StyledViewType isSharedLink={isSharedLink}>
      <div data-product-tour-id="schedule-desktop">
        <DropdownSelect
          value={value}
          options={options}
          minSelectWidth={160}
          tickSelectedOption
          tickRight
          iconRight={<IconChevronDown />}
          onChange={onChange}
          buttonStyle={{
            padding: `2px 2px 2px 8px`,
          }}
        />
      </div>
    </StyledViewType>
  );
}
