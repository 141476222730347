import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconProjectTentative = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="m12.922 3.351 5.125 3.065a2.64 2.64 0 0 1 1.265 2.27v6.128a2.64 2.64 0 0 1-1.265 2.27l-5.125 3.065a2.456 2.456 0 0 1-2.531 0l-5.125-3.065A2.64 2.64 0 0 1 4 14.814V8.686a2.64 2.64 0 0 1 1.266-2.27L10.39 3.35a2.456 2.456 0 0 1 2.53 0Zm-.76 1.362a.983.983 0 0 0-1.012 0L6.025 7.778a1.056 1.056 0 0 0-.506.908v6.128c0 .375.193.721.506.908l5.125 3.065c.313.187.7.187 1.012 0l5.126-3.065c.313-.187.506-.533.506-.908V8.686c0-.375-.193-.721-.506-.908l-5.126-3.065Z"
        clipRule="evenodd"
      />
    </SVGIcon>
  );
};

export { IconProjectTentative };
