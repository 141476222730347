import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import { trackEvent } from '@float/common/lib/analytics';
import { EH, Popover, Spacer } from '@float/ui/deprecated';
import {
  CHART_TYPE_OPTIONS,
  MODE_OPTIONS,
  UNIT_OPTIONS,
} from '@float/ui/deprecated/Chart';
import { IconExport } from '@float/ui/deprecated/Earhart/Icons';

import ChartVirtualSelect from './components/ChartVirtualSelect';
import ModeOptions from './components/ModeOptions';
import ReportsDatePicker from './components/ReportsDatePicker';
import ReportViewPicker from './components/ReportViewPicker';
import { ModeSelection } from './styles';

const TabBarButtonGroup = styled.div`
  padding: 10px 0;
`;

const ReportTabsContainer = styled.div`
  background-color: ${({ theme }) => theme.surface1};
  border-bottom: 0;
  font-size: 13px;
  box-sizing: border-box;
  z-index: 2;
  position: sticky;
  top: 0;
  padding-top: 14px;

  @media (max-width: 1010px) {
    top: 96px;
  }
`;

const ReportTabs = styled.div`
  height: 54px;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 1010px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ExportButton = ({ options }) => {
  const [open, setOpen] = useState(false);

  // TODO This is a big memory leak source
  // Replace with a new library
  return (
    <Popover
      arrow={false}
      animation={false}
      className="menu"
      placement="bottom-end"
      distance={10}
      open={open}
      onOpenChange={setOpen}
      content={
        <EH.List
          options={options}
          minWidth="205px"
          onClick={(opt) => {
            trackEvent('reports-csv-export', { type: opt.title });
            setOpen(false);

            opt.fn();
          }}
        />
      }
    >
      <NavIconBtn isPrimary={false} iconLeft={<IconExport size={20} />}>
        Export
      </NavIconBtn>
    </Popover>
  );
};

export const ReportGlobalControls = ({
  memberViewSelf,
  hideTeamModeFilters,
  reportsSettings,
  timeTrackingEnabled,
  timeoffApprovalsEnabled,
  isDraftProjectReport,
  onDateRangeChange,
  datePickerSettings,
  unitOptions,
  modeOptions,
  includeChartTypeSelection,
  hasLoggedTime,
}) => {
  const onModeChange = useCallback(
    (val) => reportsSettings.updateSettings({ comparisonMode: val.value }),
    [reportsSettings],
  );

  return (
    <>
      <ReportTabsContainer>
        <ReportTabs>
          <ReportsDatePicker
            settings={datePickerSettings || reportsSettings.settings}
            rangeOpts={datePickerSettings?.rangeOpts}
            onDateRangeChange={
              onDateRangeChange ??
              ((startDate, endDate, rangeMode) => {
                reportsSettings.updateSettings({
                  startDate,
                  endDate,
                  rangeMode,
                });
              })
            }
          />
          <Spacer axis="x" />
          <TabBarButtonGroup>
            <ModeSelection>
              {!!timeTrackingEnabled && !isDraftProjectReport && (
                <ModeOptions
                  value={reportsSettings.settings.comparisonMode}
                  hasLoggedTime={hasLoggedTime}
                  options={modeOptions || MODE_OPTIONS}
                  onChange={onModeChange}
                />
              )}
              <ChartVirtualSelect
                value={reportsSettings.settings.timeUnit}
                options={unitOptions || UNIT_OPTIONS}
                onChange={(val) =>
                  reportsSettings.updateSettings({ timeUnit: val.value })
                }
              />
              {!!includeChartTypeSelection && (
                <>
                  <Spacer size={10} />
                  <ChartVirtualSelect
                    value={reportsSettings.settings.chartType}
                    options={CHART_TYPE_OPTIONS}
                    onChange={(val) =>
                      reportsSettings.updateSettings({ chartType: val.value })
                    }
                  />
                </>
              )}
            </ModeSelection>
          </TabBarButtonGroup>
        </ReportTabs>
      </ReportTabsContainer>
      <ReportViewPicker
        settings={reportsSettings.settings}
        updateSettings={reportsSettings.updateSettings}
        timeTrackingEnabled={timeTrackingEnabled}
        isDraftProjectReport={isDraftProjectReport}
        timeoffApprovalsEnabled={timeoffApprovalsEnabled}
        memberViewSelf={memberViewSelf}
        hideTeamModeFilters={hideTeamModeFilters}
        unitOptions={unitOptions}
        includeChartTypeSelection={includeChartTypeSelection}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  timeoffApprovalsEnabled: !!state.companyPrefs.timeoff_approvals,
  timeTrackingEnabled:
    state.companyPrefs.time_tracking && state.companyPrefs.time_tracking > 0,
});

export default connect(mapStateToProps)(ReportGlobalControls);
