import { createSelector } from 'reselect';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getIsLogTimeView } from '@float/common/selectors/appInfo/scheduleView';
import { getUser } from '@float/common/selectors/currentUser';
import { createSelectorWithShallowEqualSetResultCheck } from '@float/common/selectors/lib/createSelectorWithShallowEqualSetResultCheck';
import { getLoggedTimesListRaw } from '@float/common/selectors/loggedTimes';
import {
  getPeople,
  getPeopleListRaw,
} from '@float/common/selectors/people/people';
import { getMeFilter } from '@float/common/selectors/search';
import { getActiveFilters } from '@float/common/selectors/views';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

import { forPeople } from '../core';
import { isAllowedToViewPerson } from '../permissions/isAllowedToViewPerson';
import { getSearchDerivedContext } from './derivedContext';
import { getSearchResults } from './searchResults';

const selectAccessiblePeopleList = createSelector(
  [getPeopleListRaw, getUser],
  (people, user) =>
    people.filter((person) => isAllowedToViewPerson({ user }, person)),
);

const selectAccessiblePeopleIdsSet = createSelector(
  [selectAccessiblePeopleList],
  (people) => new Set(people.map((person) => person.people_id)),
);

// OPTIMIZATION: When the results of consecutive calls are equals
// keep the same referential identity to reduce the recoputations of the consumers
const selectSearchFilteredPeopleIdsFromSearchCore =
  createSelectorWithShallowEqualSetResultCheck(
    [
      getUser,
      getSearchDerivedContext,
      getActiveFilters,
      getMeFilter,
      (state: ReduxStateStrict) => state.projects.projects,
      (state: ReduxStateStrict) => state.timeoffs.timeoffs,
      (state: ReduxStateStrict) => state.timeoffTypes.timeoffTypes,
      selectAccessiblePeopleList,
      (state: ReduxStateStrict) => state.accounts.accounts,
      (state: ReduxStateStrict) => state.departments.departments,
      (state: ReduxStateStrict) => state.tasks.tasks,
      getLoggedTimesListRaw,
      getIsLogTimeView,
      (state: ReduxStateStrict) => state.roles.roles,
    ],
    (
      user,
      context,
      filters,
      me,
      projects,
      timeoffs,
      timeoffTypes,
      people,
      accounts,
      departments,
      tasks,
      loggedTimes,
      isLogTimeView,
      roles,
    ) => {
      return forPeople(
        {
          user,
          search: context,
          projects,
          timeoffs,
          timeoffTypes,
          accounts,
          me,
          departments,
          tasks,
          loggedTimes,
          isLogTimeView,
          roles,
        },
        people,
        filters,
      );
    },
  );

const selectSearchFilteredPeopleIdsFromSearchWorkerResults =
  createSelectorWithShallowEqualSetResultCheck(
    [getSearchResults],
    (searchResults) => {
      // Check on the searchResults state
      // It is undefined when the search is processed with selectors (Search worker disabled)
      // or when this selector is executed inside the Search worker
      if (searchResults) {
        return searchResults.people;
      }

      return null;
    },
  );

const selectSearchFilteredPeopleIdsFromSearchResolve =
  createSelectorWithShallowEqualSetResultCheck(
    [getActiveFilters, getSearchResults, selectAccessiblePeopleIdsSet],
    (filters, searchResults, accessiblePeopleSet) => {
      if (filters.length && searchResults) {
        // ACL checks are not executed by SBL
        // So we intersect the SBL results with our ACL results
        return accessiblePeopleSet.intersection(searchResults.people);
      }

      // If there are no filters we want to return all the people
      // that the user can read
      return accessiblePeopleSet;
    },
  );

// OPTIMIZATION: We are deliberately not using `createSelector` here in order to run only the selector that we need
// instead of running al the dependecies eagerly
export const getSearchFilteredPeopleIds = (state: ReduxStateStrict) => {
  if (
    // On the WebWorker the featureFlags are not available
    // and we don't need to check the FF there because with SBL
    // the search won't run there
    featureFlags.isReady &&
    featureFlags.isFeatureEnabled(FeatureFlag.SearchBeyondLimits)
  ) {
    return selectSearchFilteredPeopleIdsFromSearchResolve(state);
  }

  const searchWorkerResults =
    selectSearchFilteredPeopleIdsFromSearchWorkerResults(state);

  if (searchWorkerResults) {
    return searchWorkerResults;
  }

  return selectSearchFilteredPeopleIdsFromSearchCore(state);
};

export const getSearchFilteredPeople = createSelector(
  [getSearchFilteredPeopleIds, getPeople],
  (ids, people) => {
    return people.filter((person) => ids.has(person.people_id));
  },
);
