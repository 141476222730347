import React from 'react';

import { formatHours } from '../utils/formatHours';
import { MainCellItem } from './types';

import { hourElement, singleRowIcons } from '../../EntityItemStyles.css';

export function getSingleRow(
  item: MainCellItem,
  fullDay: boolean,
  iconElements: React.ReactNode,
) {
  return (
    <>
      {!fullDay && (
        <div className={hourElement}>{formatHours(item.entity)}</div>
      )}

      <div className={singleRowIcons}>{iconElements}</div>
    </>
  );
}
