import { PROMPTS } from '@float/constants/onboarding';

const getProjectName = (context) => {
  return { projectName: context.data.projectName };
};

const getEntriesCount = (context) => {
  return { total: context.filter((entry) => entry.success).length };
};

const getProductTourAnalyticsData = (context) => {
  const data = {
    prompt_index: context.step,
    prompt_name: context.title,
  };

  if (context.replaying) {
    data.prompt_replaying = context.replaying;
  }

  if (context.interaction !== null) {
    data.user_interaction = context.interaction ? 'clicked_x' : 'next';
  }

  return data;
};

const getAddATaskAnalyticsData = (context) => {
  const data = { action: context.action };

  if (context.task_id) {
    data.task_id = context.task_id;
  }

  return data;
};

const getHelpGuideAnalyticsData = (context) => {
  const data = { user_interaction: context.interaction };

  return data;
};

const ONBOARDING_PROMPTS_ANALYTICS_METADATA = {
  [PROMPTS.welcomeGetToKnowYou]: [{ key: 'onboarding_get_to_know_you' }],
  [PROMPTS.welcomeAddProject]: [
    { key: 'onboarding_quick_add_project', dataFn: getProjectName },
  ],
  [PROMPTS.welcomeAddTask]: [
    { key: 'onboarding_quick_add_tasks', dataFn: getEntriesCount },
  ],
  [PROMPTS.welcomeAddPerson]: [
    { key: 'onboarding_quick_add_people', dataFn: getEntriesCount },
  ],
  [PROMPTS.productTour]: [
    {
      key: 'onboarding_product_tour_prompt_shown',
      dataFn: getProductTourAnalyticsData,
    },
    {
      key: 'onboarding_product_tour_prompt_dismissed',
      dataFn: getProductTourAnalyticsData,
    },
  ],
  [PROMPTS.addFirstTask]: [
    { key: 'onboarding_add_first_task_shown' },
    { key: 'onboarding_add_first_task_dismissed' },
    {
      key: 'onboarding_add_first_task_modal',
      dataFn: getAddATaskAnalyticsData,
    },
  ],
  [PROMPTS.helpGuide]: [
    { key: 'onboarding_help_guide_shown' },
    {
      key: 'onboarding_help_guide_dismissed',
      dataFn: getHelpGuideAnalyticsData,
    },
  ],
};

export const getPromptAnalyticsMetadata = (prompt, state) => {
  if (ONBOARDING_PROMPTS_ANALYTICS_METADATA[prompt]) {
    return ONBOARDING_PROMPTS_ANALYTICS_METADATA[prompt][state];
  }

  return {};
};
