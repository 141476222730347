import { useEffect } from 'react';
import * as Intercom from '@intercom/messenger-js-sdk';

import { Resources } from '@float/common/api3/constants';
import {
  IntercomPlatform,
  requestIntercomHash,
} from '@float/common/api3/intercom';
import { useStoppableSWR } from '@float/libs/hooks/useStoppableSWR';

export function trackEvent(eventName: string, metaData = {}) {
  Intercom.trackEvent(eventName, metaData);
}

export function trackCompanyData(key: string, value: unknown, cid: number) {
  Intercom.update({
    companies: [{ company_id: cid, [key]: value }],
  });
}

export function trackUserData(key: string, value: unknown) {
  if ('amplitude' in window) {
    const instance = window?.amplitude.getInstance();
    if (instance) {
      instance.setUserProperties({ [key]: value });
    }
  }

  Intercom.update({ [key]: value });
}

export function logout() {
  Intercom.shutdown();
}

export const getIntercomAppId = () => {
  const isProd = import.meta.env.PROD;
  const appId = isProd ? 'c74jyb7j' : 'zek44tcg';

  return appId;
};

export const initIntercomWithoutUser = () => {
  Intercom.Intercom({
    api_base: 'https://api-iam.intercom.io',
    app_id: getIntercomAppId(),
  });
};

export const useInitIntercomWithIdentifiedUser = (
  accountId: number | undefined,
  platform: IntercomPlatform,
  accessToken: string | null,
) => {
  const hasAccessToken = Boolean(accessToken);

  const { data } = useStoppableSWR(
    Resources.IntercomHash,
    () => requestIntercomHash(platform),
    {
      fallbackData: undefined,
      isDataFetchingEnabled: hasAccessToken,
    },
  );

  useEffect(() => {
    if (data?.hash && accountId) {
      const appId = getIntercomAppId();

      Intercom.Intercom({
        api_base: 'https://api-iam.intercom.io',
        app_id: appId,
        user_id: String(accountId),
        user_hash: data.hash,
      });
    }
  }, [data?.hash, accountId]);
};

export default { logout };
