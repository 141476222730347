import React, { StrictMode } from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconCalendarPlus = (props: SVGIconProps) => (
  <StrictMode>
    <SVGIcon {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.25 11.25v-2.5a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.5m5.75-4.5v4.5M19.25 17h-4.5M8 4.75v3.5m8-3.5v3.5m-8.25 2.5h8.5"
      />
    </SVGIcon>
  </StrictMode>
);
