import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { t, Trans } from '@lingui/macro';
import { keyBy } from 'lodash';

import { addRole } from '@float/common/actions';
import { formatRateWithCurrencySymbol } from '@float/common/lib/rates/rates';
import { getRolesOptions } from '@float/common/selectors/roles';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { Checkbox } from '@float/ui/components/Checkbox';
import { CheckboxProps } from '@float/ui/components/Checkbox/types';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { getCurrencyProps } from '@float/web/selectors';

import * as stylesCommon from './BulkEditPeopleModal.css';
import * as styles from './Role.css';

export type RoleProps = {
  value: number | string | null;
  onChange: (value: RoleProps['value']) => void;
};

export type RoleHandle = {
  getFields: () => Promise<{
    role_id: number | null;
    default_hourly_rate?: string | null;
  }>;
};

export const Role = forwardRef<RoleHandle, RoleProps>((props, ref) => {
  const { value, onChange } = props;

  const dispatch = useAppDispatchStrict();

  const currencyConfig = useAppSelectorStrict(getCurrencyProps);

  const rolesOptions = useAppSelectorStrict(getRolesOptions);

  const rolesOptionsById = useMemo(
    () => keyBy(rolesOptions, 'value'),
    [rolesOptions],
  );

  const rolesOptionsWithIcons = useMemo(
    () =>
      rolesOptions.map((option) => ({
        ...option,
        iconRight: option.defaultHourlyRate ? (
          <span className={stylesCommon.roleRate}>
            <Trans>
              {formatRateWithCurrencySymbol(
                option.defaultHourlyRate,
                currencyConfig,
              )}{' '}
              /hr
            </Trans>
          </span>
        ) : null,
      })),
    [rolesOptions, currencyConfig],
  );

  const roleSelected =
    value && rolesOptionsById[value] ? rolesOptionsById[value] : null;

  const roleSelectedDefaultHourlyRateFormatted = formatRateWithCurrencySymbol(
    roleSelected?.defaultHourlyRate,
    currencyConfig,
  );

  const roleSelectedRateSuffix = roleSelectedDefaultHourlyRateFormatted ? (
    <span className={stylesCommon.roleRateSelected}>
      <Trans>{roleSelectedDefaultHourlyRateFormatted} /hr</Trans>
    </span>
  ) : null;

  const [shouldApplyRoleRate, setShouldApplyRoleRate] = useState(true);

  const handleChangeRole = (e: { value: number | string }) => {
    onChange(e.value);
  };

  const handleChangeApplyRoleRate: CheckboxProps['onChange'] = (
    applyNewRoleRate,
  ) => {
    if (typeof applyNewRoleRate !== 'boolean') return;

    setShouldApplyRoleRate(applyNewRoleRate);
  };

  // Exposing getFields as so the BulkeEditModal component can use the getFields method before submitting
  useImperativeHandle(
    ref,
    () => {
      return {
        /*
         * The "Role" VirtualSelect differentiates between new and existing roles by the datatype of `value`:
         *   - For new roles, `value` is set as a string (the role name).
         *   - For existing roles, `value` is a number (the role_id)
         *
         * Creating a new role before `bulkUpdate` is called prevents an unnecessary rerender of the People table.
         */
        async getFields() {
          const defaultHourlyRateField =
            shouldApplyRoleRate && roleSelected?.defaultHourlyRate
              ? {
                  default_hourly_rate: null,
                }
              : {};

          const shouldCreateANewRole = typeof value === 'string';

          const roleId = shouldCreateANewRole
            ? (
                await dispatch(
                  addRole(
                    { name: value },
                    { trackEvent: true, from: 'people-management-bulk' },
                  ),
                )
              ).id
            : value;

          const roleIdField = {
            role_id: roleId,
          };

          // We need to ensure we call the onChange to use the new numerical value,
          // otherwise when the flow is interrupted with confirm modal, there would be an attempt
          // to create a role with a same name value which will cause a runtime error
          if (shouldCreateANewRole) onChange(roleId);

          return {
            ...roleIdField,
            ...defaultHourlyRateField,
          };
        },
      };
    },
    [roleSelected, shouldApplyRoleRate, value, dispatch, onChange],
  );

  return (
    <div className={styles.wrapper}>
      <div>
        <VirtualSelect
          // @ts-expect-error TS doesn't infer PropTypes from VirtualSelect
          label={t`Role`}
          visibleItems={6}
          creatable
          clearInputOnDropdownOpen={false}
          options={rolesOptionsWithIcons}
          value={value}
          onChange={handleChangeRole}
          suffix={roleSelectedRateSuffix}
          hideSelectedIcon={true}
        />
      </div>

      {roleSelected?.defaultHourlyRate && (
        <div>
          <Checkbox
            label={t`Apply new Role rate`}
            checked={shouldApplyRoleRate}
            onChange={handleChangeApplyRoleRate}
          />
        </div>
      )}
    </div>
  );
});
