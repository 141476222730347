import { createSelector } from 'reselect';

import { PROMPTS } from '@float/constants/onboarding';

import { getAllProjects, getLastUpdatedProject, getPromptData } from './common';

export const getLastUpdatedProjectData = createSelector(
  [getPromptData, getLastUpdatedProject, getAllProjects],
  (promptData, lastUpdatedProject, allProjects) => {
    let tasks;

    if (promptData[PROMPTS.welcomeAddTask]) {
      tasks = promptData[PROMPTS.welcomeAddTask];
    }

    if (promptData[PROMPTS.welcomeAddProject]) {
      return {
        project_name: promptData[PROMPTS.welcomeAddProject].data.projectName,
        tasks,
        ...promptData[PROMPTS.welcomeAddProject].response,
      };
    }

    // if there aren't any data on onboarding reducer
    // (e.g., user refreshed the window and is resuming the survey)
    // get it from lastUpdated if available, otherwise
    // try to get last project
    let { project_id: projectID } = lastUpdatedProject;

    if (!projectID || !allProjects[projectID]) {
      const allProjectIDS = Object.keys(allProjects);
      projectID = allProjectIDS[allProjectIDS.length - 1];
    }

    if (allProjects[projectID]) {
      return { ...allProjects[projectID], tasks };
    }

    return {};
  },
);
