import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentPlan, getUser } from 'selectors';

import { isPaidPlan } from '@float/common/selectors/companyPrefs';
import { useAppSelector } from '@float/common/store';
import EH from '@float/ui/deprecated/Earhart';
import { IconBuilding } from '@float/ui/icons/essentials/IconBuilding';
import { IconHat } from '@float/ui/icons/essentials/IconHat';
import { IconStatus } from '@float/ui/icons/essentials/IconStatus';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';

import { isPlanStarter } from '../Billing/TeamAccountFeaturesNew/helpers';
import { FreeTrialUpgrade } from '../FreeTrialUpgrade';
import AddonTag from './AddonTag';
import {
  StyledFreeTrialUpgradeWrapper,
  StyledNav,
  StyledScroll,
  StyledScrollContent,
} from './styles';

export const SideNav = ({ className, userCanSee }) => {
  const plan = useAppSelector(getCurrentPlan);
  const user = useAppSelector(getUser);
  const isPlanPaid = useAppSelector(isPaidPlan);
  const showTag = isPlanStarter(
    plan.plan_id,
    isPlanPaid,
    user.time_tracking,
    user.plus_pack,
  );
  const location = useLocation();

  const [currentPathname, setCurrentPathname] = useState(location.pathname);

  const isActive = (pathname) => currentPathname.includes(pathname);

  useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location]);

  const canSeeSettingsSection =
    userCanSee.billing ||
    userCanSee.general ||
    userCanSee.api ||
    userCanSee.security;

  return (
    <StyledNav className={className}>
      <header>
        <h2>Team settings</h2>
      </header>

      <StyledScroll>
        <StyledScrollContent>
          {canSeeSettingsSection && (
            <ul>
              <li>
                <h5>Settings</h5>
              </li>

              {userCanSee.billing && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EH.Icons.IconCreditCard}
                    to="/admin/billing"
                    active={isActive('/admin/billing')}
                  >
                    Plans &amp; billing
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.general && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EH.Icons.IconCog}
                    to="/admin/general"
                    active={isActive('/admin/general')}
                  >
                    General
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.general && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EH.Icons.IconBell}
                    to="/admin/notifications"
                    active={isActive('/admin/notifications')}
                  >
                    Notifications
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.api && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EH.Icons.IconTerminal}
                    to="/admin/api"
                    active={isActive('/admin/api')}
                  >
                    Integrations
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}

              {userCanSee.security && (
                <li>
                  <EH.Buttons.SettingsNavButton
                    icon={EH.Icons.IconShieldTick}
                    to="/admin/security"
                    active={isActive('/admin/security')}
                  >
                    Security
                    {showTag && <AddonTag />}
                  </EH.Buttons.SettingsNavButton>
                </li>
              )}
            </ul>
          )}

          <ul>
            <li>
              <h5>Admin</h5>
            </li>

            <li>
              <EH.Buttons.SettingsNavButton
                icon={EH.Icons.IconSlider}
                to="/admin/preferences"
                active={isActive('/admin/preferences')}
              >
                Hours &amp; currency
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.lockLoggedTime && (
              <li>
                <EH.Buttons.SettingsNavButton
                  icon={EH.Icons.IconWatch}
                  to="/admin/time-tracking"
                  active={isActive('/admin/time-tracking')}
                >
                  Time tracking
                  {showTag && <AddonTag />}
                </EH.Buttons.SettingsNavButton>
              </li>
            )}

            <li>
              <EH.Buttons.SettingsNavButton
                icon={IconUsers}
                to="/admin/guests"
                active={isActive('/admin/guests')}
              >
                Guests
              </EH.Buttons.SettingsNavButton>
            </li>

            <li>
              <EH.Buttons.SettingsNavButton
                icon={EH.Icons.IconCalendar}
                to="/admin/timeoffs"
                active={isActive('/admin/timeoffs')}
                style={{ position: 'relative' }}
              >
                Time off
              </EH.Buttons.SettingsNavButton>
            </li>

            <li>
              <EH.Buttons.SettingsNavButton
                icon={IconStatus}
                to="/admin/status"
                active={isActive('/admin/status')}
                style={{ position: 'relative' }}
              >
                Statuses
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.adminForTags && (
              <li>
                <EH.Buttons.SettingsNavButton
                  icon={EH.Icons.IconTag}
                  to="/admin/tags"
                  active={isActive('/admin/tags')}
                >
                  Tags
                </EH.Buttons.SettingsNavButton>
              </li>
            )}

            <li>
              <EH.Buttons.SettingsNavButton
                icon={IconBuilding}
                to="/admin/departments"
                active={isActive('/admin/departments')}
              >
                Departments
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.roles && (
              <li data-callout-id="roles-management">
                <EH.Buttons.SettingsNavButton
                  icon={IconHat}
                  to="/admin/roles"
                  active={isActive('/admin/roles')}
                >
                  Roles
                </EH.Buttons.SettingsNavButton>
              </li>
            )}

            <li>
              <EH.Buttons.SettingsNavButton
                icon={EH.Icons.IconBriefcase}
                to="/admin/clients"
                active={isActive('/admin/clients')}
              >
                Clients
              </EH.Buttons.SettingsNavButton>
            </li>

            {userCanSee.teamActivity && (
              <li>
                <EH.Buttons.SettingsNavButton
                  icon={EH.Icons.IconActivity}
                  to="/admin/activity"
                  active={isActive('/admin/activity')}
                >
                  Activity
                  {showTag && <AddonTag />}
                </EH.Buttons.SettingsNavButton>
              </li>
            )}
          </ul>
        </StyledScrollContent>
      </StyledScroll>

      <StyledFreeTrialUpgradeWrapper>
        <FreeTrialUpgrade />
      </StyledFreeTrialUpgradeWrapper>
    </StyledNav>
  );
};

export default SideNav;
