import React from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { Rights } from '@float/common/lib/acl';
import { useAppSelector } from '@float/common/store';
import {
  bindVal,
  Col,
  EH,
  FieldLabel,
  Input,
  MultiSelect,
  Spacer,
  VirtualSelect,
} from '@float/ui/deprecated';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import {
  getDepartments,
  getSubdepartments,
  getUser,
  userCanSeeBudgets,
} from '@float/web/selectors';
import {
  getAccurateNameByAccountId,
  getDirectManagersByPeopleId,
} from '@float/web/selectors/people';
import { DEFAULT_TAG_COLOR } from '@float/web/selectors/tags';

import { PersonRole } from './components/PersonRole/PersonRole';
import { PersonType } from './components/PersonType/PersonType';
import { getRateInputPlaceholderValue } from './PersonInfo.helpers';

let self;

function onPersonTypeChange({ value }) {
  self.setFormState({ people_type_id: value });
}

const MangerNames = styled.span`
  ${EH.Typography.Label16.R400};
  color: ${EH.Colors.TCore.Emp.High12};
  padding-left: 2px;
`;

export const ReadOnlyManagedBy = ({ personId }) => {
  const accurateNameByAccountId = useAppSelector(getAccurateNameByAccountId);
  const directManagersByPeopleId = useAppSelector(getDirectManagersByPeopleId);
  const managers = directManagersByPeopleId[personId] || [];

  if (isEmpty(managers)) {
    return null;
  }

  return (
    <>
      <FieldLabel>Managed by</FieldLabel>
      <Col alignItems="flex-start">
        <Spacer size={6} />
        <MangerNames>
          {managers
            .map((accountId) => accurateNameByAccountId[accountId])
            .join(', ')}
        </MangerNames>
      </Col>
    </>
  );
};

export const PersonDepartmentField = () => {
  const { form } = self.state;
  const isLimitedByDepartment = self.isUserLimitedByDepartment();

  const user = useAppSelector(getUser);
  const departments = useAppSelector(getDepartments);
  const subDepartments = useAppSelector(getSubdepartments);

  if (user.department_filter?.length === 1) {
    return (
      <Input
        style={{ marginBottom: 22 }}
        readOnly
        noBorder
        label="Department"
        value={self.props.departments[user.department_filter[0]].name}
      />
    );
  }

  return (
    <VirtualSelect
      label="Department"
      style={{ marginBottom: 22 }}
      placeholder="No department"
      visibleItems={6}
      creatable={Rights.canCreateDepartment(user)}
      autoSelectOnBlur
      nonNullable={isLimitedByDepartment}
      hideClearIcon={isLimitedByDepartment}
      clearInputOnDropdownOpen={false}
      value={form.department_id}
      options={self.props.departmentOptions.map((o) => {
        const department = departments[o.value];
        return {
          ...o,
          icon: department.parent_id && <IconArrowDownRight size={16} />,
        };
      })}
      errors={self.state.formErrors.department_id}
      onChange={(option) => {
        const { isCreate } = option;
        const nextDepartmentId = isCreate ? option.value : +option.value;
        const newState = { department_id: nextDepartmentId || 0 };
        const { department_id, account } = self.state.form;
        if (newState.department_id === department_id) {
          return;
        }

        // make sure selected department is a part of viewable departments
        // unless a parent department is already in the list
        if (!isCreate && nextDepartmentId && account?.department_filter_set) {
          const currentDepartmentFilter = account?.department_filter || [];
          const canViewNextDepartment = currentDepartmentFilter.some(
            (departmentId) => {
              return subDepartments[departmentId].includes(nextDepartmentId);
            },
          );

          if (!canViewNextDepartment) {
            newState.account = {
              ...account,
              department_filter: [
                ...currentDepartmentFilter.filter(
                  (depId) =>
                    depId !== nextDepartmentId &&
                    !subDepartments[nextDepartmentId].includes(depId),
                ),
                nextDepartmentId,
              ],
            };
          }
        }

        self.setFormState(newState);
      }}
    />
  );
};

export default function renderPersonInfo(that) {
  self = that;

  const {
    currentUser,
    peopleTagByLabel,
    roles,
    rolesOptions,
    personTypeOptions,
    currencyConfig,
  } = self.props;

  const { form } = self.state;
  const { initialPerson } = self;

  const peopleTagsWithColor = form.tags.map((tag) => {
    const color = peopleTagByLabel[tag.label]?.color
      ? `#${peopleTagByLabel[tag.label].color}`
      : DEFAULT_TAG_COLOR;

    return {
      ...tag,
      color,
    };
  });

  const { value: roleId, onChange: onRoleChange } = bindVal(
    self,
    'role_id',
    null,
  );

  const hasCustomRate = !!form.default_hourly_rate;
  const canSeeBudgets = userCanSeeBudgets(currentUser);
  const showRates = canSeeBudgets;

  const {
    value: currencyValue,
    onChange: onCurrencyChange,
    errors: currencyErrors,
  } = bindVal(
    self,
    'default_hourly_rate',
    undefined,
    Input.validators.number(6),
  );

  const currencyValuePlaceholder = getRateInputPlaceholderValue(
    form.role_id,
    roles,
  );

  return (
    <div>
      <PersonRole
        roleId={roleId}
        rolesOptions={rolesOptions}
        currencyConfig={currencyConfig}
        hasCustomRate={hasCustomRate}
        showRates={showRates}
        onRoleChange={onRoleChange}
      />
      <PersonDepartmentField />
      <MultiSelect
        creatable={Rights.canCreateTags(currentUser, { tag: { type: 2 } })}
        style={{ marginBottom: 22 }}
        values={peopleTagsWithColor}
        options={self.props.tagOptions}
        visibleItems={6}
        errors={self.state.formErrors.tags}
        onAdd={(tag) => {
          self.setFormErrors({
            tags: [],
          });
          self.setFormState({
            tags: [...(form.tags || []), tag],
          });
        }}
        onRemove={(tag) => {
          self.setFormErrors({
            tags: [],
          });
          self.setFormState({
            tags: form.tags.filter((t) => t.value !== tag.value),
          });
        }}
      />

      <PersonType
        currencyErrors={currencyErrors}
        currencyValue={currencyValue}
        currencyValuePlaceholder={currencyValuePlaceholder}
        currentUser={currentUser}
        initialPerson={initialPerson}
        onCurrencyChange={onCurrencyChange}
        onPersonTypeChange={onPersonTypeChange}
        person={form}
        personTypeOptions={personTypeOptions}
        personTypeErrors={self.state.formErrors.personType}
      />

      <ReadOnlyManagedBy personId={form.people_id} />
    </div>
  );
}
