import {
  QueryApiParams,
  QueryApiResult,
  search,
} from '@float/common/api3/search';

import { normalize, SearchAutocompleteCategory } from '../helpers';
import { SearchAutocompleteQueryItem } from '../selectors/getSearchAutocompleteResults';
import { REMOTE_TYPE_TO_FILTER } from './constants';

const CATEGORY_TYPE_TO_REMOTE_TYPE = {
  clients: 'client',
  departments: 'department',
  jobTitles: 'role',
  roles: 'role',
  people: 'people',
  managers: 'manager',
  personTags: 'peopleTag',
  personTypes: 'peopleType',
  projectStatuses: 'projectStatus',
  projectTags: 'projectTag',
  projects: 'project',
  projectOwners: 'projectOwner',
  tasks: 'task',
  taskStatuses: 'taskStatus',
  timeoffs: 'timeOffType',
  timeoffStatuses: 'timeOffStatus',
  phases: 'phase',
};

function formatItem(item: QueryApiResult): SearchQueryItem {
  const type = REMOTE_TYPE_TO_FILTER[item.type];
  const val = item.name;

  // Keeping this because it's used when sorting the items on sortFilteredCandidates
  const normalizedVal = normalize(val);

  switch (type) {
    case 'person':
    case 'timeoff':
    case 'project':
      return {
        type,
        val,
        normalizedVal,
        isActive: item.meta?.isActive !== 0,
        id: item.id,
      };
    case 'department':
      return {
        type,
        val,
        normalizedVal,
        id: item.id,
        parent_id: item.meta?.parent_department_id ?? null,
      };
    default:
      return {
        type,
        val,
        normalizedVal,
        id: item.id,
      };
  }
}

function groupItems(items: SearchQueryItem[]): SearchQueryItem[] {
  const added = new Map<
    `${SearchQueryItem['type']}/${string}`,
    SearchQueryItem
  >();
  const result: SearchQueryItem[] = [];

  for (const item of items) {
    const key = `${item.type}/${item.normalizedVal}` as const;

    const entry = added.get(key);

    if (entry) {
      switch (entry.type) {
        case 'person':
        case 'timeoff':
        case 'project':
          if (entry.type !== item.type) break;

          // If one of the two items is active
          // mark the entry as active
          if (entry.isActive !== item.isActive) {
            entry.isActive = true;
          }

          break;
      }
    } else {
      added.set(key, item);
      result.push(item);
    }
  }

  return result;
}

export type SearchQueryItem = SearchAutocompleteQueryItem & {
  id: number;
};

export type AutocompleteQueryApiReturnValue = {
  items: SearchQueryItem[];
  count: number;
};

export async function queryApi(params: {
  query: string;
  category?: SearchAutocompleteCategory;
}): Promise<AutocompleteQueryApiReturnValue> {
  if (params.query.length <= 2 && !params.category) {
    return {
      items: [],
      count: 0,
    };
  }

  if (params.category === 'savedSearches') {
    return {
      items: [],
      count: 0,
    };
  }

  const category =
    params.category && CATEGORY_TYPE_TO_REMOTE_TYPE[params.category];

  const apiParams: QueryApiParams = {
    query: params.query,
  };

  if (category) {
    apiParams.filter = {
      key: 'category',
      value: category,
    };
    apiParams.pagination = {
      page: 1,
      pageSize: 50,
    };
  }

  const [items, , count] = await search.query(apiParams);

  return {
    items: groupItems(items.map(formatItem)),
    count,
  };
}
