import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const DEFAULT_SIZE = 6;

export const StyledNewIndicator = styled.div`
  width: ${(p) => p.size || DEFAULT_SIZE}px;
  height: ${(p) => p.size || DEFAULT_SIZE}px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.orange};
  display: inline-block;
  opacity: ${(p) => (p.fadeOut ? 0 : 1)};
  transition: opacity 2s ease-in-out;
`;

export const NewIndicator = ({ style, size, fadeOut }) => {
  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShouldFadeOut(fadeOut), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [fadeOut]);

  return (
    <StyledNewIndicator style={style} size={size} fadeOut={shouldFadeOut} />
  );
};

const UnreadIndicatorComponent = ({ unread, ...rest }) => {
  if (!unread) {
    return null;
  }

  return <NewIndicator {...rest} />;
};

const mapStateToProps = (state) => ({
  unread: state.notifications.unread,
});

export const UnreadIndicator = connect(mapStateToProps)(
  UnreadIndicatorComponent,
);
