import React, { ComponentProps } from 'react';
import { Trans } from '@lingui/react';
import { RecipeVariants } from '@vanilla-extract/recipes';
import { format } from 'date-fns';

import { getDaysRemainingText } from '@float/common/lib/timeoff/helpers/getDaysRemainingText';
import { getPluralDays } from '@float/common/lib/timeoff/helpers/getPluralDays';
import { noop } from '@float/libs/utils/noop';
import { TimeoffPreviewMode } from '@float/types/timeoffType';

import * as styles from './TimeoffRemainingDays.css';

type Props = ComponentProps<'span'> & {
  date: string;
  onClickDate?: () => void;
  previewMode?: TimeoffPreviewMode;
  remainingDays: number;
  withDate: boolean;
};

export type TimeoffRemainingDaysAppearance = NonNullable<
  RecipeVariants<typeof styles.span>
>;

const getAppearance = (
  days: number,
): TimeoffRemainingDaysAppearance['appearance'] =>
  days < 0 ? 'over' : 'default';

export const TimeoffRemainingDays = (props: Props) => {
  const {
    date,
    remainingDays,
    onClickDate = noop,
    previewMode,
    withDate,
  } = props;

  const appearance = getAppearance(remainingDays);
  const formattedDate =
    previewMode === TimeoffPreviewMode.Today
      ? TimeoffPreviewMode.Today
      : format(new Date(date), 'dd MMM yyyy');

  const onClickDateHandler = () => {
    onClickDate();
  };

  return (
    <span className={styles.span({ appearance })}>
      <Trans
        id={getDaysRemainingText(remainingDays, withDate).id}
        values={{
          days: getPluralDays(Math.abs(remainingDays)),
          date: formattedDate,
        }}
        components={{
          toggle: (
            <a
              className={styles.link}
              role="button"
              tabIndex={0}
              onClick={onClickDateHandler}
            />
          ),
        }}
      />
    </span>
  );
};
