import { difference } from 'lodash';
import { getNotifications } from 'selectors';

import request from '@float/common/lib/request';
import { ACTIVITY_ITEMS_PER_PAGE } from '@float/constants/activity';

import { receivedActivity } from '../../activity/actions';

export const NOTIFICATIONS_FETCH_BEGIN = 'NOTIFICATIONS_FETCH_BEGIN';
export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
export const NOTIFICATIONS_SEEN = 'NOTIFICATIONS_SEEN';
export const NOTIFICATION_FEED_CLOSED = 'NOTIFICATION_FEED_CLOSED';

const itemsPerPage = ACTIVITY_ITEMS_PER_PAGE;

export const receivedNotifications =
  ({ items, page, perPage, isLiveUpdate }) =>
  (dispatch) => {
    const activities = [];
    items.forEach((notif) => {
      if (notif.activity) {
        activities.push({
          ...notif.activity,
          unseen: !notif.seen,
        });
      }
    });

    if (activities.length) {
      dispatch(
        receivedActivity({
          items: activities,
          specialName: 'notificationFeed',
          page,
        }),
      );
    }

    dispatch({
      type: NOTIFICATIONS_FETCH_SUCCESS,
      items,
      page,
      perPage,
      isLiveUpdate,
    });
  };

export const requestNotifications = ({ toClear = false } = {}) => {
  return async (dispatch, getState) => {
    const { shared_link_view: isSharedLink } = getState().currentUser;
    if (isSharedLink) {
      return Promise.resolve(null);
    }
    const { page: currentPage, loadState } = getState().notifications;
    const previousPage = toClear ? 0 : currentPage;
    if (loadState === 'LOADING') {
      return Promise.resolve(null);
    }

    dispatch({ type: NOTIFICATIONS_FETCH_BEGIN });

    const page = previousPage + 1;
    return request
      .get(
        'svc/nf/notifications',
        {
          page,
          'per-page': itemsPerPage,
        },
        { hostname: '', version: '', jwt: true },
      )
      .then(({ items }) => {
        dispatch(receivedNotifications({ items, page, perPage: itemsPerPage }));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const notificationsSeen = ({ ids, isLiveUpdate = false }) => ({
  type: NOTIFICATIONS_SEEN,
  ids,
  isLiveUpdate,
});

export const markNotificationsAsSeen = (ids) => (dispatch) => {
  return request
    .put(
      'svc/nf/notifications',
      {
        ids,
      },
      { hostname: '', version: '', jwt: true },
    )
    .then((response) => {
      if (response && typeof response.updated !== 'undefined') {
        dispatch(notificationsSeen({ ids }));
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const getNotificationsNotAlreadySeen = (state) => {
  const notifications = getNotifications(state);
  const { pendingUpdate } = state.notifications;
  let ids = notifications.filter((n) => !n.seen).map((x) => x.nf_id);
  if (ids && ids.length && pendingUpdate && pendingUpdate.items) {
    const idsAlreadyMarkedAsSeen = Object.keys(pendingUpdate.items);
    ids = difference(ids, idsAlreadyMarkedAsSeen);
  }
  return ids;
};

const notificationFeedClosed = () => ({ type: NOTIFICATION_FEED_CLOSED });

export const closeNotificationFeed = () => (dispatch, getState) => {
  dispatch(notificationFeedClosed());

  const state = getState();
  const ids = getNotificationsNotAlreadySeen(state);
  if (ids && ids.length) {
    dispatch(markNotificationsAsSeen(ids)).then(() => {
      dispatch(notificationFeedClosed());
    });
  }
};

export const subscribeToBrowserPush = () => {
  // TODO: feature disabled for initial release
  return false;
};
