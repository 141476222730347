import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useDebouncedCallback } from '@float/common/lib/useDebouncedCallback';
import { useSafeContext } from '@float/libs/hooks/useSafeContext';
import { CellItem } from '@float/types';

const createContextConsumerHook =
  <T extends unknown>(context: React.Context<T | null>) =>
  () => {
    const ctxVal = useContext(context);

    if (!ctxVal) {
      throw Error('context consumer must be used in a context provider');
    }

    return ctxVal;
  };

type ViewportContextValue = {
  boundaryCol: number;
  setBoundaryCol: React.Dispatch<React.SetStateAction<number>>;
  heightsUpdatedAt: number;
  setHeightsUpdatedAt: React.Dispatch<React.SetStateAction<number>>;
  parentLinkCount: Record<string, number>;
  setParentLinkCount: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  rowsUpdatedAt: number;
  setRowsUpdatedAt: React.Dispatch<React.SetStateAction<number>>;
};

type ViewportSettersContextValue = {
  setBoundaryCol: React.Dispatch<React.SetStateAction<number>>;
  setHeightsUpdatedAt: React.Dispatch<React.SetStateAction<number>>;
  setParentLinkCount: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
  setRowsUpdatedAt: React.Dispatch<React.SetStateAction<number>>;
};

const ViewportContext = createContext<ViewportContextValue | null>(null);
// OPTIMIZATION: A setters only context to opt-out from subscribing to the values updates
const ViewportSettersContext =
  createContext<ViewportSettersContextValue | null>(null);
const VisibleTasksContext = createContext<Record<string, boolean> | null>(null);
const RegisterTasksContext = createContext<
  ((item: CellItem) => () => void) | null
>(null);

export const useViewportContext = createContextConsumerHook(ViewportContext);
export const useViewportSettersContext = createContextConsumerHook(
  ViewportSettersContext,
);
export const useVisibleTasks = createContextConsumerHook(VisibleTasksContext);

export const useRegisterVisibleTask = (item: CellItem, noAccess?: boolean) => {
  const registerTask = useSafeContext(RegisterTasksContext);
  useEffect(() => {
    if (item.type !== 'task' || noAccess) {
      // We only care about tracking the rendered state of tasks
      return;
    }

    return registerTask(item);
  }, [item, noAccess, registerTask]);
};

export function VisibleTasksContextProvider(props: {
  children: React.ReactNode;
}) {
  const visibleTasksRef = useRef<Record<string, boolean>>({});
  const [visibleTasks, setVisibleTasks] = useState(visibleTasksRef.current);
  const notifyVisibleTasks = useDebouncedCallback(
    () => setVisibleTasks(visibleTasksRef.current),
    300, // arbitrary number
    [],
  );

  const registerTask = useCallback(
    (item: CellItem) => {
      visibleTasksRef.current[item.entityId] = true;
      notifyVisibleTasks();

      return () => {
        delete visibleTasksRef.current[item.entityId];
        notifyVisibleTasks();
      };
    },
    [notifyVisibleTasks],
  );

  return (
    <VisibleTasksContext.Provider value={visibleTasks}>
      <RegisterTasksContext.Provider value={registerTask}>
        {props.children}
      </RegisterTasksContext.Provider>
    </VisibleTasksContext.Provider>
  );
}

export function ViewportContextProvider(props: { children: React.ReactNode }) {
  const [boundaryCol, setBoundaryCol] = useState(0);
  const [heightsUpdatedAt, setHeightsUpdatedAt] = useState(0);
  const [parentLinkCount, setParentLinkCount] = useState<
    Record<string, number>
  >({});
  const [rowsUpdatedAt, setRowsUpdatedAt] = useState(0);

  const setters = useMemo(() => {
    return {
      setBoundaryCol,
      setHeightsUpdatedAt,
      setParentLinkCount,
      setRowsUpdatedAt,
    };
  }, []);

  const value = useMemo(() => {
    return {
      boundaryCol,
      setBoundaryCol,
      heightsUpdatedAt,
      setHeightsUpdatedAt,
      parentLinkCount,
      setParentLinkCount,
      rowsUpdatedAt,
      setRowsUpdatedAt,
    };
  }, [boundaryCol, heightsUpdatedAt, parentLinkCount, rowsUpdatedAt]);

  return (
    <ViewportSettersContext.Provider value={setters}>
      <ViewportContext.Provider value={value}>
        <VisibleTasksContextProvider>
          {props.children}
        </VisibleTasksContextProvider>
      </ViewportContext.Provider>
    </ViewportSettersContext.Provider>
  );
}
