import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getAllSeen = createSelector(
  [(state) => state.activityData],
  (activityData) => {
    return get(
      activityData,
      'activityCollections.mainActivityFeed.allSeen',
      true,
    );
  },
);

export const getNotifications = createSelector(
  [(state) => state.notifications.items],
  (notifications) => Object.values(notifications),
);

export const getNotificationFeedActivities = createSelector(
  [
    (state) =>
      (state.activityData.activityCollections.notificationFeed || {}).items,
    getNotifications,
    (state) => state.accounts.accounts,
  ],
  (items = [], notifications, accounts) => {
    return items.map((item) => {
      const notification =
        notifications.find((n) => n.af_id === item._id) || {};
      return {
        ...item,
        unseen: !notification.seen,
        actioned_by: {
          ...item.actioned_by,
          // Get the avatar from the account if the account exists
          avatar: accounts[item.actioned_by.account_id]
            ? accounts[item.actioned_by.account_id].avatar
            : null,
        },
      };
    });
  },
);
