import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { getUser } from '@float/common/selectors/currentUser';
import { getIsScheduleLoading } from '@float/common/selectors/tasks';
import { PROMPTS } from '@float/constants/onboarding';
import { getPromptsUserHasDone } from '@float/web/store/onboardingManager/selectors/common';

export const getOldOnboardingStatus = createSelector(
  [getPromptsUserHasDone],
  (promptsUserHasDone) => {
    const completed = promptsUserHasDone.includes(PROMPTS.onboardingModal);

    return { completed };
  },
);

const getPrompts = (state) => state.legacyOnboarding.prompts;
const getPhases = (state) => state.phases.phases;

export const getOnboardingStatus = createSelector(
  [getUser, getIsScheduleLoading, getPrompts, getPhases],
  (user, scheduleLoading, prompts, phases) => {
    // Do not show to existing users who have already
    // seen the previous (deprecated) onboarding modal
    const status = {
      isComplete: prompts.includes(PROMPTS.onboardingModal),
      showAddPhase: false,
      showLogTime: false,
      showNonAccountOwner: false,
    };

    if (!user.accountLoaded || status.isComplete || scheduleLoading) {
      return status;
    }

    const isAccountOwner = user.account_tid == 1;
    if (isAccountOwner) {
      const phaseTipSeen = prompts.includes(PROMPTS.welcomeAddPhase);
      const logTimeTipSeen = prompts.includes(PROMPTS.welcomeLogTime);

      status.showAddPhase = !phaseTipSeen && isEmpty(phases);
      status.showLogTime = !logTimeTipSeen;
      status.isComplete = phaseTipSeen && logTimeTipSeen;
    } else {
      status.isComplete = !status.showNonAccountOwner;
    }

    return status;
  },
);
