import { moment } from '@float/libs/moment';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';

import {
  isRejectedTimeoff,
  isTentativeTimeoff,
} from '../../../../../common/src/lib/timeoffs';
import { trackTaskModalInteractivity } from '../tracking/trackTaskModalInteractivity';
import { getTrackTimeoffData, trackTimeoff } from '../tracking/trackTimeoff';

function getExportedStatusCopiedFromState(state) {
  const isRepeating = state.isRepeating;
  return {
    ...state.task,
    isStatus: true,
    start_date: moment(state.startDate).format('YYYY-MM-DD'),
    end_date: moment(state.endDate).format('YYYY-MM-DD'),
    name: state.statusText,
    status_type_id: state.statusTypeId,
    color: state.statusColor,
    repeat_state: isRepeating ? state.repeatState : 0,
    repeat_start: isRepeating ? state.repeatStart : null,
    repeat_end_date: isRepeating
      ? moment(state.repeatEnd).format('YYYY-MM-DD')
      : null,
    people_id: state.peopleIds[0] || state.originalTaskUnmodified.people_ids[0],
  };
}

// We export the task copied from the state, this was previously done through a
// key loop that copied attributes over, but we changed state variables to be camelCase,
// So now we must be more explicit about copying over. Ideally we can just export
// the state itself and remove this function but that would require an api refactor

export function getExportedTaskCopiedFromState(
  task = { ...this.state.task },
  isCancelling = false,
  state = this.state,
) {
  if (this.isStatusMode(state)) {
    return getExportedStatusCopiedFromState.call(this, state);
  }

  task.task_meta_id = state.taskMetaId;
  task.name = state.taskName;

  task.end_date = moment(state.endDate).format('YYYY-MM-DD');
  task.start_date = moment(state.startDate).format('YYYY-MM-DD');
  task.start_time = state.startTime;
  task.end_time = state.endTime;
  task.hours = Math.min(state.hoursPd, state.totalHours);
  // @entity.length
  task.length = state.length;
  task.mode = state.mode;
  task.status = state.status;

  task.people = state.people;
  task.person_name = state.personName;

  const isRepeating = state.isRepeating;
  task.repeat_state = isRepeating ? state.repeatState : 0;
  task.repeat_start = isRepeating ? state.repeatStart : null;
  task.repeat_end_date = isRepeating
    ? moment(state.repeatEnd).format('YYYY-MM-DD')
    : null;

  const timeoffMode = this.isTimeoff(state);
  task.isTimeoff = timeoffMode;

  if (state.integrationSynced && !state.integrationSyncLocked) {
    task.integration_status = 0;
  }

  if (timeoffMode) {
    // Sometimes we want to use it to get raw values w/o future transformation
    if (!isCancelling) {
      if (
        this.isRequestingTimeoff(state) ||
        this.isEditingRequestedTimeoff(state)
      ) {
        task.status = 1;
      } else if (this.isApproveRejectMode(state)) {
        if (this.isRejecting) {
          task.status = -1;
        } else if (this.isApproving) {
          task.status = 2;
        }
      }
    }

    task.timeoff_notes = state.notes;
    task.full_day = state.isFullDay;
    if (task.full_day && (task.status === -1 || task.status === 2)) {
      task.hours = null;
    }
    if (this.isApproveRejectMode(state)) {
      task.status_note = state.status_note;
    }
    if (isTentativeTimeoff(task) || isRejectedTimeoff(task)) {
      task.full_day = false;
    }
    const type = state.timeoffType;

    if (type) {
      task.type = type;
      task.timeoffType = type;
      task.timeoff_type_name = type.timeoff_type_name;
      task.timeoff_type_id = type.timeoff_type_id;
    }

    task.people_ids = state.peopleIds;
  } else {
    task.notes = state.notes;
    // Project
    task.project = state.project;
    if (task.project) {
      task.project_name = state.project.project_name;
      task.project_id = state.project.project_id;
    }

    task.client_id = state.clientId;
    task.client_name = state.clientName;
    task.people_ids = state.peopleIds;
    task.phase_id = state.phaseId;

    // For tentative projects or phases, the task status must be tentative
    // It's important that we do this assignment after the task.project & task.phase_id have been updated
    // See: https://linear.app/float-com/issue/CS-1348/unable-to-update-the-allocation-incorrect-tentative-projects-may-only
    task.status = this.getStatusOnSave(task);
  }

  task.taskIdsToUnlink = state.taskIdsToUnlink;
  task.notes_meta = state.notes_meta;
  task.isFirstTask = state.isFirstTask;

  return task;
}

async function saveAfterDelay() {
  if (this.props.onSave) {
    const stopInteractivityTracker = trackTaskModalInteractivity(this);

    try {
      const originalEntity = this.state.originalTaskUnmodified;
      const updatedEntity = getExportedTaskCopiedFromState.call(this);
      await this.props.onSave(originalEntity, updatedEntity);
      trackTimeoff(getTrackTimeoffData(this, originalEntity, updatedEntity));
    } catch (e) {
      console.error(e);
      this.setState({ updating: false });
    }

    stopInteractivityTracker();

    return;
  }

  throw new Error('props.onSave missing');
}

export function save(e) {
  prevent(e);

  const { readOnly, isEditingTheirSchedule } = this.state;
  const disableUpdate = readOnly && !isEditingTheirSchedule;

  if (disableUpdate || !this.validate() || this.isNotesExcerpt()) {
    return false;
  }

  setTimeout(() => saveAfterDelay.call(this), 0);
  return true;
}

export function deleteItem() {
  if (this.props.onDelete) {
    this.props.onDelete(this.state.originalTaskUnmodified);
    return;
  }

  throw new Error('props.deleteItem missing');
}
