import { css } from 'styled-components';

import {
  Breakpoint,
  breakpoints,
} from '@float/ui/deprecated/Theme/breakpoints';

import { media } from './media';

export const withCurrentBreakpointCSSVariable = Object.keys(breakpoints).map(
  (breakpointName) => {
    const mediaBreakpoint = media[breakpointName as Breakpoint];

    return mediaBreakpoint!(
      css({ '--current-breakpoint': `'${breakpointName}'` }),
    );
  },
);
