import React from 'react';
import { FieldPath, FieldValue } from 'react-hook-form';
import { t } from '@lingui/macro';

import { GroupedOptions } from '.';
import { ProjectFormData } from '../../types';

import * as styles from './styles.css';

export function ReadOnlyOptions<N extends FieldPath<ProjectFormData>>(props: {
  option:
    | GroupedOptions<FieldValue<ProjectFormData>[N]>['options'][0]
    | undefined;
}) {
  if (!props.option) return null;
  const label = props.option.label;
  return (
    <div
      className={styles.readOnlyOption}
      aria-roledescription={t`${label} value`}
    >
      {props.option.icon}
      {props.option.label}
    </div>
  );
}
