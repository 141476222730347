import React, { useCallback, useState } from 'react';
import { Trans } from '@lingui/macro';
import { isUndefined } from 'lodash';

import {
  Container,
  EarhartButton,
  EarhartControl,
  Group,
} from '@float/common/components/Schedule/util/Controls/styles';
import { getUser } from '@float/common/selectors/currentUser';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelector } from '@float/common/store';
import { todayManager } from '@float/libs/dates';
import useWindowSize from '@float/libs/web/hooks/useWindowSize';
import { EH, Icons, Popover, Spacer } from '@float/ui/deprecated';

import TaskEditModes from './TaskEditModes';

const visibleGroupStyle = { opacity: 1 };

const MODES = [
  { value: 0, name: 'Days', width: 245 },
  { value: 1, name: 'Weeks', width: 90 },
  { value: 2, name: 'Months', width: 27 },
];

function ModeTooltip({ currentMode, setMode }) {
  const onClickOption = useCallback(
    (data) => {
      setMode(data);
    },
    [setMode],
  );

  return (
    <EH.List
      appearance="flue"
      options={MODES}
      value={Number(currentMode)}
      onClick={onClickOption}
    />
  );
}

export function SharedLinkControls({ user, dates, actions, visibleRangeRef }) {
  const [open, setOpen] = useState(false);
  const { dayWidth, setDayWidth } = useScheduleContext();

  if (!visibleRangeRef.current) return null;

  const [todayCol] = dates.toDescriptor(todayManager.getToday());
  const showTodayButton = visibleRangeRef.current.colStart + 1 !== todayCol;
  const currentMode = MODES.find((m) => m.width == dayWidth);

  return (
    <Container>
      <Group style={visibleGroupStyle}>
        <EarhartControl>
          <Popover
            className="menu overflow"
            distance={6}
            content={
              <ModeTooltip
                currentMode={
                  isUndefined(user.prefs.sked_view)
                    ? currentMode.value
                    : user.prefs.sked_view
                }
                setMode={(mode) => {
                  setOpen(false);
                  setDayWidth(mode.width);
                }}
              />
            }
            placement="top"
            open={open}
            onOpenChange={setOpen}
          >
            <EarhartButton emphasisHigh={true}>
              {currentMode.name} <Icons.Down />
            </EarhartButton>
          </Popover>
          <Spacer size={4} />
          <EarhartButton
            isActive={!showTodayButton}
            onClick={() => actions.scrollToToday()}
          >
            <Trans>Today</Trans>
          </EarhartButton>
          <Spacer size={4} />
          <EarhartButton
            className="arrow"
            justifyContent="center"
            onClick={() => actions.scrollWeeks(-1)}
          >
            <Icons.Left />
          </EarhartButton>
          <Spacer size={4} />
          <EarhartButton
            className="arrow"
            justifyContent="center"
            onClick={() => actions.scrollWeeks(1)}
          >
            <Icons.Right />
          </EarhartButton>
        </EarhartControl>
      </Group>
    </Container>
  );
}

export default function Controls({
  actions,
  visibleRangeRef,
  isSidebarOpen,
  actionMode,
}) {
  const screen = useWindowSize();
  const user = useAppSelector(getUser);
  const isDesktop = screen.width >= 1010;

  if (!visibleRangeRef.current) return null;

  return (
    <Container isSidebarOpen={isSidebarOpen}>
      {!user.shared_link_view && isDesktop && (
        <Group style={visibleGroupStyle}>
          <TaskEditModes
            user={user}
            actionMode={actionMode}
            actions={actions}
          />
        </Group>
      )}
    </Container>
  );
}
