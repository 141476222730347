import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconExpand = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M8.75 10.5L12 7L15.25 10.5"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 14L12 17.5L8.75 14"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
};

export { IconExpand };
