import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { FETCH_COMPANY_PREFS_SUCCESS } from '@float/common/actions/companyPrefs';
import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_USER_SUCCESS,
} from '@float/common/actions/currentUser';
import { Resources } from '@float/common/api3/constants';
import { IntercomPlatform } from '@float/common/api3/intercom';
import {
  requestMePageData,
  requestPageData,
} from '@float/common/api3/pageData';
import { useRequestSharePageData } from '@float/common/api3/pageData/pageData.hooks';
import { pushValueToGTM } from '@float/common/lib/gtm';
import { useInitIntercomWithIdentifiedUser } from '@float/common/lib/intercom';
import { selectJWTAccessToken } from '@float/common/store/jwt/jwt.selectors';
import { featureFlags } from '@float/libs/featureFlags';
import { DO_NOT_USE_setGlobalPageData } from '@float/libs/unsafeGlobalData';
import { CurrentUser } from '@float/types/account';
import { MePageData } from '@float/types/pageData';
import { identifyUserToLogger } from '@float/web/lib/logger/initializeWebLogger';
import {
  useWebAppSelectorStrict,
  useWebAppStoreStrict,
} from '@float/web/lib/store';
import { UPDATE_PROMPTS_SUCCESS } from '@float/web/store/onboardingManager/actions';

export const useBootstrapGlobalServicesForApp = (): {
  isReady: boolean;
} => {
  const [isFeatureFlagsUserIdentified, setIsFeatureFlagsUserIdentified] =
    useState(false);

  const store = useWebAppStoreStrict();

  const accessToken = useWebAppSelectorStrict(selectJWTAccessToken);

  const { data } = useSWR(Resources.PageData, requestPageData, {
    fallbackData: undefined,
  });

  useInitIntercomWithIdentifiedUser(
    data?.user?.user.admin_id,
    IntercomPlatform.Web,
    accessToken,
  );

  useEffect(() => {
    const identifyUserToFeatureFlags = async (user: CurrentUser) => {
      await featureFlags.identify(user);

      setIsFeatureFlagsUserIdentified(true);
    };

    if (data?.user) {
      identifyUserToLogger(data.user.user);

      identifyUserToFeatureFlags(data.user.user);

      DO_NOT_USE_setGlobalPageData({
        currency: data.user.company_prefs.currency,
      });

      pushValueToGTM({
        people_count: data.user.company_prefs?.people_count,
        company_id: data.user.user?.cid,
      });

      store.dispatch({
        type: FETCH_USER_SUCCESS,
        user: data.user.user,
      });

      store.dispatch({
        type: FETCH_COMPANY_PREFS_SUCCESS,
        companyPrefs: data.user.company_prefs,
      });

      store.dispatch({
        type: UPDATE_PROMPTS_SUCCESS,
        prompts: data.user.prompts,
      });

      store.dispatch({
        type: FETCH_COMPANIES_SUCCESS,
        companies: data.company.companies,
      });
    }
  }, [data, store]);

  return {
    isReady: isFeatureFlagsUserIdentified,
  };
};

export const useBootstrapGlobalServicesForShareApp = (): {
  isReady: boolean;
} => {
  const store = useWebAppStoreStrict();

  const { data, error } = useRequestSharePageData();

  useEffect(() => {
    if (error && 'redirectUrl' in error) {
      window.location.replace(error.redirectUrl);

      return;
    }
  });

  useEffect(() => {
    if (data) {
      DO_NOT_USE_setGlobalPageData({
        currency: data.company_prefs.currency,
      });

      store.dispatch({
        type: FETCH_USER_SUCCESS,
        user: data.user,
      });

      store.dispatch({
        type: FETCH_COMPANY_PREFS_SUCCESS,
        companyPrefs: data.company_prefs,
      });
    }
  }, [data, store]);

  return {
    isReady: Boolean(data),
  };
};

export const useBootstrapGlobalServicesForAuth = (): {
  isReady: boolean;
} => {
  return {
    isReady: true,
  };
};

export const useBootstrapGlobalServicesForMultiAccountSignup = (): {
  isReady: boolean;
  data: MePageData | undefined;
} => {
  const { data, isLoading } = useSWR(Resources.MePageData, requestMePageData, {
    fallbackData: undefined,
  });

  return {
    isReady: !isLoading,
    data,
  };
};
