import React, { MouseEventHandler } from 'react';
import { useWatch } from 'react-hook-form';
import { t } from '@lingui/macro';
import cn from 'classnames';

import useMedia from '@float/common/lib/useMedia';
import { BudgetType } from '@float/types/project';

import { ArchivedTag } from '../../components/ArchivedTag';
import { CurrencyInputField } from '../../components/CurrencyInputField';
import { DurationInputField } from '../../components/DurationInputField';
import { InputField } from '../../components/InputField';
import { SidePanelTableColumn } from '../../components/SidePanelTableSection/SidePanelTableColumn';
import { SidePanelTableRow } from '../../components/SidePanelTableSection/SidePanelTableRow';
import { PhaseDatesField } from '../../fields/PhaseDatesField';
import { createEmptyPhaseRecord } from '../../helpers/createRowEntities';
import { useAddOnEnterKeyDown } from '../../hooks/useAddOnEnterKeyDown';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { usePhaseLiveUpdate } from '../../hooks/usePhaseLiveUpdate';
import { useRemoveOnBlur } from '../../hooks/useRemoveOnBlur';
import { DEFAULT_PHASE_DURATION } from '../../lib/projectValidationHelpers';
import { ProjectFormData } from '../../types';
import { useShowPhasePanel } from './hooks/useShowPhasePanel';
import { PhaseColor } from './PhaseColors';
import { ProjectPhasesMenu } from './ProjectPhasesMenu';

import * as styles from './styles.css';

export function ProjectPhasesRow(props: {
  index: number;
  budgetType?: BudgetType.TotalFee | BudgetType.TotalHours;
  addDisabled: boolean;
}) {
  const { index } = props;
  const values = useWatch<ProjectFormData, `phases.${number}`>({
    name: `phases.${index}`,
  });
  const showPhasePanel = useShowPhasePanel(index);
  const isTemplate = useIsTemplate();
  const isMobile = useMedia() === 'small';
  const inputs = {
    phase_name: `phases.${index}.phase_name`,
    budget: `phases.${index}.budget_total`,
    color: `phases.${index}.color`,
    parent_offset: `phases.${index}.parent_offset`,
    duration: `phases.${index}.duration`,
  } as const;

  const hasBudget = Boolean(props.budgetType);
  const hoursBudget = props.budgetType === BudgetType.TotalHours;
  const feeBudget = props.budgetType === BudgetType.TotalFee;
  const handlePhaseRowClick: MouseEventHandler = (e) => {
    e.preventDefault();
    showPhasePanel();
  };
  const isEmpty = !values.phase_id && !values.phase_name?.trim();
  const { ref: blurRef, onBlurHandler } = useRemoveOnBlur({
    isEmpty,
    index,
  });
  const { onEnterKeyDown } = useAddOnEnterKeyDown({
    canAdd: !props.addDisabled && !isEmpty,
    data: createEmptyPhaseRecord,
  });

  usePhaseLiveUpdate(`phases.${index}`, values?.phase_id);

  const rowStyle = isTemplate
    ? styles.templatePhaseGridTemplateColumns
    : styles.phaseGridTemplateColumns;

  return (
    <SidePanelTableRow
      onBlur={onBlurHandler}
      onKeyDown={onEnterKeyDown}
      ref={blurRef as React.RefObject<HTMLDivElement>}
      className={cn(
        rowStyle({
          gridTemplateColumns: hasBudget ? 'budget' : 'default',
        }),
      )}
      data-testid={`phase_${index}`}
      onClick={handlePhaseRowClick}
      hover
    >
      <SidePanelTableColumn>
        <PhaseColor index={index} />
      </SidePanelTableColumn>
      <SidePanelTableColumn className={styles.phaseNameContainer}>
        <InputField
          padding="margin"
          name={inputs.phase_name}
          label={t`Phase name`}
          readOnly={!values.active}
          hideLabel
          autoFocus={!values.phase_id}
          placeholder={t`Add a phase name`}
          blockOutsideInteraction
        />
        {!values.active && (
          <ArchivedTag data-testid={`phaseArchive_${index}`} size="small" />
        )}
      </SidePanelTableColumn>
      <SidePanelTableColumn active={hasBudget}>
        {hoursBudget && (
          <DurationInputField
            name={inputs.budget}
            label={t`Phase budget`}
            fieldClassName={styles.phaseInputPadding}
            readOnly={!values.active}
            blockOutsideInteraction
            hideLabel
          />
        )}
        {feeBudget && (
          <CurrencyInputField
            name={inputs.budget}
            label={t`Phase budget`}
            fieldClassName={styles.phaseInputPadding}
            readOnly={!values.active}
            blockOutsideInteraction
            hideLabel
          />
        )}
      </SidePanelTableColumn>
      {isTemplate && (
        <>
          <SidePanelTableColumn>
            <DurationInputField
              name={inputs.parent_offset}
              label={t`Start after`}
              fieldClassName={styles.phaseInputPadding}
              readOnly={!values.active}
              unit="days"
              blockOutsideInteraction
              hideLabel
            />
          </SidePanelTableColumn>
          <SidePanelTableColumn>
            <DurationInputField
              name={inputs.duration}
              label={t`Duration`}
              fieldClassName={styles.phaseInputPadding}
              readOnly={!values.active}
              placeholder={`${DEFAULT_PHASE_DURATION}`}
              unit="days"
              blockOutsideInteraction
              hideLabel
            />
          </SidePanelTableColumn>
        </>
      )}
      <SidePanelTableColumn active={!isTemplate && !isMobile}>
        <PhaseDatesField name={`phases.${index}`} />
      </SidePanelTableColumn>
      <SidePanelTableColumn>
        <ProjectPhasesMenu
          index={index}
          handleEditPhaseDetails={() => showPhasePanel()}
        />
      </SidePanelTableColumn>
    </SidePanelTableRow>
  );
}
