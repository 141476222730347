import { useEffect, useMemo, useState } from 'react';

import { fastObjectSpread } from '@float/common/lib/fast-object-spread';
import { CellsMap } from '@float/types/cells';

/**
 * Hook that subscribes to the cells heights updates
 */
export function useCellsHeights(cells: CellsMap) {
  const [updatedAt, setHeightsUpdatedAt] = useState(cells._heightsUpdatedAt);

  const subscribeToHeightsUpdate = cells._subscribeToHeightsUpdate;
  const heights = cells._heights;

  useEffect(() => {
    const unsubscribe = subscribeToHeightsUpdate((heightsUpdatedAt) => {
      // Saving _heightsUpdatedAt into the state
      // because cells._heights always have the same reference
      setHeightsUpdatedAt(heightsUpdatedAt);
    });

    return () => {
      unsubscribe();
    };
  }, [subscribeToHeightsUpdate]);

  // Adding updatedAt to refresh the heights ref when the heights values are updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => fastObjectSpread(heights), [updatedAt, heights]);
}
