import React, { useMemo } from 'react';
import { omit } from 'lodash';
import { getProjectsMapRaw, getUser, userCanSeeBudgets } from 'selectors';

import { Rights } from '@float/common/lib/acl';
import { useAppSelector } from '@float/common/store';
import { NON_MONETARY_BUDGET_TYPES } from '@float/constants/budgets';
import BulkEditModal from 'components/elements/BulkEditModal/BulkEditModal';

import Budget from './Budget';
import Client from './Client';
import Color from './Color';
import EditRights from './EditRights';
import ProjectOwner from './ProjectOwner';
import ProjectType from './ProjectType';
import Tags from './Tags';

const fields = {
  client_id: {
    label: 'Client',
    render: Client,
  },
  color: {
    label: 'Color',
    render: Color,
  },
  tags: {
    label: 'Tags',
    render: Tags,
    defaultValue: [],
  },
  type: {
    label: 'Type',
    render: ProjectType,
    defaultValue: {},
  },
  budget: {
    label: 'Budget',
    render: Budget,
    defaultValue: {},
  },
  projectOwner: {
    label: 'Project Owner',
    render: ProjectOwner,
    defaultValue: {},
  },
  editRights: {
    label: 'Edit rights',
    render: EditRights,
    defaultValue: {},
  },
} as const;

interface BulkEditProjectsModalProps {
  ids: number[];
  onUpdate: (payload: unknown) => void;
}

const useBulkEditFields = (selectedIds: number[]) => {
  const currentUser = useAppSelector(getUser);
  const projects = useAppSelector(getProjectsMapRaw);

  let bulkdEditFields: Partial<typeof fields> = fields;

  const allowEditingBudget =
    userCanSeeBudgets(currentUser) ||
    selectedIds.every((id) => {
      const project = projects[id];
      return (
        project &&
        project.budget_type &&
        NON_MONETARY_BUDGET_TYPES.includes(project.budget_type)
      );
    });

  if (!allowEditingBudget) {
    bulkdEditFields = omit(fields, ['budget']);
  }

  const canUpdateEditRightsForProject = useMemo(
    () =>
      selectedIds.every((projectId) => {
        const project = projects[projectId];
        return Rights.canDeleteProject(currentUser, { project });
      }),
    [projects, selectedIds, currentUser],
  );

  if (!canUpdateEditRightsForProject) {
    bulkdEditFields = omit(fields, ['projectOwner', 'editRights']);
  }

  return bulkdEditFields;
};

function BulkEditProjectsModal(props: BulkEditProjectsModalProps) {
  const projects = useAppSelector(getProjectsMapRaw);
  const bulkdEditFields = useBulkEditFields(props.ids);

  return (
    <BulkEditModal
      {...props}
      type="project"
      entities={projects}
      fields={bulkdEditFields}
    />
  );
}

export default BulkEditProjectsModal;
