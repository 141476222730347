import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconCircleStatus = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.75 12.004a7.25 7.25 0 1 1 14.5 0 7.25 7.25 0 0 1-14.5 0Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 11.85h-2l-1.5 4.5-3-9-1.5 4.5H7"
      />
    </SVGIcon>
  );
};

export { IconCircleStatus };
