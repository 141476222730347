import React, { ComponentProps } from 'react';

import { useRequestedDaysBalance } from '@float/common/lib/timeoff/hooks/useRequestedDaysBalance';
import { useTimeoffBalanceData } from '@float/common/lib/timeoff/hooks/useTimeoffBalanceData';
import { useTimeoffRemainingDaysBalance } from '@float/common/lib/timeoff/hooks/useTimeoffRemainingDaysBalance';
import { Person } from '@float/types/person';
import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffType } from '@float/types/timeoffType';

import { TimeoffBalanceView } from './TimeoffBalance.view';

type Props = ComponentProps<'div'> & {
  endDate: string;
  hoursPd: number;
  ignoreTimeoffId: Timeoff['timeoff_id'];
  isTimeoffRequest: boolean;
  personId: Person['people_id'];
  personStartDate: Person['start_date'];
  repeatEndDate: string | null;
  repeatState: RepeatState;
  startDate: string;
  timeoffType: TimeoffType;
};

export const TimeoffBalance = (props: Props) => {
  const {
    endDate,
    hoursPd,
    ignoreTimeoffId,
    isTimeoffRequest,
    personId,
    personStartDate,
    repeatEndDate,
    repeatState,
    startDate,
    timeoffType,
  } = props;

  // get time off balance data from API
  const { today, next, endOfPeriod } = useTimeoffBalanceData({
    endDate,
    ignoreTimeoffId,
    personId,
    repeatEndDate,
    repeatState,
    timeoffType,
  });

  // calculate requested days
  const requestedDays = useRequestedDaysBalance({
    endDate,
    hoursPd,
    ignoreTimeoffId,
    personId,
    repeatEndDate,
    repeatState,
    startDate,
  });

  // calculate remaining days
  const { remainingDays, previewMode, toggleDateBalance } =
    useTimeoffRemainingDaysBalance({
      endDate,
      endOfPeriod,
      hoursPd,
      ignoreTimeoffId,
      next,
      personId,
      personStartDate,
      repeatEndDate,
      repeatState,
      startDate,
      timeoffType,
      today,
    });

  return (
    <TimeoffBalanceView
      date={repeatEndDate || endDate}
      isTimeoffRequest={isTimeoffRequest}
      onClickDate={toggleDateBalance}
      previewMode={previewMode}
      remainingDays={remainingDays}
      requestedDays={requestedDays}
      type={timeoffType.balance_type}
    />
  );
};
