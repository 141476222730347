import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Button } from '@float/ui/deprecated';
import { IconSpinner32x32 } from '@float/ui/deprecated/Earhart/Icons/Icon/IconSpinner32x32';

import { ViewTypeFilter } from '../hooks/useViewsOptions';

import PersonalViewsEmptyState from '../assets/PersonalViewsEmptyState.svg';
import SharedViewsEmptyState from '../assets/SharedViewsEmptyState.svg';

import * as styles from './ViewsEmptyState.css';

type ViewsEmptyStateProps = {
  viewNameFilter: string;
  viewTypeFilter: ViewTypeFilter;
  onAddFilterClick: () => void;
  loading: boolean;
};

export function ViewsEmptyState(props: ViewsEmptyStateProps) {
  if (props.loading) {
    const type =
      props.viewTypeFilter === 'all'
        ? ''
        : props.viewTypeFilter === 'personal'
          ? t`personal`
          : t`shared`;

    return (
      <div className={styles.container}>
        <IconSpinner32x32 />

        <div className={styles.loading}>
          <Trans>Loading {type} Views</Trans>
        </div>
      </div>
    );
  }

  if (props.viewNameFilter) {
    return (
      <div className={styles.container}>
        <div className={styles.noSearchResults}>
          <Trans>No results for “{props.viewNameFilter}”</Trans>
        </div>
      </div>
    );
  }

  if (props.viewTypeFilter === 'shared') {
    return (
      <div className={styles.container}>
        <img
          width="49"
          height="48"
          src={SharedViewsEmptyState}
          alt={t`No shared Views`}
        />
        <div className={styles.label}>
          <Trans>No shared Views</Trans>
        </div>

        <div className={styles.description}>
          <Trans>
            Managers, Admins and Account Owners can create shared Views
          </Trans>
        </div>
      </div>
    );
  }

  if (props.viewTypeFilter === 'personal') {
    return (
      <div className={styles.container}>
        <img
          width="49"
          height="48"
          src={PersonalViewsEmptyState}
          alt={t`No personal Views`}
        />
        <div className={styles.label}>
          <Trans>No personal Views</Trans>
        </div>

        <div className={styles.description}>
          <Trans>
            Create a personal View by adding a filter, then clicking “Save”
          </Trans>
        </div>

        <Button
          appearance="ghost"
          size="small"
          onClick={props.onAddFilterClick}
        >
          <Trans>Add a filter</Trans>
        </Button>
      </div>
    );
  }

  return null;
}
