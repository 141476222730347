// Adapted from https://github.com/chrisbolin/react-detect-offline
// We want to ping multiple endpoints to detect offline status.
// 1) https://connectivitycheck.gstatic.com/generate_204
// 2) One of our own (in case the above is blocked for some reason)

import { Children, Component, createElement, isValidElement } from 'react';

import { logger } from '@float/libs/logger';

const inBrowser = typeof navigator !== 'undefined';

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern =
  /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const fetchWithTimeout = ({
  url,
  options,
  timeout = 20000,
}: {
  url: string;
  options?: RequestInit;
  timeout?: number;
}) => {
  return new Promise<Response>((resolve, reject) => {
    const timeoutId = setTimeout(() => reject(), timeout);

    fetch(url, options).then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      },
    );
  });
};

const ping = ({ url, timeout }: { url: string; timeout?: number }) => {
  return fetchWithTimeout({
    url,
    timeout,
    options: {
      method: 'HEAD',
      mode: 'no-cors',
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
  });
};

type BaseDetectorProps = {
  children?: React.ReactNode;
  onChange?: Function;
  polling:
    | {
        urls: string[];
        interval: number;
        timeout: number;
      }
    | boolean;
  wrapperType?: string;
  render: ({
    online,
    connectionLostAt,
  }: {
    online: boolean;
    connectionLostAt?: Date;
  }) => React.ReactNode;
};

type BaseDetectorState = {
  online: boolean;
  connectionLostAt?: Date;
};

type PollingConfig = {
  enabled: boolean;
  urls?: Array<string>;
  timeout?: number;
  interval?: number;
  maxOnlineDurationWithoutPing?: number;
};

const defaultPollingConfig = {
  enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
  urls: ['https://ipv4.icanhazip.com/'],
  timeout: 5000,
  interval: 5000,
};

// base class that detects offline/online changes
class Base extends Component<BaseDetectorProps, BaseDetectorState> {
  pollingId: number | null = null;

  constructor(props: BaseDetectorProps) {
    super(props);
    this.state = {
      online:
        inBrowser && typeof navigator.onLine === 'boolean'
          ? navigator.onLine
          : true,
    };
    // bind event handlers
    this.goOnline = this.goOnline.bind(this);
    this.goOffline = this.goOffline.bind(this);
  }

  componentDidMount() {
    window.addEventListener('online', this.goOnline);
    window.addEventListener('offline', () => this.goOffline());

    if (this.getPollingConfig().enabled) {
      this.startPolling();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.goOnline);
    window.removeEventListener('offline', () => this.goOffline());

    if (this.pollingId) {
      this.stopPolling();
    }
  }

  renderChildren() {
    const { children, wrapperType } = this.props;

    // usual case: one child that is a react Element
    if (isValidElement(children)) {
      return children;
    }

    // no children
    if (!children || !wrapperType) {
      return null;
    }

    // string children, multiple children, or something else
    return createElement(wrapperType, {}, ...Children.toArray(children));
  }

  getPollingConfig(): PollingConfig {
    switch (this.props.polling) {
      case true:
        return defaultPollingConfig;
      case false:
        return { enabled: false };
      default:
        return Object.assign({}, defaultPollingConfig, this.props.polling);
    }
  }

  goOnline() {
    if (!this.state.online) {
      this.callOnChangeHandler(true);
      this.setState({ online: true });
    }
  }

  goOffline({ forceRefresh }: { forceRefresh?: boolean } = {}) {
    if (this.state.online) {
      this.callOnChangeHandler(false);
      if (forceRefresh) {
        return this.setState(
          { online: false, connectionLostAt: new Date() },
          this.goOnline,
        );
      }
      this.setState({ online: false, connectionLostAt: new Date() });
    }
  }

  callOnChangeHandler(online: boolean) {
    if (this.props.onChange) {
      this.props.onChange(online);
    }
  }

  startPolling() {
    const {
      interval,
      maxOnlineDurationWithoutPing,
      urls = [],
      timeout,
    } = this.getPollingConfig();
    let lastPingAt = Date.now();

    this.pollingId = window.setInterval(async () => {
      let url;
      let online;

      for (let i = 0; i < urls.length; i++) {
        url = urls[i];

        try {
          online = await ping({ url, timeout });
          const now = Date.now();

          // When the user's network connection remains online,
          // but hasn't completed a ping in a long time, we trigger
          // a connection reset to fetch missing updates.
          if (
            maxOnlineDurationWithoutPing &&
            now - lastPingAt > maxOnlineDurationWithoutPing
          ) {
            this.goOffline({ forceRefresh: true });
          }

          if (online) {
            lastPingAt = now;
            break;
          }
        } catch (ex) {
          if (ex instanceof Error) {
            logger.log(`Error pinging ${url}: ${ex.message}`);
          }
        }
      }

      if (online) {
        this.goOnline();
      } else {
        this.goOffline();
      }
    }, interval);
  }

  stopPolling() {
    this.pollingId && clearInterval(this.pollingId);
  }
}

export class Online extends Base {
  render() {
    return this.state.online ? this.renderChildren() : null;
  }
}

export class Offline extends Base {
  render() {
    return !this.state.online ? this.renderChildren() : null;
  }
}

export class Detector extends Base {
  render() {
    const { online, connectionLostAt } = this.state;
    return this.props.render({ online, connectionLostAt });
  }
}
