import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  CellStylesConfig,
  useCellStyles,
} from '@float/common/lib/cellStylesManager/hooks/useCellStyles';
import { getCellStylesConfig } from '@float/common/lib/cellStylesManager/utils/getCellStylesConfig';
import { useTaskMetasPrefetch } from '@float/common/lib/hooks/useTaskMetasPrefetch';
import { formatClockTimeAsHours } from '@float/common/lib/time';
import { displayHoursFormatIsTime } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { SerenaState } from '@float/common/selectors/serena';
import { useAppSelectorStrict } from '@float/common/store';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { noop } from '@float/libs/utils/noop';
import { CellItem, CurrentUser } from '@float/types';
import { InputTime } from '@float/ui/deprecated/InputTime';

import { BaseEntityLayer } from '../../BaseEntityLayer';
import { getDimensions } from '../../helpers';
import { getBoxInnerRegular } from '../BoxElements/getBoxInnerRegular';

import { entityItemBox } from '../../EntityItemStyles.css';
import * as styles from './styles.css';

export type BoxLoggedTimeItemProps = {
  actions: {
    trackHoveredItem: (item: CellItem) => void;
    setDragItem: (params: unknown) => void;
    logTime: (item: CellItem, hours: number) => void;
  };
  dayWidth: number;
  hourHeight: number;
  item: CellItem<'loggedTime'>;
  logMyTimeView?: boolean;
  printMode?: boolean;
  reduxData: SerenaState;
  suvSingleDay?: string | null;
  isEditable: boolean;
  user: CurrentUser;
  onItemDragChange?: (dragging: boolean) => void;
  onItemDragError?: (error: string) => void;
  onItemClick?: () => void;
};

declare global {
  interface Window {
    TASK_DRAG_SHADOW?: string;
  }
}

export const BoxLoggedTimeItem = (props: BoxLoggedTimeItemProps) => {
  const {
    actions,
    dayWidth,
    hourHeight,
    item,
    logMyTimeView,
    printMode,
    reduxData,
    suvSingleDay,
    isEditable,
    user,
    onItemDragChange = noop,
    onItemDragError = noop,
    onItemClick = noop,
  } = props;

  const { isEnd, isStart } = item;

  const submitRef = useRef(false);

  const companyPrefs = useAppSelectorStrict(getCompanyPrefs);

  const [hoursInput, setHoursInput] = useState(item.entity.hours);

  const dimensions = getDimensions(item, dayWidth, hourHeight, suvSingleDay);

  const { color, config } = getCellStylesConfig(
    item,
    reduxData,
    actions,
    printMode,
  );
  const cellStyles = useCellStyles(item, color, config as CellStylesConfig);

  const boxInner = getBoxInnerRegular(
    item,
    reduxData,
    companyPrefs,
    cellStyles,
    props,
  );

  const { prefetchTaskMetas } = useTaskMetasPrefetch();

  const onMouseEnter = () => {
    actions.trackHoveredItem(item);

    if (item.type === 'loggedTime') {
      prefetchTaskMetas(item.entity.project_id);
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const setBoxRef = (node: HTMLDivElement | null) => {
    ref.current = node;
    item._boxRef = node;
  };

  const onMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const target = event.target as HTMLElement;

    if (event.button !== 0) return;

    if (target.tagName === 'BUTTON' || target.tagName === 'INPUT') {
      return;
    }

    const element = ref.current;

    if (!element) return;
    if (!element.contains(event.target as HTMLElement)) {
      // The user clicked on a tooltip
      return;
    }

    const rect = element.getBoundingClientRect();

    const { height } = rect;

    // Edge uses left/top for unknown reasons
    const offsetX = rect.x || rect.left;
    const offsetY = rect.y || rect.top;

    const dragVersionWidth = dayWidth;
    const dragVersion = (
      <BaseEntityLayer
        rounded
        isStart
        isEnd
        style={{
          width: dragVersionWidth,
          height: dimensions.height,
          boxShadow: window.TASK_DRAG_SHADOW || '',
        }}
      >
        <div
          className={entityItemBox}
          data-rounded
          data-is-start
          data-is-end
          style={cellStyles ?? {}}
        >
          {boxInner}
        </div>
      </BaseEntityLayer>
    );

    actions.setDragItem({
      item,
      element: dragVersion,
      offsetX,
      offsetY,
      clientX: event.clientX,
      clientY: event.clientY,
      width: dragVersionWidth,
      height,
      shiftKey: false,
      onItemDragChange,
      onItemDragError,
      onItemClick,
    });
  };

  const onClick = (event: MouseEvent<HTMLElement>) => {
    const { tagName } = event.target as HTMLElement;

    if (tagName === 'BUTTON' || tagName === 'INPUT') {
      return;
    }

    prevent(event);
  };

  const shouldDisplayAsTime = displayHoursFormatIsTime(user.prefs);

  const [loggedTime, setLoggedTime] = useState(() => {
    return formatDecimalHoursAsClockTime(hoursInput || 0);
  });

  const onChangeLoggedTimeDuration = (time: string, isCompleted: boolean) => {
    setLoggedTime(time);

    if (isCompleted) {
      const hrs = formatClockTimeAsHours(time);

      setHoursInput(hrs);
    }
  };

  const handleSubmit = useCallback(() => {
    actions.logTime(item, hoursInput);
  }, [item, actions, hoursInput]);

  const withTimeFormat = Boolean(loggedTime);

  const inputElement = shouldDisplayAsTime ? (
    <InputTime
      className={styles.inputTime}
      appearance="minimal-input"
      disabled={!isEditable}
      fitToContent={true}
      onChange={onChangeLoggedTimeDuration}
      min="00:01"
      size="small"
      timeFormat="24"
      timeType="duration"
      value={loggedTime}
    />
  ) : (
    <input
      className={styles.input}
      type="number"
      min={0.01}
      step={0.01}
      disabled={!isEditable}
      value={hoursInput}
      data-with-time-format={withTimeFormat}
      onFocus={(event) => {
        const { currentTarget } = event;
        // We use a setTimeout to try to still select the full field if the user
        // clicked and dragged a little bit.
        setTimeout(() => currentTarget.select(), 25);
      }}
      onChange={(event) => {
        setHoursInput(Number(event.currentTarget.value));
      }}
    />
  );

  useEffect(() => {
    setHoursInput(item.entity.hours);

    if (shouldDisplayAsTime) {
      setLoggedTime(formatDecimalHoursAsClockTime(item.entity.hours || 0));
    }
  }, [item.entity.hours, shouldDisplayAsTime]);

  return (
    <div
      className={entityItemBox}
      ref={setBoxRef}
      data-is-end={isEnd}
      data-is-start={isStart}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      data-rounded
      style={cellStyles}
    >
      {boxInner}
      {item.isTaskReference && logMyTimeView && (
        <form
          className={styles.baseLogWrapper({
            height: item.entity.hours === 0.75 ? 'full' : 'default',
            bottom: item.entity.hours <= 0.5 ? 'small' : 'medium',
          })}
          style={{
            backgroundColor: cellStyles.backgroundColor,
          }}
          onSubmit={(event) => {
            event.preventDefault();

            // Prevent double submit
            if (submitRef.current) return;
            submitRef.current = true;

            handleSubmit();
          }}
        >
          {inputElement}
          {isEditable && (
            <button className={styles.button} type="submit">
              Log
            </button>
          )}
        </form>
      )}
    </div>
  );
};
