import { isArray } from 'lodash';

import API3 from '@float/common/api3';
import { PROMPT_ID } from '@float/types/onboarding';
import { WebAppDispatch } from '@float/web/lib/store';

export const UPDATE_PROMPTS_SUCCESS = 'UPDATE_PROMPTS_SUCCESS';
export const SET_PROMPT = 'SET_PROMPT';
export const SET_PROMPT_DATA = 'SET_PROMPT_DATA';
export const REPLAY_PRODUCT_TOUR = 'REPLAY_PRODUCT_TOUR';

export type OnboardingActions = {
  type: typeof UPDATE_PROMPTS_SUCCESS;
  prompts: PROMPT_ID[];
};

export const updatePrompts = (
  prompts: PROMPT_ID[] | PROMPT_ID,
  saveOnServer = true,
) => {
  return async (dispatch: WebAppDispatch) => {
    prompts = isArray(prompts) ? prompts : [prompts];
    dispatch({ type: UPDATE_PROMPTS_SUCCESS, prompts });

    if (saveOnServer) await API3.saveUserPrompts(prompts);
  };
};

export const setPrompt = (prompt: PROMPT_ID) => {
  return {
    type: SET_PROMPT,
    prompt,
  };
};

export const setPromptData = (prompt: PROMPT_ID, data: unknown) => {
  return {
    type: SET_PROMPT_DATA,
    prompt,
    data,
  };
};

export const replayProductTour = () => {
  return {
    type: REPLAY_PRODUCT_TOUR,
  };
};
