import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';

import { IEditTaskModal } from '../../../EditTaskModal.types';
import { TimeoffApproveRejectHeaderProps } from '../TimeoffApproveRejectHeader';

/**
 * This helper function generates the properties for the TimeoffApproveRejectHeader
 * component. Since the EditTaskModal has not yet been migrated to TypeScript,
 * the properties declaration is kept separate to ensure they are type-checked.
 *
 * @param {IEditTaskModal} self - The context object that contains the interface
 * of the EditTaskModal.
 * @returns {TimeoffApproveRejectHeaderProps} The properties for the
 * TimeoffApproveRejectHeader component.
 */

export const getTimeoffApproveRejectHeaderProps = (
  self: IEditTaskModal,
): TimeoffApproveRejectHeaderProps => {
  const { props, state } = self;

  const currentTimeoffType = props.timeoffTypesMap[state.timeoffTypeId];

  const timeoff = state?.task as Timeoff;
  const ignoreTimeoffId = timeoff.timeoff_id;
  const originalRepeatingInstance =
    state.repeatState > RepeatState.NoRepeat
      ? state?.task.allInstances?.[0]
      : null;

  const timeoffApproveRejectHeaderProps: TimeoffApproveRejectHeaderProps = {
    endDate: originalRepeatingInstance
      ? originalRepeatingInstance.end_date
      : state.endDate,
    hoursPd: state.hoursPd,
    ignoreTimeoffId,
    personId: state.peopleIds[0],
    personStartDate: props.peopleMap[state.peopleIds[0]]?.start_date,
    repeatEndDate:
      state.repeatState > RepeatState.NoRepeat ? state.repeatEnd : null,
    repeatState: state.repeatState,
    startDate: originalRepeatingInstance
      ? originalRepeatingInstance.start_date
      : state.startDate,
    timeoff,
    timeoffType: currentTimeoffType,
    isReadOnly: state.readOnly,
  };

  return timeoffApproveRejectHeaderProps;
};
