import styled, { css } from 'styled-components';

import { Label12 } from '../../Typography';

const styles = css`
  ${Label12.M500};

  display: inline-flex;
  align-items: center;

  border-radius: 50px;

  padding: 4px 8px;

  color: ${({ colorPrimary }) => colorPrimary};

  background-color: ${({ colorSecondary }) => colorSecondary};

  span {
    display: flex;

    &:empty {
      display: none;
    }
  }

  svg {
    width: 18px;
    height: 18px;

    margin: 0 -3px;
  }

  span:not(:empty) + span:not(:empty) {
    margin-left: 4px;
  }

  ${({ trailingIcon }) =>
    // Trailing icon
    trailingIcon &&
    css`
      flex-direction: row-reverse;

      span:not(:empty) + span:not(:empty) {
        margin-right: 4px;
        margin-left: 0px;
      }
    `};
`;

export const StyledSpan = styled.span`
  ${styles}
`;
