import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconFolder = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.25 17.25v-7.5a2 2 0 0 0-2-2H4.75v9.5a2 2 0 0 0 2 2h10.5a2 2 0 0 0 2-2Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m13.5 7.5-.931-1.708a2 2 0 0 0-1.756-1.042H6.75a2 2 0 0 0-2 2V11"
      />
    </SVGIcon>
  );
};

export { IconFolder };
