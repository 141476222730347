import { createEditor as createBaseEditor } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';

import LinkifyPlugin from '../plugins/linkify-plugin';
import MaxLengthPlugin from '../plugins/max-length-plugin';
import MentionsPlugin from '../plugins/mentions-plugin';

export function createEditor(params: { maxLength: number }) {
  let editor = createBaseEditor();

  editor = MaxLengthPlugin(editor, { maxLength: params.maxLength });
  editor = MentionsPlugin(editor);
  editor = LinkifyPlugin(editor);
  editor = withReact(editor);
  editor = withHistory(editor);

  return editor as ReactEditor;
}
