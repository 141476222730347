import React, { forwardRef, useCallback, useMemo } from 'react';
import cs from 'classnames';
import { LayoutProps, motion } from 'framer-motion';

import { TimeString } from '@float/types/datesManager';

import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';

import * as styles from '../../EditTaskModal.css';

export type StatusTimeSectionProps = {
  layout: LayoutProps['layout'];
  startDate: Date;
  startTime: TimeString | null;
  endDate: Date;
  peopleIds: number[];
  isReadOnly: boolean;
  onChange: (data: AllocationTimeSectionPayload) => void;
};

export const StatusTimeSection = forwardRef<
  HTMLDivElement,
  StatusTimeSectionProps
>((props, ref) => {
  const { layout, startDate, endDate, peopleIds, isReadOnly, onChange } = props;

  const { getIsWorkDay, getNumberOfAllocationDays } = useAllocationDaysHelpers({
    peopleIds,
  });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate);
  }, [startDate, endDate, getNumberOfAllocationDays]);

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    onChange({
      startDate,
      endDate,
    });
  };

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      <div className={cs(styles.timeSectionColumn)}>
        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          numberOfAllocationDaysInDateRange={numberOfAllocationDays}
          isReadOnly={isReadOnly}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
        />
      </div>
    </motion.div>
  );
});
