import {
  isEligibleLinkSource,
  isEligibleLinkTarget,
} from '@float/common/components/Schedule/actions/_handleLinkInteraction';
import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import { DEFAULT_COLOR } from '@float/constants/colors';

export function getCellStylesConfig(item, reduxData, actions, isPrintMode) {
  const phase = reduxData.phases[item.entity.phase_id];
  const project = reduxData.projects[item.entity.project_id];

  /**
   * The color is optional in our public Project API.
   * It may happen it is undefined
   */
  const color = (phase?.color || project?.color || DEFAULT_COLOR).toLowerCase();

  const config = {
    actionMode: actions.actionMode,
    cursor: actions.getOverrideCursor(item),
    isLinking: actions.linkInfo?.base,
    isLinkOrigin: actions.linkInfo?.base.entityId === item.entityId,
    isPrintMode,
    isSelected: actions.isItemSelected(item),
    isSelectedAsLink: actions.linkInfo?.base?.entityId === item.entityId,
    timeOffType: reduxData.timeoffTypes[item.entity.timeoff_type_id],
  };

  if (!config.isLinking && config.actionMode === TASK_EDIT_MODES.LINK) {
    config.isEligibleLinkSource = isEligibleLinkSource(reduxData, item);
  }

  if (config.isLinking && !config.isLinkOrigin) {
    config.isEligibleLinkTarget = isEligibleLinkTarget(
      actions.linkInfo,
      item,
    )[0];
  }

  return { color, config };
}
