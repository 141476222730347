import { useCallback, useMemo, useState } from 'react';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelector } from '@float/common/store';

const MAX_RECENT_MENTIONS = 6;

const getLocalKey = (user, trigger) => {
  const { admin_id, cid } = user || {};
  return `${cid || ''}::${admin_id || ''}::${trigger}::mentions`;
};

function useRecentMentions(data, trigger) {
  const user = useAppSelector(getUser);

  const getLocal = useCallback(() => {
    const key = getLocalKey(user, trigger);
    const item = localStorage.getItem(key);
    const data = item ? JSON.parse(item) : [];

    return data;
  }, [user, trigger]);

  const saveLocal = useCallback(
    (data) => {
      const key = getLocalKey(user, trigger);
      const json = JSON.stringify(data);

      localStorage.setItem(key, json);
    },
    [user, trigger],
  );

  const [recentMentions, setRecentMentions] = useState(getLocal());

  const dataById = useMemo(
    () =>
      data.reduce((all, item) => {
        all[item.account_id] = item;
        return all;
      }, {}),
    [data],
  );

  const recentMentionsData = useMemo(
    () =>
      recentMentions.reduce((all, item) => {
        if (dataById[item.a]) {
          all.push(dataById[item.a]);
        }

        return all;
      }, []),
    [dataById, recentMentions],
  );

  const addRecentMention = useCallback(
    (data) => {
      // since we are exposing the account_id on the local_storage
      // we can as well just disguise it a bit
      const mention = { a: data.account_id };

      let mentions = [
        // add current mention to the first place
        mention,
        // only keep mentions different from current one and still exist in original data
        ...recentMentions.filter(
          (item, index) => item.a !== mention.a && dataById[item.a],
        ),
      ];

      // only keep the first MAX_RECENT_MENTIONS in the array
      if (mentions.length > MAX_RECENT_MENTIONS) {
        mentions = mentions.slice(0, MAX_RECENT_MENTIONS);
      }

      // update state
      setRecentMentions(mentions);
    },
    [recentMentions, setRecentMentions, dataById],
  );

  useUpdateEffect(() => {
    saveLocal(recentMentions);
  }, [recentMentions]);

  // useRecentMentions isn't fully ready to work with different mentions
  // as it is currently assuming that account_id exists in the data object
  //
  // @todo: we should make useRecentMentions mention type agnostic,
  // one way to go about it, is to look at the trigger field and pick
  // the right data based on it
  // see for example: app/ui/Earhart/RichText/commands/insert-mention.js
  //
  // we have a storybook story that exemplifies how RichMentions
  // can be used with different mention types (e.g., tags)
  // the following line is just to make sure that story still works
  if (trigger !== '@') return [];

  return [recentMentionsData, addRecentMention];
}

export default useRecentMentions;
