import React from 'react';

import EH from '@float/ui/deprecated/Earhart';

import { StyledTagStatic, StyledTagStaticColumn } from './styles';

const AddonTag = ({ isColumn }) => {
  const StyledContainer = isColumn ? StyledTagStaticColumn : StyledTagStatic;

  return (
    <StyledContainer>
      <EH.Tags.TagStatic
        colorPrimary={EH.Colors.Primary.Flue.Light[9]}
        colorSecondary={EH.Colors.Primary.Flue.Light[4]}
      >
        Pro
      </EH.Tags.TagStatic>
    </StyledContainer>
  );
};

export default AddonTag;
