import {
  AccountType,
  AnyEntity,
  CellHelperData,
  CurrentUser,
  Person,
  Phase,
  Project,
  SavedView,
} from '@float/types';

import { SerenaState } from '../selectors/serena';
import {
  Access,
  assigneesInManagementGroup,
  BitsHandler,
  isOwnPersonalView,
  Rights,
} from './acl';
import { getUserAccess as getAccountAccess } from './acl/lib/helpers';

export { Rights };

export const getUserAccess = (user: CurrentUser) => {
  return {
    // Roles
    isAccountOwner: () => user.account_type_id === AccountType.AccountOwner,
    isAdmin: () => user.account_type_id === AccountType.Admin,
    isBilling: () => user.account_type_id === AccountType.Billing,
    isMember: () => user.account_type_id === AccountType.Member,
    isGuest: () => !user.people_id,
    isAdminOrHigher: () =>
      [AccountType.AccountOwner, AccountType.Admin, AccountType.Billing].some(
        (x) => x == user.account_type_id,
      ),
  };
};

export const projectEditable = (project: Project, user: CurrentUser) => {
  if (!project) {
    return false;
  }

  return Rights.canUpdateProject(user, { project });
};

export const isEditableProjectForMember = (
  project: Project,
  person: Person,
  user: CurrentUser,
) => {
  if (person) {
    return (
      user.people_id === person.people_id &&
      person.active_project_ids &&
      person.active_project_ids.includes(project.project_id)
    );
  }
  return false;
};

export const milestoneEditable = (project: Project, user: CurrentUser) => {
  return Rights.canUpdateMilestones(user, { project });
};

export const personTaskable = (
  person: Person,
  user: CurrentUser,
  logTimeView = false,
) => {
  if (logTimeView) {
    return Rights.canCreateLoggedTime(user, {
      person,
      ignore: [
        'isNotLocked',
        'isLoggedTimeProjectOwner',
        'onLoggedTimeProjectTeam',
        'loggedTimeProjectIsCommon',
      ],
    });
  }

  return (
    Rights.canCreateTaskForPeople(user, [person]) ||
    Rights.canCreateTimeoffForPeople(user, [person]) ||
    Access.canRequestTimeoff(user, { person })
  );
};

export const personTaskableWithProject = (
  person: Person,
  user: CurrentUser,
  project: Project,
  logTimeView: boolean,
) => {
  if (logTimeView) {
    return Rights.canCreateLoggedTime(user, {
      person,
      project,
      ignore: ['isNotLocked'],
    });
  }

  return (
    Rights.canCreateTask(user, { person, project }) ||
    Rights.canCreateTimeoffForPeople(user, [person]) ||
    Access.canRequestTimeoff(user, { person })
  );
};

export const userCanCreateNewEntities = (
  user: CurrentUser,
  logTimeView = false,
) => {
  if (logTimeView) {
    return Rights.canCreateLoggedTime(user);
  }

  return (
    Rights.canCreateTask(user) ||
    Rights.canCreateTimeoff(user) ||
    Access.canRequestTimeoff(user)
  );
};

export const userCanOnlyViewThemself = (user: {
  account_type_id: AccountType;
}) => {
  const isMember = user.account_type_id == AccountType.Member;
  if (!isMember) {
    return false;
  }

  const access = getAccountAccess(user);
  const bits = BitsHandler.from(32, access);
  return !bits.get(0);
};

export const userCanViewOthers = (user: { account_type_id: number }) =>
  !userCanOnlyViewThemself(user);

export const userCanUpdateThemself = (user: CurrentUser) => {
  return Rights.canCreateTask(user);
};

export const personCanOnlyRequestTimeoff = (
  person: Person,
  user: CurrentUser,
  logTimeView = false,
) => {
  const {
    account_type_id: accountTypeId,
    timeoff_approvals: timeoffApprovals,
    people_id: peopleId,
  } = user;

  const noCreateRights = !personTaskable(
    person,
    {
      ...user,
      timeoff_approvals: false,
    },
    logTimeView,
  );
  const isOwnUser = person.people_id === peopleId;
  return (
    noCreateRights &&
    accountTypeId === AccountType.Member &&
    timeoffApprovals &&
    isOwnUser
  );
};

export const memberCanEdit = (
  entity: AnyEntity,
  user: CurrentUser,
  serenaState: SerenaState,
  shouldCheckIfCompleteable: boolean,
) => {
  const timeoffApprovalsEnabled = user.timeoff_approvals;

  const peopleIds = 'people_ids' in entity && entity.people_ids;
  const peopleId = 'people_id' in entity && entity.people_id;
  const isTaskReference = 'isTaskReference' in entity && entity.isTaskReference;
  const timeoffId = 'timeoff_id' in entity && entity.timeoff_id;
  const statusRequest = 'status_request' in entity && entity.status_request;

  // TODO Ensure entity.status_request is relevant here
  const memberEditingOwnRequestedTimeoff =
    timeoffApprovalsEnabled && timeoffId && statusRequest;

  if (shouldCheckIfCompleteable) {
    if (!peopleIds) {
      return false;
    }

    if (peopleIds.length > 1) {
      return false;
    }

    return peopleIds[0] === user.people_id;
  }

  const canUpdateThemself = userCanUpdateThemself(user);

  if (!canUpdateThemself && !memberEditingOwnRequestedTimeoff) {
    return false;
  }

  if (peopleIds && peopleIds.length > 1) {
    return false;
  }

  if (isTaskReference && peopleId != user.people_id) {
    return false;
  }

  if (peopleIds) {
    return peopleIds[0] === user.people_id;
  }

  return peopleId === user.people_id;
};

const checkIfParentIsActive = (entity: AnyEntity, serenaState: SerenaState) => {
  const projectId = 'project_id' in entity && entity.project_id;
  const phaseId = 'phase_id' in entity && entity.phase_id;

  if (projectId) {
    const project = serenaState.projects[projectId];
    if (!project?.active) {
      return false;
    }
  }

  if (phaseId) {
    const phase = serenaState.phases[phaseId];
    if (!phase?.active) {
      return false;
    }
  }

  return true;
};
export const entityEditable = (
  entity: AnyEntity,
  user: CurrentUser,
  serenaState: SerenaState,

  checkCompleteable = false,
) => {
  const projectId = 'project_id' in entity && entity.project_id;
  const phaseId = 'phase_id' in entity && entity.phase_id;

  const parentIsActive = checkIfParentIsActive(entity, serenaState);
  if (!parentIsActive) {
    return false;
  }

  if (user.account_type_id === AccountType.AccountOwner) return true;
  if (user.account_type_id === AccountType.Admin) return true;
  if (user.account_type_id === AccountType.Billing) return true;

  if (user.account_type_id === AccountType.Member) {
    return memberCanEdit(entity, user, serenaState, checkCompleteable);
  }

  if (user.account_type_id === AccountType.Manager) {
    const { projects, phases } = serenaState;
    const project = projectId ? projects[projectId] : undefined;
    const phase = phaseId ? phases[phaseId] : undefined;
    return Rights.canUpdateEntity(user, { entity, project, phase });
  }

  throw new Error('Unknown account type');
};

export const canEditEntity = (
  entity: AnyEntity,
  user: CurrentUser,
  serenaStateOrCellHelperData: Pick<
    SerenaState | CellHelperData,
    'people' | 'projects' | 'timeoffTypes' | 'phases'
  >,
) => {
  const {
    projects,
    phases,
    people = {},
    timeoffTypes,
  } = serenaStateOrCellHelperData;
  const allPeople =
    'allPeople' in serenaStateOrCellHelperData &&
    (serenaStateOrCellHelperData.allPeople as Record<string, Person>);

  const peopleIds = 'people_ids' in entity && entity.people_ids;
  const peopleId = 'people_id' in entity && entity.people_id;
  const phaseId = 'phase_id' in entity && entity.phase_id;
  const timeoffTypeId = 'timeoff_type_id' in entity && entity.timeoff_type_id;
  const projectId = 'project_id' in entity && entity.project_id;

  const phase = phaseId
    ? (phases as Record<number, Phase>)[phaseId]
    : undefined;
  const project = projectId ? projects[projectId] : undefined;
  const timeoffType =
    timeoffTypes && timeoffTypeId ? timeoffTypes[timeoffTypeId] : undefined;

  const peopleMap = allPeople || people;
  const assignees = peopleIds
    ? peopleIds.map((id) => peopleMap[id])
    : undefined;
  const person = peopleId ? peopleMap[peopleId] : undefined;

  return Rights.canUpdateEntity(user, {
    entity,
    project,
    timeoffType,
    phase,
    people: assignees,
    person,
  });
};

export const entityLoggable = (
  entity: AnyEntity,
  serenaStateOrCellHelperData: Pick<
    SerenaState | CellHelperData,
    'phases' | 'user' | 'projects'
  >,
  isOnLockedDay = false,
) => {
  const { projects, phases, user } = serenaStateOrCellHelperData;

  const peopleIds = 'people_ids' in entity && entity.people_ids;
  const peopleId = 'people_id' in entity && entity.people_id;
  const phaseId = 'phase_id' in entity && entity.phase_id;
  const projectId = 'project_id' in entity && entity.project_id;

  if (isOnLockedDay && user.account_type_id === AccountType.Member) {
    return false;
  }

  if (projectId) {
    if (!projects[projectId] || !projects[projectId].active) {
      return false;
    }
  }

  const phase = phaseId
    ? (phases as Record<number, Phase>)[phaseId]
    : undefined;

  if (phaseId) {
    if (!phase || !phase.active) {
      return false;
    }
  }

  if (user.account_type_id === AccountType.AccountOwner) return true;
  if (user.account_type_id === AccountType.Admin) return true;
  if (user.account_type_id === AccountType.Billing) return true;

  if (user.account_type_id === AccountType.Member) {
    if (peopleId == user.people_id) return true;
    if (peopleIds && peopleIds.includes(user.people_id)) return true;

    return false;
  }

  if (user.account_type_id === AccountType.Manager) {
    const project = projectId ? projects[projectId] : undefined;
    return Rights.canUpdateEntity(user, { entity, project, phase });
  }

  return false;
};

// This is nearly the same as entityEditable, but even users without edit rights
// are able to complete their own tasks.
export const entityCompleteable = (
  entity: AnyEntity,
  user: CurrentUser,
  serenaState: SerenaState,
) => {
  return entityEditable(entity, user, serenaState, true);
};

export const canSeeTimeoffBalance = (
  person: { people_id: number | null; department_id?: number },
  user: CurrentUser,
) => {
  if (!person) return false;

  if (user.account_type_id === AccountType.Member) {
    return Boolean(user.people_id && person.people_id === user.people_id);
  }

  if (user.account_type_id === AccountType.Manager) {
    return (
      Boolean(user.people_id && person.people_id === user.people_id) ||
      assigneesInManagementGroup(user, { person })
    );
  }

  return true;
};

export const personEditable = (person: Person, currentUser: CurrentUser) => {
  return Rights.canUpdatePeople(currentUser, { person });
};

export const isViewPageAccessibleByMembers = (
  view: Pick<SavedView, 'settings'>,
) => {
  return (
    view.settings.page !== 'manage-people' &&
    view.settings.page !== 'manage-projects'
  );
};

export const userCanSeeView = (
  currentUser: CurrentUser,
  view: Pick<SavedView, 'created_by' | 'personal' | 'settings'>,
) => {
  if (view.personal === false) {
    if (currentUser.account_type_id === AccountType.Member) {
      return isViewPageAccessibleByMembers(view);
    }

    return true;
  }

  return isOwnPersonalView(currentUser, { entity: view });
};

export function userCanChangeProjectEditRights(
  user: CurrentUser,
  project?: Pick<Project, 'project_manager'>,
) {
  if (!project) return true;

  const isPowerUser = [
    AccountType.AccountOwner,
    AccountType.Admin,
    AccountType.Billing,
    // This is here for backward compat with the legacy project form
    AccountType.DepartmentManager,
  ].includes(user.account_type_id);

  return isPowerUser || project.project_manager === user.account_id;
}
