import { createSelector } from 'reselect';

import { config } from '@float/libs/config';
import { PROMPT_ID } from '@float/types/onboarding';
import { WebAppStateStrict } from '@float/web/reducers/types';

import { getPromptData } from './common';

export const getPromptDataFrom = (prompt: PROMPT_ID) =>
  createSelector([getPromptData], (promptData) => promptData[prompt]);

export const getPromptsFromUrl = () => {
  const urlParams = new URLSearchParams(window.location?.search);

  if (urlParams.has('prompts')) {
    return urlParams
      .get('prompts')!
      .split(',')
      .filter((prompt) => prompt !== '')
      .map((prompt) => Number(prompt)) as Array<PROMPT_ID>;
  }

  return null;
};

/**
 * On non-prod environments we can override the user/company prompts
 * via a query string on the URL:
 *
 * /?prompts       - user hasn't seen any prompt
 * /?prompts=29    - user has seen the 'Get to know you' prompt
 * /?prompts=29,23 - user has seen the 'Get to know you' and 'Add task' prompt
 *
 * @returns the prompts from the URL or the user prompts in case there's no override
 */

export const getCompletedPrompts = createSelector(
  [
    () => !config.isStagingOrProduction && getPromptsFromUrl(),
    (state: WebAppStateStrict) => state.companyPrefs.prompts,
    (state: WebAppStateStrict) => state.legacyOnboarding?.prompts,
  ],
  (prompts, companyPrompts = [], userPrompts = []): Array<PROMPT_ID> => {
    if (prompts) {
      return prompts;
    }

    // merge company prompts with user prompts
    // https://linear.app/float-com/issue/CS-36
    let completedPrompts = userPrompts.concat(companyPrompts);

    // remove duplicated prompts
    completedPrompts = completedPrompts.filter((item, index) => {
      return completedPrompts.indexOf(item) === index;
    });

    return completedPrompts;
  },
);
