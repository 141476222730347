import { useMemo } from 'react';

import useWindowSize from './useWindowSize';

const DEFAULT_PADDING = [4, 4];

export type AnchorItem = {
  id: string;
  config?: {
    arrowPosition: string;
    position: 'top' | 'bottom' | 'left' | 'right';
    alignment: 'start' | 'center' | 'end';
    offset?: {
      y: number;
      x: number;
    };
    padding?: [number, number];
  };
};

export type AnchorData = { anchors: AnchorItem[] };

export type AnchorValue = ReturnType<typeof useAnchor>;

export const useAnchor = (data: AnchorData, selector = 'product-tour') => {
  const screen = useWindowSize(true, 600);

  const anchor = useMemo(() => {
    let anchorElement;

    if (data) {
      const anchorData = data.anchors.find(({ id }) =>
        document.querySelector(`[data-${selector}-id*='${id}']`),
      );

      if (anchorData) {
        const { id, config } = anchorData;

        anchorElement = document.querySelector(
          `[data-${selector}-id*='${id}']`,
        );

        if (anchorElement) {
          const { padding = DEFAULT_PADDING } = config || {};

          const anchorElementRect = anchorElement.getBoundingClientRect();

          const offsetX = config?.offset?.x || 0;
          const offsetY = config?.offset?.y || 0;

          const rect = {
            top: anchorElementRect.top - padding[1] + offsetY,
            left: anchorElementRect.left - padding[0] + offsetX,
            width: anchorElementRect.width + padding[0] * 2,
            height: anchorElementRect.height + padding[1] * 2,
          };

          return { id, rect, config, element: anchorElement };
        }
      }
    }

    return { rect: null };

    // force this value to re-compute when screen dimensions change
    // since a previous anchor might have gone and new anchor might be available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, screen.width, screen.height]);

  return anchor;
};
