import classNames from 'classnames';

import { FloatLogo } from '@float/ui/components/FloatLogo';
import { Button, EH } from '@float/ui/deprecated';

import { StyledHeader, StyledHeaderLeft, StyledHeaderRight } from './styles';

const CompanySelectHeader = (props) => {
  const { className } = props;

  return (
    <StyledHeader className={classNames(className)}>
      <StyledHeaderLeft>
        <a href="https://www.float.com" title="Float">
          <FloatLogo height={26} />
        </a>
      </StyledHeaderLeft>

      <StyledHeaderRight>
        <Button
          appearance="ghost"
          icon={EH.Icons.IconLogout}
          href="/logout"
          as="a"
        >
          Sign out
        </Button>
      </StyledHeaderRight>
    </StyledHeader>
  );
};

export default CompanySelectHeader;
