import useSWR from 'swr';

import { getSharedPageLinkToken } from '@float/libs/web/sharedLinkView';
import { SharePageData, SharePageNotFound } from '@float/types/pageData';

import { requestSharePageData } from '.';
import { Resources } from '../constants';

export const useRequestSharePageData = () => {
  const sharedPageLinkToken = getSharedPageLinkToken();

  const [resource, fetcher] = sharedPageLinkToken
    ? [Resources.SharePageData, () => requestSharePageData(sharedPageLinkToken)]
    : [null, null];

  return useSWR<SharePageData, SharePageNotFound>(resource, fetcher, {
    fallbackData: undefined,
  });
};
