import { useEffect, useState } from 'react';

import ResizeService from '@float/libs/web/resizeService';
import { getMedia } from '@float/ui/deprecated/Theme/helpers/responsive/media';

function useMedia() {
  const [media, setMedia] = useState(getMedia);

  useEffect(() => {
    function onResize() {
      setMedia(getMedia());
    }

    ResizeService.add(onResize);
    return () => {
      ResizeService.remove(onResize);
    };
  }, []);

  return media;
}

export default useMedia;
