import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setTitle } from '@float/common/lib/utils';
import { setPageTitle } from '@float/web/actions/app';

function TitleSetter(props) {
  const { title, Component, setPageTitle, ...rest } = props;

  useEffect(() => {
    setTitle(title);
    setPageTitle(title);
  }, [setPageTitle, title]);

  return <Component {...rest} />;
}

const mapDispatchToProps = (dispatch) => ({
  setPageTitle: (title) => dispatch(setPageTitle(title)),
});

const ConnectedTitleSetter = connect(null, mapDispatchToProps)(TitleSetter);

export default function withTitle(title, Component) {
  return (routerProps) => {
    return (
      <ConnectedTitleSetter
        title={title}
        Component={Component}
        {...routerProps}
      />
    );
  };
}
