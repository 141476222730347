import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import * as Intercom from '@intercom/messenger-js-sdk';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';

import history from '@float/common/lib/history';
import { SnackbarProvider, TooltipProvider } from '@float/ui/deprecated';
import { FontsPrefetch } from '@float/web/components/FontsPrefetch';

import { App, ShareApp } from './components/App';
import { Auth } from './components/auth/Auth';
import { MountDevToolsIfEnabled } from './devtools';
import { PageNotFound } from './views/PageNotFound';

class QueryRouter extends React.Component<{ children?: ReactNode }> {
  history = history;

  componentDidMount() {
    this.history.listen(() => {
      Intercom.update({});
    });
  }

  render() {
    return (
      <ConnectedRouter history={this.history}>
        {this.props.children}
      </ConnectedRouter>
    );
  }
}

const Router = (props: { store: Store }) => (
  <Provider store={props.store}>
    <FontsPrefetch />
    <QueryRouter>
      <TooltipProvider>
        <SnackbarProvider>
          <Switch>
            <Route path="/signup/exist" component={Auth} />
            <Route path="/select-company" component={Auth} />
            <Route path="/me" component={Auth} />
            <Route path="/share" component={ShareApp} />
            <Route path="/page-not-found" component={PageNotFound} />
            <Route path="/" component={App} />
            <Route component={PageNotFound} />
          </Switch>
          <MountDevToolsIfEnabled />
        </SnackbarProvider>
      </TooltipProvider>
    </QueryRouter>
  </Provider>
);

export { Router };
