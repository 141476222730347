import React from 'react';
import { Trans } from '@lingui/macro';
import { usePromptTracker } from 'OnboardingManager/hooks/usePromptTracker';

import { useIsManagerOrHigher } from '@float/common/lib/acl/useIsManagerOrHigher';
import { PROMPTS } from '@float/constants/onboarding';
import { AnchorData, Button, Callout } from '@float/ui/deprecated';

import illustration from './InsightsPercentageCalloutIllustration.svg';

import * as styles from './InsightsPercentageCallout.css';

const anchor: AnchorData = {
  anchors: [
    {
      id: 'date-range-callout',
      config: {
        arrowPosition: '45px',
        position: 'bottom',
        alignment: 'start',
        offset: {
          y: -5,
          x: 0,
        },
      },
    },
  ],
};

export const InsightsPercentageCallout = () => {
  const [done, setDone] = usePromptTracker(PROMPTS.dateRangeInsightsPercentage);
  const isManagerOrHigher = useIsManagerOrHigher();

  const visible = !done && isManagerOrHigher;

  return (
    <Callout
      visible={visible}
      anchorData={anchor}
      onClose={setDone}
      className={styles.wrapper}
    >
      <Callout.Content className={styles.content}>
        <Callout.Title>
          <Trans>Date range insights now have a percentage option</Trans>
        </Callout.Title>

        <Callout.Paragraph className={styles.description}>
          <Trans>
            Use the switch in the date range insights drop down to show
            utilization in hours, or as a percentage of capacity.{' '}
            <a
              href="https://support.float.com/en/articles/8850727-date-range-insights"
              target="_blank"
              className={styles.learnMoreLink}
              rel="noreferrer"
            >
              Learn more
            </a>
            .
          </Trans>
        </Callout.Paragraph>

        <img
          className={styles.illustration}
          src={illustration}
          width="104"
          height="113"
        />
      </Callout.Content>

      <Callout.ActionBar className={styles.actionBar}>
        <Button appearance="primary" onClick={setDone}>
          <Trans>Okay</Trans>
        </Button>
      </Callout.ActionBar>
    </Callout>
  );
};
