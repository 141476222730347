import React from 'react';

import { getNumberOfDigitsAfterDot } from '../helpers/getNumberOfDigitsAfterDot';
import { InputAllocationHoursProps } from '../InputAllocationHours';

export type UseInputAllocationHoursParams = Pick<
  InputAllocationHoursProps,
  'hoursPerDay' | 'hoursTotal' | 'onChangeHoursPerDay' | 'onChangeHoursTotal'
>;

export const useInputAllocationHours = ({
  hoursPerDay,
  hoursTotal,
  onChangeHoursPerDay,
  onChangeHoursTotal,
}: UseInputAllocationHoursParams) => {
  const handleHoursPerDayChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const hoursPerDayValue = e.currentTarget.value
      ? Number(e.currentTarget.value)
      : e.currentTarget.value;

    // No changes, don't emit any value updates
    if (hoursPerDayValue === hoursPerDay) return;

    // Empty input case
    if (typeof hoursPerDayValue === 'string') {
      onChangeHoursPerDay(null);
      return;
    }

    // Disallow more than 2 digits after the dot
    if (getNumberOfDigitsAfterDot(hoursPerDayValue) > 2) return;

    onChangeHoursPerDay(hoursPerDayValue);
  };

  const handleHoursTotalChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const hoursTotalValue = e.currentTarget.value
      ? Number(e.currentTarget.value)
      : e.currentTarget.value;

    // No changes, don't emit any value updates
    if (hoursTotalValue === hoursTotal) return;

    // Empty input case
    if (typeof hoursTotalValue === 'string') {
      onChangeHoursTotal(null);
      return;
    }

    // Disallow more than 2 digits after the dot
    if (getNumberOfDigitsAfterDot(hoursTotalValue) > 2) return;

    // Disallow values greater than 9999
    if (hoursTotalValue > 9999) return;

    onChangeHoursTotal(hoursTotalValue);
  };

  return {
    handleHoursPerDayChange,
    handleHoursTotalChange,
  };
};
