import { useMemo } from 'react';

import { ProjectInputData } from '@float/common/actions';
import { determineEntityStatus } from '@float/common/actions/lib/determineEntityStatus';
import {
  mapPhaseToPhaseRecord,
  mapTemplatePhaseToPhaseRecord,
} from '@float/common/actions/phases/phases.helpers';
import { parseBudgetString } from '@float/common/lib/budget/helpers/parseBugdetString';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { DEFAULT_PALETTE } from '@float/constants/colors';
import { Milestone, TaskMeta } from '@float/types';
import {
  BudgetPriority,
  BudgetType,
  Project,
  ProjectStatus,
} from '@float/types/project';

import {
  createEmptyMilestoneRecord,
  createEmptyPhaseRecord,
  createEmptyTaskRecord,
  createEmptyTeamMemberRecord,
} from '../helpers/createRowEntities';
import {
  FormType,
  isBudgetNotAccessible,
  ProjectEditData,
  ProjectFormData,
  ProjectFormInitialData,
  ProjectMilestoneRecord,
  ProjectTaskRecord,
  RowEntity,
} from '../types';
import { useSortedTeams } from './useSortedTeam';

export function getRandomDefaultColor() {
  return DEFAULT_PALETTE[Math.floor(Math.random() * DEFAULT_PALETTE.length)];
}

export const DEFAULT_FORM_VALUES = {
  type: FormType.Project,
  projectId: undefined,
  templateId: undefined,
  project: {
    active: true,
    budget_total: null,
    budget_type: BudgetType.Disabled,
    budget_priority: BudgetPriority.Project,
    common: false,
    client_id: undefined,
    color: DEFAULT_PALETTE[0],
    default_hourly_rate: null,
    description: '',
    end_date: null,
    locked_task_list: 1,
    non_billable: false,
    notes_meta: [],
    project_manager: undefined,
    project_name: '',
    start_date: null,
    status: ProjectStatus.Confirmed,
    tags: [],
    duration: null,
  },
  team: [],
  tasks: [],
  phases: [],
  milestones: [],
} satisfies ProjectFormData;

function parseBudgetType(project: Project) {
  if (isBudgetNotAccessible(project.budget_type)) {
    return undefined;
  }

  return project.budget_type ?? BudgetType.Disabled;
}

function parseBudgetPriority(project: Project) {
  if (isBudgetNotAccessible(project.budget_type)) {
    return 0;
  }

  return project.budget_priority ?? BudgetPriority.Project;
}

export function mapProjectToFormValues(project: Project): ProjectInputData {
  return {
    active: project.active,
    budget_total: parseBudgetString(project.budget_total),
    budget_priority: parseBudgetPriority(project),
    budget_type: parseBudgetType(project),
    client_id: project.client_id,
    color: project.color,
    common: project.common,
    default_hourly_rate: parseBudgetString(project.default_hourly_rate),
    description: project.description || '',
    end_date: project.end_date,
    locked_task_list: project.locked_task_list,
    non_billable: project.non_billable,
    notes_meta: project.notes_meta || [],
    project_manager: project.project_manager,
    project_name: project.project_name,
    status: determineEntityStatus(project),
    start_date: project.start_date,
    tags: project.tags,
    duration: project.duration,
  };
}

export function mapTaskToRecord(task: TaskMeta): ProjectTaskRecord {
  return {
    task_name: task.task_name,
    billable: task.billable,
    // the budget can be undefined in some cases, so we need to fallback to null
    // for empty values, otherwise we'll get NaN values in the budget input fields
    budget: task.budget || null,
    task_meta_id: task.task_meta_id,
    count_logged_time: task.count_logged_time,
    count_tasks: task.count_tasks,
    isSelected: false,
    unnamedTask: Boolean(
      task.project_id && task.task_meta_id && !task.task_name,
    ),
  };
}

export function mapMilestoneToRecord(
  milestone: Milestone,
): ProjectMilestoneRecord {
  return {
    milestone_id: milestone.milestone_id,
    date: milestone.date,
    end_date: milestone.end_date,
    name: milestone.name,
    duration: milestone.duration,
    parent_offset: milestone.parent_offset,
    template_milestone_id: milestone.template_milestone_id,
  };
}

// Uses project data to parse out form values for side panel
export const useProjectFormValues = (
  data: ProjectFormInitialData,
  panelPayload: Partial<ProjectEditData> = {},
): ProjectFormData => {
  const { project, phases, tasks, milestones, templateId, type } = data;
  const { projectName, status, entityToAdd } = panelPayload;
  const isTemplate = Boolean(templateId) || type === FormType.ProjectTemplate;
  const defaultProjectManager = useAppSelectorStrict(
    (state) => getUser(state).account_id,
  );
  const { sortAndMapProjectAndPhaseTeamRecords } = useSortedTeams();

  const defaultValue: ProjectFormData = useMemo(
    () => ({
      ...DEFAULT_FORM_VALUES,
      templateId,
      type,
      project: {
        ...DEFAULT_FORM_VALUES.project,
        project_name: projectName || DEFAULT_FORM_VALUES.project.project_name,
        status: status ?? ProjectStatus.Confirmed,
        project_manager: defaultProjectManager,
        color: project?.color || getRandomDefaultColor(),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultProjectManager],
  );

  const projectPhasesRecords = useMemo(() => {
    const phaseMapper = isTemplate
      ? mapTemplatePhaseToPhaseRecord
      : mapPhaseToPhaseRecord;
    const phaseData = phases.map(phaseMapper);
    if (entityToAdd === RowEntity.Phase) {
      phaseData.push(createEmptyPhaseRecord());
    }
    return phaseData;
  }, [phases, entityToAdd, isTemplate]);

  const projectTasksRecords = useMemo(() => {
    const taskData = tasks.map(mapTaskToRecord);
    if (entityToAdd === RowEntity.Task) {
      taskData.push(createEmptyTaskRecord());
    }
    return taskData;
  }, [tasks, entityToAdd]);

  const projectTeam = useMemo(() => {
    if (!project) return [];
    const team = sortAndMapProjectAndPhaseTeamRecords(
      project.people_ids,
      project.people_rates,
      phases,
    );
    if (entityToAdd === RowEntity.Team) {
      team.push(
        createEmptyTeamMemberRecord() as unknown as {
          people_id: number;
          hourly_rate: number;
        },
      );
    }
    return team;
  }, [project, phases, sortAndMapProjectAndPhaseTeamRecords, entityToAdd]);

  const projectMilestones = useMemo(() => {
    const milestonesData = milestones.map(mapMilestoneToRecord);
    if (entityToAdd === RowEntity.Milestone) {
      milestonesData.push(createEmptyMilestoneRecord());
    }
    return milestonesData;
  }, [milestones, entityToAdd]);

  return useMemo(() => {
    if (!project) return defaultValue;
    const projectValues = projectName
      ? {
          ...project,
          project_name: projectName,
        }
      : project;
    const result: ProjectFormData = {
      type,
      projectId: project.project_id,
      templateId,
      project: mapProjectToFormValues(projectValues),
      phases: projectPhasesRecords,
      tasks: projectTasksRecords,
      milestones: projectMilestones,
      team: projectTeam,
    };

    return result;
  }, [
    project,
    projectName,
    defaultValue,
    projectPhasesRecords,
    projectTasksRecords,
    projectMilestones,
    projectTeam,
    templateId,
    type,
  ]);
};
