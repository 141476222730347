import React from 'react';
import * as Styled from 'styled-components';

import Theme, { GlobalStyles } from '@float/ui/deprecated/Theme';

export const ThemeProvider = ({ children }) => {
  return (
    <Styled.ThemeProvider theme={Theme}>
      <GlobalStyles />
      {children}
    </Styled.ThemeProvider>
  );
};
