import { search, SearchResolveContext } from '@float/common/api3/search';
import { CurrentUser } from '@float/types/account';
import { BaseFilterToken } from '@float/types/view';

import { isOr, PERSON_RELATED_TYPES } from '../helpers';
import { convertFilterTokenToSearchResolveQuery } from './helpers/convertFilterTokenToSearchResolveQuery';
import { convertResolveResultsToSearchResults } from './helpers/convertResolveResultsToSearchResults';
import { groupFiltersByLogicalOperator } from './helpers/groupFiltersByLogicalOperator';

export type SearchResolveParams = {
  filters: BaseFilterToken[];
  context: SearchResolveContext;
  user: CurrentUser;
};

export type SearchResolveResult = {
  contextId: string;
  result: ReturnType<typeof convertResolveResultsToSearchResults>;
};

export async function resolveApi(
  params: SearchResolveParams,
): Promise<SearchResolveResult> {
  // TODO: Filter-out the non-valid filters
  // https://linear.app/float-com/issue/PS-1443/discovery-specify-all-the-exceptions-implemented-on-the-client-search
  const meFilterActive = params.filters.some((filter) => filter.type === 'me');

  let appliedFilters = params.filters;

  // If Me filter is on, do not filter by people related criteria
  if (meFilterActive) {
    appliedFilters = appliedFilters.filter(
      (x) => !PERSON_RELATED_TYPES.includes(x.type),
    );
  }

  // Convert the filter token in query compatible with the resolve API
  // Since we need to gather the ids values from an API the call is async
  // and we run the conversions in parallel
  const resolvedFilters = await Promise.all(
    appliedFilters.map(async (filter) => ({
      logicalOperator: isOr(filter.operator)
        ? ('or' as const)
        : ('and' as const),
      filters: await convertFilterTokenToSearchResolveQuery(
        filter,
        params.user,
      ),
    })),
  );

  const { contextId, result } = await search.resolve({
    query: groupFiltersByLogicalOperator(resolvedFilters),
    context: params.context,
  });

  return {
    contextId,
    result: convertResolveResultsToSearchResults(result),
  };
}
