import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconRefresh = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M11.25 4.75L8.75 7L11.25 9.25"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 19.25L15.25 17L12.75 14.75"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 7H13.25C16.5637 7 19.25 9.68629 19.25 13V13.25"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 17H10.75C7.43629 17 4.75 14.3137 4.75 11V10.75"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);
