import { Resources } from '../constants';
import { makeRequest, MakeRequestPagination } from '../makeRequest';

export enum IntercomPlatform {
  Web = 'web',
  Mobile = 'mobile',
}

export type IntercomHash = {
  hash: string;
};

export const requestIntercomHash = (
  platform: IntercomPlatform,
): Promise<IntercomHash> =>
  makeRequest<IntercomHash>({
    resource: Resources.IntercomHash,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
    query: {
      platform,
    },
  });
