import React, { StrictMode } from 'react';

import { useBootstrapGlobalServicesForMultiAccountSignup } from '@float/web/lib/bootstrap/bootstrap.hooks';

import { FullPageLoader } from '../FullPageLoader';
import TeamExist from './TeamExist/TeamExist';

export const SignupExist = (props: object) => {
  const { isReady, data } = useBootstrapGlobalServicesForMultiAccountSignup();

  return (
    <main className="page-multi-account-login">
      <StrictMode>
        <FullPageLoader isLoading={!isReady}>
          <TeamExist {...props} email={data?.maEmail} />
        </FullPageLoader>
      </StrictMode>
    </main>
  );
};
