import React from 'react';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import styled from 'styled-components';

import { ToggleButton, ToggleGroup } from '@float/ui/deprecated';

const ToggleGroupWrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

function getDefaultValue({ projects, ids, key }) {
  const defaultValue = projects[ids[0]][key];
  if (
    !isUndefined(defaultValue) &&
    ids.every((id) => projects[id][key] === defaultValue)
  ) {
    return +defaultValue || 0;
  }
  return 0;
}

class ProjectType extends React.Component {
  componentDidMount() {
    this.setDefaultValue();
  }

  onNonBillableChange = (val) => {
    const { tentative } = this.props.value;
    this.props.onChange({ non_billable: val, tentative });
  };

  onTentativeChange = (val) => {
    const { non_billable } = this.props.value;
    this.props.onChange({ non_billable, tentative: val || 0 });
  };

  getFields = async () => {
    const { value } = this.props;
    const fields = {};
    if (value.non_billable !== this.initialValue.non_billable) {
      fields.non_billable = value.non_billable;
    }
    if (value.tentative !== this.initialValue.tentative) {
      fields.tentative = value.tentative;
    }
    return fields;
  };

  setDefaultValue = () => {
    const { ids, projects } = this.props;
    this.initialValue = {
      non_billable: getDefaultValue({ projects, ids, key: 'non_billable' }),
      tentative: getDefaultValue({ projects, ids, key: 'tentative' }) || null,
    };
    this.props.onChange(this.initialValue);
  };

  render() {
    const { non_billable = 0, tentative = 0 } = this.props.value;
    return (
      <ToggleGroupWrapper>
        <ToggleGroup
          appearance="stitched"
          disableUnselect
          value={non_billable}
          onChange={this.onNonBillableChange}
        >
          <ToggleButton value={0}>Billable</ToggleButton>
          <ToggleButton value={1}>Non-billable</ToggleButton>
        </ToggleGroup>

        <ToggleGroup value={tentative} onChange={this.onTentativeChange}>
          <ToggleButton value={1}>Tentative</ToggleButton>
        </ToggleGroup>
      </ToggleGroupWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects.projects,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ProjectType,
);
