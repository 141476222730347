import React, { MouseEvent } from 'react';
import { t, Trans } from '@lingui/macro';

import { GenericActivity } from '@float/types/activity';
import { Button } from '@float/ui/components/Button';
import { IconKebabDots } from '@float/ui/icons/essentials/IconKebabDots';

import { Notifications } from '../Notifications/Notifications';
import { useNotificationsPanelController } from './hooks/useNotificationsPanelController';

import * as styles from './NotificationsPanel.css';

export const NotificationsPanel = () => {
  const { onClickNotification, onClickMore } =
    useNotificationsPanelController();

  const handleNotificationClick = (notification: GenericActivity) => {
    onClickNotification(notification);
  };

  const handleMoreClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();

    onClickMore();
  };

  return (
    <div className={styles.notificationsPanel}>
      <div className={styles.header}>
        <h4 className={styles.title}>
          <Trans>Notifications</Trans>
        </h4>
        <Button
          appearance="clear-flay"
          aria-label={t`notifications settings`}
          iconEnd={IconKebabDots}
          onClick={handleMoreClick}
          size="icon"
        />
      </div>

      <div className={styles.notificationsPanelContent} role="list">
        <Notifications onClick={handleNotificationClick} />
      </div>
    </div>
  );
};
