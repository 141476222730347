import {
  AndNotOperator,
  AndOperator,
  FilterOperators,
  OrNotOperator,
  OrOperator,
} from '@float/types/filters';

/*
 * '-', '+', '|'
 *  Does not include Nor '|-'
 */
export const OPERATOR_PRIMITIVES = new Set(['-', '+', '|']);

function getOperatorAtPosition(value: string, position: number) {
  const char = value.charAt(position);

  return OPERATOR_PRIMITIVES.has(char) ? char : '';
}

export function getOperator(value: string) {
  let operator = '';

  // We can have upto two operators
  // E.g. Or Not, which would be |-
  operator += getOperatorAtPosition(value, 0);
  operator += getOperatorAtPosition(value, 1);

  return operator;
}

export function isOp<Op extends FilterOperators | ''>(
  input?: string,
  op?: Op,
): input is Op {
  if (!input || !op) return false;

  return getOperator(input).includes(op);
}

export function isAnd(input?: string): input is AndOperator | OrOperator {
  return isOp(input, '+');
}

export function isNot(input?: string): input is AndNotOperator | OrNotOperator {
  return isOp(input, '-');
}

export function isOr(input?: string): input is OrOperator | OrNotOperator {
  return isOp(input, '|');
}
