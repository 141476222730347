import React from 'react';
import { t } from '@lingui/macro';

import { TIME_FORMAT } from '@float/common/lib/time';
import { CellItem } from '@float/types';
import { EH, Icons } from '@float/ui/deprecated';

import { formatHours } from '../utils/formatHours';

import { hourElement, singleRowIcons } from '../../EntityItemStyles.css';

export function getSingleRowLoggedTime(
  item: CellItem<'task'> | CellItem<'timeoff'> | CellItem<'loggedTime'>,
  color: string | undefined,
  suvSingleDay: string | null | undefined,
  displayFormat: keyof typeof TIME_FORMAT,
) {
  const { entity, hasActiveTimer } = item;

  const hasNotes = 'notes' in entity && entity.notes;

  if (hasActiveTimer) {
    return (
      <div className={hourElement}>
        <EH.Icons.IconActiveTimer
          color={color}
          size={15}
          data-testid="icon-clock"
        />
        {t`Timer running...`}
      </div>
    );
  }

  return (
    !item.isTaskReference && (
      <>
        <div className={hourElement}>
          <EH.Icons.IconLogged
            color={color}
            size={15}
            data-testid="icon-clock"
          />
          {formatHours(item.entity, false, displayFormat)}
        </div>

        <div className={singleRowIcons}>
          {hasNotes && (
            <Icons.SerenaNotes
              color={color}
              size={suvSingleDay ? 15 : 12}
              data-testid="icon-notes"
            />
          )}
        </div>
      </>
    )
  );
}
