import React from 'react';
import { plural, t, Trans } from '@lingui/macro';

type DeleteMessageProps = {
  row: {
    isEdited: boolean;
    isDeletable: boolean;
    id: number;
    entity: {
      client_id: number;
      name: string;
      project_count: number;
    };
  };
};

export const DeleteMessage = (props: DeleteMessageProps) => {
  const { row } = props;
  const { name, project_count: projectCount } = row.entity;

  return {
    title: t`Delete Client`,
    message: <Message name={name} projectCount={projectCount} />,
    twoStep: projectCount > 0,
  };
};

const getClientLabel = (name: string | undefined) =>
  name ? <strong>{name}</strong> : 'this Client';

const getProjectLabel = (projectCount: number) =>
  plural(projectCount, {
    one: `# project`,
    other: `# projects`,
  });

const Message = (props: { name: string; projectCount: number }) => {
  const { name, projectCount } = props;

  return (
    <p>
      <Trans>Are you sure you want to delete {getClientLabel(name)}?</Trans>
      {projectCount > 0 && (
        <>
          {' '}
          <Trans>
            This will remove the client from the{' '}
            <strong>{getProjectLabel(projectCount)}</strong> they are currently
            assigned to.
          </Trans>
        </>
      )}
    </p>
  );
};
