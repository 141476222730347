import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getIsPmSidebarFiltered } from 'selectors';
import styled from 'styled-components';

import { PROMPTS } from '@float/constants/onboarding';

import Notification from './Notification';

const notificationStyle = {
  border: 0,
  top: 104,
  right: 399,
  bottom: 'auto',
  left: 'auto',
  padding: '11px 12px',
  textAlign: 'center',
};

const StyledTip = styled.div`
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  ${(p) => p.theme.antialias}

  strong {
    padding-top: 5px;
    cursor: pointer;
    display: inline-block;
  }
`;

const ID = PROMPTS.pmSidebarItemDrag;

let targetEl = null;

const PmSidebarItemDragTip = ({ alreadySeen }) => {
  const [show, setShow] = useState(false);

  const onClose = () => {
    if (targetEl) {
      targetEl.removeEventListener('mousedown', onClose);
      targetEl = null;
    }
    setShow(false);
  };

  useEffect(() => {
    if (!alreadySeen) {
      const timer = setTimeout(() => {
        const elements = document.getElementsByClassName('pm-sidebar-items');
        targetEl = elements.length ? elements[0] : null;
        if (targetEl) {
          targetEl.addEventListener('mousedown', onClose);
          setShow(true);
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
        targetEl?.removeEventListener('mousedown', onClose);
        targetEl = null;
      };
    }
  }, []); // eslint-disable-line

  return (
    <>
      <Notification
        id={ID}
        show={!alreadySeen && show}
        width={136}
        animation="fade"
        style={notificationStyle}
        dark
        arrow="right"
        showClose={false}
        onClose={onClose}
      >
        <StyledTip>
          Drag out an item to schedule it.
          <br />
          <strong onClick={onClose}>Got it!</strong>
        </StyledTip>
      </Notification>
    </>
  );
};
const mapStateToProps = (state) => {
  const { prompts = [] } = state.legacyOnboarding;
  const items = getIsPmSidebarFiltered(state)
    ? state.pmSidebar.filteredItems
    : state.pmSidebar.items;
  const hasItems = Boolean(items.length);

  return {
    // Only show if there's at least one item in the
    // sidebar and user has not already seen the tip.
    alreadySeen: !hasItems || prompts.includes(ID),
  };
};
export default connect(mapStateToProps, undefined)(PmSidebarItemDragTip);
