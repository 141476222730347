import React from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@float/common/store';
import { PROMPTS } from '@float/constants/onboarding';
import { Button, EH, Modal, ModalBody } from '@float/ui/deprecated';
import { getOnboardingStatus } from '@float/web/store/legacyOnboarding/selectors';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';

import { InAppGuideStyle, modalStyle } from './styles';

import logTimeIcon from './images/log-time.svg';

const StyledLogTimeContent = styled.div`
  p {
    max-width: 100%;
    margin-bottom: 32px;

    line-height: 26px;
  }

  h1 {
    ${EH.Typography.DisplayXS.M500};

    margin-bottom: 16px;
  }
`;

const StyledLogTimeIcon = styled.img`
  width: 42px;
  height: 48px;

  margin-bottom: 16px;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 24px 32px 32px;
`;

export const WelcomeLogTime = () => {
  const dispatch = useAppDispatch();
  const { showLogTime } = useAppSelector(getOnboardingStatus);
  const markAsSeen = () => dispatch(updatePrompts(PROMPTS.welcomeLogTime));

  if (!showLogTime) return null;

  return (
    <>
      <InAppGuideStyle />
      <Modal
        isOpen
        width={620}
        style={modalStyle}
        className="in-app-guide-modal"
        onClose={markAsSeen}
      >
        <StyledModalBody>
          <StyledLogTimeContent>
            <StyledLogTimeIcon src={logTimeIcon} />
            <div>
              <h1>Here's where your team logs their time</h1>
              <p>
                It's populated with suggestions from the schedule, so they'll be
                done in seconds. Then you can compare your estimates to actual
                time spent over in Reports
              </p>
            </div>
          </StyledLogTimeContent>
          <Button onClick={markAsSeen}>OK, got it</Button>
        </StyledModalBody>
      </Modal>
    </>
  );
};
