import React from 'react';
import cn from 'classnames';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

import * as styles from './IconSpinner.css';

export type IconSpinnerProps = SVGIconProps & {
  animated?: boolean;
};

export const IconSpinner = (props: IconSpinnerProps) => {
  const { className, animated, ...svgIconAttributes } = props;

  return (
    <SVGIcon
      className={cn({ [styles.animated]: animated }, className)}
      {...svgIconAttributes}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M19.25 12a7.25 7.25 0 1 0-3.365 6.121"
      />
    </SVGIcon>
  );
};
