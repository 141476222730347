import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconSwitch = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m12.75 15.75 3.25 3.5 3.25-3.5M4.75 8.25 8 4.75l3.25 3.5M16 8.75v10.5M8 4.75v10.5"
      />
    </SVGIcon>
  );
};

export { IconSwitch };
