import { IconBeach } from '@float/ui/icons/essentials/IconBeach';
import { IconBuilding } from '@float/ui/icons/essentials/IconBuilding';
import { IconChart } from '@float/ui/icons/essentials/IconChart';
import { IconCircleStatus } from '@float/ui/icons/essentials/IconCircleStatus';
import { IconDottedCircleLeft } from '@float/ui/icons/essentials/IconDottedCircleLeft';
import { IconFolder } from '@float/ui/icons/essentials/IconFolder';
import { IconFolderTag } from '@float/ui/icons/essentials/IconFolderTag';
import { IconFolderUser } from '@float/ui/icons/essentials/IconFolderUser';
import { IconHat } from '@float/ui/icons/essentials/IconHat';
import { IconInstituition } from '@float/ui/icons/essentials/IconInstituition';
import { IconOrganization } from '@float/ui/icons/essentials/IconOrganization';
import { IconStamp } from '@float/ui/icons/essentials/IconStamp';
import { IconStar } from '@float/ui/icons/essentials/IconStar';
import { IconTasks } from '@float/ui/icons/essentials/IconTasks';
import { IconUserCircle } from '@float/ui/icons/essentials/IconUserCircle';
import { IconUserLines } from '@float/ui/icons/essentials/IconUserLines';
import { IconUsers } from '@float/ui/icons/essentials/IconUsers';
import { IconUserTag } from '@float/ui/icons/essentials/IconUserTag';

import { SearchFilterDropdownDataType } from './types';

export const SearchFilterDropdownData: SearchFilterDropdownDataType = [
  {
    icon: IconStar,
    label: 'Saved filter',
    shortLabel: 'Saved',
    showSeparatorAfter: true,
    type: 'savedSearches',
    value: 'savedSearches',
  },

  {
    icon: IconUserCircle,
    label: 'Me',
    plural: 'Me',
    type: 'people',
    value: 'me',
    showSeparatorAfter: true,
  },

  {
    icon: IconBuilding,
    label: 'Department',
    type: 'departments',
    value: 'departments',
  },

  {
    icon: IconHat,
    label: 'Role',
    type: 'people',
    value: 'jobTitles',
  },

  {
    icon: IconUsers,
    label: 'Person',
    plural: 'People',
    type: 'people',
    value: 'people',
  },

  {
    icon: IconUserTag,
    label: 'Person tag',
    type: 'people',
    value: 'personTags',
  },

  {
    icon: IconUserLines,
    label: 'Person type',
    type: 'people',
    value: 'personTypes',
  },

  {
    icon: IconOrganization,
    label: 'Manager',
    type: 'people',
    value: 'managers',
    showSeparatorAfter: true,
  },

  {
    icon: IconBeach,
    label: 'Time off',
    type: 'timeoffs',
    value: 'timeoffs',
  },

  {
    icon: IconStamp,
    label: 'Time off status',
    plural: 'Time off statuses',
    type: 'timeoffs',
    value: 'timeoffStatuses',
    showSeparatorAfter: true,
  },

  {
    icon: IconInstituition,
    label: 'Client',
    type: 'clients',
    value: 'clients',
  },

  {
    icon: IconFolder,
    label: 'Project',
    type: 'projects',
    value: 'projects',
  },

  {
    icon: IconDottedCircleLeft,
    label: 'Phase',
    type: 'projects',
    value: 'phases',
  },

  {
    icon: IconFolderTag,
    label: 'Project tag',
    type: 'projects',
    value: 'projectTags',
  },

  {
    icon: IconFolderUser,
    label: 'Project owner',
    type: 'projects',
    value: 'projectOwners',
  },

  {
    icon: IconChart,
    label: 'Project status',
    plural: 'Project statuses',
    type: 'projects',
    value: 'projectStatuses',
    showSeparatorAfter: true,
  },

  {
    icon: IconTasks,
    label: 'Task',
    type: 'tasks',
    value: 'tasks',
  },

  {
    icon: IconCircleStatus,
    label: 'Allocation status',
    plural: 'Allocation statuses',
    type: 'tasks',
    value: 'taskStatuses',
  },

  {
    hideInAllView: true,
    label: 'Contains',
    type: 'contains',
    value: 'contains',
  },
];
