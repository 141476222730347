import React, { useRef } from 'react';
import { t } from '@lingui/macro';

import { useOnMount } from '@float/libs/hooks/useOnMount';
import { Input } from '@float/ui/deprecated';

import { useInputAllocationHours } from './hooks/useInputAllocationHours';

import * as styles from './InputAllocationHours.css';

export type InputAllocationHoursProps = {
  hoursPerDay: number | null;
  hoursTotal: number | null;
  isReadOnly: boolean;
  isTotalHoursInputDisabled: boolean;
  onChangeHoursPerDay: (hoursPerDay: number | null) => void;
  onChangeHoursTotal: (hoursTotal: number | null) => void;
  onBlurHoursPerDay: () => void;
  onBlurHoursTotal: () => void;
};

export const InputAllocationHours = (props: InputAllocationHoursProps) => {
  const {
    hoursPerDay,
    hoursTotal,
    isReadOnly,
    isTotalHoursInputDisabled,
    onChangeHoursPerDay,
    onChangeHoursTotal,
    onBlurHoursPerDay,
    onBlurHoursTotal,
  } = props;

  const inputHoursPerDayRef = useRef<HTMLInputElement>();

  // When this component appears in the DOM we want it to focus,
  // so user can start typing without an extra click
  useOnMount(() => {
    if (isReadOnly) return;

    inputHoursPerDayRef.current?.focus();
  });

  const { handleHoursPerDayChange, handleHoursTotalChange } =
    useInputAllocationHours({
      hoursPerDay,
      hoursTotal,
      onChangeHoursPerDay,
      onChangeHoursTotal,
    });

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <Input
          inputRef={inputHoursPerDayRef}
          appearance="underline"
          type="number"
          label={t`Hours/day`}
          hideArrows
          selectTextOnFocus
          value={`${hoursPerDay}`}
          min={0.25}
          step={0.25}
          // errors={errors.hoursPd}
          readOnly={isReadOnly}
          noBorder={isReadOnly}
          onChange={handleHoursPerDayChange}
          onKeyPress={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              onBlurHoursPerDay();
            }
          }}
          onBlur={onBlurHoursPerDay}
          isStaticPlaceholder
        />
      </div>
      <div className={styles.column}>
        <Input
          appearance="underline"
          type="number"
          label={t`Total hours`}
          hideArrows
          selectTextOnFocus
          value={`${hoursTotal}`}
          min={hoursPerDay}
          step={hoursPerDay}
          // errors={errors.hoursPd}
          readOnly={isReadOnly || isTotalHoursInputDisabled}
          disabled={isTotalHoursInputDisabled}
          noBorder={isReadOnly}
          noBackground={isTotalHoursInputDisabled}
          onChange={handleHoursTotalChange}
          onKeyPress={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              onBlurHoursTotal();
            }
          }}
          onBlur={onBlurHoursTotal}
          isStaticPlaceholder
        />
      </div>
    </div>
  );
};
