import React, { useEffect } from 'react';
import { forEach } from 'lodash';
import { getSearchFilteredActivePeople, getUser } from 'selectors';

import Avatar from '@float/common/components/Avatar/Avatar';
import { Rights } from '@float/common/lib/acl';
import { useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import {
  Icons,
  MultiSelect,
  ReadOnlyField,
  VirtualSelect,
} from '@float/ui/deprecated';

let etmThis;
const allViewableLabel = 'Select all';
const allViewableValue = '*';

const getPersonAvatar = (person) => {
  if (person.people_id === allViewableValue) {
    return <Icons.AllViewable />;
  }

  return (
    <Avatar
      name={person.name}
      imageUrl={person.avatar_file}
      readOnly
      size="xs"
    />
  );
};

const mapPersonToOption = (person) => ({
  label: person.name,
  value: person.people_id,
  icon: getPersonAvatar(person),
});

const useAssignablePeopleOptions = ({ taskMode, peopleIds }) => {
  const [people, setPeople] = React.useState([]);

  const user = useAppSelector(getUser);
  const searchActivePeople = useAppSelector(getSearchFilteredActivePeople);

  useEffect(() => {
    const assigneeOptions = [];
    forEach(searchActivePeople, (person) => {
      const canAssignToPerson =
        taskMode == 'task'
          ? Rights.canCreateTaskForPeople(user, [person])
          : taskMode == 'timeoff'
            ? Rights.canCreateTimeoffForPeople(user, [person])
            : taskMode == 'status'
              ? Rights.canCreateStatus(user, { person })
              : false;

      if (canAssignToPerson) {
        const option = mapPersonToOption(person);
        assigneeOptions.push(option);
      }
    });

    assigneeOptions.sort((a, b) => {
      const aName = String(a.label).toLowerCase();
      const bName = String(b.label).toLowerCase();
      return aName.localeCompare(bName);
    });

    setPeople(assigneeOptions);
  }, [user, searchActivePeople, taskMode]);

  return people;
};

const getAssignedElemReadOnly = () => {
  const actualNumValues = etmThis.state.task.people_ids.length;
  const values = etmThis.state.peopleIds
    // sometimes the id isn't found in the peopleMap
    // https://rollbar.com/float/fe-web-app/items/4880/
    .map((id) => etmThis.props.peopleMap[id]?.name)
    .filter(Boolean)
    .sort((a, b) => {
      return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
    });

  const others = actualNumValues - values.length;

  let label = 'Assigned to';
  if (values.length > 1) {
    label += `: ${actualNumValues} people`;
  }

  let text = values.join(', ');
  if (others > 0) {
    text += `${values.length > 1 ? ',' : ''} and ${others} other${
      others == 1 ? '' : 's'
    }`;
  }

  return (
    <ReadOnlyField
      type="scrollableText"
      label={label}
      value={text}
      wrapperStyle={{ marginBottom: 20 }}
    />
  );
};

const shouldShowSingleSelectDropdown = (self) => {
  const { taskMode, isOffWork } = self.state;

  if (isOffWork) return true;

  const singleSelectFields = ['status'];
  const isSingleSelectField = singleSelectFields.includes(taskMode);
  return isSingleSelectField;
};

export const getAssignedElem = (self) => {
  etmThis = self;

  if (
    etmThis.isReadOnly() ||
    etmThis.isMember() ||
    etmThis.state.integrationSyncLocked
  ) {
    return getAssignedElemReadOnly();
  }

  return <AssigneesField self={etmThis} />;
};

export const AssigneesField = (props) => {
  const { self } = props;
  const { peopleIds, originalTaskUnmodified } = self.state;

  const assignablePeopleOptions = useAssignablePeopleOptions(self.state);
  const isSingleAssignee = shouldShowSingleSelectDropdown(self);

  if (isSingleAssignee) {
    // If the user removed all people while in the task/timeoff tab and switch
    // to status, default to the original person.
    const value = peopleIds[0] ?? originalTaskUnmodified.people_ids[0];

    return (
      <VirtualSelect
        label="Assigned to"
        style={{ marginBottom: 16 }}
        value={value}
        options={assignablePeopleOptions}
        visibleItems={6}
        nonNullable
        clearInputOnDropdownOpen={false}
        onChange={(val) => {
          etmThis.setState((ps) => {
            const { calcEntityEndDate } = etmThis.props;

            const newEnd = calcEntityEndDate({
              start_date: moment(ps.startDate).format('YYYY-MM-DD'),
              length: Math.floor(ps.totalHours / ps.hoursPd),
              people_ids: [val.value],
              timeoff_id: etmThis.state.task.timeoff_id,
            });

            return {
              peopleIds: [val.value],
              endDate: moment(newEnd),
              errors: {
                ...ps.errors,
                assignedElem: null,
              },
            };
          });
        }}
        errors={etmThis.state.errors.assignedElem}
      />
    );
  }

  const values = etmThis.state.peopleIds
    .map((id) => {
      const person = etmThis.props.peopleMap[id];

      if (!person) return undefined;

      return {
        label: person.name,
        value: person.people_id,
      };
    })
    .filter(Boolean);

  const withSelectAll = values.length < assignablePeopleOptions.length;

  return (
    <MultiSelect
      label={`Assigned to${
        values.length > 1 ? `: ${values.length} people` : ''
      }`}
      options={[
        ...assignablePeopleOptions,
        ...(withSelectAll
          ? [
              mapPersonToOption({
                name: allViewableLabel,
                people_id: allViewableValue,
              }),
            ]
          : []),
      ]}
      values={values}
      style={{ marginBottom: 16 }}
      keyboardHighlight
      color="charcoalGrey"
      visibleItems={6}
      onAdd={(opt) => {
        etmThis.setState((ps) => {
          if (opt.value === allViewableValue) {
            return {
              peopleIds: assignablePeopleOptions.map((o) => o.value),
              errors: { ...ps.errors, assignedElem: null },
            };
          }

          return {
            peopleIds: [...ps.peopleIds, opt.value],
            errors: {
              ...ps.errors,
              assignedElem: null,
            },
          };
        });
      }}
      onRemove={(opt) => {
        etmThis.setState((ps) => {
          return {
            peopleIds: ps.peopleIds.filter((id) => id !== opt.value),
          };
        });
      }}
      onReplace={(selectedOpt, newOpt) => {
        etmThis.setState((ps) => {
          if (newOpt.value === allViewableValue) {
            return {
              peopleIds: assignablePeopleOptions.map((o) => o.value),
              errors: { ...ps.errors, assignedElem: null },
            };
          }

          return {
            peopleIds: ps.peopleIds
              .filter((id) => id !== selectedOpt.value)
              .concat([newOpt.value]),
            errors: { ...ps.errors, assignedElem: null },
          };
        });
      }}
      errors={etmThis.state.errors.assignedElem}
    />
  );
};
