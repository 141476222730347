import { logger } from '@float/libs/logger';
import { promiseWithResolvers } from '@float/libs/utils/promiseWithResolvers';
import { CurrentUser } from '@float/types/account';

import { FeatureFlag } from './constants';
import {
  isFeatureFlagVariant,
  isTargetedDebugLogFlagVariants,
} from './helpers';
import {
  FeatureFlagChangeHandler,
  FeatureFlagClientAdapter,
  FeatureFlagOverrides,
  FlagVariant,
} from './types';

function assertInitialization(
  isInitializing: boolean,
): asserts isInitializing is false {
  if (isInitializing)
    throw new Error('The feature flags client is still initializing');
}

function assertClientAdapterReady(
  clientAdapter: FeatureFlagClientAdapter | undefined,
): asserts clientAdapter is FeatureFlagClientAdapter {
  if (!clientAdapter)
    throw new Error(
      'The feature flags client has not started initializing, you need to call `initialize`',
    );
}

export class FeatureFlags {
  private _clientAdapter: FeatureFlagClientAdapter | undefined;

  private get clientAdapter(): FeatureFlagClientAdapter {
    assertInitialization(this._isInitializing);
    assertClientAdapterReady(this._clientAdapter);

    return this._clientAdapter;
  }

  private _isInitializing = false;

  public get isReady() {
    return Boolean(this._clientAdapter?.isReady && !this._isInitializing);
  }

  public waitForReady() {
    return this._readyPromise.promise;
  }

  private _overrides: FeatureFlagOverrides = {};
  private _readyPromise = promiseWithResolvers();

  private _values: Partial<Record<FeatureFlag, unknown>> = {};

  public async initialize(clientAdapter: FeatureFlagClientAdapter) {
    if (this._isInitializing || this.isReady) {
      logger.warn('The feature flag service has already been initialized');
      return;
    }

    this._isInitializing = true;
    this._clientAdapter = clientAdapter;

    const result = await clientAdapter.initialize();
    this._readyPromise.resolve(undefined);

    this._overrides = result?.overrides ?? {};
    this._isInitializing = false;

    for (const flag of Object.values(FeatureFlag)) {
      this._values[flag] = this.clientAdapter.getFlagVariant(flag);
    }
  }

  public async identify(user: CurrentUser) {
    await this.clientAdapter.identify(user);
  }

  getFlagVariant(key: FeatureFlag): FlagVariant {
    assertInitialization(this._isInitializing);
    assertClientAdapterReady(this._clientAdapter);

    const variant = this._values[key];

    if (typeof variant === 'undefined') {
      return false;
    }

    if (typeof variant === 'boolean') {
      return variant;
    }

    if (
      typeof variant === 'string' &&
      (isFeatureFlagVariant(variant) || isTargetedDebugLogFlagVariants(variant))
    ) {
      return variant;
    }

    logger.error(
      `Unsupported feature flag variant \`${variant}\` for flag \`${key}\`. ` +
        `Use \`getUnconstrainedFlagVariant\` if this is the value you were expecting.`,
    );

    return false;
  }

  /**
   * Use only if you need to handle an exotic feature flag configuration
   */
  public getUnconstrainedFlagVariant(key: FeatureFlag): unknown {
    return this._values[key];
  }

  public setOverrides(value: FeatureFlagOverrides) {
    this._overrides = value;
  }

  public isFeatureEnabled(key: FeatureFlag): boolean {
    const { _overrides } = this;

    if (key in _overrides) {
      // TS incorrectly thinks the accessed value might be undefined here
      return _overrides[key] as boolean;
    }

    const variant: FlagVariant = this.getFlagVariant(key);

    if (String(variant) === 'off') {
      return false;
    }

    return Boolean(variant);
  }

  public isDebugLogVariantEnabled(variantName: string | FlagVariant) {
    const variant: FlagVariant = this.getFlagVariant(
      FeatureFlag.TargetedDebugLogs,
    );

    return variantName === variant;
  }

  public addChangeHandler(handler: FeatureFlagChangeHandler) {
    this.clientAdapter.addChangeHandler(handler);
  }

  public removeChangeHandler(handler: FeatureFlagChangeHandler) {
    this.clientAdapter.removeChangeHandler(handler);
  }
}
