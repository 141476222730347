export type GetToKnownYouOption = {
  data: string;
  default?: boolean;
  desc?: string;
  label: string;
};

export type GetToKnownYouDataType = Record<
  'teamSize' | 'supportEffort',
  GetToKnownYouOption[]
>;

export enum SupportEffort {
  Low = 'Low',
  High = 'High',
  No = 'No',
}

export type SupportEffortType = keyof typeof SupportEffort;
