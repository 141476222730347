import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconProjectDraft = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10.891 3.239a1.765 1.765 0 0 1 1.773 0l.75.435-.759 1.323-.75-.436a.252.252 0 0 0-.254 0l-.75.436-.76-1.323.75-.435Zm4.025 1.306 1.501.871-.76 1.323-1.5-.871.759-1.323Zm-7.778.871 1.502-.87.76 1.322-1.502.87-.76-1.322ZM4.886 6.723l.751-.436.76 1.323-.75.435a.255.255 0 0 0-.128.22v.872H4v-.871c0-.637.338-1.225.886-1.543Zm13.033-.436.75.436c.549.318.887.906.887 1.543v.87h-1.52v-.87a.255.255 0 0 0-.127-.22l-.75-.436.76-1.323Zm1.637 4.592v1.742h-1.52V10.88h1.52ZM4 12.62V10.88h1.52v1.742H4Zm0 2.613v-.87h1.52v.87c0 .091.048.175.126.22l.75.436-.759 1.323-.75-.436A1.783 1.783 0 0 1 4 15.234Zm15.556-.87v.87c0 .637-.338 1.225-.887 1.543l-.75.436-.76-1.323.75-.435a.255.255 0 0 0 .127-.22v-.872h1.52ZM8.64 18.953l-1.502-.87.76-1.323 1.501.871-.76 1.323Zm7.777-.87-1.501.87-.76-1.322 1.502-.87.76 1.322Zm-5.526 2.177-.75-.435.76-1.323.75.436a.252.252 0 0 0 .253 0l.751-.436.76 1.323-.75.435a1.765 1.765 0 0 1-1.774 0Z"
        clipRule="evenodd"
      />
    </SVGIcon>
  );
};

export { IconProjectDraft };
