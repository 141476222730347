import { plural, t } from '@lingui/macro';

import { capitalize } from '@float/libs/utils/string/capitalize';
import {
  TimeoffBalanceType,
  TimeoffType,
  TimeoffTypeBalance,
} from '@float/types/timeoffType';

export const formatTimeoffTypeAsOption = (
  type: TimeoffType,
  timeoffTypesBalance: undefined | TimeoffTypeBalance[],
) => {
  let description = undefined;
  let isLoadingDescription: boolean = true;

  if (type.balance_type === TimeoffBalanceType.Unlimited) {
    description = t`Unlimited`;
    isLoadingDescription = false;
  } else if (timeoffTypesBalance) {
    const currentTimeoffTypeBalance = timeoffTypesBalance.find(
      (timeoffTypeBalance) => timeoffTypeBalance.id === type.timeoff_type_id,
    );

    if (currentTimeoffTypeBalance) {
      description = plural(currentTimeoffTypeBalance.daysAvailable, {
        one: '# day available',
        other: '# days available',
      });
      isLoadingDescription = false;
    }
  }

  return {
    label: capitalize(type.timeoff_type_name),
    value: type.timeoff_type_id,
    description,
    isLoadingDescription,
  };
};
