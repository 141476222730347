import { t } from '@lingui/macro';

import { ProjectStatus } from '@float/types/project';

export function getEntityStatusLabel(status: ProjectStatus) {
  const entityStatusLabels = {
    [ProjectStatus.Draft]: t`Draft`,
    [ProjectStatus.Tentative]: t`Tentative`,
    [ProjectStatus.Confirmed]: t`Confirmed`,
  };
  return entityStatusLabels[status];
}
