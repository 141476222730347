import { roundToTwoDecimals } from '@floatschedule/activity-format-npm';

import { selectDatesManager } from '@float/common/selectors/currentUser';
import { useCellsHelpers } from '@float/common/serena/hooks/useCellsHelpers';
import { useAppSelectorStrict } from '@float/common/store';
import { Person } from '@float/types/person';
import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';
import {
  TimeoffBalanceType,
  TimeoffPreviewMode,
  TimeoffType,
} from '@float/types/timeoffType';

import { usePreviewMode } from './usePreviewMode';
import { useTimeoffPreviewDates } from './useTimeoffPreviewDates';
import { useTotalDays } from './useTotalDays';

const calculateRemainingDays = (
  timeoffBalance: number | null,
  days: number,
) => {
  if (timeoffBalance === null) return null;
  return roundToTwoDecimals(timeoffBalance - days);
};

export type UseTimeoffRemainingDaysBalanceProps = {
  endDate: string;
  endOfPeriod: number | null;
  hoursPd: number;
  ignoreTimeoffId: Timeoff['timeoff_id'] | null | undefined;
  next: number | null;
  personId: Person['people_id'];
  personStartDate?: Person['start_date'];
  repeatEndDate: string | null;
  repeatState: RepeatState;
  startDate: string;
  timeoffType: TimeoffType;
  today: number | null;
};

interface IUseTimeoffRemainingDaysBalance {
  remainingDays: number | null;
  previewMode: TimeoffPreviewMode;
  toggleDateBalance: () => void;
}

export const useTimeoffRemainingDaysBalance = (
  props: UseTimeoffRemainingDaysBalanceProps,
): IUseTimeoffRemainingDaysBalance => {
  const {
    endDate,
    endOfPeriod,
    hoursPd,
    ignoreTimeoffId,
    next,
    personId,
    personStartDate,
    repeatEndDate,
    repeatState,
    startDate,
    timeoffType,
    today,
  } = props;

  const dates = useAppSelectorStrict(selectDatesManager);
  const { cells, helpers } = useCellsHelpers();

  const { previewMode, togglePreviewMode } = usePreviewMode(
    TimeoffPreviewMode.Future,
  );

  const { balance_type: timeoffBalanceType } = timeoffType;
  const previewAccruedEnd =
    repeatState > 0 && repeatEndDate ? repeatEndDate : endDate;

  const { previewDate, previewRepeatEndDate } = useTimeoffPreviewDates({
    endDate,
    personStartDate,
    previewAccruedEnd,
    previewMode,
    timeoffBalanceType,
  });

  const days = useTotalDays({
    cells,
    dates,
    endDate,
    helpers,
    hoursPd,
    ignoreTimeoffId,
    personId,
    previewDate,
    previewRepeatEndDate,
    repeatEndDate,
    repeatState,
    startDate,
    timeoffBalanceType,
  });

  let remainingDays;

  if (timeoffBalanceType === TimeoffBalanceType.Unlimited) {
    remainingDays = null;
  } else {
    let timeoffBalance;

    if (
      timeoffBalanceType === TimeoffBalanceType.AccruedPerYear ||
      timeoffBalanceType === TimeoffBalanceType.AccruedPerAnniversary
    ) {
      timeoffBalance = previewMode === TimeoffPreviewMode.Today ? today : next;
    } else {
      timeoffBalance = endOfPeriod;
    }

    remainingDays = calculateRemainingDays(timeoffBalance, days);
  }

  return { remainingDays, previewMode, toggleDateBalance: togglePreviewMode };
};
