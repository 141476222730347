import { getProductTourConfig } from 'OnboardingManager/components/ProductTour/config';
import { createSelector } from 'reselect';

import { getUser } from '@float/common/selectors/currentUser';
import { getLocationPathname } from '@float/common/selectors/location';
import { PROMPTS } from '@float/constants/onboarding';
import { getFriendlyLocation } from 'components/Nav/helpers';

import { getPromptsUserHasDone } from './common';
import { getPromptDataFrom } from './prompt';

export const getProductTourStatus = createSelector(
  [
    getPromptsUserHasDone,
    getLocationPathname,
    getPromptDataFrom(PROMPTS.productTour),
  ],
  (promptsUserHasDone, pathname, productTourData) => {
    const pageName = getFriendlyLocation(pathname);

    const status = { completed: false, data: productTourData, show: false };

    status.completed = promptsUserHasDone.includes(PROMPTS.productTour);
    status.show = !status.completed && pageName === 'timeline';

    return status;
  },
);

const getProductTourStepsData = () => getProductTourConfig();

export const getProductTourAllStepsData = createSelector(
  [getProductTourStepsData, getUser],
  (productTourStepsData, user) => {
    const data = productTourStepsData.filter((step) => {
      const { isDisabled } = step;

      return !isDisabled || (isDisabled && !isDisabled(user));
    });

    return {
      stepsData: data,
      stepsTotal: data.length - 1, // - 1, because the Welcome step doesn't count
    };
  },
);
