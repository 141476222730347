import React from 'react';
import cx from 'classnames';

import OutsideHandler from '@float/common/components/elements/OutsideHandler';
import { IconArrowDown, IconCheck } from '@float/ui/deprecated/Earhart/Icons';
import { Spacer } from '@float/ui/deprecated/Layout/Layout';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { ListOption } from '../../VirtualSelect/VirtualSelect';
import {
  IconLeft,
  StyledDropdownLabel,
  StyledDropdownLabelText,
  StyledDropdownOption,
  StyledDropdownOptionContent,
  StyledDropdownOptions,
  StyledDropdownSelect,
  StyledIcon,
} from '../styles';

const defaultValue = { label: 'Please select an option', value: '' };

const BottomHoverTooltip = ({ title, children }) => {
  if (!title) {
    return children;
  }

  return (
    <TextTooltip
      className="hint"
      content={title}
      placement="bottom"
      distance={-8}
    >
      {children}
    </TextTooltip>
  );
};

class DropdownSelect extends React.Component {
  state = {
    isOpen: false,
  };

  toggle = (e) => {
    e.preventDefault();

    const { currentTarget } = e;

    this.setState({ isOpen: !this.blockNextOpen && !this.state.isOpen }, () => {
      if (!this.state.isOpen) {
        currentTarget.blur();
      }
    });
  };

  close = () => {
    this.setState({ isOpen: false });

    // Hack around OutsideHandler triggering this and the toggle function
    this.blockNextOpen = setTimeout(() => {
      this.blockNextOpen = null;
    }, 20);
  };

  onChange = (option) => {
    const { onChange, value } = this.props;

    const hasChanged = option.value !== value;
    if (hasChanged && typeof onChange === 'function') {
      onChange(option);
    }

    if (typeof option.onSelect === 'function') {
      option.onSelect();
    }

    this.close();
  };

  renderOption = (opt, i) => {
    const option = { ...opt };

    if (!option || !option.label) {
      return null;
    }

    const { value, tickSelectedOption = false, tickRight = false } = this.props;
    let { icon } = option;
    if (value == option.value && tickSelectedOption) {
      if (tickRight) {
        option.iconRight = <IconCheck />;
      } else {
        icon = <IconCheck />;
      }
    }

    // do not option.value if value is an object or an array
    const key = `dd-sel-${
      typeof option.value !== 'undefined' &&
      typeof option.value !== 'object' &&
      !Array.isArray(option.value)
        ? option.value
        : i
    }`;

    return (
      <StyledDropdownOption
        key={key}
        className={cx(
          option.className,
          {
            'selected-value': value == option.value,
          },
          option.appearance || 'flay',
        )}
        withDivider={option.withDivider}
        color={option.color}
        icon={icon}
        onClick={() => this.onChange(option)}
        role="option"
      >
        <StyledDropdownOptionContent>
          <ListOption
            onClick={(e) => {
              e.stopPropagation();
              this.onChange(option);
            }}
            option={{
              ...option,
              icon: icon,
            }}
          />
        </StyledDropdownOptionContent>
      </StyledDropdownOption>
    );
  };

  render() {
    const {
      appearance,
      value = null,
      options,
      children,
      align,
      distance,
      minSelectWidth,
      className,
      title,
      hideDownIcon = false,
      showDownIconWhenNoValue = false,
      showChildrenAsSelectedLabel = false,
      withIconLeft = false,
      truncateAt,
      iconRight,
    } = this.props;
    let selectedOption = options.find((o) => o.value === value);
    if (!selectedOption || (showChildrenAsSelectedLabel && children)) {
      selectedOption = {
        label: children,
        hideDownIcon: !showDownIconWhenNoValue,
      };
    }
    if (!selectedOption) {
      selectedOption = defaultValue;
    }

    return (
      <StyledDropdownSelect
        $appearance={appearance}
        ref={this.props.forwardedRef}
        className={cx(className)}
        style={this.props.style}
      >
        <BottomHoverTooltip title={title}>
          <StyledDropdownLabel
            className={cx({
              active: this.state.isOpen,
              withIconLeft,
              withIconRight: !selectedOption.hideDownIcon,
            })}
            onClick={this.toggle}
            truncateAt={truncateAt}
            type="button"
            style={this.props.buttonStyle}
          >
            <StyledDropdownLabelText truncateAt={truncateAt}>
              {selectedOption.icon && (
                <>
                  {selectedOption.icon}
                  <Spacer xSize={8} />
                </>
              )}
              {selectedOption.selectedLabel || selectedOption.label}
            </StyledDropdownLabelText>
            {!hideDownIcon && !selectedOption.hideDownIcon && (
              <StyledIcon>
                {iconRight || <IconArrowDown size={20} iconNoScalingStroke />}
              </StyledIcon>
            )}
          </StyledDropdownLabel>
        </BottomHoverTooltip>
        {this.state.isOpen && (
          <OutsideHandler onClickOutside={this.close} style={{ width: '100%' }}>
            <StyledDropdownOptions
              minSelectWidth={minSelectWidth}
              className={align}
              distance={distance || 0}
            >
              {options.map(this.renderOption)}
            </StyledDropdownOptions>
          </OutsideHandler>
        )}
      </StyledDropdownSelect>
    );
  }
}

DropdownSelect.defaultProps = {
  appearance: 'default',
  alignment: 'left',
};

DropdownSelect._styles = {
  StyledDropdownSelect,
  StyledDropdownLabel,
  StyledDropdownLabelText,
  StyledDropdownOptions,
  StyledDropdownOption,
  StyledIcon,
  IconLeft,
};

export default DropdownSelect;
