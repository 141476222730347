import { memoize } from 'proxy-memoize';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';

import { getLocationPathname } from '../location';

export const selectIsLogTimeViewType = (state: ReduxStateStrict) => {
  return Boolean(state?.appInfo?.logTimeView);
};

export const selectScheduleViewType = (state: ReduxStateStrict) => {
  return state?.appInfo?.viewType;
};

export const getIsProjectPlanView = memoize(
  (state: ReduxStateStrict): boolean => {
    return selectScheduleViewType(state) === 'projects';
  },
);

export const getIsLogTimeView = memoize((state: ReduxStateStrict): boolean => {
  const location = getLocationPathname(state);
  const logTimeView = selectIsLogTimeViewType(state);

  if (location === '/log-time') return true;

  return logTimeView && location === '/';
});
