import React from 'react';
import { connect } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { map, noop } from 'lodash';

import { storeNewTag } from '@float/common/actions';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button, Modal, VirtualSelect } from '@float/ui/deprecated';

import { getDefaultValue } from './getDefaultValue';

const dropdownStyle = { marginBottom: 22 };

class BulkEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.fieldOptions = map(this.props.fields, (config, key) => {
      return {
        value: key,
        label: config.label,
      };
    });

    const key = this.fieldOptions[0].value;
    const value = getDefaultValue(key, this.props);
    this.state = {
      key,
      value,
      loading: false,
      confirmTitle: '',
      confirmMessage: '',
      confirmVisible: false,
      confirmFn: noop,
    };
  }

  openConfirm = ({ confirmTitle, confirmMessage, confirmFn }) => {
    this.setState({
      confirmVisible: true,
      confirmTitle,
      confirmMessage,
      confirmFn,
    });
  };

  closeConfirm = () => {
    this.setState({
      confirmVisible: false,
      confirmTitle: '',
      confirmMessage: '',
      confirmFn: noop,
    });
  };

  submit = async (e) => {
    prevent(e);
    const { key } = this.state;
    const { getValue, getFields, onUpdate } = this.el || {};

    const value = getValue ? await getValue() : this.state.value;
    const fields = getFields ? await getFields() : { [key]: value || null };

    const fieldConfig = this.props.fields[key];
    if (fieldConfig.getPayloadFromState) {
      fields[key] = fieldConfig.getPayloadFromState(fields[key]);
    }

    // Add new tag to state
    if (key === 'tags') {
      this.state.value.forEach((val) => {
        if (val.isCreate) {
          this.props.storeNewTag(val.value);
        }
      });
    }

    const confirmData =
      typeof fieldConfig.getConfirmData === 'function'
        ? fieldConfig.getConfirmData({
            ids: this.props.ids,
            entities: this.props.entities,
            fields,
          })
        : null;

    // There is a confirmation data available show a confirm before submitting
    if (confirmData) {
      const { title: confirmTitle, message: confirmMessage } = confirmData;

      this.openConfirm({
        confirmTitle,
        confirmMessage,
        confirmFn: () => {
          this.submitConfirmed({
            fields,
            onUpdate,
          });
        },
      });

      return;
    }

    // There is not confirm data, continue submission without confirm
    this.submitConfirmed({
      fields,
      onUpdate,
    });
  };

  submitConfirmed = async (options) => {
    const { onUpdate, fields } = options;

    this.setState({ loading: true });

    this.props.onUpdate({
      ids: this.props.ids,
      fields,
      updateFn: onUpdate,
    });
  };

  cancel = (e) => {
    prevent(e);
    this.props.onCancel();
  };

  onFieldChange = ({ value: key }) => {
    this.setState({ key, value: getDefaultValue(key, this.props) });
  };

  onValueChange = (value) => {
    this.setState({ value });
  };

  getTypeString = () => {
    const { ids, type } = this.props;
    if (ids.length === 1) {
      return type;
    }

    return this.props.plural || `${type}s`;
  };

  setRef = (el) => {
    this.el = el;
  };

  render() {
    const { ids } = this.props;
    const { key, value } = this.state;

    const ValueComponent = this.props.fields[key]?.render;
    const isFunctionalComponent = typeof ValueComponent === 'function';

    return (
      <>
        <Modal isOpen onClose={this.props.onCancel}>
          <Modal.Header>
            <Modal.Title>
              Edit {ids.length} {this.getTypeString()}
            </Modal.Title>
          </Modal.Header>

          <form onSubmit={this.submit}>
            <Modal.Body>
              <VirtualSelect
                label={t`Field`}
                placeholder={t`Select a field`}
                style={dropdownStyle}
                visibleItems={6}
                autoFocus
                hideClearIcon
                clearInputOnDropdownOpen={false}
                options={this.fieldOptions}
                value={key}
                onChange={this.onFieldChange}
              />
              {ValueComponent && (
                <ValueComponent
                  ref={isFunctionalComponent ? undefined : this.setRef}
                  ids={ids}
                  value={value}
                  onChange={this.onValueChange}
                />
              )}
            </Modal.Body>

            <Modal.Actions>
              <Button type="submit" loader={this.state.loading}>
                <Trans>Update</Trans>
              </Button>
              <Button appearance="secondary" onClick={this.cancel}>
                <Trans>Cancel</Trans>
              </Button>
            </Modal.Actions>
          </form>
        </Modal>
        <Modal isOpen={this.state.confirmVisible} onClose={this.closeConfirm}>
          <Modal.Header>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Actions>
            <Button
              appearance="primary"
              onClick={() => {
                this.state.confirmFn();
                this.closeConfirm();
              }}
            >
              <Trans>Confirm</Trans>
            </Button>
            <Button appearance="secondary" onClick={this.closeConfirm}>
              <Trans>Cancel</Trans>
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeNewTag: (tag) => dispatch(storeNewTag(tag)),
});

export default connect(null, mapDispatchToProps)(BulkEditModal);
