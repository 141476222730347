import {
  SearchResolveFilter,
  SearchResolveQuery,
} from '@float/common/api3/search';

// Aggregate all the filters into a resolve-compatible query
export function groupFiltersByLogicalOperator(
  resolvedFilters: {
    logicalOperator: 'or' | 'and';
    filters: SearchResolveFilter[] | null;
  }[],
) {
  let query: SearchResolveQuery = {
    filters: [],
    logicalOperator: 'or',
  };

  let currentQuery: SearchResolveQuery = {
    filters: [],
    logicalOperator: 'and',
  };

  // Aggregate all the filters using by logical operators
  for (const { filters, logicalOperator } of resolvedFilters) {
    if (filters === null) continue;

    // When encountering an "or" start a new query
    if (logicalOperator === 'or' && currentQuery.filters.length > 0) {
      query.filters.push(currentQuery);
      currentQuery = {
        filters: [],
        logicalOperator: 'and',
      };
    }

    // Concatenate the resolved filters to the current query
    // using the "and" logical operator
    currentQuery.filters.push(...filters);
  }

  // If it is the only query (no or operators found)
  // use it as root to compact the request
  if (query.filters.length === 0) {
    query = currentQuery;
  } else {
    query.filters.push(currentQuery);
  }

  return query;
}
