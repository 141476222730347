import { parseBudgetString } from '@float/common/lib/budget/helpers/parseBugdetString';
import { RawTaskMeta, TaskMeta } from '@float/types/task';

export function getProjectTasksFromTemplateTasks(
  taskMetas: RawTaskMeta[] | undefined,
): TaskMeta[] {
  if (!taskMetas?.length) return [];

  const result: TaskMeta[] = [];
  for (const taskMeta of taskMetas) {
    if (taskMeta.task_name) {
      result.push({
        ...taskMeta,
        task_meta_id: 0,
        budget: parseBudgetString(taskMeta.budget),
      });
    }
  }

  return result;
}
