import { RawTask, RawTimeoff, Status, Task, Timeoff } from '@float/types';

import { removeEmoji } from './emoji';

export const formatTaskPriority = (task: RawTask) => {
  const peopleId =
    task.people_id || (task.people_ids?.length === 1 && task.people_ids[0]);

  if (task.priority && peopleId) {
    const nextPriority = {
      [peopleId]: task.priority!,
    };

    return nextPriority;
  }

  return task.priority_info || {};
};

export const formatTask = (task: RawTask, formattedId?: string): Task => {
  const name = removeEmoji(task.name || task.task_name || '');

  const result: Task = {
    action: task.action,
    billable: task.billable ? 1 : 0,
    created_by: task.created_by,
    created: task.created,
    end_date: task.end_date,
    ext_calendar_event_id:
      task.ext_calendar_event_id && String(task.ext_calendar_event_id),
    ext_calendar_event_name: task.ext_calendar_event_name,
    ext_calendar_id: task.ext_calendar_id && String(task.ext_calendar_id),
    ext_calendar_recur_id:
      task.ext_calendar_recur_id && String(task.ext_calendar_recur_id),
    hours: task.hours,
    integration_status: Number(task.integration_status || 0),
    modified_by: task.modified_by,
    modified: task.modified,
    name,
    notes_meta: task.notes_meta || null,
    notes: task.notes || task.task_notes || '',
    parent_task_id: task.parent_task_id && String(task.parent_task_id),
    people_ids: task.people_id
      ? [task.people_id]
      : task.people_ids?.sort() || [],
    phase_id: Number(task.phase_id || 0),
    priority_info: formatTaskPriority(task),
    project_id: task.project_id,
    repeat_end_date: task.repeat_end || task.repeat_end_date || null,
    repeat_state: task.repeat_state
      ? (+task.repeat_state as Task['repeat_state'])
      : 0,
    root_task_id: task.root_task_id && String(task.root_task_id),
    start_date: task.start_date,
    start_time: task.start_time ? task.start_time.substring(0, 5) : '',
    status: task.status,
    task_id: formattedId || String(task.task_id),
    task_meta_id: task.task_meta_id,

    data_type: 'full',

    // metadata that shouldn't be here
    instanceCount: 0,
  };

  // Backward compatibility - remove this once we are sure of what's inside Task
  for (const key of Object.keys(task)) {
    if (key !== 'people_id' && key !== 'priority') {
      if (!(key in result)) {
        // @ts-expect-error
        result[key] = task[key];
      }
    }
  }

  return result;
};

export const formatTimeoff = (
  timeoff: RawTimeoff | Timeoff,
  formattedId?: string,
): Timeoff => {
  const timeoffId =
    typeof formattedId !== 'undefined' ? +formattedId : timeoff.timeoff_id;

  return {
    ...timeoff,
    people_ids: timeoff.people_ids || [],
    data_type: 'full',
    // @ts-expect-error RawTimeoff could have a string timeoffid when the id is temporary
    // https://linear.app/float-com/issue/PSQ1-364/replace-createdts-with-a-more-simple-id-generator
    timeoff_id: timeoffId,
    hours: timeoff.full_day ? null : timeoff.hours,
    start_time: timeoff.start_time ? timeoff.start_time.substring(0, 5) : '',
    repeat_end_date:
      'repeat_end' in timeoff ? timeoff.repeat_end : timeoff.repeat_end_date,
  };
};

export type RawStatus = Status & { repeat_end?: string };

export const formatStatus = (status: RawStatus) => {
  if (status.repeat_end) {
    const { repeat_end, ...rest } = status;

    rest.repeat_end_date = repeat_end;

    return rest;
  }

  return status;
};

export type RawCustomHoliday = {
  holiday_id: string | number;
  holiday_name: string;
  date: string;
  end_date: string;
};

export const mapHolidayV1ToV3 = (holiday: RawCustomHoliday | undefined) =>
  holiday
    ? {
        holiday_id: +holiday.holiday_id,
        name: holiday.holiday_name,
        date: holiday.date,
        end_date: holiday.end_date,
      }
    : null;

export const formatTaskId = (item: { task_id: string | number }) => {
  return item.task_id.toString().split('_')[0];
};

export const formatTimeoffId = (item: { timeoff_id: number | string }) => {
  return item.timeoff_id.toString().split('_')[0];
};

export const toNumberList = (values?: (string | number)[]) => {
  if (!values?.length) return [];
  return values.filter((x) => x).map((x) => Number(x));
};
