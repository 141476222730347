import { userAgent } from '@float/libs/web/detect';

export const gridBlocks = {};
// const MYSCHEDULE_HEIGHT = 1400;
// const SCHEDULE_HEIGHT = 400;
// const DOMURL = window.URL || window.webkitURL || window;

export default {
  removeRanges() {
    if (document.getSelection) {
      document.getSelection().removeAllRanges();
    }
  },
  ready() {
    return new Promise((resolve) => {
      const onLoad = () => {
        resolve();
        document.removeEventListener('DOMContentLoaded', onLoad, false);
      };

      document.addEventListener('DOMContentLoaded', onLoad, false);
    });
  },
  download(uri, name) {
    if (userAgent !== 'ie') {
      if (!this.link) {
        this.link = document.createElement('a');
      }

      this.link.download = name;
      this.link.href = uri;
      document.body.appendChild(this.link);
      this.link.click();
      document.body.removeChild(this.link);
    } else {
      location.replace(uri);
    }
  },
};
