import { toLinkFragment, toTextFragment } from './fragments';
import { getLinks } from './get-links';
import { hasLink } from './has-link';

/**
 * Parse a text string, identify links and return in a Slate.js nodes format
 * @param {string} text text value
 * @returns {array|string} Returns an array of children fragments or the text input as a string if untouched
 */

const toNodesFrom = (text) => {
  if (text && hasLink(text)) {
    const links = getLinks(text);

    let prevIndex = 0;
    const pasteLength = text.length;

    const children = [];

    links.forEach((link) => {
      const index = text.indexOf(link.value, prevIndex);
      const lastIndex = index + link.value.length;

      const prevText = text.slice(prevIndex, index);

      if (prevText.length) {
        children.push(toTextFragment(prevText));
      }

      children.push(toLinkFragment(link));
      prevIndex = lastIndex;
    });

    const nextText = text.slice(prevIndex, pasteLength);

    if (nextText.length) {
      children.push(toTextFragment(nextText));
    }

    if (children.length > 0) {
      return children;
    }

    return [{ text }];
  }

  return [{ text: text || '' }];
};

export { toNodesFrom };
