import React from 'react';
import { t } from '@lingui/macro';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { ProjectStatus } from '@float/types/project';
import { SVGIconComponent } from '@float/ui/primitives/SVGIcon';

import {
  IconStatusConfirmed,
  IconStatusDraft,
  IconStatusTentative,
} from '../../panelViews/Project/components/IconStatus';

import * as styles from './styles.css';

type NewProjectCardsProps = {
  handleCreateProject: (status: ProjectStatus) => void;
};

type NewProjectCardProps = {
  icon: SVGIconComponent;
  title: string;
  description: string;
  onClick: () => void;
};

function NewProjectCard(props: NewProjectCardProps) {
  return (
    <div className={styles.newProjectCard} onClick={props.onClick}>
      {React.createElement(props.icon)}
      <div className={styles.newProjectCardContent}>
        <h2 className={styles.newProjectCardTitle}>{props.title}</h2>
        <p className={styles.newProjectCardDescription}>{props.description}</p>
      </div>
    </div>
  );
}

export function NewProjectCards({ handleCreateProject }: NewProjectCardsProps) {
  return (
    <div className={styles.newProjectCards}>
      {featureFlags.isFeatureEnabled(FeatureFlag.DraftStatus) && (
        <NewProjectCard
          icon={IconStatusDraft}
          title={t`Draft`}
          description={t`Admins and account owners can view and edit`}
          onClick={() => handleCreateProject(ProjectStatus.Draft)}
        />
      )}
      <NewProjectCard
        icon={IconStatusTentative}
        title={t`Tentative`}
        description={t`All team members can view but are not notified`}
        onClick={() => handleCreateProject(ProjectStatus.Tentative)}
      />
      <NewProjectCard
        icon={IconStatusConfirmed}
        title={t`Confirmed`}
        description={t`Team members can view and receive notifications`}
        onClick={() => handleCreateProject(ProjectStatus.Confirmed)}
      />
    </div>
  );
}
