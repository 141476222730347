import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import Flip from 'gsap/Flip';

import {
  StyledHr,
  StyledIconKebabDots,
  StyledName,
  StyledProjectTask,
} from './styles';

export const ProjectTask = (props) => {
  const { className, task } = props;

  const hrRef = useRef();
  const nameRef = useRef();
  const kebabDotsRef = useRef();

  const [withName, setWithName] = useState(!!task?.data.name);

  useEffect(() => {
    setWithName(!!task?.data.name);
  }, [task]);

  useEffect(() => {
    const name = nameRef.current;
    const hr = hrRef.current;
    const icon = kebabDotsRef.current;

    const ease = 'power3.inOut';

    if (withName) {
      const state = Flip.getState(hr);

      hr.style.width = '12px';

      Flip.from(state, { duration: 0.35, ease });

      gsap.set(name, { opacity: 0, x: -5 });
      gsap.set(icon, { x: 50 });

      gsap.to(name, { opacity: 1, x: 0, duration: 0.35, delay: 0.2, ease });
      gsap.to(icon, { x: 0, duration: 0.35, delay: 0.2, ease });
    } else {
      const state = Flip.getState(hr);

      hr.style.width = '100%';

      Flip.from(state, { duration: 0.35, ease });

      gsap.to(name, { opacity: 0, duration: 0.35, ease });
      gsap.to(icon, { x: 50, duration: 0.35, ease });
    }
  }, [withName]);

  return (
    <StyledProjectTask className={className}>
      <StyledHr $primary={withName} ref={hrRef} />
      <StyledName ref={nameRef}>
        {task && !!task.data.name && task.data.name}
      </StyledName>
      <StyledIconKebabDots ref={kebabDotsRef} />
    </StyledProjectTask>
  );
};
