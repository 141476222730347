import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

// When the dates are updated via schedule actions or wherever in the app, update them in the form
export const useUpdateDates = (props: {
  watchStartDate?: string | null;
  watchEndDate?: string | null;
  startKey: string;
  endKey: string;
}) => {
  const { getValues, setValue } = useFormContext();
  useEffect(() => {
    const startDate = getValues(props.startKey);
    if (props.watchStartDate && props.watchStartDate !== startDate) {
      setValue(props.startKey, props.watchStartDate, {
        shouldDirty: false,
        shouldValidate: false,
        shouldTouch: false,
      });
    }
    const endDate = getValues(props.endKey);
    if (props.watchEndDate && props.watchEndDate !== endDate) {
      setValue(props.endKey, props.watchEndDate, {
        shouldDirty: false,
        shouldValidate: false,
        shouldTouch: false,
      });
    }
    // We only need to compare the date values when the watch values change.
    // The form values are obtained within the hook and getValue is run outside
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.watchStartDate, props.watchEndDate]);
};
