import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';

import { Person } from '@float/types/person';
import { useModalControls } from '@float/ui/deprecated/Modal/hooks';
import { IconCloseCircle } from '@float/ui/icons/essentials/IconCloseCircle';
import { IconFilter } from '@float/ui/icons/essentials/IconFilter';
import { IconFolderUser } from '@float/ui/icons/essentials/IconFolderUser';
import { IconSwitch } from '@float/ui/icons/essentials/IconSwitch';
import { IconUserCircle } from '@float/ui/icons/essentials/IconUserCircle';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';

import { SidePanelMeatballMenu } from '../../components/SidePanelMeatballMenu';
import { useFieldArrayContext } from '../../hooks/useFieldArrayContext';
import { useIsPhasePanel } from '../../hooks/useIsPhasePanel';
import { useIsTemplate } from '../../hooks/useIsTemplate';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';
import { useProjectScheduleFilter } from '../../hooks/useProjectScheduleFilter';
import { useSetPersonDefaultHourlyRate } from '../../hooks/useSetPersonDefaultHourlyRate';
import { ProjectFormData } from '../../types';
import {
  useCheckPersonAllocation,
  useShowPersonModal,
  useSwitchPersonModal,
} from './ProjectTeamSection.hooks';

type ProjectTeamMemberMenuProps = {
  index: number;
  person: Person | null;
};

export const ProjectTeamMemberMenu: React.FC<ProjectTeamMemberMenuProps> = ({
  index,
  person,
}) => {
  const showPersonModal = useShowPersonModal(person);
  const hasAllocations = useCheckPersonAllocation(person?.people_id);
  const { clearErrors, trigger, setValue } = useFormContext<ProjectFormData>();
  const isPhasePanel = useIsPhasePanel();
  const isTemplate = useIsTemplate();
  const isActive = useProjectOrPhaseFieldValue('active');
  const phaseId = useWatch<ProjectFormData, 'phase.phase_id'>({
    name: `phase.phase_id`,
  });
  const projectId = useWatch<ProjectFormData, 'projectId'>({
    name: `projectId`,
  });
  const isAssignedToPhaseOnly = useWatch<ProjectFormData>({
    name: `team.${index}.isAssignedToPhaseOnly`,
  });
  const { remove } = useFieldArrayContext();
  const showSwitchPersonModal = useSwitchPersonModal(person, index);
  const { setPersonDefaultHourlyRate } = useSetPersonDefaultHourlyRate();
  const isCreate = isPhasePanel ? !phaseId : !projectId;
  const parentField = isPhasePanel ? 'phase' : 'project';

  const onRemove = () => {
    remove(index);
    clearErrors('team');
    trigger('team');
  };
  const deleteModal = useModalControls();
  const showOnSchedule = useProjectScheduleFilter({
    key: 'person',
    value: person?.name,
  });
  const addToProjectTeam = () => {
    setValue(`team.${index}.isAssignedToPhaseOnly`, false);
    if (typeof person?.people_id === 'number') {
      setPersonDefaultHourlyRate(index, person.people_id);
    }
  };

  const items = [
    {
      value: t`View profile`,
      onClick: showPersonModal,
      icon: IconUserCircle,
    },
    {
      value: t`Switch person`,
      onClick: showSwitchPersonModal,
      hide: !isActive || isCreate || isTemplate || isPhasePanel,
      icon: IconSwitch,
    },
    {
      value: t`View using filter`,
      onClick: showOnSchedule,
      disabled: isCreate,
      hide: isTemplate,
      icon: IconFilter,
    },
    {
      value: t`Grant project level access`,
      onClick: addToProjectTeam,
      hide: !isAssignedToPhaseOnly,
      icon: IconFolderUser,
    },
    {
      value: t`Remove from ${parentField}`,
      onClick: hasAllocations ? deleteModal.openModal : onRemove,
      disabled: !isActive,
      icon: IconCloseCircle,
    },
  ].filter((item) => !item.hide);
  return (
    <React.Fragment>
      <SidePanelMeatballMenu
        disabled={!person}
        vertical
        items={items}
        trigger={{
          label: t`Team member options`,
        }}
      />
      {deleteModal.isModalVisible && (
        <ConfirmDeleteModal
          onCancel={deleteModal.closeModal}
          onDelete={onRemove}
          title={t`Remove from ${parentField}`}
          deleteLabel={t`Remove`}
          message={
            <React.Fragment>
              <strong>
                <Trans>If you remove {person?.name}</Trans>
              </strong>
              <p>
                &nbsp;&bull;&nbsp;
                <Trans>
                  All of their scheduled and logged tasks for this {parentField}{' '}
                  will be deleted
                </Trans>
              </p>
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};
