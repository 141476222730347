import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconBeach = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.75 18.25s1.75-3.5 6.25-3.5 6.25 3.5 6.25 3.5M13 14.5S11.5 10 15 7m0 0s-4.25 1.25-6.25 3.25M15 7s3 1 3 4m-3-4s-2.05-1.25-6.25-1.25M15 7s2.25-1.25 4.25-1.25"
      />
    </SVGIcon>
  );
};

export { IconBeach };
