import { datadogRum } from '@datadog/browser-rum';

import { config } from '@float/libs/config';

export function initDatadogRum() {
  datadogRum.init({
    applicationId: '5fa94314-283d-401d-b914-2f03095b36c3',
    clientToken: 'pubc10c15d1e8ff001dd03de6945ebc5297',
    site: 'datadoghq.com',
    service: 'float-web-app',
    env: config.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.BUILD_VERSION,
    sessionSampleRate: 15,
    // We don't want to use the session replay for now
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
