import styled, { css } from 'styled-components';

import { activityItemTitleWithComponents } from '@float/common/activity';

const TopTitle = styled.div`
  display: block;
  color: ${(p) => p.theme.charcoalGrey};
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
`;

const ActionLabel = styled.span`
  color: #5d6367;
  ${({ complete }) =>
    complete &&
    css`
      color: #14d9bf;
      font-weight: 700;
    `};
`;

const StrongLabel = styled.strong``;
const Text = styled.span``;

export default activityItemTitleWithComponents({
  TopTitle,
  ActionLabel,
  StrongLabel,
  Text,
});
