import mitt from 'mitt';

import { fastObjectSpread } from '@float/common/lib/fast-object-spread';
import { CellsMap, CellsMapApi, CellsMapData } from '@float/types';

import { now } from '../../util/timer';

function getCellsMap() {
  const staleCells = new Map<string | symbol, () => void>();
  const keys = new Set<string | symbol>();

  function getCell(key: string | symbol, revalidateIfStale = true) {
    if (revalidateIfStale) {
      const rebuildCell = staleCells.get(key);

      if (rebuildCell) {
        staleCells.delete(key);
        rebuildCell();
      }
    }

    return cellsData.get(key);
  }

  function markAsStale(key: string | symbol, rebuildCell: () => void) {
    // Add the key to the map keys, this way Object.keys will include the
    // keys of the cells that have not been built yet
    keys.add(key);
    staleCells.set(key, rebuildCell);
  }

  const heightsUpdatesEmitter = mitt<{ heightChange: number }>();

  function subscribeToHeightsUpdate(
    listener: (heightsUpdatedAt: number) => void,
  ) {
    heightsUpdatesEmitter.on('heightChange', listener);

    return () => {
      heightsUpdatesEmitter.off('heightChange', listener);
    };
  }

  function emitHeightsUpdate(heightsUpdatedAt: number) {
    heightsUpdatesEmitter.emit('heightChange', heightsUpdatedAt);
  }

  const cells: CellsMap = fastObjectSpread({
    _allLoaded: false,
    _heights: Object.create(null),
    _heightsResetCount: 0,
    _heightsUpdatedAt: now(),
    _helperData: {
      projects: Object.create(null),
      milestones: Object.create(null),
      phases: Object.create(null),
      timeoffTypes: Object.create(null),
      people: Object.create(null),
      timersList: [],
      byProject: {
        milestones: Object.create(null),
        phases: Object.create(null),
      },
    },
    _helpers: {},
    _lastUpdatedAt: now(),
    _lastUpdatedInsightsSourceAt: undefined,
    _loadedTypes: Object.create(null),
    _projectRowSortData: Object.create(null),
    _markAsStale: markAsStale,
    _subscribeToHeightsUpdate: subscribeToHeightsUpdate,
    _emitHeightsUpdate: emitHeightsUpdate,
    /**
     * We expose the cells value getter to handle the use cases
     * where we want to access a cell without triggering a revalidation
     */
    _getCell: getCell,
    _getCellKeys: () => keys,
  });

  const cellsMapKeys = new Set<string | symbol>(Object.keys(cells));

  function isCellApiKey(prop: string | symbol): prop is keyof CellsMapApi {
    return cellsMapKeys.has(prop);
  }

  const cellsData = new Map<
    string | symbol,
    CellsMapData[keyof CellsMapData]
  >();

  /**
   * Using a proxy to be able to intercept the cells access
   * and revalidate the value if stale
   *
   * With this we can mark the cells as stale instead of building them
   * directly, and build only the cells that are really required for the
   * app to work properly
   */
  return new Proxy(cells, {
    has(_, prop) {
      return cellsData.has(prop) || isCellApiKey(prop);
    },

    get(cells, prop) {
      if (isCellApiKey(prop)) {
        return cells[prop];
      }

      return getCell(prop, true);
    },

    set(cells, prop, value) {
      if (isCellApiKey(prop)) {
        // @ts-expect-error This kind of dynamic assignement is not ts friendly
        cells[prop] = value;

        return true;
      }

      keys.add(prop);
      staleCells.delete(prop); // If we are writing a cell value the cell is no more stale
      cellsData.set(prop, value);

      return true;
    },

    ownKeys() {
      return Array.from(keys.values());
    },

    deleteProperty(_, prop) {
      cellsData.delete(prop);

      return true;
    },

    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true,
      };
    },
  });
}

export function getCellsInitialState() {
  return {
    cells: getCellsMap(),
    pendingChanges: [],
    changes: [],
  };
}
