import React from 'react';
import { Plural, Trans } from '@lingui/macro';

import { Core } from '@float/common/earhart/colors';
import { DatePicker, FieldLabel, Icons, Input } from '@float/ui/deprecated';
import { IconChevronRight } from '@float/ui/icons/essentials/IconChevronRight';

import { useInputAllocationDateRange } from './hooks/useInputAllocationDateRange';

import * as styles from './InputAllocationDateRange.css';

export type InputAllocationDateRangeProps = {
  startDate: Date;
  endDate: Date;
  numberOfAllocationDaysInDateRange: number;
  isReadOnly: boolean;
  isFixedEndDate?: boolean;
  getIsDateDisabled: (date: Moment) => boolean;
  onChange: (data: { startDate: Date; endDate: Date }) => void;
  onChangeIsFixedEndDate?: (value: boolean) => void;
};

export const InputAllocationDateRange = (
  props: InputAllocationDateRangeProps,
) => {
  const {
    startDate,
    endDate,
    numberOfAllocationDaysInDateRange,
    isReadOnly,
    isFixedEndDate,
    getIsDateDisabled,
    onChange,
    onChangeIsFixedEndDate,
  } = props;

  const {
    startDateMoment,
    startDateFormatted,
    endDateMoment,
    endDateFormatted,
    handleStartDateChange,
    handleEndDateChange,
  } = useInputAllocationDateRange({
    startDate,
    endDate,
    onChange,
  });

  const isFixedEndDateToggleDisabled = numberOfAllocationDaysInDateRange <= 1;

  return (
    <div>
      <FieldLabel>
        <Trans>Duration:</Trans>{' '}
        <Plural
          value={numberOfAllocationDaysInDateRange}
          one="# day"
          other="# days"
        />
      </FieldLabel>
      <div className={styles.fieldDateRange}>
        {isReadOnly && (
          <>
            <Input
              className={styles.inputReadOnly}
              value={startDateFormatted}
              appearance="underline"
              readOnly
              noBorder
            />
            <div className={styles.dateRangeArrowIconWrapper}>
              <Icons.Right color={Core.Text.Secondary} />
            </div>
            <Input
              className={styles.inputReadOnly}
              value={endDateFormatted}
              appearance="underline"
              readOnly
              noBorder
            />
          </>
        )}

        {!isReadOnly && (
          <>
            <DatePicker
              ariaLabel={'startDate'}
              disableDayFn={getIsDateDisabled}
              height={40}
              inputWidth={120}
              value={startDateMoment}
              onChange={handleStartDateChange}
            />

            <div className={styles.dateRangeArrowIconWrapper}>
              <IconChevronRight
                size={32}
                color={Core.Text.Secondary}
                noScalingStroke={true}
              />
            </div>

            <DatePicker
              ariaLabel={'endDate'}
              disableDayFn={getIsDateDisabled}
              height={40}
              inputWidth={120}
              value={endDateMoment}
              minimumDate={startDateMoment}
              onChange={handleEndDateChange}
              fixedEndDateToggleIsDisabled={isFixedEndDateToggleDisabled}
              fixedEndDateToggleValue={isFixedEndDate}
              onFixedEndDateToggleValueChange={onChangeIsFixedEndDate}
            />
          </>
        )}
      </div>
    </div>
  );
};
