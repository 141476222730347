import React from 'react';
import styled, { css } from 'styled-components';

import AvatarImage from '@float/common/components/Avatar/AvatarImage';
import { Col, DropdownSelect, EH, Flex, Row } from '@float/ui/deprecated';
import { StyledDropdownLabelText } from '@float/ui/deprecated/DropdownSelect/styles';
import { createTextColorCSS } from '@float/ui/deprecated/Earhart';

import { ActiveLink, StyledLink } from './NavLink';

export const { StyledDropdownOptions, StyledDropdownLabel, IconLeft } =
  DropdownSelect._styles;

export const StyledDropdownOption = styled(
  DropdownSelect._styles.StyledDropdownOption,
)`
  font-weight: 400;
  min-height: 30px;
  ${(p) => p.color && `color: ${p.color};`}

  a, a:link, a:visited {
    min-height: 30px;
  }
`;

export const StyledSearch = styled.div`
  position: relative;
  height: 36px;
  width: 100%;

  &,
  * {
    box-sizing: border-box;
  }

  ${({ isTokenSearch }) =>
    isTokenSearch &&
    css`
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
      border: 0;
      border-radius: 4px;

      &,
      & input {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.1px;
      }

      & input {
        border-radius: 0 4px 4px 0;
      }

      & input:focus {
        outline: 0;
      }

      & .token-search-wrap {
        margin-left: 0;
        padding-left: 0;
        align-items: center;
      }

      & label {
        display: flex;
        align-items: center;
      }

      & .field-options button {
        padding: 0 !important;
        border: 0 !important;
        display: flex;
        align-items: center;
      }
    `}

  .token-search__flt-icon-search {
    margin: -4px;
    flex-grow: 0;
    justify-content: center;
    .flt-icon {
      margin: 0;
    }
    svg {
      margin-left: 0;
    }
  }

  .clear-search {
    cursor: pointer;
  }

  .filters-action {
    display: flex;

    border-radius: 6px;

    path {
      stroke: ${EH.Colors.Core.Icon.Default};
    }

    &:hover {
      background-color: ${EH.Colors.Core.ActionFlay.Hovered};
    }

    &:active {
      background-color: ${EH.Colors.Core.ActionFlay.Pressed};
    }
  }

  #search-value::-webkit-input-placeholder {
    color: ${(p) => p.theme.emphasisLow};
    ${(p) => p.theme.antialias}
  }
`;

export const StyledAddOption = styled(StyledDropdownOption)`
  min-width: 150px;
  box-sizing: border-box;

  i {
    font-size: 12px;
    font-style: normal;
    margin-left: auto;
  }
`;

export const StyledNavLinks = styled.div`
  display: flex;
  height: 100%;

  ${(p) =>
    p.isSharedLink &&
    css`
      ${StyledLink} {
        color: ${p.theme.midGrey} !important;

        &:hover {
          color: #fff !important;
        }
      }

      ${ActiveLink} {
        color: #fff !important;

        &:after {
          background-color: #fff;
        }
      }
    `}
`;

export const StyledNavRight = styled.div`
  display: flex;
  height: 60px;
  align-items: center;

  .mobile-menu-button {
    display: none;
  }

  @media screen and (max-width: 1010px) {
    height: 42px;
    width: 100%;
    margin-left: 0;

    .mobile-menu-button {
      display: block;
    }
  }
`;

export const StyledHelpOption = styled(StyledDropdownOption)`
  line-height: 1.43;
  box-sizing: border-box;
  max-width: 300px;
  padding: 15px 88px 15px 15px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.stroke2};
  @media all and (-ms-high-contrast: none) {
    display: block;

    .title {
      flex-basis: auto;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .title {
    color: ${(p) => p.theme.charcoalGrey};
    font-size: 16px;
    flex-basis: 100%;
    font-weight: 400;
  }
  .subtitle {
    color: ${(p) => p.theme.blueGrey};
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
  }

  svg {
    position: absolute;
    top: 29px;
    right: 17px;
  }

  &.webinars svg {
    top: 32px;
  }

  &.chat svg {
    top: 32px;
    right: 19px;
  }
`;

export const StyledNavIcon = styled.div`
  cursor: pointer;
  padding: 0 10px;
  margin-right: 2px;
  height: 100%;
  display: flex;
  align-items: center;
  outline: none;
  position: relative;

  &:hover:not(.no-hover) {
    path {
      fill: #fff;
    }
  }

  &.nav-avatar {
    line-height: 24px;
    left: -3px;
    margin-right: 3px;
  }

  &.nav-avatar > *:first-child {
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.blue};
    box-sizing: content-box;

    &:hover {
      border-color: #fff;
    }
  }

  @media screen and (max-width: 1010px) {
    display: none;
  }
`;

export const StyledNavIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  outline: none;

  &.tippy-active {
    pointer-events: none;

    path {
      fill: #fff;
    }

    .nav-avatar > *:first-child {
      border-color: #fff;
    }
  }
`;

export const StyledCompanyName = styled.div`
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCompanySelector = styled.strong`
  font-weight: normal;
  height: 100%;

  & > div {
    height: 100%;
  }

  a {
    color: #fff;
  }

  ${StyledDropdownLabel} {
    position: relative;
    top: 1px;
    height: 100%;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    outline: none;

    @media screen and (max-width: 875px) {
      top: 0;
    }
  }

  ${StyledDropdownOption} {
    color: ${(p) => p.theme.charcoalGrey};

    &.selected-value {
      flex-direction: column;
      align-items: flex-start;
      color: ${(p) => p.theme.blueGrey};
      background: #fff !important;
      cursor: default !important;
      height: auto;

      & > div {
        min-height: 40px;
        align-items: center;
        display: flex;
        width: 100%;
        box-sizing: border-box;
      }
    }

    &.border-top {
      border-top: 1px solid ${(p) => p.theme.lightGrey};
    }
  }

  @media screen and (max-width: 1010px) {
    ${StyledDropdownOptions} {
      bottom: 10px;
      left: 0;
      right: auto;
    }
  }
`;

export const StyledNav = styled.nav`
  position: sticky;
  z-index: 3;
  color: #fff;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  min-height: 60px;
  padding: 16px 18px;
  padding-left: 8px;

  .nav-content {
    height: 30px;
  }

  &.timeline {
    // We need to let some elements from app body to overflow over the top-nav.
    // We can achieve this by making top-nav background transparent
    // which ensures that the underneath overflowing elements are visible.
    // Now another issue appears, a portion of these elements become unclickable.
    // We mitigate the issue by letting mouse events pass throught main-nav empty space
    // Which ensure that they will be captured by the underneath overflowing elements.
    background: transparent;
    pointer-events: none;
    .nav-content {
      pointer-events: all;
    }
  }

  &.log-time {
    .nav-content.left {
      margin-right: 50px;
    }
  }

  @media (max-width: 500px) {
    align-items: flex-start;
    padding: 0px 10px;
    padding-top: 10px;
    margin-bottom: 1px;
  }

  &,
  & * {
    box-sizing: border-box;
  }
`;

export const StyledCompanySettings = styled(StyledDropdownOption)`
  padding-left: 40px;
  color: ${(p) => p.theme.charcoalGrey};
  margin: 0 0 0 -15px;
  width: calc(100% + 30px) !important;
  letter-spacing: -0.3px;
  font-size: 16px;
  font-weight: normal;
  border-bottom: 1px solid ${(p) => p.theme.lightGrey};
`;

export const StyledMobileNav = styled.div`
  display: none;
  align-items: center;
  margin-left: auto;
  padding-right: 10px;
  outline: none;
  cursor: pointer;

  @media screen and (max-width: 1010px) {
    display: flex;
  }
`;

export const StyledDropdownHeader = styled(StyledDropdownOption)`
  box-sizing: border-box;
  color: ${(p) => p.theme.blueGrey};
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  min-height: 0;
  padding-top: 15px;
  padding-bottom: 7px;
  cursor: default;
  width: 100%;
  justify-content: space-between;

  &:hover {
    background-color: inherit;
  }
`;

const _StyledDropdownDivider = styled(StyledDropdownOption)`
  margin-top: 13px;
  margin-bottom: 0;
  min-height: 0;
  border-top: 1px solid ${(p) => p.theme.grey};
`;

export const StyledDropdownDivider = (props) => (
  <_StyledDropdownDivider as="hr" {...props} />
);

export const Title = styled.div`
  text-align: center;
  margin-right: -25px;
  flex-grow: 1;
  letter-spacing: 0.25px;
  font-size: 20px;
  font-weight: 400;

  @media screen and (min-width: 1011px) {
    display: none;
  }
`;

export const StyledUpgrade = styled.a`
  background-color: ${(p) => p.theme.blue};
  color: #fff !important;
  height: 20px;
  min-width: 65px;
  border-radius: 2px;
  display: flex;
  flex-basis: 0 !important;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  min-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;

  &:hover {
    background-color: ${(p) => p.theme.darkBlue};
  }
`;

export const StyledTeamHeader = styled.span`
  flex-grow: 1;
  text-align: left;
`;

export const SidebarButtonGroup = styled(Col)`
  width: 100%;
  box-sizing: border-box;

  [data-product-tour-id='navigation-desktop'] {
    // make sure hint tooltip aligns to the middle
    a {
      display: block;
    }
  }
`;

export const SidebarNavControlGroup = styled(Row)`
  padding: 0px 18px;
  box-sizing: border-box;
`;

export const SideNavCol = styled(Col)`
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  background: ${EH.Colors.Core.SurfaceBackground.Default};
  height: calc(var(--screen-height) - var(--app-margin-top, 0px));
  padding: 16px 8px 16px;
  min-width: 68px;
  max-width: 68px;
  width: 68px;
  z-index: 10;

  &.mobile {
    position: absolute;
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    height: 100vh;
    background: ${({ theme }) => theme.white};
    top: -1px;
    left: -1px;
    padding: 0px;
    border-right: none;
    box-shadow:
      0px -7px 12px rgba(165, 159, 190, 0.06),
      2px 9px 18px rgba(9, 30, 66, 0.1),
      0px 0px 1px rgba(9, 30, 66, 0.15);
    transform: translateX(-350px);
    transition: transform 0.2s ease-out;

    &.open {
      transform: translateX(0px);
    }

    ${SidebarButtonGroup} {
      padding: 20px;
    }
  }

  @media print {
    display: none;
  }
`;

export const MenuSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  &.title {
    color: ${({ theme }) => theme.blueGrey};
    justify-content: space-between;
    align-items: center;
    margin-top: 8px !important;
    margin-bottom: 14px !important;
    font-size: 14px;
    line-height: 13px;
  }
`;

export const MenuSectionRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 20px;
  min-height: 40px;
  box-sizing: border-box;

  ${createTextColorCSS(EH.Colors.Core.Text.Default)};
`;

export const MenuFooter = styled(MenuSectionRow)`
  margin-top: 14px !important;
  margin-bottom: 8px !important;
`;

export const MenuTitle = styled.div`
  ${EH.Typography.Label16.SB600};
  color: ${EH.Colors.FIN.Lt.Emphasis.High};
  text-align: left;
`;

export const SideNavMenuBackground = styled(Flex)`
  position: absolute;
  // As per specs: close icon has to be distanced by 25px from top-right edge of sidemenu.
  // I added 10px padding to have a bigger tap area on mobile.
  // then I adjusted top and right to make sure positioning stays the same.
  top: -1px;
  left: -1px;
  width: 100vw;
  height: calc(var(--screen-height) - var(--app-margin-top, 0px));
  z-index: 10;
`;

export const MenuWrapper = styled.div`
  position: relative;
  min-width: 260px;
  background: white;
  border-radius: 10px;
  padding: 16px;
  color: ${({ theme }) => theme.charcoalGrey};

  ${({ maxHeight }) =>
    maxHeight &&
    `
    max-height: ${maxHeight}px;
  `}

  &.notfications {
    padding: 0px;
    overflow: hidden;
    ${MenuTitle} {
      padding: 16px 16px 16px 20px;
    }
  }

  &.mobile {
    width: 100%;
    min-width: initial;
    box-shadow: initial;
    box-sizing: border-box;
    border-radius: 0px;

    border-top: 1px solid ${EH.Colors.Core.Stroke.Tertiary};
    &:first-child {
      border-top: none;
    }

    ${MenuSection} {
      border-bottom: none;

      &::after {
        content: '';
        display: block;
        width: calc(100% - 40px);
        border-top: 1px solid ${EH.Colors.Core.Stroke.Tertiary};
        margin: 0px 20px;
      }

      &:last-child::after {
        display: none;
      }
    }

    ${MenuTitle} {
      margin-top: 0px !important;
      padding-bottom: 0px !important;
      border-bottom: none;
      ${EH.Typography.Label12.SB600};
      color: ${EH.Colors.Core.Text.Secondary};
    }

    ${MenuSectionRow} {
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export const StyledNavTitle = styled.h1`
  color: ${(p) => p.theme.emphasisHigh};
  font-family: Lexend;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.1px;
  flex-shrink: 0;

  ${StyledDropdownLabelText} {
    font-family: Lexend;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.1px;
    flex-shrink: 0;

    &.active,
    &:active {
      background-color: ${EH.Colors.Core.ActionFlay.Pressed};
    }

    &:hover {
      background-color: ${EH.Colors.Core.ActionFlay.Hovered};
    }
  }
`;

export const StyledNavTitleCount = styled.span`
  font-weight: 600;
`;

export const StyledNavTitleName = styled.span`
  font-weight: 400;
`;

export const StyledAvatarMenuIcon = styled.div`
  position: relative;
`;

export const StyledPlaceHolderIcon = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

export const StyledAvatarImage = styled(AvatarImage)`
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  pointer-events: none;
`;
