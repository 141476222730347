import { useOnMount } from '@float/libs/hooks/useOnMount';
import { preventDefault } from '@float/libs/utils/events/preventDefault';

// Hook to mimic the modal option in popover dialogs where interactions
// with outside elements are blocked when focused on the content.
// Also blocks the scroll of the page while the element is focused
export const useBlockOutsideInteraction = (
  ref: React.RefObject<HTMLElement>,
  isEnable?: boolean,
) => {
  useOnMount(() => {
    if (!isEnable) return;
    let originalBodyPointerEvent = '';
    let originalContainerPointerEvent = '';
    const onFocusOut = () => {
      // If new focus is outside the ref container than we unblock the interactions and clean up
      if (ref.current && !ref.current.contains(document.activeElement)) {
        document.body.style.pointerEvents = originalBodyPointerEvent;
        ref.current.style.pointerEvents = originalContainerPointerEvent;
        document.body.removeEventListener('click', onFocusOut);
        document.body.removeEventListener('wheel', preventDefault);
      }
    };

    const onFocusIn = () => {
      // Block pointer events anywhere except inside the ref container
      // Don't want to ever set the original pointer to none since that's just a clash between two components
      if (document.body.style.pointerEvents !== 'none') {
        originalBodyPointerEvent = document.body.style.pointerEvents;
      }
      originalContainerPointerEvent = ref.current!.style.pointerEvents;

      // Prevent scrolling
      document.body.addEventListener('wheel', preventDefault, {
        passive: false,
      });

      // Prevent outside click
      document.body.style.pointerEvents = 'none';
      ref.current!.style.pointerEvents = 'auto';

      // Set up click listener on page to disable the block
      document.body.addEventListener('click', onFocusOut);
    };

    ref.current?.addEventListener('focusin', onFocusIn);
    ref.current?.addEventListener('focusout', onFocusOut);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('focusin', onFocusIn);
        ref.current.removeEventListener('focusout', onFocusOut);
        ref.current.style.pointerEvents = originalContainerPointerEvent;
      }
      document.body.removeEventListener('click', onFocusOut);
      document.body.removeEventListener('wheel', preventDefault);
      document.body.style.pointerEvents = originalBodyPointerEvent;
    };
  });
};
