import React from 'react';

import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import { StyledSpan } from './styles';

const TagStatic = (props) => {
  const {
    icon,
    children,
    trailingIcon,
    colorPrimary = Colors.FIN.Lt.Stroke.Stroke4,
    colorSecondary = Colors.FIN.Lt.Surface.Surf4,
    style,
  } = props;

  return (
    <StyledSpan
      trailingIcon={trailingIcon}
      colorPrimary={colorPrimary}
      colorSecondary={colorSecondary}
      style={style}
    >
      {icon && (
        <span>{React.createElement(icon, { color: colorPrimary })}</span>
      )}
      <span>{children}</span>
    </StyledSpan>
  );
};

export default TagStatic;
