import React from 'react';

import BaseIcon from './BaseIcon';

export default (props) => (
  <BaseIcon {...props}>
    <path
      d="M13.25 8.75L9.75 12L13.25 15.25"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);
