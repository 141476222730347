import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconCollapse = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M15.25 7L12 10.5L8.75 7"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 17.5L12 14L8.75 17.5"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVGIcon>
  );
};

export { IconCollapse };
