import React, { forwardRef, useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import cs from 'classnames';
import { format } from 'date-fns';
import { LayoutProps, motion } from 'framer-motion';

import { DEFAULT_DATESTRING_FORMAT_DATE_FNS } from '@float/constants/dates';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { TimeString } from '@float/types/datesManager';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';

import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import {
  InputAllocationHours,
  InputAllocationHoursProps,
} from '../../components/InputAllocationHours/InputAllocationHours';
import {
  InputAllocationTimeRange,
  InputAllocationTimeRangeProps,
} from '../../components/InputAllocationTimeRange/InputAllocationTimeRange';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import { getAllocationCorrectedHoursUsingHoursPerDay } from '../../helpers/getAllocationCorrectedHours';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';
import { useAllocationTimeToggle } from '../../hooks/useAllocationTimeToggle';
import { useIsTotalHoursInputDisabled } from '../../hooks/useIsTotalHoursInputDisabled';

import * as styles from '../../EditTaskModal.css';

export type TimeoffTimeSectionProps = {
  startDate: Date;
  startTime: TimeString | null;
  endDate: Date;
  hoursPerDay: number;
  hoursTotal: number;
  peopleIds: number[];
  isReadOnly: boolean;
  is24HoursTimeFormat: boolean;
  timeoffId?: number;
  layout: LayoutProps['layout'];
  onChange: (data: AllocationTimeSectionPayload) => void;
};

export const TimeoffTimeSection = forwardRef<
  HTMLDivElement,
  TimeoffTimeSectionProps
>((props, ref) => {
  const {
    timeoffId,
    startDate,
    startTime,
    endDate,
    hoursPerDay,
    hoursTotal,
    peopleIds,
    isReadOnly,
    is24HoursTimeFormat,
    layout,
    onChange,
  } = props;

  const {
    getIsWorkDay,
    getNumberOfAllocationDays,
    getEndDateFromTotalHours,
    getMinimumWorkHoursInRange,
  } = useAllocationDaysHelpers({
    peopleIds,
    timeoffId,
  });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate);
  }, [startDate, endDate, getNumberOfAllocationDays]);

  const minimumWorkHoursInRange = getMinimumWorkHoursInRange(
    peopleIds,
    format(startDate, DEFAULT_DATESTRING_FORMAT_DATE_FNS),
    format(endDate, DEFAULT_DATESTRING_FORMAT_DATE_FNS),
  );

  const minimumHoursPerDayValue = 0.1;
  const maximumHoursPerDayValue = minimumWorkHoursInRange;

  // The total hours might need a re-computation on mount
  useOnMount(() => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  });

  const isTotalHoursInputDisabled = useIsTotalHoursInputDisabled(
    numberOfAllocationDays,
  );

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const {
    isAllocationByTime,
    isAllocationByHours,
    handleClickSetSpecificTimeButton,
    handleClickSetTotalHoursButton,
  } = useAllocationTimeToggle({
    startTime,
    hoursPerDay,
    numberOfAllocationDays,
    onChange,
  });

  const handleHoursPerDayChange: InputAllocationHoursProps['onChangeHoursPerDay'] =
    (hoursPerDay) => {
      onChange({
        hoursPerDay,
      });
    };

  const handleHoursTotalChange: InputAllocationHoursProps['onChangeHoursTotal'] =
    (hoursTotal) => {
      const endDate = getEndDateFromTotalHours({
        startDate,
        hoursPerDay,
        hoursTotal: hoursTotal || hoursPerDay,
      });

      onChange({
        hoursTotal,
        endDate,
      });
    };

  const handleHoursPerDayBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = hoursPerDayCorrected === maximumHoursPerDayValue;

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  };

  const handleHoursTotalBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay: Math.min(hoursPerDay, hoursTotal),
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = hoursPerDay === maximumHoursPerDayValue;

    onChange({
      hoursTotal: hoursTotalCorrected,
      hoursPerDay: hoursPerDayCorrected,
      isFullDay,
    });
  };

  const handleTimeRangeChange: InputAllocationTimeRangeProps['onChange'] = (
    data,
  ) => {
    const { startTime, hoursPerDay } = data;

    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = hoursPerDay === maximumHoursPerDayValue;

    onChange({
      startTime,
      hoursTotal: hoursTotalCorrected,
      hoursPerDay: hoursPerDayCorrected,
      isFullDay,
    });
  };

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    const numberOfAllocationDays = getNumberOfAllocationDays(
      startDate,
      endDate,
    );

    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = hoursPerDay === maximumHoursPerDayValue;

    onChange({
      startDate,
      endDate,
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  };

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      {isAllocationByHours && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationHours
            hoursPerDay={hoursPerDay}
            hoursTotal={hoursTotal}
            isReadOnly={isReadOnly}
            isTotalHoursInputDisabled={isTotalHoursInputDisabled}
            onChangeHoursPerDay={handleHoursPerDayChange}
            onChangeHoursTotal={handleHoursTotalChange}
            onBlurHoursPerDay={handleHoursPerDayBlur}
            onBlurHoursTotal={handleHoursTotalBlur}
          />

          {!isReadOnly && (
            <div className={styles.timeSectionSpecificTimeToggleWrapper}>
              <TextButton
                appearance="flue-fill"
                onClick={handleClickSetSpecificTimeButton}
              >
                <Trans>Specific time</Trans>
              </TextButton>
            </div>
          )}
        </div>
      )}

      {isAllocationByTime && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationTimeRange
            hoursPerDay={hoursPerDay}
            startTime={startTime}
            isReadOnly={isReadOnly}
            is24HoursTimeFormat={is24HoursTimeFormat}
            onChange={handleTimeRangeChange}
          />
          {!isReadOnly && (
            <div className={styles.timeSectionTotalHoursToggleWrapper}>
              <TextButton
                appearance="flue-fill"
                onClick={handleClickSetTotalHoursButton}
              >
                <Trans>Total hours</Trans>
              </TextButton>
            </div>
          )}
        </div>
      )}

      <div className={cs(styles.timeSectionColumn)}>
        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          numberOfAllocationDaysInDateRange={numberOfAllocationDays}
          isReadOnly={isReadOnly}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
        />
      </div>
    </motion.div>
  );
});
