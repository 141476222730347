import { BaseFilterToken } from '@float/types';

/**
 * Returns if a single inclusive project filter is applied
 * @param {Object[]} filters
 * @returns {boolean}
 */
export const getIsSingleInclusiveProjectFilterApplied = (
  filters: BaseFilterToken[],
) => {
  return filters.some(
    (filter) =>
      filter.type === 'project' &&
      (typeof filter.val === 'string' ||
        (Array.isArray(filter.val) && filter.val.length === 1)) &&
      !filter.operator,
  );
};
