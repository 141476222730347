import { FlattenSimpleInterpolation } from 'styled-components';

import {
  Breakpoint,
  breakpoints,
} from '@float/ui/deprecated/Theme/breakpoints';

import { media } from './media';

/**
 * Respond to breakpoint with criteria
 *
 * @todo: Add tests
 *
 * @example
 * // target a single breakpoint
 * respondTo('small')(
 *   css`
 *     background-color: green;
 *   `,
 * )
 *
 * @example
 * // target multiple breakpoints with criteria
 * respondTo('medium', '>=')(
 *   css`
 *     background-color: green;
 *   `,
 * )
 *
 * @example
 * // target multiple specific breakpoints
 * // note: criteria is ingored when targeting multiple breakpoints
 * respondTo(['small', 'large'])(
 *   css`
 *     background-color: green;
 *   `,
 * )
 *
 **/

export const respondTo =
  (breakpointName: Breakpoint, criteria = '=') =>
  (css: FlattenSimpleInterpolation) => {
    const respondingTo = [];

    const styles: FlattenSimpleInterpolation[] = [];

    if (Array.isArray(breakpointName)) {
      breakpointName.forEach((breakpoint) => {
        respondingTo.push(breakpoint);
      });
    } else {
      const includeBreakpointName = criteria.indexOf('=') > -1;
      const includeLargerBreakpoints = criteria.indexOf('>') > -1;
      const includeSmallerBreakpoints = criteria.indexOf('<') > -1;

      // respond to breakpointName if criteria includes =
      if (includeBreakpointName) {
        respondingTo.push(breakpointName);
      }

      // respond to other breakpoints if criteria includes < or >
      if (includeLargerBreakpoints || includeSmallerBreakpoints) {
        const allBreakpoints = Object.keys(breakpoints);
        const breakpointIndex = allBreakpoints.findIndex(
          (name) => name === breakpointName,
        );

        allBreakpoints.forEach((currentBreakpointName, index) => {
          if (currentBreakpointName !== breakpointName) {
            // is current breakpoint larger than breakpointName
            if (includeLargerBreakpoints && index > breakpointIndex) {
              respondingTo.push(currentBreakpointName);
            }

            // is current breakpoint smaller than breakpointName
            if (includeSmallerBreakpoints && index < breakpointIndex) {
              respondingTo.push(currentBreakpointName);
            }
          }
        });
      }
    }

    respondingTo.forEach((breakpoint) => {
      // get media styles for each matched breakpoint
      styles.push(media[breakpoint as Breakpoint]!(css));
    });

    return styles;
  };
