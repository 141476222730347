import React, { memo, useRef } from 'react';
import { t } from '@lingui/macro';
import cn from 'classnames';

import { isViewApplied as isViewAppliedSelector } from '@float/common/selectors/views';
import { useAppSelector } from '@float/common/store';
import { noop } from '@float/libs/utils/noop';
import { IconPlusSmall } from '@float/ui/deprecated/Earhart/Icons';
import { useOnClickOutside } from '@float/ui/deprecated/hooks/useOnClickOutside';
import { SaveView } from '@float/web/searchV2/components/SaveView';
import {
  FilterButton,
  FilterToken,
  MoreFilters,
} from 'components/SearchFilters';

import { ClearFiltersButton } from '../../components/ClearFiltersButton';
import { SearchFilterTokensProps } from '../types';
import { useVisibleTokens } from './useVisibleTokens';

import * as styles from './styles.css';

export const WideScreenFilterTokens = memo(
  ({
    addFilter,
    addFilterRef,
    clearFilters,
    closeAllDropdowns,
    excludeRefs,
    filters,
    moreFiltersPopupOpen,
    onAddClick,
    onFilterClick,
    removeFilter,
    shouldRenderMeFilter,
    showAddBookmarkModal,
    showDropdown,
    toggleMeFilter,
    toggleMoreFilters,
    editingFilterIndex,
  }: SearchFilterTokensProps) => {
    const filtersRef = useRef<HTMLDivElement>(null);
    const moreFiltersRef = useRef<HTMLDivElement>(null);

    const isViewApplied = useAppSelector(isViewAppliedSelector);

    useOnClickOutside(
      closeAllDropdowns,
      [...excludeRefs, moreFiltersRef, filtersRef, addFilterRef],
      showDropdown || moreFiltersPopupOpen,
    );

    const tokens = useVisibleTokens({
      containerRef: filtersRef,
      filters,
      padding: 45, // To make up for the hidden filters button space that may be toggled
    });

    const visibleFilters = filters.slice(0, tokens.visible);
    const hiddenFilters = filters.slice(tokens.visible);

    // we want the add button to take the color of the last visible filter
    const lastVisibleFilter = visibleFilters[visibleFilters.length - 1];
    const meFilter = shouldRenderMeFilter ? { type: 'me', val: '' } : null;
    const addButtonFilterReference =
      lastVisibleFilter || filters[filters.length - 1] || meFilter;

    return (
      <div className={styles.container}>
        {shouldRenderMeFilter && (
          <>
            <FilterToken
              key="me"
              filter={{ type: 'me', val: '' }}
              onRemove={toggleMeFilter}
              index={0}
            />
          </>
        )}
        <div
          className={cn(styles.filterTokensContainer, 'token-search-wrap')}
          ref={filtersRef}
          data-testid="search-filters-container"
        >
          {visibleFilters.map((filter, i) => (
            <div key={i} className={styles.tokensWrapper}>
              <FilterToken
                onRemove={() => removeFilter(filter)}
                onClick={onFilterClick}
                onChange={addFilter}
                filter={filter}
                prevFilter={filters[i - 1]}
                index={i}
                forceNoOrBetweenTypes={isViewApplied}
              />
            </div>
          ))}
        </div>
        {tokens.renderHiddenFiltersMeasureContainer(
          hiddenFilters.map((filter, i) => (
            <div key={i} className={styles.tokensWrapper}>
              <FilterToken
                onRemove={noop}
                onClick={noop}
                onChange={noop}
                filter={filter}
                index={visibleFilters.length + i}
                forceNoOrBetweenTypes={isViewApplied}
              />
            </div>
          )),
        )}
        <MoreFilters
          ref={moreFiltersRef}
          open={moreFiltersPopupOpen}
          onClick={toggleMoreFilters}
          filters={filters}
          onRemove={removeFilter}
          onFilterClick={onFilterClick}
          onChange={addFilter}
          visibleCount={tokens.visible}
          forceNoOrBetweenTypes={isViewApplied}
        />

        <FilterButton
          active={showDropdown && editingFilterIndex === null}
          variant="icon"
          ref={addFilterRef}
          onClick={onAddClick}
          filter={addButtonFilterReference}
          aria-label={t`Add filters`}
        >
          <IconPlusSmall />
        </FilterButton>
        <SaveView />
        <ClearFiltersButton onClick={clearFilters} />

        {tokens.resizeGuard}
      </div>
    );
  },
);
