import jwt_decode from 'jwt-decode';

import { Resources } from '@float/common/api3/constants';
import socket from '@float/common/lib/liveUpdates/socket';
import { FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';
import { logger } from '@float/libs/logger';
import { moment } from '@float/libs/moment';
import {
  getIsCurrentPageASharedLink,
  getSharedPageLinkToken,
} from '@float/libs/web/sharedLinkView';

import cachedFetch from '../cachedFetch';
import { handleFail } from '../errors';
import { blobStatus } from './common/blob-status';
import { convertToReadOnlyPath } from './common/convertToReadOnlyPath';
import { encode } from './common/encode';
import { joinUrl } from './common/join-url';
import { serialize } from './common/serialize';
import { tokenIsRefreshing } from './common/token';
import { fetchWrapper } from './fetchWrapper';

export const GET_JWT = 'jwt/GET_JWT';
export const GET_JWT_SUCCESS = 'jwt/GET_JWT_SUCCESS';
export const GET_JWT_FAILURE = 'jwt/GET_JWT_FAILURE';
export const CLEAR_JWT = 'jwt/CLEAR_JWT';

const requestJWTWithCookie = () => {
  return get('getJWToken', {}, { hostname: '', version: '' });
};

const isExpired = (unixSeconds) => {
  return moment.unix(unixSeconds).subtract(1, 'minute').isBefore(moment());
};

let promise = null;

export function get(url, data, opts = {}, prepend) {
  if ((opts.type || config.api.type) === 'static') {
    url += '.json';
  }
  const st = url.indexOf('?') >= 0 ? '&' : '?';
  if (data) {
    url += `${st}${serialize(data)}`;
  }
  return send(url, { headers: opts.headers }, opts, prepend);
}

export function post(url, args, opts = {}, prepend, attempt) {
  if (!opts) opts = {};
  if (!opts.contentType && opts.version === 'f3') {
    opts.contentType = 'application/json';
  }
  let body;
  if (opts.contentType === 'application/json' || opts.json) {
    body = JSON.stringify(args);
  } else if (opts.ignoreEncoding) {
    body = args;
  } else {
    body = encode(args);
  }
  const params = {
    method: 'post',
    headers: {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Content-Type': opts.contentType || 'application/x-www-form-urlencoded',
      ...opts.headers,
    },
    body,
  };
  return send(url, params, opts, prepend, attempt);
}

const exchangeShareLinkToken = () => {
  const token = getSharedPageLinkToken();
  if (!token) {
    logger.info('Token not found in window location');
    return null;
  }

  return post(
    Resources.SharePageExchangeToken,
    { token },
    { hostname: '', version: '' },
  );
};

// duplicated from common/src/api3.js to avoid circular dependency
// see https://github.com/floatschedule/float-javascript/pull/1439
async function getRefreshToken({ refresh_token }) {
  return post(
    'token',
    {
      refresh_token,
      ...(config.requestClientId ? { client_id: config.requestClientId } : {}),
    },
    {
      version: 'f3',
      contentType: 'application/x-www-form-urlencoded',
    },
  );
}

const refreshJWTToken = async ({ refreshToken, currentCompanyId }) => {
  try {
    const response = await getRefreshToken({ refresh_token: refreshToken });
    const account = response.accounts.find(
      (account) => account.company.company_id == currentCompanyId,
    );
    return {
      token: {
        access_token: account.token.access_token,
        expiry: account.token.exp,
      },
    };
  } catch (error) {
    // TODO: Add Sentry logs here.
    return { token: { access_token: null, expiry: null } };
  }
};

const fetchJWT = () => async (dispatch, getState) => {
  const { loadState, refreshToken, currentCompanyId } = getState().jwt;
  const { shared_link_view: sharedLink } = getState().currentUser;
  // HEADER, refresh token
  try {
    if (loadState !== 'LOADING') {
      dispatch({ type: GET_JWT });
      if (config.shouldUseRefreshToken) {
        promise = refreshJWTToken({ refreshToken, currentCompanyId });
      } else if (sharedLink) {
        promise = exchangeShareLinkToken();
      } else {
        promise = requestJWTWithCookie();
      }
    }
    const json = await promise;
    const {
      token: { access_token: accessToken, expiry },
    } = json;

    if (config.shouldUseRefreshToken && (!accessToken || !expiry)) {
      throw new Error('Fail refreshing token');
    }

    // Only dispatch if the new JWT is different
    const curJwt = getState().jwt;
    if (curJwt?.accessToken !== accessToken || curJwt?.expiry !== expiry) {
      dispatch(fetchedJWT({ accessToken, expiry }));
    } else {
      dispatch(fetchedJWT({}));
    }

    return accessToken;
  } catch (e) {
    dispatch({ type: GET_JWT_FAILURE });
    return null;
  }
};

export const fetchedJWT = (payload) => ({
  type: GET_JWT_SUCCESS,
  ...payload,
});

export const clearJWT = () => ({
  type: CLEAR_JWT,
});

export const getJWTAccessToken =
  (force = false, isRefreshing = false) =>
  async (dispatch, getState) => {
    const { jwt, currentUser } = getState();
    let { accessToken } = jwt;
    if ((force || !accessToken || isExpired(jwt.expiry)) && !isRefreshing) {
      accessToken = await dispatch(fetchJWT());
    }

    let tokenData = null;
    try {
      tokenData = jwt_decode(accessToken);
    } catch (e) {
      // If request failed or token wasn't processed correctly, we should handle fail properly.
      // The "Check your internet connection" modal from request.js will show up in this case.
    }

    if (
      currentUser.cid &&
      tokenData &&
      tokenData.company &&
      tokenData.company.id !== +currentUser.cid
    ) {
      handleFail(
        null,
        'Sorry, we were unable to complete that last action. Please reload this page and try again.',
      );
    }

    return accessToken;
  };

export const setJWTAccessToken =
  ({ accessToken, expiry, refreshToken, currentCompanyId }) =>
  async (dispatch) => {
    dispatch(
      fetchedJWT({ accessToken, expiry, refreshToken, currentCompanyId }),
    );
  };

function convertToErrorInstance(err) {
  return Object.assign(new Error(err.message), err);
}

export const send = async (
  url,
  params = {},
  opts = {},
  prepend = true,
  attempt = 1,
) => {
  if (!opts) opts = {};
  if (!params) params = {};
  if (prepend == null) prepend = true;

  let hostname, promise, version;
  version =
    config.api.type === 'static' ? '' : opts.version || config.api.version;
  if (opts.version === '') {
    version = '';
  }
  hostname = opts.hostname || config.api.hostname;
  if (opts.hostname === '' && !config.api?.preventRelativeHostname) {
    hostname = '';
  }
  url = joinUrl({
    url,
    hostname,
    preventRelativeHostname: config.api?.preventRelativeHostname,
    version,
    prepend,
    type: opts.type,
  });

  const status = async (response, d) => {
    let isSameRoute;

    // need to make sure this doesn't return true for "login" hostname:
    // also need to override this when trying to authenticate a user for an expired account
    if (
      !opts.noRedirect &&
      (/\/login$/.test(response.url) || /\/expired$/.test(response.url))
    ) {
      if (window.dispatchEvent && config.events) {
        window.dispatchEvent(config.events.noSession);
      }

      if (
        /text\/html/.test(response.headers.get('Content-Type')) &&
        window.location &&
        config.platform !== FloatAppPlatform.Electron
      ) {
        window.location.replace('/login');
        // response.text().then(d => {
        //   // c.log(d.indexOf('Sorry, your account has been disabled.') >= 0)
        //   // if d.indexOf('Sorry, your account has been disabled.') >= 0 || d.indexOf('Sorry, your account has been disabled.') >= 0
        //   document.body.innerHTML = d;
        //   return d;
        // });
      }
    }
    if (response.status >= 200 && response.status <= 302) {
      if (response.status === 204) {
        return {};
      }
      isSameRoute = /float\.(com|local)\/$|localhost:\d{2,4}\/$/.test(
        response.url,
      );
      if (isSameRoute) {
        return {};
      }
      if (/text\/html/.test(response.headers.get('Content-Type'))) {
        return response.text();
      }

      const json = await response.json();

      return opts.includeHeaders
        ? { headers: response.headers, data: json }
        : json;
    }

    throw response;
  };
  const handleError = (err = {}) => {
    const statusCode = err.status || err.statusCode;

    // Do not retry request if resource is permanently unavailable
    if (statusCode === 410) {
      return Promise.reject(err);
    }

    if (err.timeout) {
      return Promise.reject(err);
    }

    if (
      typeof err === 'object' &&
      /failed to fetch/gi.test(err.message) &&
      url !== '/login' &&
      !url.startsWith('/status') &&
      !url.startsWith('/svc/nf/ping') &&
      opts.type !== 'prefetch'
    ) {
      handleFail(
        null,
        'Sorry, we were unable to complete that last action. Check your internet connection, reload this page and try again.',
        {
          title: 'Notice',
          reloadLabel: 'Reload',
          newModal: true,
        },
      );
      return Promise.reject(err);
    }
    if (err.status) {
      if (/text\/html/.test(err.headers.get('Content-Type'))) {
        return err.text().then((e) => {
          const errObj = new Error(e);
          return Promise.reject(errObj);
        });
      }

      return err.json().then((e) => {
        if (e && e.message) {
          // Using an Error constructor to better track these errors on Rollbar
          // because plain objects are tracked as "Item sent with null or missing arguments"
          return Promise.reject(convertToErrorInstance(e));
        }

        return Promise.reject(e);
      });
    }

    if (err && err.message) {
      // Using an Error constructor to better track these errors on Rollbar
      // because plain objects are tracked as "Item sent with null or missing arguments"
      return Promise.reject(convertToErrorInstance(err));
    }

    return Promise.reject(err);
  };
  if (!params) {
    params = {};
  }
  if (params.headers == null) {
    params.headers = {};
  }
  params.headers['notify-uuid'] = socket.uuid;

  // params.mode = 'no-cors'
  // Note: commenting this causes requests to break on Edge 17 - https://github.com/github/fetch#sending-cookies
  params.credentials = opts.credentials || 'include';
  const isRefreshingToken = tokenIsRefreshing(params);
  const isSigningIn =
    (url.includes('/v3/token') || url.includes('/v3/magic')) &&
    !isRefreshingToken;

  if ((version === 'f3' || opts.jwt) && !isSigningIn) {
    const accessToken = await window.reduxStoreDispatch(
      getJWTAccessToken(attempt > 1, isRefreshingToken),
    );

    if (!accessToken) {
      // Preemptively fail the request if there is no JWT
      return handleError({
        url,
        statusCode: 401,
        error: 'Unauthorized',
        message: 'Invalid token format',
        attributes: {
          error: 'Invalid token format',
        },
      });
    }

    if (getIsCurrentPageASharedLink()) {
      url = convertToReadOnlyPath(url);
    }

    params.headers.Authorization = `Bearer ${accessToken}`;
    params.headers['X-Token-Type'] = 'JWT';
    params.credentials = 'omit';

    if (opts.signal) {
      params.signal = opts.signal;
    }

    promise = fetchWrapper({
      url,
      options: params,
      timeout: opts.timeout,
      cache: opts.cache,
    });
  } else {
    promise = opts.cache ? cachedFetch(url, params) : fetch(url, params);
  }

  if (opts.isBlob) {
    promise = promise.then(blobStatus).catch(handleError);
  } else {
    promise = promise.then(status).catch(handleError);
  }
  return promise;
};
