import React, { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { useToggle } from 'react-use/esm';

import * as Accordion from '@float/ui/components/Accordion';
import { Tag } from '@float/ui/components/Tag';

import { SectionCounterTag } from '../../components/SectionCounterTag';
import { SectionErrorTag } from '../../components/SectionErrorTag';
import { AccordionEntry } from '../../hooks/useAccordionState';
import { usePopulateTeamDefaultHourlyRates } from '../../hooks/usePopulateTeamDefaultHourlyRates';
import { useProjectOrPhaseFieldValue } from '../../hooks/useProjectOrPhaseFieldValue';
import { RateTypeField } from './ProjectRateType';
import { useShowHourlyRatesInfo } from './ProjectTeamSection.hooks';
import { ProjectTeamTable } from './ProjectTeamTable';

import * as projectPanelStyles from '../../styles.css';
import { projectAccordionHeader, projectSectionHeader } from '../../styles.css';
import * as styles from './styles.css';

const ProjectTeamHeader: React.FC<{
  hasSameRate: boolean;
  showRateInfo: boolean;
}> = ({ hasSameRate, showRateInfo }) => {
  if (!showRateInfo) return null;

  return (
    <div className={projectPanelStyles.projectAccordionSummary}>
      <div className={projectPanelStyles.showWhenCollapsed}>
        <Tag color="primary">
          {hasSameRate ? (
            <Trans>Same rate</Trans>
          ) : (
            <Trans>Different rates</Trans>
          )}
        </Tag>
      </div>
      <SectionErrorTag fields={['project.default_hourly_rate', 'team']} />
    </div>
  );
};

function ProjectTeamTitle() {
  return (
    <div className={projectSectionHeader}>
      <Trans>Team</Trans>
      <SectionCounterTag
        name="team"
        filter={(value) => Boolean(value.people_id)}
      />
    </div>
  );
}

export const ProjectTeamSection = (props: { isLoading?: boolean }) => {
  const showRateField = useShowHourlyRatesInfo();
  const defaultHourlyRate = useProjectOrPhaseFieldValue('default_hourly_rate');
  const hasSameRate = defaultHourlyRate !== null;

  const [toggleDefaultRate, setToggleDefaultRate] = useToggle(hasSameRate);

  const { populateTeamDefaultHourlyRates } =
    usePopulateTeamDefaultHourlyRates();

  useEffect(() => {
    if (props.isLoading) return;

    let timeoutId: NodeJS.Timeout;
    if (showRateField && !hasSameRate) {
      // When navigating between projects, ProjectPanelView gets remounted
      // and setValue needs to be invoked on the next tick for react-hook-form
      // to set isDirty appropriately.
      timeoutId = setTimeout(populateTeamDefaultHourlyRates, 0);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // populateTeamDefaultHourlyRates is an unstable prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading, showRateField, hasSameRate]);

  return (
    <Accordion.Item value={AccordionEntry.team}>
      <Accordion.Header
        title={t`Team`}
        className={projectAccordionHeader}
        info={
          <ProjectTeamHeader
            hasSameRate={hasSameRate}
            showRateInfo={showRateField}
          />
        }
      >
        <ProjectTeamTitle />
      </Accordion.Header>
      <Accordion.Content>
        <div className={styles.projectTeamSectionContainer}>
          {showRateField && (
            <RateTypeField
              toggleDefaultRate={toggleDefaultRate}
              setToggleDefaultRate={setToggleDefaultRate}
            />
          )}
          <ProjectTeamTable toggleDefaultRate={toggleDefaultRate} />
        </div>
      </Accordion.Content>
    </Accordion.Item>
  );
};
