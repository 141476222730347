import useSWR from 'swr';

import { parseFloatDate } from '@float/libs/dates';
import { DateString } from '@float/types';
import { Timeframe, TimeframeFromApi } from '@float/types/timeframes';

import { Resources } from './constants';
import { makeRequest, MakeRequestPagination } from './makeRequest';

export type GetTimeframesParams = {
  start_date: DateString;
  end_date: DateString;
};

export const formatTimeFramesFromApi = (
  timeframesFromApi: TimeframeFromApi[],
): Timeframe[] => {
  return timeframesFromApi.map((frame) => {
    const startDate = parseFloatDate(frame.start_date);
    const endDate = parseFloatDate(frame.end_date);

    return {
      id: frame.id,
      name: frame.name,
      startDate,
      endDate,
    };
  });
};

export const getTimeframes = async (
  params?: GetTimeframesParams,
): Promise<Timeframe[] | null> => {
  const results = await makeRequest<
    TimeframeFromApi[] | void,
    GetTimeframesParams | undefined
  >({
    data: params,
    resource: Resources.Timeframes,
    method: 'GET',
    pagination: MakeRequestPagination.Off,
    query: { sort: 'start_date' },
  });

  if (results) {
    return formatTimeFramesFromApi(results);
  }

  return null;
};

export const useGetTimeframes = () =>
  useSWR(Resources.Timeframes, () => getTimeframes());
