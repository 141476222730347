import { createAction } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
  active: false,
  tasks: new Set<string>(),
  loggedTimes: new Set<string>(),
  timeoffs: new Set<string>(),
  people: new Set<number>(),
  projects: new Set<number>(),
};

export type SearchResultsState = typeof DEFAULT_STATE;

export const searchResultsUpdateAction = createAction<
  Partial<SearchResultsState>,
  'SEARCH_RESULTS_UPDATE'
>('SEARCH_RESULTS_UPDATE');

export type SearchResultsUpdateAction = ReturnType<
  typeof searchResultsUpdateAction
>;

export function searchResults(
  state = DEFAULT_STATE,
  action: SearchResultsUpdateAction,
) {
  switch (action.type) {
    case searchResultsUpdateAction.type: {
      return {
        ...state,
        ...action.payload,
        active: true,
      };
    }
  }

  return state;
}
