import React from 'react';

import { StepBase } from '../../StepBase';
import {
  StyledImage,
  StyledParagraph,
  StyledTitle,
} from '../../StepBase/styles';

import scheduleNoTTDesktopWithViewsImageSource from './images/ScheduleDesktopNoTTWithViews.jpg';
import scheduleDesktopWithViewsImageSource from './images/ScheduleDesktopWithViews.jpg';
import scheduleMobileImageSource from './images/ScheduleMobile.jpg';
import scheduleNoTTMobileImageSource from './images/ScheduleMobileNoTT.jpg';

const ScheduleContent = (props) => {
  const { anchor, userSpecs } = props;

  let imageSource = '';

  if (anchor.id === 'schedule-desktop') {
    if (userSpecs.hasTimeTracking) {
      imageSource = scheduleDesktopWithViewsImageSource;
    } else {
      imageSource = scheduleNoTTDesktopWithViewsImageSource;
    }
  } else {
    if (userSpecs.hasTimeTracking) {
      imageSource = scheduleMobileImageSource;
    } else {
      imageSource = scheduleNoTTMobileImageSource;
    }
  }

  return (
    <>
      <StyledImage>
        <img src={imageSource} width="100%" height="100%" alt="Schedule" />
      </StyledImage>
      <StyledTitle>Set your focus</StyledTitle>
      <StyledParagraph>
        {userSpecs.hasTimeTracking
          ? 'Change your view from people, to projects, or the time logged on tasks.'
          : 'Change your view from people to projects.'}
      </StyledParagraph>
      <StyledParagraph>See what you need, without distraction.</StyledParagraph>
    </>
  );
};

const Schedule = (props) => {
  return (
    <StepBase {...props}>
      <ScheduleContent anchor={props.anchor} userSpecs={props.userSpecs} />
    </StepBase>
  );
};

export default Schedule;
