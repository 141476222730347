import React, { Component } from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
} from '@floatschedule/react-virtualized';
import styled from 'styled-components';

import Loader from '@float/common/components/elements/Loader';
import { EH, Spacer } from '@float/ui/deprecated';

import ActivityItem from './ActivityItem';
import { EmptyNotificationsIllustration } from './EmptyNotificationsIllustration';
import HideableList from './HideableList';

const Wrapper = styled.div`
  width: 100%;
  display: block;
  height: calc(100% - 47px);

  .ReactVirtualized__List {
    outline: none;
  }
`;

// To bring the loader vertically aligned within
// the notifications popover menu
const StyledCentred = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 64px; // height of the title container
  box-sizing: border-box;
`;

const StaticLoader = styled(Loader)`
  position: static !important;
  margin: 0 auto !important;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 14px 0px 34px;
  box-sizing: border-box;
`;

const EmptyHeading = styled.h3`
  ${EH.Typography.Label18.SB600}
  color: ${EH.Colors.Core.Lt.Emp.High};
  letter-spacing: -0.2px;
`;

const EmptyDesc = styled.span`
  ${EH.Typography.Label14.R400}
  color: ${EH.Colors.Core.Lt.Emp.High};
  letter-spacing: -0.2px;
`;

const createCellMeasurerCache = () =>
  new CellMeasurerCache({
    defaultHeight: 80,
    fixedWidth: true,
  });

export default class extends Component {
  state = {
    listHeight: window.innerHeight,
    cache: createCellMeasurerCache(),
    expanded: {},
  };

  componentDidMount() {
    this.updateCache();
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.updateCache();
    }
  }

  updateCache = ({ immediate = false } = {}) => {
    if (immediate) {
      this.setState({ cache: createCellMeasurerCache() });
      return;
    }

    setTimeout(() => {
      this.setState({ cache: createCellMeasurerCache() });
    }, 100);
  };

  isRowLoaded = ({ index }) => {
    return !!this.props.items[index];
  };

  onDetailsToggle = (index) => {
    const expanded = { ...this.state.expanded };
    expanded[index] = !expanded[index];
    this.setState({ expanded });

    if (this.list) {
      this.updateCache({ immediate: true });
    }
  };

  rowRenderer = ({ key, index, style, parent }) => {
    const { items, loadingMore, activityItemClick, bgColor } = this.props;

    const item = items[index];
    if (item.empty) return <div />;

    return (
      <CellMeasurer
        cache={this.state.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          <ActivityItem
            clickedData={item}
            avatar={item.actioned_by.avatar}
            bgColor={bgColor || (!item.seenLocalStorage && '#eff5f8')}
            timestamp={item.actioned_timestamp}
            key={item.activity_id}
            baseTitleArgs={{ ...item.baseTitleArgs }}
            onActionClick={activityItemClick}
            displayHistory={item.displayHistory}
            hideableContent={
              item.displayHistory && item.displayHistory.length ? (
                <HideableList
                  hideableItems={item.displayHistory}
                  onActionClick={activityItemClick}
                />
              ) : null
            }
            expanded={this.state.expanded[index]}
            onDetailsToggle={() => this.onDetailsToggle(index)}
          />
          {loadingMore && index === items.length - 1 && <StaticLoader />}
        </div>
      </CellMeasurer>
    );
  };

  render() {
    const {
      items,
      loadMore,
      loadingMore,
      emptyActivity,
      isNotificationFeed,
      maxHeight,
    } = this.props;

    if (emptyActivity) {
      if (loadingMore) {
        return (
          <ActivityWrapper maxHeight={undefined}>
            <StyledCentred>
              <StaticLoader />
            </StyledCentred>
          </ActivityWrapper>
        );
      }

      return (
        <ActivityWrapper maxHeight={undefined}>
          <EmptyWrapper>
            <EmptyNotificationsIllustration />
            <EmptyHeading>
              You currently have no{' '}
              {isNotificationFeed ? 'notifications' : 'activity'}.
            </EmptyHeading>
            <Spacer size={8} />
            <EmptyDesc>We hope you enjoy this peace and quiet.</EmptyDesc>
          </EmptyWrapper>
        </ActivityWrapper>
      );
    }

    const { listHeight } = this.state;

    return (
      <ActivityWrapper
        maxHeight={maxHeight}
        onHeightChange={(wrapperHeight) =>
          this.setState({ listHeight: wrapperHeight })
        }
      >
        <InfiniteLoader
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={loadMore}
          rowCount={items.length + 50}
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              ref={(el) => {
                this.list = el;
                registerChild(el);
              }}
              onRowsRendered={onRowsRendered}
              rowCount={items.length}
              deferredMeasurementCache={this.state.cache}
              rowHeight={this.state.cache.rowHeight}
              rowRenderer={this.rowRenderer}
              width={377}
              height={listHeight}
            />
          )}
        </InfiniteLoader>
      </ActivityWrapper>
    );
  }
}

const ActivityWrapper = (props) => {
  const wrapperRef = React.useRef();
  const wrapper = wrapperRef.current;

  React.useEffect(() => {
    if (!wrapper || !props.onHeightChange) {
      return;
    }

    props.onHeightChange(wrapper?.offsetHeight);
  }, [wrapper?.offsetHeight]); // eslint-disable-line

  return (
    <Wrapper
      ref={wrapperRef}
      style={{ maxHeight: props.maxHeight, height: 412 }}
    >
      <div style={{ height: '100%', width: 377 }}>{props.children}</div>
    </Wrapper>
  );
};
