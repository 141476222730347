import React from 'react';
import { compact, isArray, isEmpty } from 'lodash';

import { TYPE_TO_CATEGORY } from '@float/common/search/helpers';
import { SearchFilterDropdown } from 'components/SearchFilterDropdown';

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: this.getSelectedValues(props),
    };
  }

  componentDidMount() {
    this.checkForMultiSelectSupport();
  }

  componentDidUpdate(prevProps) {
    const newState = {};

    const { editingFilter, expandedCategory } = this.props;
    if (
      prevProps.editingFilter !== editingFilter ||
      prevProps.expandedCategory !== expandedCategory
    ) {
      newState.selectedValues = this.getSelectedValues();
      this.checkForMultiSelectSupport();
    }

    if (!isEmpty(newState)) this.setState(newState);
  }

  checkForMultiSelectSupport = () => {
    this.disableMultiSelect =
      this.props.expandedCategory === 'projectStatuses' &&
      window.location.pathname === '/report';
  };

  getSelectedValues = (props = this.props) => {
    let selectedValues = [];
    const { type, val } = props.editingFilter || {};
    if (TYPE_TO_CATEGORY[type] === props.expandedCategory) {
      selectedValues = isArray(val) ? val : [val];
    }
    return compact(selectedValues);
  };

  replaceValue = ({ type, add, remove }) => {
    const filter = { type, val: [...this.state.selectedValues] };

    remove.forEach((val) => {
      const valIdx = filter.val.indexOf(val);
      if (valIdx === -1) return;
      filter.val.splice(valIdx, 1);
    });

    add.forEach((val) => {
      filter.val.push(val);
    });

    filter.val.sort();
    this.props.addFilter(filter, undefined, undefined, true);
  };

  addValue = ({ type, val }) => {
    const filter = { type, val };
    filter.val = [...this.state.selectedValues, val];
    filter.val.sort();
    this.props.addFilter(filter, undefined, undefined, true);
  };

  removeValue = ({ type, val }) => {
    const filter = { type, val };
    filter.val = this.state.selectedValues.filter((x) => x !== val);
    if (filter.val.length)
      this.props.addFilter(filter, undefined, undefined, true);
    else {
      this.props.removeLastValueFromFilter(filter);
    }
  };

  onOperatorChange = (operator) => {
    this.props.setOperator(operator);
    if (this.state.selectedValues.length) {
      this.props.addFilter(this.props.editingFilter, operator, undefined, true);
    }
  };

  getRowCheckBox({ index }) {
    const {
      filteredContext: { result },
      expandedCategory,
    } = this.props;

    const item = result[index];
    const isSelected = this.state.selectedValues.includes(item.val);

    const showCheckbox =
      expandedCategory &&
      expandedCategory !== 'savedSearches' &&
      !this.disableMultiSelect;

    return {
      showCheckbox,
      isSelected,
    };
  }

  render() {
    const {
      addFilter,
      currentInput,
      editingFilter,
      expandCategory,
      expandedCategory,
      filteredContext,
      getPlaceholder,
      hideMeFilter,
      hidePeopleFilters,
      inputRef,
      listPopoverRef,
      onInputKeyDown,
      setInputValue,
      style,
      deleteSavedSearch,
      highlightedIndex,
      setHighlightedIndex,
    } = this.props;

    const { selectedValues } = this.state;

    return (
      <SearchFilterDropdown
        addFilter={addFilter}
        addValue={this.addValue}
        currentInput={currentInput}
        deleteSavedSearch={deleteSavedSearch}
        disableMultiSelect={this.disableMultiSelect}
        editingFilter={editingFilter}
        expandCategory={expandCategory}
        expandedCategory={expandedCategory}
        filteredContext={filteredContext}
        getPlaceholder={getPlaceholder}
        hideMeFilter={hideMeFilter}
        hidePeopleFilters={hidePeopleFilters}
        highlightedIndex={highlightedIndex}
        inputRef={inputRef}
        listPopoverRef={listPopoverRef}
        onInputKeyDown={onInputKeyDown}
        onOperatorChange={this.onOperatorChange}
        removeValue={this.removeValue}
        replaceValue={this.replaceValue}
        selectedValues={selectedValues}
        setHighlightedIndex={setHighlightedIndex}
        setInputValue={setInputValue}
        style={style}
      />
    );
  }
}

export default Autocomplete;
