import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconSortDescending = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.5 14H5M11.25 18.25h-6.5M8.25 9.75h-3.5M12.75 14.75l3.25 3.5 3.25-3.5M16 5.75v12.5"
      />
    </SVGIcon>
  );
};

export { IconSortDescending };
