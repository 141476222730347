import { Editor, Range, Transforms } from 'slate';

import { getLinks } from './get-links';
import { getWordUnderCaret } from './get-word-under-caret';
import { isLinkActive } from './is-link-active';
import { wrapLink } from './wrap-link';

/**
 * Wrap node in an anchor tag when previous word is an URL
 *
 * @param {KeyboardEvent} e KeyboardEvent object
 * @param {Editor} editor slate editor
 */

const linkifyOnKeyDown = (e, editor) => {
  if (isLinkActive(editor)) {
    // move outside the link
    if (e.key === ' ') {
      const { selection } = editor;
      const [, end] = Range.edges(selection);
      const [node] = Editor.node(editor, selection);

      if (end.offset >= node.text.length) {
        Transforms.move(editor, { unit: 'offset' });
      }
    }
    return;
  }

  if (e.key !== 'Enter' && e.key !== ' ' && e.key !== ',') {
    return;
  }

  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection);

    const [word, wordAnchorOffset] = getWordUnderCaret(
      Editor.string(editor, start.path),
      selection,
    );

    const links = getLinks(word);

    if (!links) {
      return;
    }

    links.forEach((link) => {
      const index = word.indexOf(link.value);
      const lastIndex = index + link.value.length;

      const target = {
        anchor: {
          path: start.path,
          offset: wordAnchorOffset + index,
        },
        focus: {
          path: start.path,
          offset: wordAnchorOffset + lastIndex,
        },
      };

      wrapLink(editor, link, target);
    });
  }
};

export { linkifyOnKeyDown };
