import { isOr } from '@float/common/search/helpers';
import { isFeatureEnabled } from '@float/libs/featureFlags';
import { FeatureFlag } from '@float/libs/featureFlags/constants';
import { BaseFilterToken } from '@float/types';

export function isLogicalConjunctionChangeBlocked(
  filter: BaseFilterToken,
  prevFilter: BaseFilterToken | undefined,
  forceNoOrBetweenTypes: boolean | undefined,
) {
  const noOrBetweenTypesEnabled = isFeatureEnabled(
    FeatureFlag.SearchFiltersNoOrBetweenTypes,
  );

  if (!noOrBetweenTypesEnabled && !forceNoOrBetweenTypes) return false;
  if (!prevFilter) return false;
  if (isOr(filter.operator)) return false;

  return filter.type !== prevFilter.type;
}
