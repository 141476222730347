// Initialize error tracking and translations before anything else
import './lib/config/initializeWebConfig';
import './lib/logger/initializeWebLogger';
import './translations';
import '@float/common/lib/requestIdleCallback';
import './perfMonitoring/rum';
import './floatPolyfills';
import 'formdata-polyfill';
import 'core-js/stable/set/intersection';
import 'core-js/stable/set/union';
import 'core-js/stable/set/difference';

import React from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import gsap from 'gsap';
import { Flip } from 'gsap/Flip';
import { createRoot } from 'react-dom/client';
import { Store, UnknownAction } from 'redux';

import { UIContextProviders } from '@float/common/contexts';
import { pushValueToGTM } from '@float/common/lib/gtm';
import { initIntercomWithoutUser } from '@float/common/lib/intercom';
import { ReduxState } from '@float/common/reducers/lib/types';
import { searchService } from '@float/common/search/service';
import { spawnSearchWorker } from '@float/common/search/service/spawnSearchWorker';
import { config } from '@float/libs/config';
import { todayManager } from '@float/libs/dates';
import { initializeFeatureFlagsClient } from '@float/libs/web/featureFlags';
import { ThemeProvider } from '@float/ui/deprecated';
import { AppErrorBoundary } from '@float/web/components/AppErrorBoundary';

import { FullPageLoader } from './components/FullPageLoader';
import { initPostHog } from './lib/posthog';
import { createWebAppStore } from './reducers';
import { Router } from './Router';

todayManager.startTodayAutoUpdate(); // We want to keep today updated if the user keeps the app open for more than a day

gsap.registerPlugin(Flip);

initPostHog();

const Bootstrapper = (props: {
  store: Store<ReduxState, UnknownAction, unknown>;
}) => {
  return (
    <ThemeProvider>
      <I18nProvider i18n={i18n}>
        <AppErrorBoundary>
          <UIContextProviders>
            <Router store={props.store} />
          </UIContextProviders>
        </AppErrorBoundary>
      </I18nProvider>
    </ThemeProvider>
  );
};

(async () => {
  // Initialize Intercom before anything else
  // Later we will re-initialize it with the user
  initIntercomWithoutUser();

  const wrapper = document.querySelector('.app.content-wrapper') as Element;

  // `searchWorker` must be initialized before the redux store to ensure correct
  // middleware is installed
  const worker = await spawnSearchWorker();
  searchService.setWorkerApi(worker);

  const root = createRoot(wrapper);

  root.render(<FullPageLoader isLoading />);

  await initializeFeatureFlagsClient(undefined, config.environment);

  const store = createWebAppStore();

  root.render(<Bootstrapper store={store} />);
})();

pushValueToGTM({
  event: 'WebAppLoaded',
});
