import React, { useCallback, useEffect, useState } from 'react';

import { LockLoggedTimeFrequency } from '@float/types/lockLoggedTime';
import Button from '@float/ui/deprecated/Earhart/Buttons/Button';
import { IconWarningTriangle } from '@float/ui/deprecated/Earhart/Icons';
import InputNumber, {
  InputNumberPatterns,
} from '@float/ui/deprecated/Earhart/Inputs/InputNumber';
import { OnOffToggle } from '@float/ui/deprecated/Earhart/Toggles/OnOffToggle';
import useSnackbar from '@float/ui/deprecated/Snackbar/useSnackbar';

import LockPeriodSummary from './LockPeriodSummary';
import {
  StyledCard,
  StyledColumn,
  StyledDropdown,
  StyledLabel,
  StyledLink,
  StyledRow,
  StyledRowHeader,
  StyledTitle,
} from './styles';

interface LockTimeLoggingSettingsCardProps {
  initialActive: boolean;
  isDisabled: boolean;
  initialInterval: number;
  initialFrequency: LockLoggedTimeFrequency;
  weekStartsOn: number;
  updateConfig: (
    active: boolean,
    interval: number,
    frequency: LockLoggedTimeFrequency,
  ) => void;
}

export const LockTimeLoggingSettingsCard: React.FC<
  LockTimeLoggingSettingsCardProps
> = ({
  initialActive,
  isDisabled,
  initialInterval,
  initialFrequency,
  weekStartsOn,
  updateConfig,
}) => {
  const { showSnackbar } = useSnackbar();
  const [isActive, setActive] = useState<boolean>(initialActive);
  const [updateEnabled, setUpdateEnabled] = useState<boolean>(false);
  const [interval, setInterval] = useState<number>(initialInterval);
  const [frequency, setFrequency] =
    useState<LockLoggedTimeFrequency>(initialFrequency);

  const updateActiveState = useCallback(
    async (active: boolean) => {
      try {
        await updateConfig(active, interval, frequency);
        showSnackbar(
          `Lock logged time ${active ? 'activated' : 'deactivated'}`,
        );
      } catch (error) {
        showSnackbar('Lock logged time activation failed.', {
          className: 'error',
          iconLeft: IconWarningTriangle,
          showClose: true,
        });
      }
    },
    [updateConfig, showSnackbar, interval, frequency],
  );

  const onUpdate = useCallback(async () => {
    try {
      await updateConfig(isActive, interval, frequency);
      setUpdateEnabled(false);
      showSnackbar('Settings updated.');
    } catch (error) {
      showSnackbar('Settings update failed.', {
        className: 'error',
        iconLeft: IconWarningTriangle,
        showClose: true,
      });
    }
  }, [updateConfig, showSnackbar, isActive, interval, frequency]);

  const onIntervalChange = useCallback((value: number) => {
    setInterval(value);
    setUpdateEnabled(true);
  }, []);

  const onFrequencyChange = useCallback(
    ({ value }: { value: LockLoggedTimeFrequency }) => {
      setFrequency(value);
      setUpdateEnabled(true);
    },
    [],
  );

  const onActiveStateChange = (value: boolean) => {
    setActive(value);
  };

  useEffect(() => {
    if (isActive !== initialActive) {
      updateActiveState(isActive);
    }
  }, [updateActiveState, initialActive, isActive]);

  return (
    <StyledCard>
      <StyledRowHeader disabled={!isActive}>
        <h5>Lock logged time settings</h5>

        <OnOffToggle
          isOn={isActive}
          onChange={onActiveStateChange}
          disabled={isDisabled}
        />
      </StyledRowHeader>

      <StyledRow>
        <StyledLabel disabled={!isActive}>
          Select the interval and frequency of when to lock.{' '}
          <StyledLink
            disabled={!isActive}
            href="https://support.float.com/en/articles/3616990-time-tracking"
            target="_blank"
          >
            See example
          </StyledLink>
        </StyledLabel>
      </StyledRow>

      <StyledRow>
        <StyledTitle disabled={!isActive}>Frequency</StyledTitle>
      </StyledRow>

      <StyledRow justifyContent="flex-start" alignItems="center" marginTop={12}>
        <StyledColumn>
          <StyledLabel disabled={!isActive}>Lock logged dates</StyledLabel>
        </StyledColumn>

        <StyledColumn>
          <InputNumber
            /*
            // TypeScript is inferring the wrong props
            // @ts-expect-error  */
            alignment="center"
            defaultValue={interval || 1}
            maxLength="2"
            min="1"
            max="99"
            aria-label="Lock time logging interval"
            disabled={!isActive}
            style={{ width: '2ch' }}
            onChange={onIntervalChange}
            withPattern={InputNumberPatterns.IntegerPositive}
          />
        </StyledColumn>

        <StyledColumn>
          <StyledLabel disabled={!isActive}>
            {interval > 1 ? 'days' : 'day'} after the end of every
          </StyledLabel>
        </StyledColumn>

        <StyledColumn>
          <StyledDropdown
            /*
            // TypeScript is inferring the wrong props
            // @ts-expect-error  */
            options={['Week', 'Month', 'Year']}
            aria-label="Lock time logging frequency"
            mapToValues={['week', 'month', 'year']}
            value={frequency}
            onChange={onFrequencyChange}
            disabled={!isActive}
          />
        </StyledColumn>
      </StyledRow>

      <StyledRow>
        <LockPeriodSummary
          interval={interval}
          frequency={frequency}
          weekStartsOn={weekStartsOn}
          disabled={!isActive}
        />
      </StyledRow>

      <StyledRow>
        {/* @ts-expect-error – Typescript is inferring the wrong props  */}
        <Button
          size="large"
          onClick={onUpdate}
          disabled={!isActive || !updateEnabled}
        >
          Update
        </Button>
      </StyledRow>
    </StyledCard>
  );
};
