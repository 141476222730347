import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { updateUserPref } from '@float/common/actions/currentUser';
import { getUserAccessRights } from '@float/common/selectors/userAccessRights';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppDispatch } from '@float/common/store';
import { PROMPTS } from '@float/constants/onboarding';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { useAnchor } from '@float/libs/web/hooks/useAnchor';
import { useWebAppSelectorStrict } from '@float/web/lib/store';
import { getProductTourAllStepsData } from '@float/web/store/onboardingManager/selectors/productTour';
import { getPromptDataFrom } from '@float/web/store/onboardingManager/selectors/prompt';
import { OnboardingManagerNextFunction } from '@float/web/store/onboardingManager/types';

import { StepBaseProps } from './components/StepBase';
import { StepOverlay } from './components/StepOverlay';
import { Transition } from './components/TransitionWrapper';
import { track } from './helpers/track';
import { StyledProductTour } from './styles';

type ProductTourProps = {
  next: OnboardingManagerNextFunction;
  replaying: boolean;
};

export const ProductTour = memo((props: ProductTourProps) => {
  const { next, replaying } = props;

  const dispatch = useAppDispatch();

  const { setViewType, setLogTimeViewType } = useScheduleContext();

  const { stepsData, stepsTotal } = useWebAppSelectorStrict(
    getProductTourAllStepsData,
  );
  const promptData = useWebAppSelectorStrict(
    getPromptDataFrom(PROMPTS.productTour),
  );
  const userSpecs = useWebAppSelectorStrict(getUserAccessRights);

  const [step, setStep] = useState(promptData?.fromReplay ? 1 : 0);

  const data = stepsData[step];
  const anchor = useAnchor(data);

  const { id } = data || {};
  const overlayIsIn = step <= stepsTotal;

  const goNext = useCallback(() => {
    if (step <= stepsTotal) {
      const nextStep = step + 1;

      setStep(nextStep);

      track({ step, data, replaying, dismissed: true });
    }
  }, [data, step, stepsTotal, replaying]);

  const skipTour = useCallback(() => {
    // we skip the tour by setting the step
    // out of bounds, causing the Transition component
    // to transition from current view to the exit view
    const exitStep = stepsTotal + 1;

    setStep(exitStep);

    track({ step, data, replaying, dismissed: true, clickedX: true });
  }, [data, setStep, step, stepsTotal, replaying]);

  // detect when exit view transitions in
  // to move forward in the onboarding
  const onEntered = useCallback(
    (node: HTMLElement) => {
      const isExiting = !!node.dataset.exit;

      if (isExiting) {
        next(PROMPTS.productTour, {});
      }
    },
    [next],
  );

  const commonChildProps = useMemo<StepBaseProps>(
    () => ({
      data,
      step,
      stepsTotal,
      anchor,
      userSpecs,
      goNext,
      skipTour,
    }),
    [data, step, stepsTotal, anchor, goNext, skipTour, userSpecs],
  );

  const disableHotKeys = useCallback((e: KeyboardEvent) => {
    prevent(e);
  }, []);

  useEffect(() => {
    if (step <= stepsTotal) {
      track({ step, data, replaying });
    }
  }, [step, stepsTotal, replaying, data]);

  useOnMount(() => {
    // reset schedule view type on product tour load
    setLogTimeViewType(false);
    setViewType('people');
    dispatch(updateUserPref('sked_view_type', 'people'));

    // disable background hotkeys during product tour
    document.addEventListener('keydown', disableHotKeys);

    return () => {
      document.removeEventListener('keydown', disableHotKeys);
    };
  });

  return (
    <StyledProductTour>
      <CSSTransition in={overlayIsIn} timeout={500} appear unmountOnExit>
        <StepOverlay anchor={anchor} />
      </CSSTransition>

      <Transition id={id} onEntered={onEntered}>
        {stepsData.map((step) => {
          if (step.id === id) {
            const Component = step.component;

            return <Component key={step.id} {...commonChildProps} />;
          }

          return null;
        })}
      </Transition>
    </StyledProductTour>
  );
});
