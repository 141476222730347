import { useRef } from 'react';

// As soon as `nextValue` equals `finalValue`, the returning value will stay `finalValue`.
export const useFixAtValueWhenReached = <T>(finalValue: T, nextValue: T) => {
  const finalRef = useRef(finalValue);
  const currentRef = useRef(nextValue);

  const shouldReset = finalRef.current !== finalValue;
  if (shouldReset) {
    finalRef.current = finalValue;
    currentRef.current = nextValue;
  }

  const finalReached = finalRef.current === currentRef.current;
  if (!finalReached) {
    currentRef.current = nextValue;
  }

  return currentRef.current;
};
