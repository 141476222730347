import { platform, userAgent } from '@float/libs/web/detect';

export function getDiagonalStripeGradient(
  color: string | number,
  angle: string | number,
) {
  if (platform === 'win' && userAgent === 'firefox') {
    return `linear-gradient(
      ${angle}deg,
      ${color} 25%,
      transparent 25%,
      transparent 50%,
      ${color} 50%,
      ${color} 75%,
      transparent 75%,
      transparent
    )`;
  }

  return `linear-gradient(
    ${angle}deg,
    ${color} 12.5%,
    transparent 12.5%,
    transparent 50%,
    ${color} 50%,
    ${color} 62.5%,
    transparent 62.5%,
    transparent
  )`;
}
