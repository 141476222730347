import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useMedia, useUpdateEffect } from 'react-use';
import TaskModalActivity from 'activity/components/TaskModalActivity';
import { motion } from 'framer-motion';
import { isUndefined } from 'lodash';
import ProjectColor from 'manage/projects-v2/ProjectColor';
import modalManagerHoc from 'modalManager/modalManagerHoc';
import { ModalConfig } from 'modalManager/useManageModal';
import {
  getLockPeriodDates,
  getLoggableSearchFilteredActivePeople,
  getLoggedTimesRawMap,
  getMeFilter,
  getNonTentativePhasesOptions,
  getNonTentativeProjectsOptions,
  getPeopleMap,
  getPMLoggableNonTentativeProjectsOptions,
  getUser,
  isLoggedTimeDeletePlaceholder,
  PhaseOption,
} from 'selectors';

import {
  TaskNameSelect,
  TaskNameSelectRef,
} from '@float/common/components/TaskNameSelect';
import { validateTaskName } from '@float/common/components/TaskNameSelect/validateTaskName';
import { Rights } from '@float/common/lib/acl';
import { fadeIn } from '@float/common/lib/animation.css';
import { useTaskMetas } from '@float/common/lib/hooks/useTaskMetas';
import { getDefaultPhaseForDate } from '@float/common/lib/scheduleUtils';
import { getDateString } from '@float/common/lib/utils';
import { ReduxState } from '@float/common/reducers/lib/types';
import { getLoggedTimeHasTimers } from '@float/common/selectors/timer';
import { LockedPeriods } from '@float/common/serena/Data/useRowMetas.helpers';
import { useAppSelector, useAppSelectorWithParams } from '@float/common/store';
import {
  AccountType,
  CurrentUser,
  LoggedTime,
  LoggedTimeTaskReference,
  Person,
  Phase,
  Project,
  ProjectOption,
  ProjectOptions,
  SearchTask,
  Task,
} from '@float/types';
import {
  Button,
  Modal,
  ModalBody,
  ModalTitle,
  RichText,
  Row,
  Spacer,
  VirtualSelect,
} from '@float/ui/deprecated';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { IconClose, IconTrash } from '@float/ui/deprecated/Earhart/Icons';
import { integrationTypes } from '@float/web/pmSidebar/integrationTypes';
import PhaseDropdown, {
  PhaseDropdownOption,
} from 'components/elements/PhaseDropdown';
import { ProjectAddTask } from 'components/taskModals/dom/ProjectAddTask';

import { LogTimeModalReadOnly } from '../LogTimeModalReadOnly';
import { LogTimeInputs } from './components/LogTimeInputs';
import { SelectUserLogTime } from './components/SelectUserLogTime';
import { useNotes } from './hooks/useNotes';
import { usePhaseState } from './hooks/usePhaseState';
import { usePmIntegrationInfo } from './hooks/usePmIntegrationInfo';
import { useStartTimerRights } from './hooks/useStartTimerRights';
import { useTimerControls } from './hooks/useTimerControls';
import {
  StyledCarryOverText,
  StyledHeaderDate,
  StyledInputsWrapper,
  StyledModalActions,
  StyledModalHeader,
  StyledProjectAndPhase,
  StyledSection,
  StyledSectionLink,
  StyledViewInPM,
} from './styles';

type LogTimeModalViewProps = {
  actions: {
    scrollToToday: () => void;
    isTodayVisible: () => boolean;
  };
  assignablePeople: Person[];
  isFullDayTimeoff: (personId: number | undefined, date: string) => boolean;
  isLogMyTimeView: boolean;
  isMeFilterActive: boolean;
  lockPeriod: LockedPeriods;
  manageModal: (config: ModalConfig) => void;
  modalSettings: {
    entity: LoggedTime | LoggedTimeTaskReference;
    person: Person;
    readOnly: boolean;
  };
  onSave: (changes: Array<any>) => {};
  pathname: string;
  people: Record<string, Person>;
  phasesOptions: Record<string, PhaseOption[]>;
  pmBaseUrl: string;
  pmIntegrationType: keyof typeof integrationTypes | undefined;
  pmProjectsOptions: ProjectOptions[];
  projects: Record<number, Project>;
  phases: Record<number, Phase>;
  projectsOptions: ProjectOptions[];
  taskLinksMap: any;
  tasks: Record<string, Task | SearchTask>;
  user: CurrentUser;
};

function LogTimeModalView(props: LogTimeModalViewProps) {
  const {
    actions,
    assignablePeople,
    isFullDayTimeoff,
    isLogMyTimeView,
    isMeFilterActive,
    lockPeriod,
    manageModal,
    modalSettings,
    onSave,
    pathname,
    people,
    phasesOptions,
    pmProjectsOptions,
    projects,
    phases,
    projectsOptions,
    tasks,
    user,
  } = props;

  const { entity, person: defaultPerson } = modalSettings;

  const loggedTime = useAppSelector((state) => {
    const loggedTimes = getLoggedTimesRawMap(state);

    const loggedTime = loggedTimes[entity.logged_time_id];

    if (loggedTime && isLoggedTimeDeletePlaceholder(loggedTime)) {
      // This shouldn't be possible, but if happens let's raise an error to track it
      throw new Error('Opening a LogTimeModal on a delete placeholder record');
    }

    return loggedTime as LoggedTime | undefined;
  });

  const isPmEditingSelf =
    user.account_type_id == AccountType.ProjectManager &&
    entity.people_id == user.people_id;
  const groupedProjects = useMemo(() => {
    return (isPmEditingSelf ? pmProjectsOptions : projectsOptions).map(
      (group: ProjectOptions) => ({
        ...group,
        options: group.options.map((o: ProjectOption) => ({
          ...o,
          icon: <ProjectColor color={o.color} />,
        })),
      }),
    );
  }, [isPmEditingSelf, pmProjectsOptions, projectsOptions]);

  const isMobile = useMedia('(max-width: 740px)');
  const isCreate =
    !!entity.isTaskReference ||
    !entity.logged_time_id ||
    entity.logged_time_id.includes('.');

  const firstRenderPhaseId = useRef(entity.phase_id || 0);

  const [saving, setSaving] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [date, setDate] = useState(entity.date);
  const [hours, setHours] = useState<number | null>(
    entity.hours ?? (localStorage.getItem('logTimeDefaultHours') || 0.5),
  );
  const [projectId, setProjectId] = useState(entity.project_id);
  const phaseState = usePhaseState({
    isCreate,
    entity,
    phasesOptions,
    projectId,
    onChange(phaseId) {
      taskMetas.onPhaseChange(phaseId);
    },
  });

  const currentProject = projects[projectId];
  const currentPhase = phases[phaseState.phaseId];

  const [person, setPerson] = useState<Person | CurrentUser | undefined>(() => {
    if (entity?.people_id) return people[entity.people_id];
    if (isMeFilterActive || pathname === '/log-time') return user;
    if (defaultPerson) return people[defaultPerson.people_id];
    return assignablePeople[0];
  });

  const isAssigneeReadOnly = useMemo(() => {
    return (
      !isCreate ||
      (assignablePeople?.length === 1 &&
        assignablePeople[0].people_id == person?.people_id)
    );
  }, [isCreate, assignablePeople, person?.people_id]);

  const [projectErrors, setProjectErrors] = useState<Array<string>>([]);
  const [taskErrors, setTaskErrors] = useState<Array<string>>([]);

  const taskRef = entity.task_id ? tasks[entity.task_id] : null;
  const taskRefHours = (taskRef && 'hours' in taskRef && taskRef.hours) || 0;
  const [notes, setNotes] = useNotes(entity, taskRef);

  const [triggerSubmit, setTriggerSubmit] = useState(false);

  const taskMetas = useTaskMetas({
    projectId,
    phaseId: phaseState.phaseId,
    entity,
  });

  const hasTasks = taskMetas.data?.some((entry) => entry.task_name !== '');

  const hasTimers = useAppSelectorWithParams(
    getLoggedTimeHasTimers,
    loggedTime?.logged_time_id,
  );

  const onClose = () => {
    manageModal({
      visible: false,
      modalType: 'logTimeModal',
    });
  };

  const validate = () => {
    setProjectErrors([]);
    setTaskErrors([]);

    let valid = true;

    if (!projectId) {
      setProjectErrors(['Please select a project']);
      valid = false;
    }

    const taskNameError = validateTaskName({
      taskMetas: taskMetas.data,
      project: currentProject,
      taskName: taskMetas.taskName,
    });

    if (taskNameError) {
      setTaskErrors(taskNameError);
      valid = false;
    }

    return valid;
  };

  const submit = async (
    hours: number,
    createNewLoggedTime: boolean,
    overrideDate?: string,
  ) => {
    const changes = [
      {
        type: 'loggedTime',
        entity: {
          logged_time_id: createNewLoggedTime ? null : entity.logged_time_id,
          reference_date: overrideDate || entity.reference_date,
          task_id: entity.task_id,
          priority: entity?.priority,
          people_id: person?.people_id,
          project_id: projectId,
          phase_id: phaseState.phaseId,
          task_meta_id: taskMetas.taskMetaId,
          task_name: taskMetas.taskName,
          date: overrideDate || date,
          hours,
          notes,
        },
      },
    ];

    return onSave(changes) as Promise<[{ loggedTime: LoggedTime }]>;
  };

  async function handleSubmit(overrideHours?: number) {
    if (!validate() || saving || removing) return;

    const _hours = isUndefined(overrideHours) ? Number(hours) : overrideHours;

    if (!_hours) setRemoving(true);
    else setSaving(true);

    try {
      await submit(_hours, isCreate);
      onClose();
    } catch {
      setSaving(false);
      setRemoving(false);
    }
  }

  const { canStartTimer } = useStartTimerRights({
    currentLoggedTimeDate: date,
    currentLoggedTimePerson: person,
    hasTimers,
  });

  const { handleTimerStart, handleTimerStop } = useTimerControls({
    actions,
    currentDate: date,
    currentHours: Number(hours),
    isRemoving: removing,
    isSaving: saving,
    isSuggestedTask: isCreate,
    onClose,
    submit,
    validate,
  });

  // If the user pressed enter on the task name input, we want to submit the
  // form. However, the submit function would have the old value of the
  // task name, so we have to trigger the submit on the next render.
  useEffect(() => {
    if (!triggerSubmit) return;
    handleSubmit();
  }, [triggerSubmit]); // eslint-disable-line

  function handleDateChange(date: string) {
    setDate(date);

    phaseState.onDateChange(date);
  }

  let RightIcon = IconTrash;
  if (!isMobile && entity.isTaskReference) {
    RightIcon = IconClose;
  }

  const taskNameFieldRef = useRef<TaskNameSelectRef>(null);
  const notesRef = useRef<HTMLInputElement>(null);

  const onProjectSelect = (opt: { value: string }) => {
    const projectId = Number(opt.value);
    taskMetas.onProjectChange(projectId);
    setProjectId(projectId);
    const isOriginalProjectSelected = projectId === entity.project_id;
    phaseState.setPhaseManuallyChosen(
      isCreate ? false : isOriginalProjectSelected,
    );

    if (!isCreate && isOriginalProjectSelected) {
      taskMetas.onPhaseChange(phaseState.phaseId);
      phaseState.setPhaseId(firstRenderPhaseId.current);
    } else {
      const phaseId = getDefaultPhaseForDate({
        phases: phasesOptions[projectId],
        start: date,
      });
      taskMetas.onPhaseChange(phaseId);
      phaseState.setPhaseId(phaseId);
    }
  };

  const onPhaseSelect = (option: PhaseDropdownOption) => {
    const phaseId = Number(option.value);

    taskMetas.onPhaseChange(phaseId);
    phaseState.setPhaseId(phaseId);
    phaseState.setPhaseManuallyChosen(true);
  };

  const setNotesFromRichText = (data: { text: string }) => {
    setNotes(data.text);
  };

  const onNotesCleared = useCallback(() => {
    notesRef.current!.focus();
  }, []);

  const clearNotes = useCallback(() => {
    setNotes('', onNotesCleared);
  }, [setNotes, onNotesCleared]);

  const pmIntegrationInfo = usePmIntegrationInfo(entity);

  const canCreateInline = Rights.canCreateTaskMeta(props.user, {
    project: currentProject,
  });

  const integrationLink = pmIntegrationInfo.url && (
    <StyledViewInPM as="a" href={pmIntegrationInfo.url} target="_blank">
      View in {pmIntegrationInfo.integrationName}
    </StyledViewInPM>
  );

  const onTaskNameSelectBlur = () => {
    if (!taskMetas.taskName) taskMetas.onForceShowTaskField(false);
  };

  const [animate, setAnimate] = useState(true);

  const isLayoutAnimationEnabled = (
    layout?: 'position' | 'size' | 'preserve-aspect',
  ) => (animate ? layout || true : false);
  const isLoadingTaskMetas = taskMetas.loading;

  useEffect(() => {
    if (taskMetas.forceShowTaskField) {
      taskNameFieldRef.current?.focus();
    }
  }, [taskMetas.forceShowTaskField, taskNameFieldRef]);

  // when a timer gets created, updated or deleted,
  // we need to update the current logged hours
  useUpdateEffect(() => {
    if (!loggedTime) return;

    setHours(loggedTime.hours);

    // if the updated logged time result in 0 hours that means the
    // last timer was deleted and there was no manual logged time left
    // so the modal gets closed automatically
    if (loggedTime.hours === 0) {
      onClose();
    }
  }, [loggedTime?.hours]);

  return (
    <Modal
      isOpen
      onClose={onClose}
      focusFirstEl
      layoutAnimation={isLayoutAnimationEnabled()}
    >
      <StyledModalHeader layout={isLayoutAnimationEnabled('preserve-aspect')}>
        <ModalTitle>Log time</ModalTitle>
        <StyledHeaderDate>{getDateString(date)}</StyledHeaderDate>
      </StyledModalHeader>
      <ModalBody>
        <StyledInputsWrapper
          layout={isLayoutAnimationEnabled('preserve-aspect')}
        >
          <LogTimeInputs
            canStartTimer={canStartTimer}
            date={date}
            entity={entity}
            hours={hours}
            isFullDayTimeoff={isFullDayTimeoff}
            isMobile={isMobile}
            lockPeriod={lockPeriod}
            onTimerStart={handleTimerStart}
            onTimerStop={handleTimerStop}
            person={person}
            setDate={handleDateChange}
            setHours={setHours}
            submit={handleSubmit}
            suggestedHours={taskRefHours}
            user={user}
          />
        </StyledInputsWrapper>

        <StyledProjectAndPhase $loading={isLoadingTaskMetas}>
          <motion.div
            layout={isLayoutAnimationEnabled('preserve-aspect')}
            style={{ width: '100%' }}
          >
            <VirtualSelect
              // @ts-expect-error VirtualSelect does not have types
              label="Project"
              visibleItems={6}
              placeholder=""
              nonNullable
              clearInputOnDropdownOpen={false}
              value={projectId}
              groupedOptions={groupedProjects}
              hideSelectedIcon
              onChange={onProjectSelect}
              errors={projectErrors}
            />

            <Row alignItems="center" style={{ marginTop: 5 }}>
              <PhaseDropdown
                options={props.phasesOptions[projectId]}
                value={phaseState.phaseId}
                projectColor={currentProject?.color}
                onChange={onPhaseSelect}
              />
              {taskMetas.showNoTaskLine && (
                <ProjectAddTask
                  entity={currentPhase || currentProject}
                  canEdit={canCreateInline}
                  onClickEdit={() => {
                    taskMetas.onForceShowTaskField(true);
                  }}
                />
              )}
            </Row>
          </motion.div>
        </StyledProjectAndPhase>
        {taskMetas.showEditTaskField && (
          <StyledSection
            className={fadeIn}
            layout={isLayoutAnimationEnabled('preserve-aspect')}
          >
            <TaskNameSelect
              ref={taskNameFieldRef}
              project={currentProject}
              taskErrors={taskErrors}
              value={taskMetas.taskName}
              taskNames={taskMetas.data}
              onEnter={() => setTriggerSubmit(true)}
              onInputChange={taskMetas.onTaskInputChange}
              onChange={taskMetas.onTaskChange}
              onBlur={onTaskNameSelectBlur}
              style={{
                marginBottom: pmIntegrationInfo.url === undefined ? 26 : 0,
              }}
              placeholder={
                hasTasks
                  ? 'Choose a task to log time against'
                  : 'Task name eg. planning, delivery'
              }
            />
            <StyledSectionLink>{integrationLink}</StyledSectionLink>
          </StyledSection>
        )}
        <motion.div
          layout={isLayoutAnimationEnabled('preserve-aspect')}
          onFocus={() => setAnimate(false)}
          onBlur={() => setAnimate(true)}
        >
          <RichText
            ref={notesRef}
            // @ts-expect-error RichText does not have types
            label="Notes"
            value={notes}
            onChange={setNotesFromRichText}
            maxLength={1500}
          />

          {notes &&
            entity.isTaskReference &&
            taskRef &&
            (taskRef as Task).notes === notes && (
              <Row
                justifyContent="flex-end"
                style={{
                  marginTop: 6,
                }}
              >
                <StyledCarryOverText>From schedule </StyledCarryOverText>
                <TextButton appearance="flue-fill" onClick={clearNotes}>
                  Clear
                </TextButton>
              </Row>
            )}
          {!isLogMyTimeView && (
            <>
              <Spacer size={30} />
              <SelectUserLogTime
                // only allow changing assignee if log is not created
                // https://app.asana.com/0/0/1201126149007469/1201133415280510/f
                isReadOnly={isAssigneeReadOnly}
                user={user}
                peopleList={assignablePeople}
                person={person}
                setPerson={setPerson}
              />
            </>
          )}
          {entity.logged_time_id && !entity.isTaskReference && (
            <TaskModalActivity
              task={entity}
              composed={false}
              style={{ margin: '30px 0 0' }}
            />
          )}
        </motion.div>
      </ModalBody>
      <StyledModalActions layout={isLayoutAnimationEnabled('preserve-aspect')}>
        <Button loader={saving} onClick={() => handleSubmit()}>
          {isCreate ? 'Log time' : 'Update'}
        </Button>
        <Button appearance="secondary" onClick={onClose}>
          Cancel
        </Button>
        {entity.logged_time_id && (
          <Button
            appearance="clear-danger"
            style={{ marginLeft: 'auto' }}
            iconRight={RightIcon}
            tabIndex="-1"
            loader={removing}
            onClick={() => {
              handleSubmit(0);
            }}
          >
            {!isMobile && entity.isTaskReference && 'Remove'}
            {!isMobile && !entity.isTaskReference && 'Delete'}
          </Button>
        )}
      </StyledModalActions>
    </Modal>
  );
}

function LogTimeModalRouter(props: LogTimeModalViewProps) {
  if (props.modalSettings.readOnly) {
    return <LogTimeModalReadOnly {...props} />;
  }

  return <LogTimeModalView {...props} />;
}

const mapStateToProps = (state: ReduxState) => {
  return {
    user: getUser(state),
    isMeFilterActive: getMeFilter(state),
    people: getPeopleMap(state),
    assignablePeople: getLoggableSearchFilteredActivePeople(state),
    projectsOptions: getNonTentativeProjectsOptions(state),
    pmProjectsOptions: getPMLoggableNonTentativeProjectsOptions(state),
    projects: state.projects.projects,
    phases: state.phases.phases,
    tasks: state.tasks.tasks,
    phasesOptions: getNonTentativePhasesOptions(state),
    clients: state.clients.clients,
    lockPeriod: getLockPeriodDates(state),
  };
};

const LogTimeModal = modalManagerHoc({
  Comp: connect(mapStateToProps)(LogTimeModalRouter),
  modalType: 'logTimeModal',
});

export { LogTimeModal };
