import React from 'react';
import { Trans } from '@lingui/macro';

import { Activity, ViewActivity } from '@float/types/activity';
import { RawView } from '@float/types/view';

type UpdateViewActivityType = Extract<
  Activity,
  { item_type: 'view'; activity_type: 'update' }
>;

function getChangesText(item: UpdateViewActivityType) {
  const changes: JSX.Element[] = [];

  const view = item.after_data;

  for (const [key, value] of Object.entries(item.before_data)) {
    const prop = key as keyof RawView;

    switch (prop) {
      case 'personal': {
        if (view.personal) {
          const name = item.before_data.name || view.name;

          // If the View is made personal, we want to show the name of the View
          // when it was visible to eveyone
          return [
            <Trans key="updated-to-personal">
              Updated <b>{name}</b> from shared to personal
            </Trans>,
          ];
        } else {
          // If the View is shared, we want to show the current name of the View
          return [
            <Trans key="updated-to-shared">
              Shared <b>{view.name}</b> with the Team
            </Trans>,
          ];
        }
      }

      case 'name': {
        if (view.personal) continue;
        if (!value) continue;

        changes.push(
          <Trans key="updated-view-name">
            Updated View name from <b>{value.toString()}</b> to{' '}
            <b>{view.name}</b>
          </Trans>,
        );

        break;
      }
    }
  }

  return changes;
}

export function formatViewActivityText(item: ViewActivity) {
  switch (item.activity_type) {
    case 'create': {
      const view = item.after_data;

      if (view.personal) return null;

      return {
        text: ``,
        element: (
          <Trans key="updated-to-shared">
            Shared <b>{view.name}</b> with the Team
          </Trans>
        ),
      };
    }
    case 'update': {
      const changes = getChangesText(item);

      if (changes.length === 0) return null;

      return {
        text: '',
        element: changes,
      };
    }
    case 'delete': {
      const view = item.before_data;

      if (view.personal) return null;

      return {
        text: '',
        element: (
          <Trans key="updated-to-shared">
            Deleted <b>{view.name}</b>
          </Trans>
        ),
      };
    }
  }

  return null;
}
