import { useCallback } from 'react';

import { useAppStore } from '@float/common/store';

import { SearchAutocompleteParams } from './selectors/getSearchAutocompleteResults';
import { searchService } from './service';

export function useSearchAutocompleteResultsCallback() {
  const store = useAppStore();

  return useCallback(
    async (params: SearchAutocompleteParams) => {
      return searchService.getSearchAutocompleteResults(
        store.getState(),
        params,
      );
    },
    [store],
  );
}
