import { StyleRule } from '@vanilla-extract/css';
import { css } from 'styled-components';

export const M500 = css`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
`;

export const VE_M500: StyleRule = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 10,
  lineHeight: 10,
};
