import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconFolderUser = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.05 19.25h-4.3a2 2 0 0 1-2-2v-9.5h12.5a2 2 0 0 1 2 2v1.3"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m13.5 7.5-.931-1.708a2 2 0 0 0-1.756-1.042H6.75a2 2 0 0 0-2 2V11"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.994 13.674a1.434 1.434 0 1 1-2.867 0 1.434 1.434 0 0 1 2.867 0Zm-3.707 5.546h4.546c.504 0 .892-.432.657-.878-.346-.655-1.135-1.595-2.93-1.595-1.794 0-2.583.94-2.93 1.595-.235.446.153.879.657.879Z"
      />
    </SVGIcon>
  );
};

export { IconFolderUser };
