import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconHat = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.75 12v3s1.237 1.25 5.25 1.25S17.25 15 17.25 15v-3m-10.5 0V6.75a2 2 0 0 1 2-2h6.5a2 2 0 0 1 2 2V12m-10.5 0s1.237 1.25 5.25 1.25S17.25 12 17.25 12M6.646 14.646c-1.177.488-1.896 1.14-1.896 1.854 0 1.519 3.246 2.75 7.25 2.75s7.25-1.231 7.25-2.75c0-.715-.718-1.366-1.896-1.854"
      />
    </SVGIcon>
  );
};

export { IconHat };
