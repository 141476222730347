import { EffectCallback, useEffect, useLayoutEffect } from 'react';

export function useOnMount(callback: EffectCallback) {
  // The effect is called only on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
}

export function useLayoutEffectOnMount(callback: EffectCallback) {
  // The effect is called only on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(callback, []);
}
