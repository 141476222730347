import React from 'react';
import linkifyStr from 'linkify-string';
import styled from 'styled-components';

import { sanitize } from '@float/common/lib/security';
import { userAgent } from '@float/libs/web/detect';

import * as Colors from '../Earhart/Colors';

const StyledParagraph = styled.p`
  && {
    position: relative;

    box-sizing: border-box;
    border: none;

    width: 100%;
    cursor: text;
    white-space: pre-wrap;
    word-break: break-word;

    padding: 8px 0;
    margin: 0;

    z-index: 1;

    &:focus {
      outline: none;
    }

    &[contenteditable='true'] {
      -webkit-user-select: text;
      user-select: text;
    }

    a {
      font-weight: 500;

      color: ${Colors.FIN.Lt.Emphasis.Primary};

      &:hover {
        color: ${Colors.FIN.Lt.Emphasis.Medium};
      }
    }
  }
`;

const isAllowedKeyCode = (e) => {
  return (
    [8, 13, 27, 37, 38, 39, 40].includes(e.keyCode) || e.ctrlKey || e.metaKey
  );
};

class TextArea extends React.Component {
  pRef = React.createRef();

  state = {
    hasFocus: false,
  };

  componentDidMount() {
    this.pRef.current.innerHTML = this.linkifiedValue();
    if (userAgent === 'ie') {
      this.observer = new window.MutationObserver(this.handleChange);
      this.observer.observe(this.pRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }
    if (this.props.autoFocus) {
      this.pRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const valueChangedWithoutUserInput =
      !this.state.hasFocus && prevProps.value !== this.props.value;

    if (valueChangedWithoutUserInput) {
      this.refresh();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  refresh = () => {
    if (this.pRef.current) {
      this.pRef.current.innerHTML = sanitize(this.props.value);
    }
  };

  linkifiedValue = () => {
    if (!this.props.value) return '';

    return linkifyStr(this.props.value, {
      attributes: {
        contentEditable: false,
        tabIndex: -1,
      },
    }).replace(/\n/g, '<br/>');
  };

  handlePaste = (evt) => {
    evt.preventDefault();
    const text = sanitize(evt.clipboardData.getData('text/plain'));
    document.execCommand('insertHTML', false, text);
    const newText = this.getText();
    if (!this.isWithinMaxLength(newText)) {
      this.pRef.current.innerHTML = newText.substr(0, this.props.maxLength);
    }
  };

  isFocusedOnTextArea(selection) {
    return (
      !!selection.focusNode &&
      (selection.focusNode === this.pRef.current ||
        selection.focusNode.parentNode === this.pRef.current)
    );
  }
  ensureCaretIsDisplayed() {
    const selection = window.getSelection();
    const isCaretOnRef = this.isFocusedOnTextArea(selection);
    if (!isCaretOnRef) {
      this.pRef.current.focus();
      const currentText = this.getText();
      selection.collapse(this.pRef.current, currentText.length);
    }
  }
  focus = () => {
    this.ensureCaretIsDisplayed();
  };

  handleFocus = (evt) => {
    const handleFocus = () => {
      this.setState({
        hasFocus: true,
      });

      if (typeof this.props.onFocus === 'function') {
        this.props.onFocus();
      }
    };

    if (evt.target.tagName !== 'A') {
      // Capturing events on children of contenteditable element is undpredictable. E.g. on firefox, event.target is
      // always "P" - i.e. the contenteditable element and never the child that triggers the event. For this reason,
      // to allow clicks on hyperlinks to go through, focus code (de-linkification) is called after a slight delay.
      // Without the delay, innerHTML changes immediately and link click never goes through.
      if (userAgent === 'firefox') {
        setTimeout(handleFocus, 100);
      } else {
        handleFocus();
      }
    }
  };

  handleKeyDown = (e) => {
    if (e.key === 'Tab' && typeof this.props.onTabKey === 'function') {
      return this.props.onTabKey(e);
    }
    if (!this.isWithinMaxLength() && !isAllowedKeyCode(e)) {
      e.preventDefault();
    }
  };

  handleBlur = () => {
    this.setState({ hasFocus: false });

    // contentEditable element doesn't hide the caret on blur.
    // We'll have to do it manually.
    const selection = window.getSelection();
    const isCaretOnRef = this.isFocusedOnTextArea(selection);

    if (isCaretOnRef) {
      selection.removeAllRanges();
    }

    this.pRef.current.innerHTML = this.linkifiedValue();
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur();
    }
  };

  handleChange = () => {
    const text = this.getText();
    if (this.isWithinMaxLength(text)) {
      this.props.onChange(text);
    } else {
      this.props.onChange(text.substring(0, this.props.maxLength));
    }
  };

  isWithinMaxLength = (text = this.getText()) => {
    return !this.props.maxLength || !text || text.length < this.props.maxLength;
  };

  getText = () => this.pRef.current.innerText;

  render() {
    return (
      <StyledParagraph
        ref={this.pRef}
        contentEditable={!this.props.readOnly}
        onKeyDown={this.props.maxLength ? this.handleKeyDown : undefined}
        onPaste={this.handlePaste}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onInput={this.handleChange}
      />
    );
  }
}

TextArea.defaultProps = {
  value: '',
  maxLength: undefined,
};

TextArea._styles = { StyledParagraph };

export default TextArea;
