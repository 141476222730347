import React from 'react';

import { StepBase } from '../../StepBase';
import {
  StyledHighlightedWord,
  StyledList,
  StyledListItem,
  StyledParagraph,
  StyledTitle,
} from '../../StepBase/styles';

const SideNavigationContent = (props) => {
  const { userSpecs } = props;

  return (
    <>
      <StyledTitle>Start navigating</StyledTitle>
      <StyledParagraph>Access the key features of Float:</StyledParagraph>
      <StyledList>
        <StyledListItem>
          <StyledHighlightedWord>Schedule</StyledHighlightedWord>
          {userSpecs.canOnlyViewThemself
            ? ' your tasks in one place'
            : ' your team and tasks in one place'}
        </StyledListItem>
        {userSpecs.hasManage && (
          <StyledListItem>
            Manage your <StyledHighlightedWord>People</StyledHighlightedWord>
          </StyledListItem>
        )}
        {userSpecs.hasManage && (
          <StyledListItem>
            Manage your <StyledHighlightedWord>Projects</StyledHighlightedWord>
          </StyledListItem>
        )}
        <StyledListItem>
          <StyledHighlightedWord>Report</StyledHighlightedWord>
          {userSpecs.canOnlyViewThemself
            ? ' your utilisation'
            : ' on projects and team utilisation'}
        </StyledListItem>
        {userSpecs.hasTimeTracking && (
          <StyledListItem>
            <StyledHighlightedWord>Log</StyledHighlightedWord> your time against
            scheduled hours
          </StyledListItem>
        )}
      </StyledList>
    </>
  );
};

const SideNavigation = (props) => {
  return (
    <StepBase {...props}>
      <SideNavigationContent userSpecs={props.userSpecs} />
    </StepBase>
  );
};

export default SideNavigation;
