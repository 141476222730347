import { search } from '@float/common/api3/search';
import { FilterToken } from '@float/types/view';

import { FILTER_TYPE_TO_REMOTE_TYPE } from './constants';

function toArray(val: string | string[]) {
  return Array.isArray(val) ? val : [val];
}

/** Converts the filter values into a list of ids */
export async function lookupFilter(filter: FilterToken): Promise<number[]> {
  const val = toArray(filter.val);

  if (filter.type === 'contains') return [];

  const field = FILTER_TYPE_TO_REMOTE_TYPE[filter.type];

  const results = await Promise.all(
    val.map((value) => search.lookup(value, field)),
  );

  const ids: number[] = [];

  for (const result of results) {
    for (const entry of result) {
      ids.push(entry.id);
    }
  }

  return ids;
}
