// The order of items in this array controls the order of categories that

import { LegacyFilterTypes, VirtualFilterTypes } from '@float/types';

// are rendered on the drop down.
export const FILTERABLE_KEYS = [
  'savedSearches' as const,
  'departments' as const,
  'people' as const,
  'managers' as const,
  'jobTitles' as const,
  'personTags' as const,
  'personTypes' as const,
  'timeoffs' as const,
  'clients' as const,
  'projects' as const,
  'phases' as const,
  'projectTags' as const,
  'projectOwners' as const,
  'projectStatuses' as const,
  'tasks' as const,
  'taskStatuses' as const,
  'timeoffStatuses' as const,
];

export type SearchAutocompleteCategory = (typeof FILTERABLE_KEYS)[0];

export const CATEGORY_TO_TYPE = {
  clients: 'client',
  contains: 'contains',
  departments: 'department',
  jobTitles: 'jobTitle',
  roles: 'role',
  people: 'person',
  managers: 'manager',
  personTags: 'personTag',
  personTypes: 'personType',
  projectStatuses: 'projectStatus',
  projectTags: 'projectTag',
  projects: 'project',
  projectOwners: 'projectOwner',
  tasks: 'task',
  taskStatuses: 'taskStatus',
  timeoffs: 'timeoff',
  timeoffStatuses: 'timeoffStatus',
  phases: 'phase',
  savedSearches: 'savedSearch',
} as const;

export const PERSON_RELATED_KEYS = [
  'departments',
  'people',
  'managers',
  'personTags',
  'personTypes',
  'jobTitles',
  'roles',
];

export const PERSON_RELATED_TYPES = PERSON_RELATED_KEYS.map(
  (k) => CATEGORY_TO_TYPE[k as keyof typeof CATEGORY_TO_TYPE],
) as string[];

export const FRIENDLY_NAMES = {
  clients: 'Clients',
  contains: 'Contains',
  departments: 'Departments',
  jobTitles: 'Roles',
  people: 'People',
  managers: 'Managers',
  personTags: 'Person tag',
  personTypes: 'Person type',
  projectStatuses: 'Project status',
  projectTags: 'Project tags',
  projects: 'Projects',
  projectOwners: 'Project owners',
  phases: 'Phases',
  savedSearches: 'Saved',
  tasks: 'Tasks',
  taskStatuses: 'Task status',
  timeoffs: 'Time offs',
  timeoffStatuses: 'Time off status',
};

export const OLD_TYPE_TO_TYPE: Record<LegacyFilterTypes, VirtualFilterTypes> = {
  contains: 'contains',
  people: 'person',
  project: 'project',
  'person tag': 'personTag',
  person_type: 'personType',
  department: 'department',
  'job title': 'jobTitle',
  client: 'client',
  timeoff: 'timeoff',
  task: 'task',
  task_status: 'taskStatus',
  'task status': 'taskStatus',
  timeoffStatus: 'timeoffStatus',
  tag: 'projectTag',
  project_status: 'projectStatus',
  projectOnwer: 'projectOwner',
};

export const ONLY_INITIAL_QUERY = ['view', 'timetype'];
