import styled, { css } from 'styled-components';

import { Avatar } from '@float/common/components/Avatar';
import { Button, Table } from '@float/ui/deprecated';
import { CheckboxLabel } from '@float/ui/deprecated/Checkbox/styles';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';
import { respondTo } from '@float/ui/deprecated/Theme/helpers/responsive/respondTo';

const { StyledAvatar } = Avatar._styles;
const { Cell, HeaderCell } = Table._styles;

export const NameContainer = styled.div<{
  isActive: boolean;
  hasPmIcon: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  ${StyledAvatar} {
    margin: 0 10px 0 0;
  }

  --svg-icon-color: ${Colors.FIN.Lt.Emphasis.High};

  ${({ isActive }) =>
    !isActive &&
    `
    --svg-icon-color: ${Colors.FIN.Lt.Emphasis.Disabled}};
    `}

  ${respondTo('small')(css`
    ${StyledAvatar} {
      margin: 0;
      position: absolute;
      top: 12px;
      left: 20px;
    }
  `)}

  ${(p) =>
    p.hasPmIcon &&
    css`
      ${Table.HoverLinks} {
        right: 55px;
      }
    `}
`;

export const Name = styled.span`
  font-size: 16px;
  line-height: 1.38;
  font-weight: bold;
  color: ${(p) => p.theme.charcoalGrey};
`;

export const JobTitle = styled(Table.HoverLink)`
  color: ${(p) => p.theme.charcoalGrey} !important;
`;

export const PeopleSection = styled.div`
  overflow: initial;

  ${Cell}.name {
    position: relative;
    height: 100%;
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: 1010px) {
    ${Cell}, ${HeaderCell} {
      &.tags,
      &.statusTags {
        display: none;
      }
    }
  }

  ${respondTo('small')(css`
    .desktop {
      display: none;
    }

    .mobile {
      display: initial;
    }

    ${Cell} {
      &.department,
      &.access {
        display: none;
      }

      &.statusTags {
        display: flex;
        margin-top: 4px;
      }

      &.name {
        position: static;
        height: auto;

        ${CheckboxLabel} {
          display: none;
        }
      }
    }
  `)}
`;

export const AddEntity = styled(Button)`
  min-height: 28px;
  width: 28px;
  margin: 0 12px;

  ${({ appearance }) =>
    appearance === 'secondary' &&
    css`
      background-color: transparent;
    `};
`;

export const QuickActionButton = styled(Button)`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const DiscList = styled.ul`
  padding: 0 0 0 2rem;
  li {
    list-style: disc;
    padding: 0.5rem 0;
  }
`;
