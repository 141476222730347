import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Flex = styled.div<
  Partial<
    Pick<
      CSSProperties,
      | 'flex'
      | 'justifyContent'
      | 'alignItems'
      | 'flexWrap'
      | 'marginBottom'
      | 'width'
      | 'height'
    >
  >
>`
  display: flex;

  ${({
    flex,
    justifyContent,
    alignItems,
    flexWrap,
    marginBottom,
    width,
    height,
  }) => `
    width: ${width ? width + 'px' : 'initial'};
    height: ${height ? +height + 'px' : 'initial'};
    flex: ${flex || 1};
    justify-content: ${justifyContent || 'flex-start'};
    align-items: ${alignItems || 'center'};
    margin-bottom: ${marginBottom || 0}px;
    flex-wrap: ${flexWrap};
  `}
`;
export const Spacer = styled.div<{
  axis?: 'x';
  size?: number;
  xSize?: number;
  inline?: boolean;
}>`
  margin: ${(p) => (p.axis === 'x' ? 'auto' : '0px')};
  ${({ size, xSize, inline = true }) =>
    (size || xSize) &&
    `
    display: ${inline ? 'inline-block' : 'block'};
    padding-left: ${xSize || size || 1}px;
    padding-top: ${size || 1}px;
  `}
`;

export const Row = styled(Flex)`
  width: 100%;
  flex-direction: row;
`;

export const Col = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;
