import { omit } from 'lodash';

import { parseBudgetString } from '@float/common/lib/budget/helpers/parseBugdetString';
import { sortByName, sortByOffsetAndName } from '@float/common/lib/itemSort';
import { AppStore } from '@float/common/store';
import { Milestone } from '@float/types/milestone';
import { Phase } from '@float/types/phase';
import { Project } from '@float/types/project';
import { RawTaskMeta, TaskMeta } from '@float/types/task';

import { FormType, ProjectFormInitialData } from '../types';

export function getTemplateData(
  store: AppStore,
  templateId: number | undefined,
): ProjectFormInitialData {
  if (!templateId) {
    return {
      type: FormType.ProjectTemplate,
      project: undefined,
      tasks: [],
      milestones: [],
      phases: [],
    };
  }

  const state = store.getState();

  const template = state.projects.templates[templateId] || {};
  const templateTasks = (template?.task_metas || []) as RawTaskMeta[];
  const templateTasksWithParsedBudget: TaskMeta[] = templateTasks.map(
    (taskMetaRaw) => {
      return {
        ...taskMetaRaw,
        budget: parseBudgetString(taskMetaRaw.budget),
      } satisfies TaskMeta;
    },
  );

  const project = omit(
    template,
    'milestones',
    'task_metas',
    'phases',
    'team_people',
    'creator_id',
    'project_template_id',
  ) as Project;

  return {
    type: FormType.ProjectTemplate,
    templateId,
    project: {
      ...project,
      duration: project.duration ?? null,
    },
    tasks: sortByName(templateTasksWithParsedBudget, 'task_name'),
    milestones: sortByOffsetAndName(template.milestones as Milestone[], 'name'),
    phases: sortByOffsetAndName(template.phases as Phase[], 'phase_name'),
  };
}
