import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconUserTag = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.25 8a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Zm-3 11.25h-5.3c-1.18 0-2.06-1.04-1.46-2.055C6.363 15.723 8.24 14 12.25 14m5.15-.8h3v3l-2.77 2.759a.828.828 0 0 1-1.188-.02l-1.818-1.937a.827.827 0 0 1 .03-1.162L17.4 13.2Z"
      />
    </SVGIcon>
  );
};

export { IconUserTag };
