/**
 * Prompts used by the onboarding flow
 */
export const PROMPTS = {
  onboardingModal: 1, // deprecated 2020-09
  modalTaskStatus: 2, // deprecated 2020-05
  modalPeopleInfo: 3, // deprecated 2020-05
  modalPeopleStatus: 4, // deprecated 2020-05
  modalProjectMilestones: 5, // deprecated 2020-05
  onboardingIntroVideo: 6, // deprecated 2020-09
  onboardingCreateTask: 7, // deprecated 2020-09
  onboardingRightClick: 8, // deprecated 2020-09
  onboardingIosApp: 9, // deprecated in favor of 10
  onboardingMobileApp: 10, // deprecated 2020-10
  shiftTimeline: 11,
  publicHolidays: 12,
  logTimeInput: 13,
  logTimeHeader: 14, // deprecated 2020-11
  logTimeNavItem: 15,
  logTimeAnnouncement: 16, // no longer showing this modal
  pmSidebarItemDrag: 17,
  projectImport: 18,
  peopleImport: 19,
  projectsReportTimeTrackingPromo: 20,
  welcomeAddPerson: 21,
  welcomeAddProject: 22,
  welcomeAddTask: 23,
  welcomeGuide: 24,
  welcomeLogTime: 25,
  welcomeNonAccountOwner: 26,
  reportsMode: 27,
  welcomeAddPhase: 28,
  welcomeGetToKnowYou: 29,
  logTimeMode: 30,
  productTour: 31,
  addFirstTask: 32,
  helpGuide: 33,
  managerAccessRightsOptIn: 34, // no longer showing this modal, feature 100% rolled out
  managerAccessRightsEnabled: 35, // no longer showing this modal, feature 100% rolled out
  managerAccessRightsOptinAutoSwitchReminder: 36, // no longer showing this modal, feature 100% rolled out
  jobTitleToRoleInputAlert: 37,
  jobTitleToRoleTooltip: 38, // no longer showing this tooltip
  subDepartmentsEnabledTooltip: 39, // no longer showing this modal, feature 100% rolled out
  viewsRedesign: 40, // removed
  groupByTaskNewProjectTaskTab: 41, // removed
  groupByTaskLockTaskList: 42, // removed
  groupByTaskNoTask: 43, // removed
  viewsBeta: 45, // removed
  viewsBetaSharedViews: 46, // removed
  dateRangeInsightsPercentage: 47,
  sidePanelSplash: 48, // removed
  projectSetupTour: 49, // removed
  phasesInTemplatesSplash: 50,
  onboardingSurveySuccess: 'success', // this is an hard coded prompt that shows at the end of the onboarding survey
} as const;
