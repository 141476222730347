import { TaskMetaOption } from '@float/common/selectors/taskMetas';
import { Project } from '@float/types';

export type ValidateTaskNameParams = {
  project?: Project;
  taskName: string;
  taskMetas: TaskMetaOption[];
};

export function validateTaskName({
  project,
  taskName,
  taskMetas,
}: ValidateTaskNameParams) {
  if (project?.locked_task_list === 1) {
    if (!taskName) {
      const allowEmpty = taskMetas.every((option) => option.task_name === '');

      if (!allowEmpty) {
        return ['Please select a task'];
      }
    }
  }
}
