import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { t, Trans } from '@lingui/macro';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';

import { savePerson } from '@float/common/actions';
import { KEYS } from '@float/common/search/helpers';
import {
  useAppDispatchStrict,
  useAppSelectorStrict,
} from '@float/common/store';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { moment } from '@float/libs/moment';
import { Button } from '@float/ui/deprecated';
import * as Icons from '@float/ui/deprecated/Earhart/Icons';
import { useLoader } from '@float/ui/deprecated/helpers/formHooks';
import { getDefaultRegion } from '@float/web/selectors';
import { ensurePublicHolidaysLoaded } from '@float/web/settingsV2/actions/publicHolidays';
import { Transition } from '@float/web/TransitionManager/Transition';

import {
  StyledInput,
  StyledInputContainer,
  StyledLabel,
  StyledTransitionGroup,
} from './styles';

import * as commonStyles from '../../styles.css';

const PEOPLE_CONFIG = {
  min: 3,
  max: 200,
};

const getPersonDefaultData = (index) => ({
  data: {
    index,
    name: '',
  },
});

const getInputsState = () =>
  Array.from(Array(PEOPLE_CONFIG.min).keys()).map((index) =>
    getPersonDefaultData(index),
  );

export const AddPeople = forwardRef((props, ref) => {
  const { onClickNext, previousState, transitionId, setPeopleCount } = props;

  // Refs
  const transitionRef = useRef();
  const contentRef = useRef();

  const inputsRef = useRef([]);

  // State

  // build inputs state data based either on:
  // previous state or default config
  const [state, setState] = useState(() => {
    if (previousState) {
      return previousState;
    }

    return getInputsState();
  });

  const [inputs, setInputs] = useState(() =>
    Array.from(Array(state.length).keys()),
  );

  // Selectors

  const defaultRegion = useAppSelectorStrict(getDefaultRegion);

  // Other hooks

  const dispatch = useAppDispatchStrict();

  const { loader, setLoading } = useLoader();

  // Input refs

  const setInputRef = useCallback((input) => {
    if (input) {
      const { index } = input.props;

      inputsRef.current[index] = input;
    }
  }, []);

  // Submit
  const isFormEmpty = state.every((item) => !item.data.name);
  const hasErrors = state.some((task) => task.error === true);

  const addPerson = useCallback(
    async (person) => {
      // skip if person was submitted sucessfully already or name is empty
      if (person.success || person.data.name === '') {
        return person;
      }

      // save person
      try {
        await dispatch(
          savePerson({
            department: {},
            department_id: undefined,
            active: 1,
            editable: true,
            employee_type: 1,
            people_type_id: 1,
            tags: [],
            start_date: moment().startOf('year').format('YYYY-MM-DD'),
            name: person.data.name,
            regionHolidayIds: defaultRegion ? defaultRegion.holiday_ids : null,
            management_group: {
              people: [],
              departments: [],
            },
          }),
        );

        return {
          data: person.data,
          success: true,
        };
      } catch (error) {
        return {
          data: person.data,
          error: true,
          message: [(error && error.message) || 'An error occurred.'],
        };
      }
    },
    [defaultRegion, dispatch],
  );

  // Interaction handlers

  const onClickNextHandler = useCallback(async () => {
    if (state.length > 0) {
      setLoading(true);

      // add people
      const results = await Promise.all(
        state.map(async (person) => await addPerson(person)),
      );

      setState(results);

      setLoading(false);

      // if there are no errors
      if (!results.some((response) => response.error)) {
        onClickNext(results);
      }
    }
  }, [setLoading, addPerson, state, onClickNext]);

  const addInput = useCallback(() => {
    if (inputs.length < PEOPLE_CONFIG.max) {
      const nextInputsState = inputs.slice();
      const index = inputs.length;
      nextInputsState.push(index);
      setInputs(nextInputsState);

      const newTaskState = getPersonDefaultData(index);
      const nextState = state.slice();
      nextState.push(newTaskState);
      setState(nextState);
    }
  }, [state, inputs]);

  const onInputChange = useCallback(
    (e) => {
      const { currentTarget } = e;
      const name = currentTarget.value.trim();
      const index = parseInt(currentTarget.dataset.name);

      // update next state
      const nextState = state.map((item) => {
        if (item.data.index === index) {
          return { ...item, data: { ...item.data, name } };
        }

        return item;
      });

      setState(nextState);

      const peopleCount = nextState.filter(
        (person) => !!person.data.name,
      ).length;

      setPeopleCount(peopleCount);
    },
    [state, setPeopleCount],
  );

  const onInputKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KEYS.enter) {
        onClickNextHandler();
      }
    },
    [onClickNextHandler],
  );

  // Effects

  // auto select new input when added
  useUpdateEffect(() => {
    inputsRef.current[inputsRef.current.length - 1].focusInput();
  }, [inputs]);

  // auto select first non disabled input on mount
  useOnMount(() => {
    const index = state.findIndex((task) => !task.success);

    if (index > -1) inputsRef.current[index].focusInput();
  });

  useEffect(() => {
    // ensure public holidays are loaded to get default region
    dispatch(ensurePublicHolidaysLoaded());
  }, [dispatch]);

  // Expose

  useImperativeHandle(ref, () => ({ ...transitionRef.current }));

  return (
    <Transition ref={transitionRef} id={transitionId}>
      <div className={commonStyles.content} ref={contentRef}>
        <h1 className={commonStyles.h1}>
          <Trans>Add your team</Trans>
        </h1>

        <div className={commonStyles.question}>
          <h2 className={commonStyles.h2}>
            <Trans>
              Include anyone you’d like to schedule. You can invite them to log
              in later.
            </Trans>
          </h2>

          <StyledInputContainer>
            <StyledLabel>
              <Trans>Teammates</Trans>
            </StyledLabel>

            <StyledTransitionGroup>
              {inputs.map((index) => {
                const person = state[index];

                const alreadyAdded = person.success;
                const errors = person.error && person.message;

                return (
                  <CSSTransition key={index} timeout={500} classNames="input">
                    <StyledInput
                      ref={setInputRef}
                      index={index}
                      dataName={index}
                      defaultValue={person.data.name}
                      placeholder={t`Enter first and last name`}
                      icon={alreadyAdded ? <Icons.IconCheck /> : null}
                      iconPosition="trailing"
                      disabled={alreadyAdded}
                      readOnly={loader.loading || alreadyAdded}
                      onChange={onInputChange}
                      onKeyDown={onInputKeyDown}
                      errors={errors}
                    />
                  </CSSTransition>
                );
              })}
            </StyledTransitionGroup>

            <Button
              appearance="clear"
              icon={Icons.IconPlus}
              disabled={state.length >= PEOPLE_CONFIG.max || loader.loading}
              onClick={addInput}
            >
              <Trans>Add another</Trans>
            </Button>
          </StyledInputContainer>
        </div>

        <Button
          iconRight={Icons.IconArrowRight}
          size="large"
          loader={loader.active ? loader : null}
          disabled={isFormEmpty}
          onClick={onClickNextHandler}
        >
          {hasErrors ? t`Try again` : t`Next`}
        </Button>
      </div>
    </Transition>
  );
});
