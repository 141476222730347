import { SearchAutocompleteQueryItem } from '.';

const skipFilters = new Set(['none', 'unnamed']);

export const getCategoryTotalSize = (
  filters: SearchAutocompleteQueryItem[],
): number => {
  let totalSize = 0;

  for (const candidate of filters) {
    if (!skipFilters.has(candidate.normalizedVal)) {
      totalSize += 1;
    }
  }

  return totalSize;
};
