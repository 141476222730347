import { useEffect, useRef } from 'react';
import { throttle } from 'lodash';

const supportsResizeObserver = typeof ResizeObserver !== 'undefined';

export const useResizeObserver = (
  targetRef: { current: Element | null } | null = { current: null },
  handler: (entry?: ResizeObserverEntry) => void,
  { enabled = true, wait = 500 } = {},
) => {
  // a little trick to update the callback without re-triggering the subscription effect
  const callbackRef = useRef(handler);

  useEffect(() => {
    callbackRef.current = handler;
  });

  useEffect(() => {
    if (!supportsResizeObserver || !enabled) return;

    const wrapperElement = targetRef?.current;

    if (!wrapperElement) {
      return;
    }

    const observer = new ResizeObserver(
      throttle(
        (entries) => {
          requestAnimationFrame(() => callbackRef.current(entries[0]));
        },
        wait,
        { trailing: true },
      ),
    );

    observer.observe(wrapperElement);

    return () => {
      observer.unobserve(wrapperElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};
