import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconChevronDown = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M15.25 10.75L12 14.25L8.75 10.75"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);
