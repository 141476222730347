import { t } from '@lingui/macro';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { Resources } from '@float/common/api3/constants';
import {
  deleteLinearCyclesIntegrationConfig,
  getLinearCyclesIntegrationConfig,
  LinearCyclesIntegrationDeletePayload,
  LinearCyclesIntegrationResyncPayload,
  LinearCyclesIntegrationUpdatePayload,
  resyncLinearCyclesIntegrationConfig,
  upsertLinearCyclesIntegrationConfig,
} from '@float/common/api3/linearCyclesIntegration';
import { trackEvent } from '@float/common/lib/analytics';
import { useSnackbar } from '@float/ui/deprecated/Snackbar/useSnackbar';
import { useDeferredLoadingState } from 'components/panelViews/Project/hooks/useDeferredLoadingState';

export const getActivationFeedbackCopy = (isActivating: boolean) => {
  if (isActivating) {
    return {
      errorMessage: t`There was a problem activating the Linear Cycle integration`,
      successMessage: t`Linear calendar sync activated`,
    };
  }

  return {
    errorMessage: t`There was a problem deactivating the Linear Cycle integration`,
    successMessage: t`Linear calendar sync deactivated`,
  };
};

export function useLinearIntegrationData() {
  const { showSnackbar } = useSnackbar();

  const { data: integrationConfig, isLoading: isLoadingGet } = useSWR(
    Resources.LinearCyclesIntegrationConfig,
    getLinearCyclesIntegrationConfig,
    {
      fallbackData: null,
    },
  );

  const { trigger: triggerSync, isMutating: isMutatingSync } = useSWRMutation(
    Resources.LinearCyclesIntegrationConfig,
    (_, { arg }: { arg: LinearCyclesIntegrationUpdatePayload }) =>
      resyncLinearCyclesIntegrationConfig(arg),
    {
      populateCache: true,
      revalidate: false,
      rollbackOnError: false,
      throwOnError: false,
    },
  );

  const { trigger: triggerUpdate, isMutating: isMutatingUpsert } =
    useSWRMutation(
      Resources.LinearCyclesIntegrationConfig,
      (_, { arg }: { arg: LinearCyclesIntegrationUpdatePayload }) =>
        upsertLinearCyclesIntegrationConfig(arg),
      {
        populateCache: true,
        revalidate: false,
        rollbackOnError: true,
        throwOnError: false,
      },
    );

  const { trigger: triggerDelete, isMutating: isMutatingDelete } =
    useSWRMutation(
      Resources.LinearCyclesIntegrationConfig,
      (_, { arg }: { arg: LinearCyclesIntegrationDeletePayload }) =>
        deleteLinearCyclesIntegrationConfig(arg),
      {
        populateCache: true,
        revalidate: false,
        rollbackOnError: true,
        throwOnError: false,
      },
    );

  const syncConfig = (payload: LinearCyclesIntegrationResyncPayload) =>
    triggerSync(payload, {
      optimisticData: { ...integrationConfig },
      onError: () => {
        showSnackbar(
          t`There was a problem with synching the Linear Cycle integration`,
          { className: 'error', showClose: true },
        );
      },
      onSuccess: () => {
        showSnackbar(t`Linear calendar synced`, { showClose: true });
      },
    });

  const upsertConfig = (payload: LinearCyclesIntegrationUpdatePayload) =>
    triggerUpdate(payload, {
      optimisticData: { ...integrationConfig, ...payload },
      onError: () => {
        showSnackbar(
          t`There was a problem with updating the Linear Cycle integration. Make sure you are using the correct feed URL`,
          { className: 'error', showClose: true },
        );
      },
      onSuccess: () => {
        trackEvent('Linear cycles integration connected');

        showSnackbar(t`Linear calendar synced`, { showClose: true });
      },
    });

  const updateActivationStatus = (payload: {
    active: boolean;
    version: number;
  }) => {
    const { errorMessage, successMessage } = getActivationFeedbackCopy(
      payload.active,
    );

    triggerUpdate(payload, {
      optimisticData: { ...integrationConfig, ...payload },
      onError: () => {
        showSnackbar(errorMessage, { className: 'error', showClose: true });
      },
      onSuccess: () => {
        showSnackbar(successMessage, { showClose: true });
      },
    });
  };

  const deleteConfig = (deletePayload: LinearCyclesIntegrationDeletePayload) =>
    triggerDelete(deletePayload, {
      optimisticData: null,
      onError: () => {
        showSnackbar(
          t`There was a problem with deleting the Linear Cycle integration`,
          { className: 'error', showClose: true },
        );
      },
      onSuccess: () => {
        trackEvent('Linear cycles integration disconnected');

        showSnackbar(t`Linear integration deleted`, { showClose: true });
      },
    });

  const combinedLoadingState =
    isLoadingGet || isMutatingDelete || isMutatingUpsert || isMutatingSync;

  const isLoading = useDeferredLoadingState(combinedLoadingState);

  return {
    deleteConfig,
    integrationConfig,
    isLoading,
    syncConfig,
    updateActivationStatus,
    upsertConfig,
  };
}
