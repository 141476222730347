export const isFullDayTimeoff = (timeoff) => {
  return timeoff.full_day;
};

export const isTentativeTimeoff = (timeoff) => {
  return timeoff.status === 1;
};

export const isRejectedTimeoff = (timeoff) => {
  return timeoff.status === -1;
};
