import { useEffect, useRef, useState } from 'react';
import { clamp } from 'lodash';

import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import {
  cancelInterval,
  requestInterval,
} from '@float/common/serena/util/timer';
import { isSafari } from '@float/libs/web/detect';

const SPEED_MODIFIER = 5;
const MAX_SPEED = 50;
const TARGET_FPS = 60;

function getLeftSpeed(cornerWidth, e, enabled) {
  if (!enabled) return 0;
  const boundary = cornerWidth;
  if (e.clientX >= boundary) return 0;
  return (e.clientX - boundary) / SPEED_MODIFIER;
}

function getRightSpeed(cornerWidth, e, enabled) {
  if (!enabled) return 0;
  const boundary = window.innerWidth - 80;
  if (e.clientX < boundary) return 0;
  return (e.clientX - boundary) / SPEED_MODIFIER;
}

function getTopSpeed(e, enabled) {
  if (!enabled) return 0;
  const boundary = 100;
  if (e.clientY >= boundary) return 0;
  return (e.clientY - boundary) / SPEED_MODIFIER;
}

function getBottomSpeed(e, enabled) {
  if (!enabled) return 0;
  const boundary = window.innerHeight - 100;
  if (e.clientY < boundary) return 0;
  return (e.clientY - boundary) / SPEED_MODIFIER;
}

function clampSpeed(speed) {
  return clamp(speed, -MAX_SPEED, MAX_SPEED);
}

export default function useAutoscroller({ dispatch, cornerWidth }) {
  const { scrollWrapperRef, baseColOffset } = useScheduleContext();

  const autoscroller = useRef(null);
  const [horizontalEnabled, setHorizontalEnabled] = useState(false);
  const [verticalEnabled, setVerticalEnabled] = useState(false);

  if (autoscroller.current === null) {
    autoscroller.current = {
      enable() {
        setHorizontalEnabled(true);
        setVerticalEnabled(true);
      },
      disable() {
        setHorizontalEnabled(false);
        setVerticalEnabled(false);
      },
      enableVertical(cb) {
        setVerticalEnabled(true);
        this.cb = cb;
      },
      disableVertical() {
        setVerticalEnabled(false);
        this.cb = undefined;
      },
      enableHorizontal() {
        setHorizontalEnabled(true);
      },
      disableHorizontal() {
        setHorizontalEnabled(false);
      },
    };
  }

  useEffect(() => {
    if (isSafari) return;

    const horizontalSpeed = {};
    const verticalSpeed = {};
    let interval;

    const handler = (e) => {
      const leftSpeed = getLeftSpeed(cornerWidth, e, horizontalEnabled);
      const rightSpeed = getRightSpeed(cornerWidth, e, horizontalEnabled);
      const topSpeed = getTopSpeed(e, verticalEnabled);
      const bottomSpeed = getBottomSpeed(e, verticalEnabled);

      horizontalSpeed.current = clampSpeed(leftSpeed || rightSpeed);
      verticalSpeed.current = clampSpeed(topSpeed || bottomSpeed);

      if (leftSpeed || rightSpeed || topSpeed || bottomSpeed) {
        if (interval) return; // There's already an active interval

        interval = requestInterval(() => {
          if (!scrollWrapperRef.current) return;

          scrollWrapperRef.current.scrollLeft += horizontalSpeed.current;
          scrollWrapperRef.current.scrollTop += verticalSpeed.current;

          if (autoscroller.current.cb) {
            autoscroller.current.cb();
          }
        }, 1000 / TARGET_FPS);
      } else if (interval) {
        cancelInterval(interval);
        interval = null;
      }
    };

    if (horizontalEnabled || verticalEnabled) {
      window.addEventListener('mousemove', handler);
    }

    // eslint-disable-next-line
    return () => {
      if (interval) {
        cancelInterval(interval);
        interval = null;
      }
      window.removeEventListener('mousemove', handler);
    };
  }, [
    baseColOffset,
    horizontalEnabled,
    verticalEnabled,
    cornerWidth,
    scrollWrapperRef,
  ]);

  return autoscroller.current;
}
