import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconUserPlus = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9.5 8a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8ZM6.136 17.578c.737-1.244 2.368-2.828 6.114-2.828a.75.75 0 0 0 0-1.5c-4.275 0-6.397 1.863-7.404 3.563-.463.782-.359 1.625.109 2.242.446.591 1.192.945 1.995.945h5.3a.75.75 0 0 0 0-1.5h-5.3c-.376 0-.66-.166-.799-.349-.117-.156-.153-.34-.015-.573ZM17 14a.75.75 0 0 1 .75.75v1.5h1.5a.75.75 0 0 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 0 1 0-1.5h1.5v-1.5A.75.75 0 0 1 17 14Z"
        clipRule="evenodd"
      />
    </SVGIcon>
  );
};

export { IconUserPlus };
