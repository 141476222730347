import React, { memo, useCallback, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import gsap from 'gsap';
import { trackOnboardingPrompt } from 'OnboardingManager/helpers/analytics';

import { useStateCallback } from '@float/common/lib/hooks/useStateCallback';
import { PROMPTS } from '@float/constants/onboarding';
import { useAnchor } from '@float/libs/web/hooks/useAnchor';
import * as Colors from '@float/ui/deprecated/Earhart/Colors';

import {
  StyledDotCallout,
  StyledTooltipContent,
  StyledTooltipNotification,
} from './styles';

const anchorData = {
  id: 'help-guides',
  anchors: [
    {
      id: 'help-guides',
      config: {
        position: 'right',
        alignment: 'start',
        arrowPosition: '22px',
        offset: {
          y: -8,
        },
      },
    },
  ],
};

export const HelpGuides = memo((props) => {
  const { next } = props;

  const [visible, setVisible] = useStateCallback(false);

  const anchor = useAnchor(anchorData, 'callout');

  const currentInteraction = useRef('click_background');

  const onClose = useCallback(() => {
    setVisible(false);

    trackOnboardingPrompt(
      PROMPTS.helpGuide,
      { interaction: currentInteraction.current },
      1,
    );
  }, [currentInteraction, setVisible]);

  const onExited = useCallback(() => {
    next(PROMPTS.helpGuide);
  }, [next]);

  const onAnchorClick = useCallback(() => {
    currentInteraction.current = 'help_menu';
  }, []);

  useEffect(() => {
    if (anchor.rect) {
      gsap.delayedCall(0.5, () => {
        setVisible(true, () => {
          trackOnboardingPrompt(PROMPTS.helpGuide);
        });
      });

      const element = anchor.element;

      element.addEventListener('mousedown', onAnchorClick);

      return () => {
        element.removeEventListener('mousedown', onAnchorClick);
      };
    }
  }, [anchor.rect, anchor.element, onAnchorClick, setVisible]);

  useEffect(() => {
    let delayedClose;

    if (visible) {
      delayedClose = gsap.delayedCall(8, () => {
        currentInteraction.current = 'timeout';
        onClose();
      });
    }

    return () => {
      if (delayedClose) delayedClose.kill();
    };
  }, [visible, onClose]);

  if (!anchor.rect) return null;

  return (
    <>
      <CSSTransition
        in={visible}
        onExited={onExited}
        timeout={500}
        appear
        unmountOnExit
      >
        <StyledDotCallout
          style={{
            top: anchor.rect.top + parseInt(anchor.config.arrowPosition, 10),
            left: anchor.rect.left + anchor.rect.width / 1.5,
          }}
        />
      </CSSTransition>

      <CSSTransition in={visible} timeout={1000} appear unmountOnExit>
        <StyledTooltipNotification
          anchor={anchor}
          position={anchor.config.position}
          alignment={anchor.config.alignment}
          arrowPosition={anchor.config.arrowPosition}
          gap={10}
          bgColor={Colors.Radix.Primary.Flue[3]}
          borderColor={Colors.Radix.Primary.Flue[7]}
          width={275}
          showClose={false}
          closeOnBgClick={true}
          onClose={onClose}
        >
          <StyledTooltipContent>
            Find help guides, chat to support or replay the product tour from
            here.
          </StyledTooltipContent>
        </StyledTooltipNotification>
      </CSSTransition>
    </>
  );
});
