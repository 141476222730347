import { ValueOf } from '@float/types';

export const TaskEditMode = {
  ADD_EDIT: 'addEdit',
  INSERT: 'insert', // deprecated (removed from UI)
  SPLIT: 'split',
  REPLACE: 'replace', // deprecated (removed from UI)
  COMPLETE: 'complete',
  DELETE: 'delete',
  LINK: 'link',
  LOG_TIME: 'log_time',
} as const;

export type TaskEditModes = ValueOf<typeof TaskEditMode>;
