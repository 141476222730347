import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconDottedCircleLeft = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.25 17.25c0 1.105-.911 2.025-1.976 1.733C7.162 18.129 4.75 15.326 4.75 12c0-3.326 2.412-6.129 5.524-6.983 1.065-.292 1.976.628 1.976 1.733v10.5Zm5.008.165a7.225 7.225 0 0 1-2.508 1.835m4.225-9.416c.18.688.275 1.415.275 2.166 0 .751-.095 1.478-.275 2.166M14.75 4.75a7.226 7.226 0 0 1 2.508 1.835"
      />
    </SVGIcon>
  );
};

export { IconDottedCircleLeft };
