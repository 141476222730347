import { Node, Transforms } from 'slate';

function MaxLengthPlugin(editor, options = { maxLength: 1500 }) {
  const { maxLength } = options;
  const { normalizeNode, insertBreak } = editor;

  const toText = (nodes) => {
    return nodes.map((n) => Node.string(n)).join('\n');
  };

  editor.normalizeNode = (entry) => {
    const text = toText(editor.children);

    if (text.length > maxLength) {
      Transforms.delete(editor, {
        distance: text.length - maxLength,
        reverse: true,
      });
      return;
    }

    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    const text = toText(editor.children);

    if (text.length >= maxLength) {
      return;
    }

    insertBreak();
  };

  editor.insertSoftBreak = () => {
    const text = toText(editor.children);

    if (text.length >= maxLength) {
      return;
    }

    insertBreak();
  };

  return editor;
}

export default MaxLengthPlugin;
