import {
  REPEAT_INTERVAL,
  RepeatIntervalDescription,
  RepeatIntervalKey,
} from '@float/common/components/taskModals/constants';
import { trackEvent } from '@float/common/lib/analytics';
import { TaskStatusDescription } from '@float/constants/tasks';
import {
  StatusTypeId,
  TaskMode,
  TaskStatus,
  TaskStatusEnum,
} from '@float/types';

function getRepeatOption(entity: { repeat_state: RepeatIntervalKey }) {
  return REPEAT_INTERVAL[entity.repeat_state || 0];
}

function trackCreateTask(task: {
  status: TaskStatus;
  repeat_state: RepeatIntervalKey;
  sidebarItemType: string | undefined;
}) {
  let status = TaskStatusDescription.Confirmed;
  if (task.status === TaskStatusEnum.Tentative)
    status = TaskStatusDescription.Tentative;
  if (task.status === TaskStatusEnum.Complete)
    status = TaskStatusDescription.Completed;

  const props: {
    status: TaskStatusDescription;
    repeatOption: RepeatIntervalDescription;
    sidebarItemType: undefined | string;
  } = {
    status,
    repeatOption: getRepeatOption(task),
    sidebarItemType: undefined,
  };

  if (task.sidebarItemType) {
    props.sidebarItemType = task.sidebarItemType;
  }
  trackEvent('Task added', props);
}

function trackCreateTimeoff(timeoff: {
  timeoff_type_name: string;
  repeat_state: RepeatIntervalKey;
}) {
  trackEvent('Timeoff added', {
    type: timeoff.timeoff_type_name,
    repeatOption: getRepeatOption(timeoff),
  });
}

function trackCreateStatus(status: {
  name: string;
  status_type_id: StatusTypeId;
  repeat_state: RepeatIntervalKey;
}) {
  trackEvent('Status added', {
    name: status.name,
    isCustom: status.status_type_id === 3,
    repeatOption: getRepeatOption(status),
  });
}

export type TaskPayload = {
  isCreate: boolean;
  type: TaskMode.Task;
  entity: {
    repeat_state: RepeatIntervalKey;
    sidebarItemType: string | undefined;
    status: TaskStatus;
  };
};

type TimeoffPayload = {
  isCreate: boolean;
  type: TaskMode.Timeoff;
  entity: {
    repeat_state: RepeatIntervalKey;
    timeoff_type_name: string;
  };
};

type StatusPayload = {
  isCreate: boolean;
  type: TaskMode.Status;
  entity: {
    name: string;
    repeat_state: RepeatIntervalKey;
    status_type_id: StatusTypeId;
  };
};

export type TrackableChange = TaskPayload | TimeoffPayload | StatusPayload;

export function trackAnalytics(change: TrackableChange) {
  if (change.isCreate) {
    if (change.type === 'task') {
      trackCreateTask(change.entity);
    } else if (change.type === 'timeoff') {
      trackCreateTimeoff(change.entity);
    } else if (change.type === 'status') {
      trackCreateStatus(change.entity);
    }
  }
}
