import { WebAppStateStrict } from 'reducers/types';

import { PROMPT_ID } from '@float/types/onboarding';

export const getPromptsUserHasDone = (state: WebAppStateStrict) =>
  state.onboardingManager?.promptsUserHasDone;

export const isPromptDone = (state: WebAppStateStrict, id: PROMPT_ID) =>
  Boolean(state.onboardingManager?.promptsUserHasDone?.includes(id));

export const getNavigateToPrompt = (state: WebAppStateStrict) =>
  state.onboardingManager?.navigateToPrompt;

export const getPromptData = (state: WebAppStateStrict) =>
  state.onboardingManager?.promptData;

export const getLastUpdatedProject = (state: WebAppStateStrict) =>
  state.lastUpdated.project;

export const getAllProjects = (state: WebAppStateStrict) =>
  state.projects.projects;

export const getModalManagerState = (state: WebAppStateStrict) =>
  state?.modalManager;
