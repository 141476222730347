import styled from 'styled-components';

import { hidableListWithComponents } from '@float/common/activity';

import ActivityItemHistoryLine from './ActivityItemHistoryLine';
import ActivityItemTitle from './ActivityItemTitle';

const HideableList = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 0;
`;

const HideableListItem = styled.li`
  font-size: 12px;
  margin-top: 6px;
  color: #5f6568;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default hidableListWithComponents({
  HideableList,
  HideableListItem,
  ActivityItemTitle,
  ActivityItemHistoryLine,
});
