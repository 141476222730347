import { moment } from '@float/libs/moment';

import { getWorkDays } from './getWorkDays';

export const getTotalHours = (hoursPd, length) =>
  parseFloat((hoursPd * length).toFixed(2));

export const calculateRepeatTimes = (etmThis, state) => {
  const { dates } = etmThis.props;
  const { repeatState } = state;

  if (!repeatState) {
    return 0;
  }

  let start = moment(state.startDate).format('YYYY-MM-DD');
  const repeatEnd = moment(state.repeatEnd).format('YYYY-MM-DD');

  if (state.task.allInstances?.length) {
    // If we're editing a repeating task, we want to count repeats
    // from the first task instance
    start = state.task.allInstances[0].start_date;
  }

  return dates.getRepeatStarts(repeatState, start, repeatEnd).length + 1;
};

export const calculateWorkDays = (etmThis, newState) => {
  if (newState.task) {
    newState.length = getWorkDays(etmThis.props, {
      start_date: newState.startDate,
      end_date: newState.endDate,
      people_ids: newState.peopleIds,
      timeoff_id: newState.task.timeoff_id,
    });
  }

  return newState;
};

export const calculateTotalHours = (etmThis, newState) => {
  calculateWorkDays(etmThis, newState);
  const { hoursPd, length } = newState;
  newState.totalHours = getTotalHours(hoursPd, length);
  return newState;
};
