import React from 'react';
import { useLocation } from 'react-router';

import { useSearchFiltersAnalyticsTracking } from '@float/common/lib/hooks/useSearchFiltersAnalyticsTracking';
import { getIsCurrentPageASharedLink } from '@float/libs/web/sharedLinkView';

import { FullPageLoader } from '../FullPageLoader';
import { Portal as InnerPortal } from './Portal';
import { useNoSession } from './useNoSession';
import { usePortalDataLoad } from './usePortalDataLoad';

function isWebScheduleView(pathname: string) {
  return (
    pathname === '/' ||
    pathname === '/log-time' ||
    pathname.startsWith('/share/')
  );
}

export function Portal() {
  const location = useLocation();
  const isSharedLink = getIsCurrentPageASharedLink();
  const isLoading = usePortalDataLoad(isSharedLink);

  useNoSession({ isSharedLink, loading: isLoading });

  useSearchFiltersAnalyticsTracking('filter-timeline', {
    enabled: isWebScheduleView(location.pathname) && !isLoading,
  });

  return (
    <FullPageLoader isLoading={isLoading}>
      <InnerPortal location={location} />
    </FullPageLoader>
  );
}
