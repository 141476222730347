import React from 'react';

import { IconProjectConfirmed } from '@float/ui/icons/essentials/IconProjectConfirmed';
import { IconProjectDraft } from '@float/ui/icons/essentials/IconProjectDraft';
import { IconProjectTentative } from '@float/ui/icons/essentials/IconProjectTentative';

import * as styles from './index.style.css';

export function IconStatusDraft() {
  return <IconProjectDraft className={styles.iconProjectDraft} />;
}

export function IconStatusTentative() {
  return <IconProjectTentative className={styles.iconProjectTentative} />;
}

export function IconStatusConfirmed() {
  return <IconProjectConfirmed className={styles.iconProjectConfirmed} />;
}
