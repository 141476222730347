import React, { useEffect, useMemo, useRef, useState } from 'react';
import { get, isNumber } from 'lodash';

import { trackIntegrationEvent } from '@float/common/lib/analytics';
import { getTimeAgo, popupWindow } from '@float/common/lib/utils';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { Button, withConfirm } from '@float/ui/deprecated';

import OnOffStatus from '../OnOffStatus';
import {
  P,
  Subsection,
  SubsectionAction,
  SubsectionContent,
  SubsectionLogo,
  SubsectionMessage,
  SubsectionStatus,
} from '../styles';
import ConnectLimitModal from './ConnectLimitModal';

const defaultIsAuthValid = (event, props) => {
  const { floatIntegrationsOAuth, type } = event.data || {};
  return floatIntegrationsOAuth && type === props.type;
};

const defaultIsAuthSuccess = (event) => get(event, 'data.success', false);
const defaultIsAuthError = (event) => get(event, 'data.error', false);

function SharedSubsection(props) {
  const user = useAppSelectorStrict(getUser);
  const [integrationsError, setIntegrationsError] = useState(null);
  const [authenticationError, setAuthenticationError] = useState(false);
  const [oauthPending, setOauthPending] = useState(false);
  const [retryPeopleSyncButtonEnabled, setRetryPeopleSyncButtonEnabled] =
    useState(true);
  const [showConnectLimit, setShowConnectLimit] = useState(false);
  const popupRef = useRef();

  const {
    integration,
    config = integration.config,
    labels = {},
    isAuthValid = defaultIsAuthValid,
    isAuthSuccess = defaultIsAuthSuccess,
    isAuthError = defaultIsAuthError,
    appendAuthInfo = () => ({}),
    requiresReauth,
  } = props;

  const isReauth = useMemo(
    () =>
      get(integration, 'coInts[0].ancillary.errors.API_UNAUTHORIZED') ||
      requiresReauth,
    [integration, requiresReauth],
  );

  const handleWindowMessage = (event) => {
    if (!isAuthValid(event, props)) {
      return;
    }

    setOauthPending(false);
    if (isAuthSuccess(event)) {
      if (isReauth) {
        const coInt = integration.coInts && integration.coInts[0];
        props.updateConfig(coInt, null, {
          resync: true,
          ...appendAuthInfo(event),
        });
      } else {
        const coIntId = get(integration, 'coInts[0].integrations_co_id');
        props.fetchPreload(coIntId, appendAuthInfo(event));
      }
    }
    if (isAuthError(event)) {
      setAuthenticationError(true);
    }
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  useEffect(() => {
    !props.disableFetchOnInitialLoad && props.fetchCoInts();
    window.addEventListener('message', handleWindowMessage);
    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { oauthLinkLoadState, coIntsLoadState, preloadLoadState } =
      integration;

    if (oauthLinkLoadState === 'LOAD_FAILED') {
      setIntegrationsError('oauthLink');
    } else if (coIntsLoadState === 'LOAD_FAILED') {
      setIntegrationsError('coInts');
    } else if (preloadLoadState === 'LOAD_FAILED') {
      setIntegrationsError('preload');
    } else {
      setIntegrationsError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    integration.oauthLinkLoadState,
    integration.coIntsLoadState,
    integration.preloadLoadState,
  ]);

  useEffect(() => {
    const { coInts, oauthLink, oauthLinkLoadState } = integration;
    const shouldLoad = (coInts && coInts.length === 0) || isReauth;
    if (shouldLoad && !oauthLink && !oauthLinkLoadState) {
      props.fetchOAuthLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    integration.coInts,
    integration.oauthLink,
    integration.oauthLinkLoadState,
    props.fetchOAuthLink,
  ]);

  useEffect(() => {
    const { coIntsLoadState, coInts, fetchConfigLoadState } = integration;
    if (
      coIntsLoadState === 'LOAD_SUCCESS' &&
      coInts.length &&
      coInts[0] &&
      !config &&
      !fetchConfigLoadState
    ) {
      props.fetchConfig(coInts[0].integrations_co_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    integration.coInts,
    integration.coIntsLoadState,
    config,
    integration.fetchConfigLoadState,
    props.fetchConfig,
  ]);

  useEffect(() => {
    const { coIntsLoadState, preloadLoadState, fetchConfigLoadState } =
      integration;
    if (
      // Trigger setup when integrations are loaded or obsolete (but still valid)
      coIntsLoadState === 'LOAD_SUCCESS' &&
      preloadLoadState === 'LOAD_SUCCESS' &&
      fetchConfigLoadState !== 'LOADING'
    ) {
      props.startSetup();
      if (popupRef.current) {
        popupRef.current.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    integration.preloadLoadState,
    integration.fetchConfigLoadState,
    integration.fetchConfigLoadState,
  ]);

  useEffect(() => {
    if (
      get(integration.coInts, '[0].ancillary.errors.API_UNAUTHORIZED') &&
      integration.preloadLoadState === 'LOADING'
    ) {
      props.clearPreload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration.coInts, integration.preloadLoadState]);

  useEffect(() => {
    if (
      integration.retryPeopleSyncLoadState !== 'LOADING' &&
      !retryPeopleSyncButtonEnabled
    ) {
      setRetryPeopleSyncButtonEnabled(true);
    }
  }, [integration.retryPeopleSyncLoadState, retryPeopleSyncButtonEnabled]);

  useEffect(() => {
    if (integration.coIntsLoadState === 'LOAD_SUCCESS') {
      props.onCoIntStatusLoad(
        integration.coInts && integration.coInts.length > 0
          ? integration.coInts[0].status
          : null,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration.coIntsLoadState]);

  const onConnectAccountClick = (e) => {
    preventDefaultAndStopPropagation(e);

    if (props.connectLimitReached && !isReauth) {
      setShowConnectLimit(true);
      return;
    }

    trackIntegrationEvent(
      props.type,
      `${isReauth ? 'Reauth' : 'Integration'} started`,
      user.cid,
    );

    const { oauthLink } = props.integration;
    if (oauthLink) {
      popupRef.current = popupWindow(oauthLink, 'Integration_OAuth', 600, 700);
      // if popup is closed by user, set oauthPending to false
      const intervalId = setInterval(() => {
        if (popupRef.current && popupRef.current.closed) {
          setOauthPending(false);
          clearInterval(intervalId);
        }
      }, 500);
      setOauthPending(true);
      setAuthenticationError(false);
    }
  };

  const onEditClick = (e) => {
    e.preventDefault();
    const coInt = props.integration.coInts[0];
    if (coInt) {
      const coIntId = coInt.integrations_co_id;
      props.fetchPreload(coIntId);
      props.fetchConfig(coIntId);
    }
  };

  const onRetryPeopleSyncClick = (e) => {
    preventDefaultAndStopPropagation(e);
    const coIntId = props.integration.coInts[0].integrations_co_id;
    props.retryPeopleSync(coIntId);
    setRetryPeopleSyncButtonEnabled(false);
  };

  const onTriggerManualSync = () => {
    const coInt = props.integration.coInts[0];
    props.updateConfig(coInt, null, { resync: true });
    props.fetchCoInts();
  };

  const BaseAction = ({
    children,
    onClick = (e) => onConnectAccountClick(e),
    ...others
  }) => (
    <Button minWidth="100%" onClick={onClick} {...others}>
      {children}
    </Button>
  );

  const { addCoIntLoadState, preloadLoadState, initialImportStarted, coInts } =
    props.integration;

  const preloadLoading = preloadLoadState === 'LOADING';
  const coInt = (coInts && coInts.length && coInts[0]) || null;
  const isSyncingStatus = get(coInt, 'status') === 2; // yet to complete setup

  let isError = false;
  let isOn = false;
  let Message = null;
  let Action = <BaseAction disabled={true}>Loading...</BaseAction>;

  const EditAction = (
    <BaseAction
      appearance="secondary"
      onClick={onEditClick}
      disabled={preloadLoading}
      loader={preloadLoading}
    >
      {preloadLoading ? 'Loading...' : 'Edit'}
    </BaseAction>
  );

  if (props.error || integrationsError) {
    isError = true;
    if (props.error?.editLink) {
      Action = EditAction;
    }
    if (props.error) {
      Message = <span>{props.error.message}</span>;
    } else if (integrationsError === 'preload') {
      Message = (
        <span>
          Sorry, we were unable to import your data. Try again by selecting
          Connect.
        </span>
      );
    } else {
      Message = (
        <>
          <span>Sorry, we were unable to load the necessary data.</span>
          <br />
          <span>Retry by reloading this page.</span>
        </>
      );
    }

    if (integrationsError === 'preload') {
      Action = (
        <BaseAction disabled={oauthPending} loader={oauthPending}>
          {oauthPending ? 'Connecting...' : 'Connect'}
        </BaseAction>
      );
    }
  } else if (coInt && coInt.status !== 0) {
    const {
      ancillary: {
        errors = {},
        import_stats: importStats = {},
        last_action: lastAction,
      },
      synced,
      status,
    } = coInt;

    // default action when coInt is available
    Action = (
      <>
        {props.manualSync && (
          <BaseAction
            disabled={isSyncingStatus}
            loader={isSyncingStatus}
            onClick={onTriggerManualSync}
          >
            {!isSyncingStatus && 'Sync now'}
          </BaseAction>
        )}
        {EditAction}
      </>
    );

    if (errors.EXCEEDS_PEOPLE_CAP && config && config.futurePeople) {
      let projectsImported;
      if (isNumber(importStats.projects)) {
        const counter =
          importStats.projects === 1
            ? labels.project || 'project'
            : labels.projects || 'projects';
        projectsImported = `${importStats.projects} ${counter}`;
      }
      isOn = true;
      isError = true;
      Action = (
        <>
          <BaseAction
            onClick={(e) => onRetryPeopleSyncClick(e)}
            disabled={!retryPeopleSyncButtonEnabled}
          >
            Retry Import
          </BaseAction>
          {EditAction}
        </>
      );
      Message = (
        <>
          <>
            {projectsImported && lastAction === 'import' && (
              <>
                {projectsImported} successfully imported{' '}
                {getTimeAgo(synced, true)}.
                <br />
                <br />
              </>
            )}
            {lastAction === 'sync' && (
              <>
                Connected to {props.label}
                <br />
                Last synced: {getTimeAgo(synced, true)}.<br />
                <br />
                <br />
              </>
            )}
          </>
          <span>
            Failed to import {labels.people || 'people'}. Plan cap reached.
          </span>
          <br />
          <span>Please upgrade your Float plan and retry.</span>
        </>
      );
    } else if (errors.API_UNAUTHORIZED || props.requiresReauth) {
      const loader = oauthPending;
      const disabled = loader || integration.oauthLinkLoadState === 'LOADING';
      isError = true;
      const buttonLabel = props.requiresReauth ? 'Reauthorize' : 'Reconnect';
      const messageText = props.requiresReauth
        ? `${props.label} has made internal changes to their login system. Please reauthorized your account.`
        : `There was an authorization problem when connecting to ${props.label}.
          Please reconnect.`;
      Action = (
        <BaseAction
          onClick={(e) => onConnectAccountClick(e)}
          disabled={disabled}
        >
          {loader ? 'Connecting...' : buttonLabel}
        </BaseAction>
      );
      Message = <span>{messageText}</span>;
    } else if (status === 1) {
      const loader = integration.preloadLoadState === 'LOADING';
      Action = (
        <BaseAction onClick={onEditClick} disabled={loader} loader={loader}>
          {loader ? 'Loading...' : 'Continue Setup'}
        </BaseAction>
      );
      Message = <span>Finish setting up your {props.label} integration</span>;
    } else if (lastAction === 'import' && status === 3) {
      const counts = [];
      if (isNumber(importStats.projects)) {
        const counter =
          importStats.projects === 1
            ? labels.project || 'project'
            : labels.projects || 'projects';
        counts.push(`${importStats.projects} ${counter}`);
      }
      if (isNumber(importStats.people)) {
        const counter =
          importStats.people === 1
            ? labels.person || 'person'
            : labels.people || 'people';
        counts.push(`${importStats.people} ${counter}`);
      }

      isOn = true;
      // using default Action
      Message = (
        <span>
          {counts.join(' and ')} successfully imported{' '}
          {getTimeAgo(synced, true)}.
        </span>
      );
    } else if (synced) {
      // lastAction === 'sync'
      isOn = true;
      // using default Action
      Message = <span>Last synced: {getTimeAgo(synced, true)}.</span>;
    }
  } else if (integration.oauthLink || authenticationError) {
    const loader =
      oauthPending ||
      addCoIntLoadState === 'LOADING' ||
      preloadLoadState === 'LOADING' ||
      initialImportStarted;
    const disabled = loader || integration.oauthLinkLoadState === 'LOADING';
    Action = (
      <BaseAction disabled={disabled} loader={loader}>
        {loader ? 'Connecting...' : 'Connect'}
      </BaseAction>
    );
    if (authenticationError) {
      isError = true;
      Message = <span>{props.authenticationErrorMessage}</span>;
    }
  }

  return (
    <Subsection>
      <SubsectionLogo>{props.logo}</SubsectionLogo>
      <SubsectionContent>
        <P>{props.children}</P>
        {(Message || props.additionalMessage) && (
          <SubsectionMessage isError={isError}>
            {props.additionalMessage}
            {Message}
          </SubsectionMessage>
        )}
      </SubsectionContent>
      <SubsectionStatus>
        <OnOffStatus
          isOn={isOn}
          isError={isError}
          customText={
            (isReauth && 'Warning') ||
            (!!isError && 'Error') ||
            (isSyncingStatus && 'Syncing')
          }
        />
      </SubsectionStatus>
      <SubsectionAction>{Action}</SubsectionAction>
      {showConnectLimit && (
        <ConnectLimitModal onClose={() => setShowConnectLimit(false)} />
      )}
    </Subsection>
  );
}

export default withConfirm(SharedSubsection);
