import React from 'react';
import { t } from '@lingui/macro';

import { getProjectTaskMetas } from '@float/common/selectors/taskMetas';
import { useAppSelectorStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { PanelType } from '@float/web/sidePanel/types';
import { useSidePanel } from '@float/web/sidePanel/useSidePanel';

import { getDefaultProjectName } from './helpers/mapToAsyncProjectApiPayload';
import { parseProjectFormDataBeforeSave } from './helpers/parseProjectFormDataBeforeSave';
import { useGetProjectData } from './hooks/useGetProjectData';
import { useHasChangedOnce } from './hooks/useHasChangedOnce';
import { useMilestoneSave } from './hooks/useMilestoneSave';
import { usePhaseSave } from './hooks/usePhaseSave';
import { useProjectFormValues } from './hooks/useProjectFormValues';
import { useProjectSave } from './hooks/useProjectSave';
import { useSidePanelSnackbar } from './hooks/useSidePanelSnackbar';
import { useTaskSave } from './hooks/useTaskSave';
import { ProjectPanelView } from './ProjectPanelView';
import { ProjectEditData, ProjectFormData } from './types';

export const ProjectPanelController: React.FC = () => {
  const { sidePanelOpen, hideSidePanel, showProjectSidePanel, panelPayload } =
    useSidePanel<ProjectEditData>(PanelType.ProjectPanel);
  const { projectId, templateId } = panelPayload || {};
  const { showSnackbar, showSuccessSnackbar } = useSidePanelSnackbar();

  const { data, isLoading } = useGetProjectData(panelPayload);
  const values = useProjectFormValues(data, panelPayload);
  const taskMetas = useAppSelectorStrict((state) =>
    getProjectTaskMetas(state, projectId),
  );

  const projectSave = useProjectSave();
  const phaseSave = usePhaseSave();
  const taskSave = useTaskSave();
  const milestoneSave = useMilestoneSave();

  const isEditing = Boolean(projectId);

  /*
    It is necessary to inject RHF's `defaultValues` here instead of using
    `values` from above because RHF's `defaultValues` could get updated using
    `reset` or `resetField` in workflows such as opening the phase panel from
    within project panel and adding/updating phases. These kind of changes need
    to reflect in project panel, and not as dirty fields, since already saved.
  */
  async function handleSubmit(
    currentValues: ProjectFormData,
    defaultValues: ProjectFormData,
  ) {
    const { project, tasks, milestones } = currentValues;
    const { phases, team } = parseProjectFormDataBeforeSave(currentValues);
    const projectName = project.project_name || getDefaultProjectName();
    if (!projectId) {
      try {
        if (featureFlags.isFeatureEnabled(FeatureFlag.CreateLayeredProject)) {
          const processId = await projectSave.handleCreateAsync({
            project,
            team,
            phases,
            milestones,
            tasks,
          });
          showSnackbar(t`Creating “${projectName}”`, {
            id: processId,
            loader: true,
            persist: true,
          });
          panelPayload?.afterSave?.(processId);
          return;
        }

        const projectId = await projectSave.handleCreate({
          project,
          team,
          options: { fromTemplate: templateId },
        });

        await Promise.all([
          phaseSave.handleBulkUpsert(projectId, phases, team),
          milestoneSave.handleBulkMilestonesUpsert(milestones, projectId),
          taskSave.handleBulkTasksUpsert(tasks, projectId),
        ]);

        panelPayload?.afterSave?.(projectId);

        showSuccessSnackbar(t`“${projectName}” added`, {
          onLinkClick: () => {
            showProjectSidePanel({
              ...panelPayload,
              templateId: undefined,
              projectId,
            });
          },
        });
      } catch (err) {
        showSnackbar(t`“${projectName}” creation failed`);
        throw err;
      }
    } else {
      try {
        await Promise.all([
          projectSave
            .handleUpdate({
              projectId,
              update: { project, team },
              currentValues: values,
            })
            // Some updates on Phase are possible only after the project update
            .then(() =>
              phaseSave.handleBulkUpdate(
                projectId,
                phases,
                defaultValues.phases,
                team,
              ),
            ),
          taskSave.handleUpdate(tasks, values.tasks, projectId),
          milestoneSave.handleUpdate(milestones, values.milestones, projectId),
        ]);

        showSuccessSnackbar(t`“${projectName}” updated`, {
          onLinkClick: () => {
            showProjectSidePanel(panelPayload);
          },
        });
      } catch (err) {
        showSnackbar(t`“${projectName}” update failed`);
        throw err;
      }
    }
  }

  const idHasChanged = useHasChangedOnce(projectId || templateId);
  // The key needs to be derived from the computed form values and not from the
  // panelPayload. Reason is that when navigating between projects (without closing side panel),
  // there is a path that shows old project data, until the new data loads. So `values.projectId`
  // can be momentarily old while `ids.projectId` is new. We want our view to remount when the
  // actual data is ready to be shown so that the form state is reset with the right values.
  const key = values.projectId || templateId || 'newProject';

  return (
    <ProjectPanelView
      key={key} // Reset the component state when navigating projects
      animate={!idHasChanged || !sidePanelOpen} // We don't want to animate when navigating projects
      isOpen={sidePanelOpen}
      isEditing={isEditing}
      taskMetas={taskMetas}
      isLoading={isLoading}
      onClose={hideSidePanel}
      values={values}
      onSubmit={handleSubmit}
      openSections={panelPayload?.openSections}
    />
  );
};
