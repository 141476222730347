import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconChevronDropdown = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.25 10.5 12 15.75 6.75 10.5"
    />
  </SVGIcon>
);
