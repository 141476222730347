import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

export const IconStamp = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.75 14.75a3 3 0 0 1 3-3h6.5a3 3 0 0 1 3 3v1.5H5.75v-1.5ZM10 11.5 8.969 5.932a1 1 0 0 1 .983-1.182h4.096a1 1 0 0 1 .983 1.182L14 11.5m-9.25 7.75h14.5"
    />
  </SVGIcon>
);
