import styled from 'styled-components';

import { getTimeAgoCompact } from '@float/common/lib/time';
import { moment } from '@float/libs/moment';

const Wrapper = styled.span`
  font-size: 11px;
  line-height: 1.38;
  letter-spacing: -0.19px;
  color: ${(p) => p.theme.blueGrey};
`;

export default function TimeAgoWrapper({ timestamp }) {
  const timeAgo = getTimeAgoCompact(timestamp);
  const timeTitle = moment(timestamp).format('MMM DD YYYY [at] h:mma');

  return <Wrapper title={timeTitle}>{timeAgo}</Wrapper>;
}
