import { accumulateDecimalHours } from '@float/common/lib/timer/accumulateDecimalHours';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getFilteredEntities } from '@float/common/search/selectors/filteredEntities';
import { selectDatesManager } from '@float/common/selectors/currentUser';
import { getMilestones } from '@float/common/selectors/milestones';
import { getPhasesMapRaw } from '@float/common/selectors/phases';
import { selectIsWorkDayGetter } from '@float/common/selectors/schedule/isWorkDay';
import { forEachEntityDate } from '@float/libs/datesRepeated/forEachEntityDate';
import { getAreDatesOverlapping } from '@float/libs/datesRepeated/getAreDatesOverlapping';
import { passthrough } from '@float/libs/utils/noop';
import { Person } from '@float/types/person';
import { ScheduleRowList } from '@float/types/rows';

import { getTimeRangePhaseIdsByPerson } from '../insights/selectors/getTimeRangePhaseIdsByPerson';
import { getTimeRangeTasksByPerson } from '../insights/selectors/getTimeRangeTasksByPerson';
import {
  addHoursWithDecimalHoursAccumulation,
  MilestonesEntry,
} from './helpers';
import { ProjectsBreakdownData } from './types';

/**
 * Traverse the schedule rows in order to create the base data structure
 * for the Project Plan CSV export
 */
export function getProjectsInitialBreakdownData(rows: ScheduleRowList) {
  const data: ProjectsBreakdownData = {
    projectOrder: [],
    projects: {},
  };

  const people: Record<number, Person> = {};

  for (const row of rows) {
    if (row.type === 'project') {
      const project = row.data;

      data.projectOrder.push(project.project_id);

      data.projects[project.project_id] = {
        id: project.project_id,
        name: project.project_name,
        client: project.client_name === 'No Client' ? '' : project.client_name,
        peopleOrder: [],
        people: {},
        totals: {},
      };
    } else if ('projectId' in row) {
      const project = data.projects[row.projectId];
      const person = row.data;
      project.peopleOrder.push(person.people_id);
      project.people[person.people_id] = {
        id: person.people_id,
        name: person.name,
        jobTitle: person.job_title,
        department: (person.department && person.department.name) || '',
        tasks: {},
      };
      people[person.people_id] = person;
    }
  }

  return { data, people: Object.values(people) };
}

const getTaskKey = (name: string, notes: string, phaseId: number | null) =>
  `${name}:${notes}:${phaseId || ''}`;

export function getProjectsScheduleData(payload: {
  rows: ScheduleRowList;
  state: ReduxStateStrict;
  startDate: string;
  endDate: string;
  getDateAggregationIndex?: (date: string) => string;
}) {
  const {
    startDate,
    state,
    endDate,
    rows,
    getDateAggregationIndex = passthrough,
  } = payload;
  const dates = selectDatesManager(state);
  const filteredEntities = getFilteredEntities(state);
  const getIsWorkDay = selectIsWorkDayGetter(state);
  const phases = getPhasesMapRaw(state);

  const tasksByPerson = getTimeRangeTasksByPerson(state, {
    endDate,
    startDate,
  });

  const phaseIdsByPerson = getTimeRangePhaseIdsByPerson(state, {
    endDate,
    startDate,
  });

  const start = dates.toNum(startDate);
  const end = dates.toNum(endDate);

  const { data, people } = getProjectsInitialBreakdownData(rows);

  for (const person of people) {
    const workDaysInRange = new Set<string>();
    const phaseIds =
      phaseIdsByPerson.get(person.people_id) || new Set<number>();

    // Collect all the work days inside of the selected range
    // to exclude all the allocations that are either on non-working days
    // or outside the range
    for (let d = start; d <= end; d++) {
      const date = dates.fromNum(d);

      if (getIsWorkDay(person, date)) {
        workDaysInRange.add(date);
      }
    }

    for (const entity of tasksByPerson.get(person.people_id) || []) {
      if (!filteredEntities.task.has(entity.task_id)) continue;

      // If the task project is not part of the schedule rows, skip the allocation
      const project = data.projects[entity.project_id];
      if (!project) continue;

      // If the person is not currently listed as project member (due to filters or the project group not expanded)
      // skip the allocation
      const projectMember = project.people[person.people_id];
      if (!projectMember) continue;

      forEachEntityDate(dates, entity, (dateNum) => {
        const date = dates.fromNum(dateNum);

        // Check if is outside range or if is a non-work day
        if (!workDaysInRange.has(date)) return;

        const phaseId = entity.phase_id;

        // Track the allocation to the phase to exclude it from the next loop
        if (phaseId) phaseIds.delete(phaseId);

        const key = getTaskKey(
          entity.name,
          entity.notes || '',
          entity.phase_id,
        );
        if (!projectMember.tasks[key]) {
          projectMember.tasks[key] = {
            phase: (phaseId && phases[phaseId]?.phase_name) || 'No phase',
            name: entity.name,
            notes: entity.notes,
            dateData: {},
          };
        }

        // Calculate the total hours for the target entry
        const index = getDateAggregationIndex(date);

        if (!project.totals[index]) project.totals[index] = 0;

        addHoursWithDecimalHoursAccumulation(
          projectMember.tasks[key],
          index,
          entity.hours,
        );
        project.totals[index] = accumulateDecimalHours(
          project.totals[index],
          entity.hours,
        );
      });
    }

    // Add the phases that are part of the time range but have no related allocations
    for (const phaseId of phaseIds) {
      const phase = phases[phaseId];
      if (!phase) continue;

      const key = getTaskKey('', '', phaseId);
      const project = data.projects[phase.project_id];

      const projectMember = project.people[person.people_id];
      if (!projectMember) continue;

      if (!projectMember.tasks[key]) {
        projectMember.tasks[key] = {
          phase: phase.phase_name,
          name: '',
          notes: '',
          dateData: {},
        };
      }
    }
  }

  return data;
}

export function getMilestonesEntries(payload: {
  state: ReduxStateStrict;
  startDate: string;
  endDate: string;
  data: ProjectsBreakdownData;
}) {
  const { data, state, endDate, startDate } = payload;

  const milestones: MilestonesEntry[] = [];

  for (const entity of getMilestones(state)) {
    if (
      !getAreDatesOverlapping(entity.date, entity.end_date, startDate, endDate)
    ) {
      continue;
    }

    if (!data.projects[entity.project_id]) continue;

    milestones.push({
      name: entity.name,
      project_id: entity.project_id,
      start_date: entity.date,
      end_date: entity.end_date,
    });
  }

  milestones.sort((a, b) => {
    if (a.start_date !== b.start_date) {
      return String(a.start_date).localeCompare(b.start_date);
    }

    if (a.end_date !== b.end_date) {
      return -1 * String(a.end_date).localeCompare(b.end_date);
    }

    return String(a.name)
      .toLowerCase()
      .localeCompare(String(b.name).toLowerCase());
  });

  return milestones;
}
