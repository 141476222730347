import { useCallback } from 'react';
import { addDays, differenceInCalendarDays, format } from 'date-fns';

import { selectPersonById } from '@float/common/selectors/people/people';
import { selectEntityWorkDaysDurationGetter } from '@float/common/selectors/schedule/getEntityWorkDaysDuration';
import { selectMinWorkHoursInRangeGetter } from '@float/common/selectors/schedule/getMinWorkHoursInRange';
import { selectIsWorkDayGetter } from '@float/common/selectors/schedule/isWorkDay';
import {
  useAppSelectorStrict,
  useAppSelectorWithParams,
} from '@float/common/store';
import { DEFAULT_DATESTRING_FORMAT_DATE_FNS } from '@float/constants/dates';

export type UseAllocationDaysHelpersParams = {
  peopleIds: number[];
  timeoffId?: number;
};

export const useAllocationDaysHelpers = ({
  peopleIds,
  timeoffId,
}: UseAllocationDaysHelpersParams) => {
  const person = useAppSelectorWithParams(selectPersonById, peopleIds[0]);

  const getMinimumWorkHoursInRange = useAppSelectorStrict(
    selectMinWorkHoursInRangeGetter,
  );

  const getEntityWorkDaysDurationWithParams = useAppSelectorStrict(
    selectEntityWorkDaysDurationGetter,
  );

  const getIsWorkDayWithParams = useAppSelectorStrict(selectIsWorkDayGetter);

  const getEntityWorkDaysDuration = useCallback(
    ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
      return getEntityWorkDaysDurationWithParams(
        {
          start_date: format(startDate, DEFAULT_DATESTRING_FORMAT_DATE_FNS),
          end_date: format(endDate, DEFAULT_DATESTRING_FORMAT_DATE_FNS),
          timeoff_id: timeoffId,
        },
        person,
      );
    },
    [person, timeoffId, getEntityWorkDaysDurationWithParams],
  );

  const getIsWorkDay = useCallback(
    (date: Date) => {
      const isPersonSelected = Boolean(person);

      if (!isPersonSelected) return false;

      return getIsWorkDayWithParams(
        person,
        format(date, DEFAULT_DATESTRING_FORMAT_DATE_FNS),
        timeoffId,
      );
    },
    [person, timeoffId, getIsWorkDayWithParams],
  );

  // This function calculates number of allocation days (including non-working days if needed)
  // Number of allocation days represents the number of days to which the allocation would be done
  // In some cases the allocation migh be scheduled on a non-working days,
  // so better calling it as a numberOfAllocationDays
  const getNumberOfAllocationDays = useCallback(
    (startDate: Date, endDate: Date) => {
      const isPersonSelected = Boolean(person);

      // There is no person selected, return the difference between dates
      if (!isPersonSelected)
        return differenceInCalendarDays(endDate, startDate) + 1;

      let numberOfAllocationDaysInDateRange = getEntityWorkDaysDuration({
        startDate,
        endDate,
      });

      if (timeoffId) {
        return numberOfAllocationDaysInDateRange;
      }

      // When there is an allocation we migth add extra days if they were scheduled on non working days
      const startDateFormattedToDateString = format(
        startDate,
        DEFAULT_DATESTRING_FORMAT_DATE_FNS,
      );
      const endDateFormattedToDateString = format(
        endDate,
        DEFAULT_DATESTRING_FORMAT_DATE_FNS,
      );

      const isStartDateAWorkDay = isPersonSelected && getIsWorkDay(startDate);

      const isEndDateAWorkDay = isPersonSelected && getIsWorkDay(endDate);

      const isStartingAndEndingOnTheSameDate =
        startDateFormattedToDateString === endDateFormattedToDateString;

      // Doesn't start from a work day, add an extra day
      // This scenario is possible if the user initiates the scheduling from a non-work day
      if (!isStartDateAWorkDay)
        numberOfAllocationDaysInDateRange =
          numberOfAllocationDaysInDateRange + 1;

      // Doesn't end on a work day, add an extra day
      // This scenario is possible if the user initiates the scheduling from a non-work day
      if (!isEndDateAWorkDay && !isStartingAndEndingOnTheSameDate)
        numberOfAllocationDaysInDateRange =
          numberOfAllocationDaysInDateRange + 1;

      return numberOfAllocationDaysInDateRange;
    },
    [person, timeoffId, getIsWorkDay, getEntityWorkDaysDuration],
  );

  const getEndDateFromTotalHours = useCallback(
    ({
      startDate,
      hoursPerDay,
      hoursTotal,
    }: {
      startDate: Date;
      hoursPerDay: number;
      hoursTotal: number;
    }) => {
      const numberOfAllocationDaysRequired = Math.floor(
        hoursTotal / hoursPerDay,
      );

      let endDate = startDate;
      let length = 1;

      while (numberOfAllocationDaysRequired - length > 0) {
        endDate = addDays(endDate, 1);

        if (getIsWorkDay(endDate)) {
          length += 1;
        }
      }

      return endDate;
    },
    [getIsWorkDay],
  );

  return {
    getIsWorkDay,
    getNumberOfAllocationDays,
    getEndDateFromTotalHours,
    getMinimumWorkHoursInRange,
  };
};
