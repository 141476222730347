import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { mapPhaseToPhaseRecord } from '@float/common/actions/phases/phases.helpers';
import { useAppSelectorWithParams } from '@float/common/store';
import { Phase } from '@float/types/phase';
import { getPhaseById } from '@float/web/selectors';

import { PhaseFieldName } from '../fields/PhaseDatesField.hooks';

// Phase dates are already watched via useUpdateDates in PhaseDatesField.hooks.ts
type PhaseWatchField = keyof Pick<
  Phase,
  'active' | 'budget_total' | 'color' | 'phase_name'
>;

/**
 * Custom hook for live updating phase rows in the project side panel.
 * Introduced to support the "multi-side panel" workflow of opening
 * the project side panel, then drilling down into a single phase side panel,
 * saving changes to that phase, and then coming back to the (previously opened)
 * project side panel.
 * @param name - The root phase key in RHF, either `phase` or `phases.{number}`.
 * @param phaseId - The ID of the phase to live update.
 */
export const usePhaseLiveUpdate = (name: PhaseFieldName, phaseId?: number) => {
  const { getValues, resetField } = useFormContext();
  const phase = useAppSelectorWithParams(getPhaseById, phaseId ?? undefined);

  const updateField = (field: PhaseWatchField) => {
    if (!phase || phase[field] === undefined) return;

    const phaseRecord = mapPhaseToPhaseRecord(phase);
    const formFieldKey = `${name}.${field}`;
    const currentFormFieldValue = getValues(formFieldKey);
    const newFormFieldValue = phaseRecord[field];

    if (newFormFieldValue === currentFormFieldValue) return;
    resetField(formFieldKey, {
      defaultValue: newFormFieldValue,
    });
  };

  // This function creates a useEffect hook for a specific field.
  // The effect will only run when the specified field changes.
  const useFieldUpdateEffect = (field: PhaseWatchField) => {
    useEffect(() => {
      updateField(field);
      // We only want to update when the `phase[field]` changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phase?.[field]]);
  };

  // Using individual effect for each field to ensure we only update the
  // single form field (i.e. invoke setValue for a field) if the underlying
  // store value has changed for that field. This way, dirty form fields
  // that haven't changed in the store don't get overwritten.
  useFieldUpdateEffect('active');
  useFieldUpdateEffect('budget_total');
  useFieldUpdateEffect('color');
  useFieldUpdateEffect('phase_name');
};
