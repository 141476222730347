import styled from 'styled-components';

const Text = styled.span`
  display: inline-block;
  color: ${(p) => p.theme.charcoalGrey};
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 260px;
  margin-top: 10px;
  white-space: nowrap;
`;

const itemTypeNotesFieldMap = {
  task: () => 'task_notes',
  timeoff: (item) =>
    item.after_data?.status === 2 || item.after_data?.status === -1
      ? 'status_note'
      : 'timeoff_notes',
  project: () => 'project_desc',
  phase: () => 'notes',
};

export default function ActivityItemMention({ item }) {
  const reference = itemTypeNotesFieldMap[item.item_type]?.(item);
  const text = reference && item.after_data?.[reference];
  const metadata = item.after_data?.notes_meta;
  const ignoreMetaData =
    item.item_type === 'timeoff' && item.after_data?.status === -1;

  const hasMention = !!text && !!(metadata || ignoreMetaData);

  return hasMention && <Text>{text}</Text>;
}
